import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ItemDetails } from 'src/app/datamodels/groupedmodel/itemDetails';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { SellerDetails } from 'src/app/datamodels/groupedmodel/sellerDetails';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { MatTableDataSource } from '@angular/material/table';
import { HandlereturnService } from 'src/app/services/handlereturn.service';
import { ReturnModel } from 'src/app/datamodels/returnOrders/returnDetailsModel';


@Component({
  selector: 'app-view-all-return-orders',
  templateUrl: './view-all-return-orders.component.html',
  styleUrls: ['./view-all-return-orders.component.css']
})
export class ViewAllReturnOrdersComponent implements OnInit {

  userReturnOrders: ReturnModel[] = []
  userIdPurchaseOrders: PurchaseOrderModel[];
  returnVersionsAvailableForPO: boolean = false;
  constructor(
    private authService: AuthService,
    private hrs: HandlereturnService,
    private hpo: HandlePurchaseOrderService
  ) { }

  private loginUserInfo: any
  private currentUserId: string
  purchaseOrderID: string;
  isLoading: boolean = false
  sellerTypePurchaseOrders: PurchaseOrderModel[] = []
  buyerTypePurchaseOrders: PurchaseOrderModel[] = []
  sellerTypeDataSource: any
  buyerTypeDataSource: any
  itemDetailsinPO: ItemDetails[] = [];
  sellerDetails: SellerDetails[] = [];
  sellerAccountType: boolean = false
  buyerAccountType: boolean = false
  gstDetailsPending: boolean = false;
  gstVerificationPending: boolean = false;
  fetchAccountRETsForAccountType: string;
  displayedSellerTypeTableColumns: string[] = []
  displayedBuyerTypeTableColumns: string[] = []
  isSellerCheckingPOtypeAsBuyer: boolean = false
  showCompletedOrdersOnly: boolean = false
  currentUrl: string;
  preserveBuyerTypeData: PurchaseOrderModel[] = []
  preseveSellerTypeData: PurchaseOrderModel[] = []
  isSellerCheckingRETURNAsBuyer: boolean = false
  sellerAsSellerSearch: string = ''
  isPOSavailable: boolean = false;

  ngOnInit(): void {
    this.isLoading = true;
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.fetchAccountRETsForAccountType = this.loginUserInfo.accounttype
    // console.log('un-parsedlogDetails', this.loginUserInfo)
    this.loginUserInfo.accounttype === 'Seller' ? this.sellerAccountType = true : this.buyerAccountType = true
    if (this.loginUserInfo.GST_Id === '') {
      this.gstDetailsPending = true
    }
    if (this.loginUserInfo.isGSTVerified === false) {
      this.gstVerificationPending = true
    }

    this.getAllReturnOrders(this.currentUserId, this.fetchAccountRETsForAccountType)
  }

  getAllReturnOrders(currentUserId: string, loginAccounttype: string) {
    try {
      this.hpo.getAllUserPurchaseOrders(currentUserId, loginAccounttype).subscribe(
        userFetchedPOs => {
          this.isLoading = false;
          if (userFetchedPOs && userFetchedPOs.length > 0) {
            this.isPOSavailable = true
            this.userIdPurchaseOrders = userFetchedPOs.filter(eachPO => { return eachPO.returnVersions.length > 0 })
            if (this.userIdPurchaseOrders) {
              this.returnVersionsAvailableForPO = true
              // from backend we have fetched and filtered all purchase orders where this user account 
              // is either present in as buyerId in buyerDetails or sellerId in sellerDetails

              // now we are dissecting them to check - 
              //1 sellerTypePurchaseOrders , sellerDataSource -where this userId is selling[ this is 1stSeller and is selling to buyer] - creates invoices and listing
              // buyerTypePurchaseOrders , buyerDataSource -where this userId is buying[ this is 1stSeller/orBuyer and has to pay for invoice of items purchased from other seller]
              this.sellerTypePurchaseOrders = this.userIdPurchaseOrders.filter(eachPO => { return eachPO.sellerDetails._id === this.currentUserId })
              this.buyerTypePurchaseOrders = this.userIdPurchaseOrders.filter(eachPO => { return eachPO.buyerDetails.buyerId === this.currentUserId })
              // buyerTypeDatasource is table where this user has placed an order from / 
              // or is buying so he wants to see will see invoice orders that other Sellers created for thisuser
              this.buyerTypeDataSource = this.buyerTypePurchaseOrders
              this.preserveBuyerTypeData = this.buyerTypeDataSource
              // sellerTypeDataSource is table where other ThisUser/sellerHasCreatedInvoiceOrders
              this.sellerTypeDataSource = this.sellerTypePurchaseOrders
              this.preseveSellerTypeData = this.preseveSellerTypeData
            }
          }
        }
      )
    }
    catch (e) { }
  }


  applySellerAccountSellerTypeFilter(event: Event) {
    try {
      const filterValue = (event.target as HTMLInputElement).value;
      this.sellerTypeDataSource.filter = filterValue.trim().toLowerCase();
    }
    catch (e) {
    }
  }
  applySellerAccountBuyerTypeFilter(event: Event) {
    try {
      const filterValue = (event.target as HTMLInputElement).value;
      this.buyerTypeDataSource.filter = filterValue.trim().toLowerCase();
    }
    catch (e) {
    }
  }
  applyBuyerAccountBuyerTypeFilter(event: Event) {
    try {
      const filterValue = (event.target as HTMLInputElement).value;
      this.buyerTypeDataSource.filter = filterValue.trim().toLowerCase();
    }
    catch (e) {
    }
  }

}
