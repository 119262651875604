<div class="chatBox" (click)="openBottomSheet()">
  <div class="loop-wrapper">
    <div class="truck"></div>
  </div>
</div>
<footer class="footer">
  <div class="aboutApp">
    <p>Bulk Procurement Made Easy</p>
    <p>Manufacuters - Wholesalers - Distributors - Retailers</p>
    <div class="icons">
      <div class="singleIcon">
        <mat-icon>favorite</mat-icon>
        <p>Quality Products</p>
      </div>
      <div class="singleIcon">
        <mat-icon>supervisor_account</mat-icon>
        <p>Verified Suppliers</p>
      </div>
      <div class="singleIcon">
        <mat-icon> local_shipping</mat-icon>
        <p>Vaayu Delivery</p>
      </div>
      <div class="singleIcon">
        <mat-icon>store</mat-icon>
        <p> Bulk Orders</p>
      </div>
      <div class="singleIcon">
        <mat-icon>assessment</mat-icon>
        <p>GST & Accounting Kit </p>
      </div>
    </div>
    <div>
      <p>
        And Much More <mat-icon>done_all</mat-icon>
      </p>
      <p class="highlight">
        Karo Vyapaar only on Myapaar
      </p>
    </div>
  </div>

</footer>

<div class="col5">
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="footer-col">
          <h4 class="headingFooter">Myapaar</h4>
          <div class="quickLinks">
            <a routerLink="/AboutMyapaar" mat-stroked-button class="quickLinksButtons about"> About Us</a>
            <a mat-stroked-button target="_blank" rel="noopener" href="https://allindusroots.in/"
              class="quickLinksButtons allindusroots"> All Indus Roots</a>
            <a routerLink="/Blogs/Published" mat-stroked-button class="quickLinksButtons">Blogs</a>
            <a routerLink="/FAQs" mat-stroked-button class="quickLinksButtons faqs"> FAQs</a>
            <!-- <a mat-stroked-button routerLink="/BecomePartner" class="footerNote partner"> Partnership</a>
            <a mat-stroked-button class="footerNote subscribesavings">Savings</a> -->
            <a mat-stroked-button class="quickLinksButtons email" target="_blank" rel="noopener"
              href="mailto:solutions@myapaar.com">Contact</a>
            <!-- <a routerLink="/TermsToAgree" mat-stroked-button class="tnc">Terms Conditions</a> -->
            <a routerLink="/TermsToAgree" mat-stroked-button class="quickLinksButtons">Terms Conditions</a>
          </div>
        </div>
        <div class="footer-col">
          <h4 class="headingFooter disclaimer">Note</h4>
          <div>
            <p class="footerNote">
              Myapaar app registration for Merchants is free until December 2025 with no hidden fees. We only request
              GST ID, PAN ID, and
              Company Address for KYC. Beware of unauthorized solicitations demanding payments. Trust and security are
              our
              priorities.
            </p>
          </div>
        </div>
        <div class="footer-col">
          <h4 class="headingFooter">follow us</h4>
          <div class="footerNote">Copyright © 2024
            <span> Myapaar</span>, platform of
            <span>All Indus Roots LLP</span> <br>
            <span> All
              rights
              reserved.</span> <br>
          </div>

          <div class="social-links">
            <div class="fb">
              <a class="circle-link" title="facebook" href="https://www.facebook.com/profile.php?id=61553258164931"
                target="_blank" rel="noopener">
                <img id="fbmypr" alt="facebook myapaar" src="../../../assets/svgiconsandimages/facebook.svg"
                  alt="Myapaar facebook platform" style="width:100%;">
              </a>
            </div>
            <div class="ln">
              <a class="circle-link" title="linkedin" href="https://www.linkedin.com/in/myapaar-co-a9169429b/"
                target="_blank" rel="noopener">
                <img id="linmypr" alt="linkedin myapaar" src="../../../assets/svgiconsandimages/linkedin.svg"
                  alt="Myapaar linkedin platform" style="width:100%;">
              </a>
            </div>
            <div class="ig">
              <a class="circle-link" title="instagram" href="https://www.instagram.com/myapaar_co/" target="_blank"
                rel="noopener">
                <img id="IGmypr" alt="instagram myapaar" src="../../../assets/svgiconsandimages/instagram.svg"
                  alt="Myapaar instagram platform" style="width:91%;">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>