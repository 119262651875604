import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidationErrors, AbstractControl } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { AccountType } from 'src/app/datamodels/accountTypeModel';
import { AllstatesService } from 'src/app/services/allstates.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MypseoService } from 'src/app/services/mypseo.service';
interface selectAccount {
  id: number,
  accountTypeName: string,
  accountTypeIMG: string,
  accountSubType: string,
  checked: boolean
}
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})

export class SignupComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  private authListenerSubs: Subscription;
  hide = true;
  viralityReferral: string
  allComplete: boolean = false;
  atleastOneItemSelected: boolean = false;

  // validationMessages: Validations
  // formErrors: Validations
  selectedAccountsCards: selectAccount[] = []
  accountTypes: selectAccount[] = [
    { id: 1, checked: false, accountTypeName: "Seller", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/1-myp-fpo.png', accountSubType: "Producer" },
    { id: 2, checked: false, accountTypeName: "Seller", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/2-myp-manuf.png', accountSubType: "Manufacturer" },
    { id: 3, checked: false, accountTypeName: "Seller", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/3-myp-agroprocess.png', accountSubType: "Agro-Processor" },
    { id: 5, checked: false, accountTypeName: "Seller", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/4-myp-wholesale.png', accountSubType: "Wholesaler" },
    { id: 6, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/5-myp-hotel.png', accountSubType: "Hotel" },
    { id: 7, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/6-myp-rest.png', accountSubType: "Restaurant" },
    { id: 8, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/7-myp-cafe.png', accountSubType: "Cafe" },
    { id: 9, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/8-cloudKitchen.png', accountSubType: "Cloud Kitchen" },
    { id: 10, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/9-supermart.png', accountSubType: "Retail Grocery Mart" },
    { id: 10, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/10-kirana.png', accountSubType: "Kirana Shop" },
  ]

  accountSubtypeList: string;
  pincodeFullInfo: any = [];
  pinLocalities
  localityName: string;
  cardSelected: any
  constructor(
    private stateService: AllstatesService,
    private router: Router,
    private mySeo: MypseoService,
    private _snackBar: MatSnackBar,
    public authService: AuthService) { }
  appBannerslandingproducts: string[] = [
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Signup/1.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Signup/2.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Signup/3.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Signup/4.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Signup/5.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Signup/6.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Signup/7.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Signup/8.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Signup/9.png",
  ]
  ngOnInit(): void {
    // console.info('this.accountTypes', this.accountTypes)
    this.mySeo.updateTitle('Ab Karo Vyapaar only on Myapaar | B2B Marketplace for Food and Agri Supplies')
    this.mySeo.updateDescription('Sign up now to access top-quality products, competitive pricing, reliable suppliers, and secure transactions. Suppliers can update inventory and reach buyers PAN India and globally for domestic and impex bulk supply.')
    this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(
      //we get auth status which is a boolean
      authStatus => {
        this.isLoading = false;
      }
    )
  }

 
  ngOnDestroy() {
    //prevent any data leak
    this.authListenerSubs.unsubscribe();
  }

}
