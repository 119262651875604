import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { RaiseDisputeModel } from 'src/app/datamodels/groupedmodel/raiseDisputeModel';
import { ReportProblemService } from 'src/app/services/report-problem.service';

@Component({
  selector: 'app-all-user-issues',
  templateUrl: './all-user-issues.component.html',
  styleUrls: ['./all-user-issues.component.css']
})
export class AllUserIssuesComponent implements OnInit {
  private loginUserInfo: any
  isLoading: boolean = false
  currentUserId: string;
  viewIssues: RaiseDisputeModel[] = []
  constructor(
    private authService: AuthService,
    private rps: ReportProblemService
  ) { }

  ngOnInit(): void {
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.loadAllMyIssues(this.currentUserId)
  }

  loadAllMyIssues(userID: string) {
    try {
      this.isLoading = true
      this.rps.getAllMyIssues(userID).then(allIssues => {
        this.isLoading = false
        if (allIssues && allIssues.status === 200) {
          this.viewIssues = allIssues.issues
        }
      })
    }
    catch (e) {
      throw e
    }
  }

}
