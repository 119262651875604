import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { SubscriptionModel } from '../datamodels/Subscription/subscriptionModel';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {
  private domain: string | undefined
  constructor(
    private http: HttpClient, private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }

  async getAllSubs(): Promise<{ subs: SubscriptionModel[] }> {
    const allSubs = await this.http.get<{ subs: SubscriptionModel[] }>(`${this.domain}api/subservice/getSubs`).toPromise()
    return allSubs
  }
  async newSubscription(selectedSub: SubscriptionModel, requestedUser: any): Promise<{ message: string, status: number }> {
    const requestSubs = new FormData()
    requestSubs.append('subnormalID', JSON.stringify(selectedSub.subnormalID))
    requestSubs.append('subName', selectedSub.subName)
    requestSubs.append('subPrice', JSON.stringify(selectedSub.subPrice))
    requestSubs.append('userId', requestedUser.userId)
    requestSubs.append('fullName', requestedUser.fullName)
    requestSubs.append('companyName', requestedUser.companyName)
    requestSubs.append('GST', requestedUser.GST)
    const newSubsRequest = await this.http.post<{ message: string, status: number }>(`${this.domain}api/subservice/newSubsRequest`, requestSubs).toPromise()
    return newSubsRequest
  }
}
