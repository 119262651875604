import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ItemDetails } from 'src/app/datamodels/groupedmodel/itemDetails';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { SellerDetails } from 'src/app/datamodels/groupedmodel/sellerDetails';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-view-all-purchase-orders',
  templateUrl: './view-all-purchase-orders.component.html',
  styleUrls: ['./view-all-purchase-orders.component.css']
})
export class ViewAllPurchaseOrdersComponent implements OnInit {
  private loginUserInfo: any
  private currentUserId: string
  purchaseOrderID: string;
  isLoading: boolean = false
  userIdPurchaseOrders: PurchaseOrderModel[] = []
  sellerTypePurchaseOrders: PurchaseOrderModel[] = []
  buyerTypePurchaseOrders: PurchaseOrderModel[] = []
  sellerTypeDataSource: any
  buyerTypeDataSource: any
  itemDetailsinPO: ItemDetails[] = [];
  sellerDetails: SellerDetails[] = [];
  sellerAccountType: boolean = false
  buyerAccountType: boolean = false
  gstDetailsPending: boolean = false;
  gstVerificationPending: boolean = false;
  fetchAccountPOsByAccountType: string;
  displayedSellerTypeTableColumns: string[] = []
  displayedBuyerTypeTableColumns: string[] = []
  isSellerCheckingPOtypeAsBuyer: boolean = false
  showCompletedOrdersOnly: boolean = false
  currentUrl: string;
  isPOSavailable: boolean = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private handlePurchaseOrder: HandlePurchaseOrderService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.fetchAccountPOsByAccountType = this.loginUserInfo.accounttype
    // console.log('un-parsedlogDetails', this.loginUserInfo)
    this.loginUserInfo.accounttype === 'Seller' ? this.sellerAccountType = true : this.buyerAccountType = true
    if (this.loginUserInfo.GST_Id === '') {
      this.gstDetailsPending = true
    }
    if (this.loginUserInfo.isGSTVerified === false) {
      this.gstVerificationPending = true
    }
    if (this.router.url.includes('Completed') && this.currentUserId && this.currentUserId !== '') {
      this.showCompletedOrdersOnly = true
      this.getCompletedUserPurchaseOrders(this.currentUserId, this.fetchAccountPOsByAccountType)
      return
    }
    this.getAllPurchaseOrders(this.currentUserId, this.fetchAccountPOsByAccountType)
  }

  getCompletedUserPurchaseOrders(currentUserId: string, loginAccounttype: string) {
    try {
      this.handlePurchaseOrder.getAllUserPurchaseOrders(currentUserId, loginAccounttype).subscribe(fetchedPOs => {
        this.isLoading = false;
        if (fetchedPOs && fetchedPOs.length > 0) {
          this.isPOSavailable = true
          this.userIdPurchaseOrders = fetchedPOs.filter(allPos => {
            return allPos.poStatus === 'Delivered' && allPos.invoiceStatus === 'Delivered'
          })
          // console.log('allPos', this.userIdPurchaseOrders)
          // from backend we have fetched and filtered all purchase orders where this user account 
          // is either present in as buyerId in buyerDetails or sellerId in sellerDetails

          // now we are dissecting them to check - 
          //1 sellerTypePurchaseOrders , sellerDataSource -where this userId is buying[ this is 1stSeller and is buying from 2ndSeller]
          //1 buyerTypePurchaseOrders , buyerDataSource -where this userId is selling[ this is 1stSeller and is other users want to buy from this accountuser]
          this.sellerTypePurchaseOrders = this.userIdPurchaseOrders.filter(eachPO => { return eachPO.sellerDetails._id === this.currentUserId })
          this.buyerTypePurchaseOrders = this.userIdPurchaseOrders.filter(eachPO => { return eachPO.buyerDetails.buyerId === this.currentUserId })
          // buyerTypeDatasource is table where this user has placed an order from / 
          // or is buying so he wants to see sellerInfo in PO , this1st seller is acting like a buyer towards 2ndSeller
          //  and so this user will be delivering and wants to see buyerInfo in PO
          this.displayedBuyerTypeTableColumns = ["pon", "status", "sellerName", "invoice_Status", "po_document"]
          this.buyerTypeDataSource = new MatTableDataSource(this.buyerTypePurchaseOrders)

          // sellerTypeDataSource is table where other userss have placed / rqeuestes an order from this 1stSeller so we show buyerInfo
          this.displayedSellerTypeTableColumns = ["pon", "status", "buyerName", "invoice_Status", "po_document"]
          this.sellerTypeDataSource = new MatTableDataSource(this.sellerTypePurchaseOrders)
        }
      })
    }
    catch (e) { }
  }

  getAllPurchaseOrders(currentUserId: string, loginAccounttype: string) {
    try {
      this.handlePurchaseOrder.getAllUserPurchaseOrders(currentUserId, loginAccounttype).subscribe(fetchedPOs => {
        if (fetchedPOs && fetchedPOs.length > 0) {
          this.isLoading = false;
          this.isPOSavailable = true
          this.userIdPurchaseOrders = fetchedPOs
          // console.log('allPos', this.userIdPurchaseOrders)
          // from backend we have fetched and filtered all purchase orders where this user account 
          // is either present in as buyerId in buyerDetails or sellerId in sellerDetails

          // now we are dissecting them to check - 
          //1 sellerTypePurchaseOrders , sellerDataSource -where this userId is buying[ this is 1stSeller and is buying from 2ndSeller]
          //1 buyerTypePurchaseOrders , buyerDataSource -where this userId is selling[ this is 1stSeller and is other users want to buy from this accountuser]
          this.sellerTypePurchaseOrders = this.userIdPurchaseOrders.filter(eachPO => { return eachPO.sellerDetails._id === this.currentUserId })
          this.buyerTypePurchaseOrders = this.userIdPurchaseOrders.filter(eachPO => { return eachPO.buyerDetails.buyerId === this.currentUserId })
          // buyerTypeDatasource is table where this user has placed an order from / 
          // or is buying so he wants to see sellerInfo in PO , this1st seller is acting like a buyer towards 2ndSeller
          //  and so this user will be delivering and wants to see buyerInfo in PO
          this.displayedBuyerTypeTableColumns = ["pon", "status", "sellerName", "invoice_Status", "po_document"]
          this.buyerTypeDataSource = new MatTableDataSource(this.buyerTypePurchaseOrders)

          // sellerTypeDataSource is table where other userss have placed / rqeuestes an order from this 1stSeller so we show buyerInfo
          this.displayedSellerTypeTableColumns = ["pon", "status", "buyerName", "invoice_Status", "po_document"]
          this.sellerTypeDataSource = new MatTableDataSource(this.sellerTypePurchaseOrders)
        }
        if (fetchedPOs && fetchedPOs.length === 0) {
          this.isLoading = false;
          this.isPOSavailable = false
        }
      })
    }
    catch (e) { }
  }

  applySellerAccountSellerTypeFilter(event: Event) {
    try {
      const filterValue = (event.target as HTMLInputElement).value;
      this.sellerTypeDataSource.filter = filterValue.trim().toLowerCase();
    }
    catch (e) {
    }
  }
  applySellerAccountBuyerTypeFilter(event: Event) {
    try {
      const filterValue = (event.target as HTMLInputElement).value;
      this.buyerTypeDataSource.filter = filterValue.trim().toLowerCase();
    }
    catch (e) {
    }
  }
  applyBuyerAccountBuyerTypeFilter(event: Event) {
    try {
      const filterValue = (event.target as HTMLInputElement).value;
      this.buyerTypeDataSource.filter = filterValue.trim().toLowerCase();
    }
    catch (e) {
    }
  }

}
