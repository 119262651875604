<div class="contentAbout">
    <div class="serviceCard part1">
        <div class="serviceBanner1">
            <div class="centered">
                <h1 class="heading">About Myapaar</h1>
            </div>
            <img src="https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/myapaar-aboutus-team.jpg"
                alt="Myapaar about platform" style="width:100%;">
        </div>
        <div class="contentAboutusteam">
            <div class="contentHeading">
                <h1 class="mainHead">
                    Leading B2B E-Commerce Platform.
                </h1>
                <h3 class="subHead">
                    You know Why ?
                </h3>
            </div>
            <div class="mainContent">
                <p>
                    The premier B2B platform revolutionizing bulk commerce - Buying and Selling, Audits and
                    Inventory Management for merchants in India.
                </p>
                <p>
                    At Myapaar, we empower sellers to
                    manage their inventory and <span class="highlight"> sell goods and products in bulk</span> to buyers
                    throughout India,
                    while offering buyers <span class="highlight">maximum savings</span> and convenience to
                    buy and procure the products needed for their business and operations.
                </p>
                <p>
                    <span class="highlight"> 4 cr. Merchants including Producers, Wholesalers, Distributers and
                        Retailers aim
                        to connect with us. </span>
                </p>
            </div>
        </div>
    </div>
    <div class="serviceCard part2">
        <div class="contentAboutusteam">
            <div class="contentHeading">
                <h1 class="mainHead">
                    Mobile se Vyapaar.
                </h1>
                <h3 class="subHead">
                    Agribusiness made easy
                </h3>
            </div>
            <div class="mainContent">
                <p>
                    Our mission at Myapaar is to streamline the bulk buying and selling process, providing a seamless
                    platform that fosters growth and prosperity for <span class="highlight">businesses of all
                        sizes.</span>
                </p>
                <p>
                    We strive to enhance
                    efficiency, reduce costs, and facilitate long-term partnerships between Merchants and Buyers and
                    establish an <span class="highlight">everlasting trust</span>.
                </p>
            </div>
        </div>
        <div class="serviceBanner2">
            <div class="centered">
                <h1 class="heading">Mission</h1>
            </div>
            <img src="https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/myapaar-aboutus-seller.jpg"
                alt="Myapaar about seller" style="width:100%;">
        </div>
    </div>
    <div class="serviceCard part3">
        <div class="serviceBanner3">
            <div class="centered">
                <h1 class="heading">Vision</h1>
            </div>
            <img src="https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/myapaar-aboutus-buyer.jpg"
                alt="myapaar about buyer" style="width:100%;">
        </div>
        <div class="contentAboutusteam">
            <div class="contentHeading">
                <h1 class="mainHead">
                    Karo Vyapaar only on Myapaar.
                </h1>
                <h3 class="subHead">
                    B2B Procurement and Sourcing
                </h3>
            </div>
            <div class="mainContent">
                <p>
                    We envision a future where all businesses can leverage our innovative platform to access a vast
                    network of opportunities, driving growth, prosperity, and sustainable commerce on a global scale.
                </p>
                <p>
                    Products from India have gained significant traction globally due to
                    their quality, uniqueness, and
                    affordability.
                    We aspire to redefine the way bulk transactions are conducted globally, and
                    become <span class="highlight"> India's most
                        loved
                        B2B procurement platform</span> where efficiency,
                    trust, and
                    success join hands.
                </p>
                <p>
                    Your business helping hand and your <span class="highlight"> Raw Material
                        Superstore.</span>
                </p>
            </div>
        </div>
    </div>
    <div class="serviceCard part4">
        <div class="contentAboutusteam2">
            <div class="servicesList">
                <div class="serv1 secServ">
                    <div class="imgSec im1">
                    </div>
                    <div class="descSec">
                        <p class="desc de1">
                            Buying and Selling in Bulk
                        </p>
                    </div>
                </div>
                <div class="serv2 secServ">
                    <div class="imgSec im2">
                    </div>
                    <div class="descSec">
                        <p class="desc de2">
                            Item Listings and Product Advertisement
                        </p>
                    </div>
                </div>
                <div class="serv3 secServ">
                    <div class="imgSec im3">
                    </div>
                    <div class="descSec">
                        <p class="desc de3">
                            Doorstep Pickup and Delivery
                        </p>
                    </div>
                </div>
                <div class="serv4 secServ">
                    <div class="imgSec im4">
                    </div>
                    <div class="descSec">
                        <p class="desc de4">
                            Build Business Brand Online
                        </p>
                    </div>
                </div>
                <div class="serv5 secServ">
                    <div class="imgSec im5">
                    </div>
                    <div class="descSec">
                        <p class="desc de5">
                            Easy Finance and Pay Later
                        </p>
                    </div>
                </div>
                <div class="serv6 secServ">
                    <div class="imgSec im6">
                    </div>
                    <div class="descSec">
                        <p class="desc de6">
                            Accounting and Bookkeeping
                        </p>
                    </div>
                </div>
                <div class="serv7 secServ">
                    <div class="imgSec im7">
                    </div>
                    <div class="descSec">
                        <p class="desc de7">
                            Customer Support Solutions
                        </p>
                    </div>
                </div>
            </div>
            <div class="finalClosing">
                <h1>
                    Join us at Myapaar and experience a new era of efficiency and opportunity in bulk commerce.
                </h1>
                <h3>
                    Free Premium Access Membership for 2024.
                </h3>
                <h5>
                    10,000 ₹ per year charge 2025 onwards
                </h5>
            </div>
        </div>
    </div>

</div>