import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { VaayuputraService } from 'src/app/services/vaayuputra.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.css']
})
export class DeleteAccountComponent implements OnInit {
  selectedAccountType: string
  accountTypes: string[] = [
    "Seller",
    "Buyer",]
  fullName: string
  phone: string
  companyName: string
  reasonToDelete: string
  displayPostSuccess: boolean = false;
  isLoading: boolean = false;
  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private vaayu: VaayuputraService
  ) { }

  ngOnInit(): void {

  }
  doneForm() {
    try {
      if (
        !this.selectedAccountType || this.selectedAccountType === undefined ||
        !this.fullName || this.fullName === undefined ||
        !this.companyName || this.companyName === undefined ||
        !this.phone || this.phone === undefined ||
        !this.reasonToDelete || this.reasonToDelete === undefined
      ) {
        this.notify('Add Details')
        return
      }
      this.isLoading = true
      const reqForm = {
        accountType: this.selectedAccountType,
        fullName: this.fullName,
        companyName: this.companyName,
        phone: this.phone,
        reasonWhyDelete: this.reasonToDelete
      }
      this.vaayu.deleteUserRequest(reqForm).then(posted => {
        this.isLoading = false
        let notifyMess: string
        if (posted && posted.status === 201) {
          this.displayPostSuccess = true
          notifyMess = posted.message
        }
        else {
          notifyMess = posted.message
        }
        this.notify(notifyMess)
      })
    }
    catch (e) { throw e }
  }
  notify(mess: string) {
    try {
      const action = "Ok"
      // give snackBar error then route to profule page on action
      // login error notific
      let snackbarref = this._snackBar.open(mess, action, {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      snackbarref.afterDismissed().subscribe(() => {
        this.router.navigate([''])
      })
      // go to profile page - the user can see all the info here and edit it - like upload GST and PAN as well
    }
    catch (e) {
      throw e
    }
  }


}
