import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SignUpPageShiftService {
  private accountTypeData = new BehaviorSubject("")
  currentData = this.accountTypeData.asObservable();

  constructor() { }
  setAccTypeData(data: any) {
    this.accountTypeData.next(data);
  }

}
