import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ItemPushcartService {
  public listingItemDetails: any = [];
  public subject = new Subject<any>();
  private messageSource = new BehaviorSubject(this.listingItemDetails);
  currentMessage = this.messageSource.asObservable();
  subjectvariety = new Subject()
  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }
  addListing(listingItem) {
    this.subjectvariety.next(listingItem)  //triggering an event // we are expecting the varietyItem on which add to cart is clicked
  }
  getVarietyinCart() {
    return this.subjectvariety.asObservable() //gets what is being sent in SendVarietyTocart() . it accepts 1 product as argument
  }
} 
