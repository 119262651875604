import { Injectable } from '@angular/core';
import { PostsAndFaqFullInfoModel } from '../datamodels/PostsFaqs/faqAndPostsModel';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class MypseoService {
  private domain: string | undefined
  useTheseQuestions: PostsAndFaqFullInfoModel[] = []

  constructor(private meta: Meta, private http: HttpClient,
    private title: Title,
    private settingsService: SettingsService) {
    this.domain = settingsService.cherryURL
  }

  // getFAQsSetMeta() {
  //   this.useTheseQuestions = this.getAllPostedFAQs()
  //   this.addMetaTags(this.useTheseQuestions)
  //   return this.useTheseQuestions
  // }


  getFAQsOnly() {
    this.getAllPostedFAQs().then(allFAQs => {
      if (allFAQs && allFAQs.status === 200) {
        this.useTheseQuestions = allFAQs.FAQsfetched
        return this.useTheseQuestions
      }
    })
  }


  getFAQsSetMeta() {
    this.getAllPostedFAQs().then(allFAQs => {
      if (allFAQs && allFAQs.status === 200) {
        this.useTheseQuestions = allFAQs.FAQsfetched
        this.addMetaTags(this.useTheseQuestions)
        return this.useTheseQuestions
      }
    })
  }

  addMetaTags(faqs: PostsAndFaqFullInfoModel[]) {
    let useMetaInfo = faqs.map(eachFaq => {
      const metaInfo = {
        name: eachFaq.question,
        content: eachFaq.answer,
        property: ''
      }
      return metaInfo
    })
    const descriptiveSite = [
      { name: 'keywords', property: "og:keywords", content: 'Myapaar, Advance Payment wholesale, Vyapaar online, Vyapaar agriculture, agribusiness, Chana, rice, wheat, gehu, dal, pulses, soyabean oil, essential oils, india spices, spices vyapaar, spices wholesaler, cardamom wholesaler, mushrooms, Myapaar Facebook, Myapaar instagram, Myapaar website, E-Commerce, Wholesale, Bulk, India Wholesale, Suppliers India, Dealers India, Wholesale Grains, Wholesale Buyers, Sell in bulk, Buy in Bulk Wholesale Rice, Wholesale Essential Oils, Wholesale Steel, Wholesale Iron, Wholesale Bronze, Wholesale Peetal,All Indus Roots,Wholesale Cotton, Cotton Suppliers, Honey Suppliers, Merchants , Suppliers, Traders, Sellers, Producers, Distributors, Wholesalers, Retailers' },
      { name: 'copyright', property: "og:copyright", content: 'Karo Vyapaar only on Myapaar' },
      { name: 'application-name', property: "og:type", content: 'Myapaar' },
      { name: 'description', property: "og:description", content: 'Myapaar ecommerce platform helps Suppliers and Buyers with bulk and wholesale trade, simplifying buying and selling in large quantities for Agri products. Sell and Buy in Bulk in India and Globally. Manage your inventory effortlessly to streamline business operations. Create Purchase Orders and Invoices easily and track Payments.' },
      { name: 'generator', property: "og:app", content: 'Angular' },
      { name: 'website', property: "og:url", content: 'https://www.myapaar.com/' },
      { name: 'facebook', property: "fb:app_id", content: '61553258164931' },
      { name: 'facebook', property: "fb:site", content: 'https://www.facebook.com/profile.php?id=61553258164931' },
      { name: 'facebook', property: "fb:description", content: 'Myapaar establishes bulk and wholesale trade and product exchange, simplifying buying and selling in large quantities. Manage your inventory effortlessly to streamline business operations' },
      { name: 'instagram', property: "instagram:app_id", content: 'myapaar_co' },
      { name: 'instagram', property: "instagram:site", content: 'https://www.instagram.com/myapaar_co/?hl=en' },
      { name: 'instagram', property: "instagram:description", content: 'Myapaar establishes bulk and wholesale trade and product exchange, simplifying buying and selling in large quantities. Manage your inventory effortlessly to streamline business operations' },
      { name: 'linkedin', property: "linkedin:site", content: 'https://www.linkedin.com/in/myapaar-co-a9169429b/' },
      { name: 'linkedin', property: "linkedin:app_id", content: 'myapaar-co-a9169429b' },
      { name: 'linkedin', property: "linkedin:description", content: 'Myapaar establishes bulk and wholesale trade and product exchange, simplifying buying and selling in large quantities. Manage your inventory effortlessly to streamline business operations' },
    ]
    useMetaInfo = [...useMetaInfo, ...descriptiveSite]
    this.meta.addTags(useMetaInfo)
  }

  async postQuestions(questionUser: string): Promise<{
    message: string; status: number
  }> {
    const questAsked = new FormData()
    questAsked.append('questByUser', questionUser)
    const resQuestAsked = await this.http.post<{ message: string; status: number }>(`${this.domain}api/posts/postFAQ`, questAsked).toPromise()
    return resQuestAsked
  }

  async getAllPostedFAQs(): Promise<{
    status: number
    ;
    FAQsfetched: PostsAndFaqFullInfoModel[]
  }> {
    const allFAQs = await this.http.get<{ status: number; FAQsfetched: PostsAndFaqFullInfoModel[] }>(`${this.domain}api/posts/findAllFAQs`).toPromise()
    return allFAQs
  }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url })
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }
}
