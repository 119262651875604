import { Component, OnInit,Input } from '@angular/core';
import { State } from 'src/app/datamodels/zones/states/Statemodel';
import { StateCategory } from 'src/app/datamodels/zones/states/statecategorymodel';

@Component({
  selector: 'app-stateallitemsofcategory',
  templateUrl: './stateallitemsofcategory.component.html',
  styleUrls: ['./stateallitemsofcategory.component.css']
})
export class StateallitemsofcategoryComponent implements OnInit {
  

  constructor() { }

  ngOnInit(): void {
  }

}
