import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { AllstatesService } from 'src/app/services/allstates.service';
import { ProductsdialogmanagerService } from 'src/app/services/productsdialogmanager.service';
import { QuickViewServiceService } from 'src/app/services/quick-view-service.service';
import { StateModel } from 'src/app/datamodels/Statemodel';
import { VarietydialogmanagerService } from 'src/app/services/varietydialogmanager.service';
import { VarietyItems } from 'src/app/datamodels/varietyModel';
import { _Bottom } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-varietyofproducts',
  templateUrl: './varietyofproducts.component.html',
  styleUrls: ['./varietyofproducts.component.css']
})
export class VarietyofproductsComponent implements OnInit {
  VarietiesofProduct: VarietyItems[] = []
  divStatSelected: boolean;
  divStatId: string;
  divStatIdStore: any[] = [];
  loadVarieties: any
  allstates: StateModel[];
  // divStatFlagExists: boolean;
  addedToWishList: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public productService: ProductService,
    public allStatesService: AllstatesService,
    // private wls: WishlistService,
    public pdms: ProductsdialogmanagerService,
    public qvds: QuickViewServiceService,
    public vdms: VarietydialogmanagerService) { }

  ngOnInit(): void {
    // this.onLoadStates();
    this.onLoadVarietiesofProducts();

  }


  //LoadVarieties
  onLoadVarietiesofProducts() {
    this.route.params.pipe(switchMap((params: Params) =>
      this.productService.getVarietiesforProductUniqueSelected(params['productUnique'])))
      .subscribe(producthasVarieties => {
        this.loadVarieties = producthasVarieties
        this.VarietiesofProduct = this.loadVarieties.filteredVarietyByProductUnique
        if (this.loadVarieties.query) {
       
        }
        else {
        }
      })
  }
  //LoadStates  
  onLoadStates() {
    this.allStatesService.getAllStates().subscribe((resstate) => {
      this.allstates = resstate
    })
  }
  // ClickingVarietyItem
  onSelectVariety(_id: string) {
    this.router.navigate(['viewMainVariety', _id], { queryParamsHandling: "preserve" })
      .then
  }


}
