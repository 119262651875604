import { Injectable } from '@angular/core';
import { BlogPostModel } from '../datamodels/blogPostModel';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  shuffledBlogsRandom: BlogPostModel[];
  constructor(

  ) { }

  getDatesDifference(date1: Date, date2: Date) {
    const differenceTimeBetweenDates = Math.abs(new Date(date1).getTime() - new Date(date2).getTime());
    const differenceDays = Math.ceil(differenceTimeBetweenDates / (1000 * 3600 * 24));
    return differenceDays
  }

  convertDataUrlToBlob(dataUrl: string): Blob {
    try {
      const arr = dataUrl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    }
    catch (e) {
      throw e
    }
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  getBusinessNameForURL(companyName: string) {
    const bnameToUse = companyName.split(' ').join('_')
    return bnameToUse
  }

  getRandomBlogs(allBlogs: BlogPostModel[]) {
    this.shuffledBlogsRandom = this.shuffle(allBlogs)
    return this.shuffledBlogsRandom
  }

  playWidgetAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/audios/myapaari.mp3";
    audio.load();
    audio.play();
  }

  playMessageAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/audios/messageExchange.wav";
    audio.load();
    audio.play();
  }

  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  shuffle(array) {
    try {
      let currentIndex = array.length;

      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
      return array
    }
    catch (e) {
      throw e
    }

  }
}
