import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RaiseDisputeModel } from 'src/app/datamodels/groupedmodel/raiseDisputeModel';
import { ReportProblemService } from 'src/app/services/report-problem.service';
import { HandlereturnService } from 'src/app/services/handlereturn.service';
import { ReturnModel } from 'src/app/datamodels/returnOrders/returnDetailsModel';

interface ReturnOrderReportingProblem {
  disputeCategory: number;
  disputeDescription: string;
}
@Component({
  selector: 'app-raise-dispute-return',
  templateUrl: './raise-dispute-return.component.html',
  styleUrls: ['./raise-dispute-return.component.css']
})
export class RaiseDisputeReturnComponent implements OnInit {
  private loginUserInfo: any
  private currentUserId: string
  returnOrderID: string;
  isLoading: boolean = false
  currentReturnOrderwithId: ReturnModel;
  sellerAccountType: boolean = false
  // if this PO is created by 1seller for some order he wants from another 2seller
  isUserActingBuyer: boolean = false
  // if this PO is created by other buyers for some order they want from this 1seller
  isUserActingSeller: boolean = false
  buyerAccountType: boolean = false
  gstDetailsPending: boolean = false;
  gstVerificationPending: boolean = false;
  listReasonsForReporting: ReturnOrderReportingProblem[] = []
  reportProblemReturnOrderForm: FormGroup;
  sellerIsSeller: boolean = false
  sellerIsBuyer: boolean = false
  buyerIsBuyer: boolean = false
  isAllowPhoneCall: boolean = false


  private userIsBuyerAndReportingActive: ReturnOrderReportingProblem[] = [

    {
      disputeCategory: 1,
      disputeDescription: 'Return Active but items not picked up'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Unable to create Return Order for some items'
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Incorrect Contact / Items Information in Return Order',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Return Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Lost Return Order Pickup Verification Code',
    },
    {
      disputeCategory: 6,
      disputeDescription: 'Others',
    }
  ]

  private userIsBuyerAndReportingPicked: ReturnOrderReportingProblem[] = [

    {
      disputeCategory: 1,
      disputeDescription: 'Status of Items and Shipment'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Seller Not Accepting Return'
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Incorrect Contact / Items in Return Order',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Return Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Lost Return Order Pickup Verification Code',
    },
    {
      disputeCategory: 6,
      disputeDescription: 'Others',
    },
  ]


  private userIsBuyerAndReportingReturned: ReturnOrderReportingProblem[] = [

    {
      disputeCategory: 1,
      disputeDescription: 'Refund status for Returned Items'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Seller not accepting Return Items in correct condition'
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Incorrect Contact / Items in Return Order',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Return Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Others',
    }
  ]

  private userIsBuyerAndReportingCancelled: ReturnOrderReportingProblem[] = [
    {
      disputeCategory: 1,
      disputeDescription: 'Cancelled Return Order by mistake'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Unable to create new Return',
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Return Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Lost Credit Ratings After Return',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Others',
    }
  ]



  private userIsSellerAndReportingActive: ReturnOrderReportingProblem[] = [
    {
      disputeCategory: 1,
      disputeDescription: 'Incorrect Contact / Items Information in Return Order',
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Return Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Others',
    }
  ]

  private userIsSellerAndReportingPicked: ReturnOrderReportingProblem[] = [

    {
      disputeCategory: 1,
      disputeDescription: 'Status of Items and Shipment'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Change in Delivery Address'
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Incorrect Contact / Items in Return Order',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Return Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Others',
    }
  ]

  private userIsSellerAndReportingReturned: ReturnOrderReportingProblem[] = [
    {
      disputeCategory: 1,
      disputeDescription: 'Bad Quality of Return Items'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Incorrect Contact / Items in Return Order Recieved',
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Return Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Return Items are not same or Missing Quantity',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Others',
    }
  ]

  private userIsSellerAndReportingCancelled: ReturnOrderReportingProblem[] = [
    {
      disputeCategory: 1,
      disputeDescription: 'Cancelled By Mistake'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Buyer Not Responding'
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Return Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Lost Credit Ratings After Cancellation',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Others',
    }
  ]

  reportProblemData: RaiseDisputeModel
  isDisputeResponseAvailable: boolean = false
  disputeResponseWithIssueStatus: string;
  disputeResponseWithIssueID: string;
  constructor(
    private authService: AuthService,
    private router: ActivatedRoute,
    private hrs: HandlereturnService,
    private rps: ReportProblemService
  ) { }
  formErrors = {
    'disputeCategory': 'Select Reason',
    'phone': '',
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.reportProblemReturnOrderForm = new FormGroup({
      disputeCategory: new FormControl(null),
      disputeDescription: new FormControl(null, {
        validators: [Validators.maxLength(100)]
      }),
      isAllowPhoneCall: new FormControl(false),
      phone: new FormControl('', { validators: [Validators.minLength(10), Validators.maxLength(10)] }),
    })
    // this.listcancelReasonsForUserTypes(this.cancelPurchaseOrderdata)
    this.returnOrderID = this.router.snapshot.params.retnId;
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.getCurrentReturnOrder(this.returnOrderID);
    this.loginUserInfo.accounttype === 'Seller' ? this.sellerAccountType = true : this.buyerAccountType = true
    if (this.loginUserInfo.GST_Id === '') {
      this.gstDetailsPending = true
    }
    if (this.loginUserInfo.isGSTVerified === false) {
      this.gstVerificationPending = true
    }

  }

  getCurrentReturnOrder(returnOrderID: string) {
    try {
      this.hrs.getReturnOrder(returnOrderID).subscribe(
        (returnOrderFound) => {
          this.currentReturnOrderwithId = returnOrderFound.findReturn
          if (this.currentReturnOrderwithId) {
            this.isLoading = false;
            if (this.currentUserId === this.currentReturnOrderwithId.buyerDetails.buyerId) {
              this.isUserActingBuyer = true
              this.isUserActingSeller = false
              this.reportProblemData = {
                accountTypeOfUser: this.loginUserInfo.accounttype,
                issueStatus: null,
                phone: null,
                orderType: 'Return Order',
                orderGenerateDate: this.currentReturnOrderwithId.dateOfRETURNGenerated,
                orderID: this.currentReturnOrderwithId._id,
                orderNumber: this.currentReturnOrderwithId.returnNumber,
                orderStatus: this.currentReturnOrderwithId.returnStatus,
                userActingAsBuyer: this.isUserActingBuyer,
                userActingAsSeller: this.isUserActingSeller,
                userID: this.currentUserId,
                disputeReason: null
              }
            }
            if (this.currentUserId === this.currentReturnOrderwithId.sellerDetails._id) {
              this.isUserActingBuyer = false
              this.isUserActingSeller = true

              this.reportProblemData = {
                accountTypeOfUser: this.loginUserInfo.accounttype,
                issueStatus: null,
                phone: null,
                orderType: 'Return Order',
                orderGenerateDate: this.currentReturnOrderwithId.dateOfRETURNGenerated,
                orderID: this.currentReturnOrderwithId._id,
                orderNumber: this.currentReturnOrderwithId.returnNumber,
                orderStatus: this.currentReturnOrderwithId.returnStatus,
                userActingAsBuyer: this.isUserActingBuyer,
                userActingAsSeller: this.isUserActingSeller,
                userID: this.currentUserId,
                disputeReason: null
              }
            }
            this.listcancelReasonsForUserTypes(this.reportProblemData)

          }
        }
      )
    }
    catch (e) { }
  }

  listcancelReasonsForUserTypes(reportProblemData: RaiseDisputeModel) {
    //Mainseller as seller
    if (reportProblemData.accountTypeOfUser === 'Seller'
      && reportProblemData.userActingAsSeller && !reportProblemData.userActingAsBuyer) {
      this.sellerIsSeller = true
      this.sellerIsBuyer = false
      this.buyerIsBuyer = false
      if (this.currentReturnOrderwithId.returnStatus === 'Returned') {
        this.listReasonsForReporting = this.userIsSellerAndReportingReturned
      }
      if (this.currentReturnOrderwithId.returnStatus === 'Picked') {
        this.listReasonsForReporting = this.userIsSellerAndReportingPicked
      }
      if (this.currentReturnOrderwithId.returnStatus === 'Active') {
        this.listReasonsForReporting = this.userIsSellerAndReportingActive
      }

      if (this.currentReturnOrderwithId.returnStatus === 'Cancelled') {
        this.listReasonsForReporting = this.userIsSellerAndReportingCancelled
      }
    }
    //Mainseller as buyer
    if (reportProblemData.accountTypeOfUser === 'Seller'
      && reportProblemData.userActingAsBuyer && !reportProblemData.userActingAsSeller) {
      this.sellerIsBuyer = true

      this.sellerIsSeller = false
      this.buyerIsBuyer = false
      if (this.currentReturnOrderwithId.returnStatus === 'Returned') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingReturned
      }
      if (this.currentReturnOrderwithId.returnStatus === 'Picked') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingPicked
      }
      if (this.currentReturnOrderwithId.returnStatus === 'Active') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingActive
      }
      if (this.currentReturnOrderwithId.returnStatus === 'Cancelled') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingCancelled
      }

    }
    //MainBuyer
    if (reportProblemData.accountTypeOfUser === 'Buyer') {
      this.buyerIsBuyer = true
      this.sellerIsSeller = false
      this.sellerIsBuyer = false
      if (this.currentReturnOrderwithId.returnStatus === 'Returned') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingReturned
      }
      if (this.currentReturnOrderwithId.returnStatus === 'Active') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingActive
      }
      if (this.currentReturnOrderwithId.returnStatus === 'Picked') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingPicked
      }
      if (this.currentReturnOrderwithId.returnStatus === 'Cancelled') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingCancelled
      }
    }

  }

  onReportProblem(reportingReasonsData: ReturnOrderReportingProblem[]) {
    // get Reason Arrays according to buyertype
    // since value of dropdown is bound to category, list filter category and description from listed reasons
    let selectedReasonOfallReasonWithUserTypes = reportingReasonsData.filter(allListedReasonTypes => { return allListedReasonTypes.disputeCategory === this.reportProblemReturnOrderForm.value.disputeCategory })
    if (!selectedReasonOfallReasonWithUserTypes || selectedReasonOfallReasonWithUserTypes.length === 0) {
      const othersCategory = [{
        disputeCategory: 5,
        disputeDescription: 'Others',
      }]
      selectedReasonOfallReasonWithUserTypes = othersCategory
    }
    // patch value for formcontrol
    this.reportProblemReturnOrderForm.patchValue({
      'disputeDescription': selectedReasonOfallReasonWithUserTypes[0].disputeDescription
    })

    this.isAllowPhoneCall === false ? this.reportProblemReturnOrderForm.value.phone = '' : this.reportProblemReturnOrderForm.value.phone

    const reasonForReporting: ReturnOrderReportingProblem = {
      disputeCategory: this.reportProblemReturnOrderForm.value.disputeCategory,
      disputeDescription: this.reportProblemReturnOrderForm.value.disputeDescription,
    }
    try {
      if (this.reportProblemReturnOrderForm.invalid || this.reportProblemReturnOrderForm == undefined) {
        return
      }
      if (this.reportProblemReturnOrderForm.valid) {
        this.reportProblemReturnOrderForm.value.disputeDescription = reasonForReporting.disputeDescription
        const reportInvoiceOrderWithReason: RaiseDisputeModel = {
          accountTypeOfUser: this.reportProblemData.accountTypeOfUser,
          issueStatus: this.reportProblemData.issueStatus,
          phone: this.reportProblemReturnOrderForm.value.phone,
          orderType: this.reportProblemData.orderType,
          orderGenerateDate: this.reportProblemData.orderGenerateDate,
          orderID: this.reportProblemData.orderID,
          orderNumber: this.reportProblemData.orderNumber,
          orderStatus: this.reportProblemData.orderStatus,
          userActingAsBuyer: this.reportProblemData.userActingAsBuyer,
          userActingAsSeller: this.reportProblemData.userActingAsSeller,
          userID: this.reportProblemData.userID,
          disputeReason: reasonForReporting
        }
        this.rps.reportRETURNproblem(
          reportInvoiceOrderWithReason
        ).then(responseReporting => {
          if (responseReporting && responseReporting.status === 201) {
            this.isDisputeResponseAvailable = true
            this.disputeResponseWithIssueID = `Issue ID : ${responseReporting.issueID}`
            this.disputeResponseWithIssueStatus = `${responseReporting.message}`
          }
        })
      }
    }
    catch (e) {
      throw e
    }
  }
}