import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlogPostModel } from 'src/app/datamodels/blogPostModel';
import { CommentpostService } from 'src/app/services/commentpost.service';
import { MypseoService } from 'src/app/services/mypseo.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-blog-posts',
  templateUrl: './blog-posts.component.html',
  styleUrls: ['./blog-posts.component.css']
})
export class BlogPostsComponent implements OnInit {
  allBlogs: BlogPostModel[];
  isBlogsLoading: boolean = true
  twoRandomMainBLogs: BlogPostModel[];
  otherRandomTrendBLogs: BlogPostModel[];
  shuffledBlogsRandom: BlogPostModel[];
  lastThreeBlogs: BlogPostModel[];
  useHref: string;
  blogLoading: boolean

  constructor(
    private router: Router,
    private mySeo: MypseoService,
    private utils: UtilsService,
    private hblogs: CommentpostService) { }

  ngOnInit(): void {
    this.getAllBlogs()
    this.mySeo.updateTitle('The Future of B2B Food Supply: Trends and Predictions | Myapaar Blog')
    this.mySeo.updateDescription('Discover Myapaar, your trusted B2B marketplace for food and agricultural supplies. Access a wide range of products, competitive pricing, reliable suppliers, and secure transactions. Join us today!')
  }

  getAllBlogs() {
    this.blogLoading = true
    try {
      this.hblogs.getAllBlogs().then(allBlogs => {
        if (allBlogs && allBlogs.status == 200) {
          this.allBlogs = allBlogs.blogsfetched
          this.shuffledBlogsRandom = this.utils.getRandomBlogs(this.allBlogs)
          this.twoRandomMainBLogs = this.shuffledBlogsRandom.slice(0, 2)
          this.otherRandomTrendBLogs = this.shuffledBlogsRandom.slice(2, this.shuffledBlogsRandom.length)
          this.lastThreeBlogs = this.shuffledBlogsRandom.slice(this.shuffledBlogsRandom.length - 3, this.shuffledBlogsRandom.length)
          this.blogLoading = false
        }
      })
    }
    catch (e) {
      throw e
    }

  }


  createHrefForBlog(blogTitle: string) {
    const pieceLink = `Blogs/Published/Article/${blogTitle}`
    this.useHref = pieceLink
    this.router.navigate([pieceLink])
    // this.useHref = `https://www.myapaar.com/Blogs/Published/Article/${blogTitle}`
  }

}
