import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  isLoading: boolean = false;
  otpSent: boolean = false
  otpVerified: boolean = false
  resetPasswordEnable: boolean = false;
  loginform: FormGroup;
  verifyOTPForm: FormGroup;
  resetPasswordForm: FormGroup;
  hide = true;
  forgotPasswordOtp: number = null;
  private authListenerSubs: Subscription;
  validationMessages = {
    'email': {
      'required': 'Email is required',
      'email': 'Email not in valid format',
      'password': 'enter password'
    },
    'phone': {
      'required': 'Mobile number is required',
      'minlength': 'Atleast 10 digits required. ',
      'pattern': 'Only numbers are allowed',
    },
  }
  formErrors = {
    'email': '',
    'password': 'enter password',
    'phone': '',
  }
  userIdforEmail: any;
  userTokenInfo: any;
  constructor(public authService: AuthService,
    private router: Router,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(
      //we get auth status which is a boolean
      authStatus => {
        this.isLoading = false;
      }
    )
    // form to check if user exists and send Otp
    this.loginform = new FormGroup({
      email: new FormControl('', { validators: [Validators.email] }),
      phone: new FormControl('', { validators: [Validators.minLength(10), Validators.maxLength(10)] }),
    });
    // form to check if user exists and verify the Sent OTP

    this.verifyOTPForm = new FormGroup({
      email: new FormControl('', { validators: [Validators.required, Validators.email] }),

      forgotPasswordOtp: new FormControl(null, {
        validators: [Validators.maxLength(6), Validators.required]
      }),

      password: new FormControl('', { validators: [Validators.required] })

    })
    // form to check if user exists with email and OTP, and then reset password with sent password

    // this.resetPasswordForm = new FormGroup({
    //   email: new FormControl('', { validators: [Validators.required, Validators.email] }),
    //   forgotPasswordOtp: new FormControl(null, {
    //     validators: [Validators.maxLength(6), Validators.required]
    //   }),
    //   password: new FormControl('', { validators: [Validators.required] }),
    // })
    this.loginform.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged();
  }
  ngOnDestroy() {
    //prevent any data leak
    this.authListenerSubs.unsubscribe();
  }
  onValueChanged(data?: any) {
    if (!this.loginform) {
      return;
    }
    const lform = this.loginform
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        //clear previous messages if any
        this.formErrors[field] = '';
        const control = lform.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + '';
            }
          }
        }
      }
    }
  }
  onverifyuserExist() {
    if (!this.loginform.get('phone').value && !this.loginform.get('email').value) {
      const errMEssage = "Add Phone number or Email"
      this.notifyError(errMEssage, "Ok")
      return
    }
    this.isLoading = true; //spinner starts
    {
      this.authService.forgotPassword(
        this.loginform.value.email,
        this.loginform.value.phone,
      ).subscribe(response => {
        this.userTokenInfo = response
        JSON.stringify(this.userTokenInfo);
        if (response && response.status === 200) {
          this.isLoading = false;
          this.otpSent = true;
          this.userIdforEmail = response.userId
        }
      }, error => {
        this.isLoading = false;
        this.invalidUserError(error.error.message, "Retry")
        // this.loginError(error.error.message, "Retry")
      })
    }
  }
  async resetPasswordWithOTP() {
    this.forgotPasswordOtp = this.verifyOTPForm.get('forgotPasswordOtp').value
    this.isLoading = true; //spinner starts
    await this.authService.resetPasswordwithOTP(
      this.userIdforEmail,
      this.verifyOTPForm.value.forgotPasswordOtp,
      this.verifyOTPForm.value.password,
    ).then(p => {
      this.otpVerified = true
      this.resetPasswordEnable = true

      // [disabled]="!myForm.value?.myRadio"
      this.passwordChangeSuccessful(`OTP Verified and password changed successfully`, "Ok")
      this.isLoading = false;
      this.router.navigate(['/']);

    }).catch(e => {
      this.isLoading = false;

      this.invalidUserError(e.error.message, "Retry")
    })
  }
  passwordChangeSuccessful(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }
  invalidUserError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }

  notifyError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }

}
