<!-- <h1>{{listingId}}</h1> -->
<div *ngIf="this.createPostComments">
    <p class="loginToComment">Login to give ratings and feedback</p>
</div>
<mat-card *ngIf="!this.createPostComments">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <form [formGroup]="reviewform" (submit)="onSavePost()" *ngIf="!isLoading">
        <div class="comment-image">
            <div class="section image">
                <div class="image-preview"
                    *ngIf="reviewImagePreview!=='' && reviewImagePreview && reviewform.get('reviewImage').valid">
                    <img [src]="reviewImagePreview" [alt]="reviewform.value.title">
                </div>
            </div>
            <div class="comment-row">
                <div class="section commentBox">
                    <mat-form-field appearance="outline">
                        <textarea matInput rows="3" name="comment" formControlName="comment"
                            placeholder="Feedback"></textarea>
                        <mat-error *ngIf="reviewform.get('comment').invalid">Your feedback</mat-error>
                    </mat-form-field>
                </div>
                <div class="section action-slider">
                    <mat-error *ngIf="reviewform.get('rating').invalid">Rate between 1 to 5</mat-error>
                    <mat-slider [min]="1" [max]="5" [step]="1" formControlName="rating" thumbLabel></mat-slider>
                </div>
            </div>
        </div>
        <div id="commentAction">
            <!-- change triggers the file picking - from no file to somefile , or somefile to some other file -->
            <button id="imageButton" mat-raised-button type="button" (click)="filePicker.click()">Add Image</button>
            <input type="file" #filePicker (change)="onImagePicked($event)">
            <button id="feedbackButton" [disabled]="!reviewform.valid" mat-raised-button color="warn" type="submit">Comment
            </button>
        </div>

    </form>

</mat-card>