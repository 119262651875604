import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PurchaseOrderModel } from '../datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { UserAuthModel } from '../datamodels/userModel';
import { CancelPurchaseOrderModel } from '../datamodels/purchaseOrderModel/cancelPurchaseOrder';
import { VersionInvoicing } from '../datamodels/invoiceOrderModel/invoiceVersionDataModel';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class HandlePurchaseOrderService {
  private bundledPurchaseOrderItems = new BehaviorSubject(null);
  sharedBundledPOitems = this.bundledPurchaseOrderItems.asObservable()
  private domain: string | undefined
  purchaseOrders: PurchaseOrderModel[] = []
  constructor(
    private http: HttpClient
    , private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }
  updateBundledPOitems(bundledPOitemsAndItemID: { purchaseOrderDetails: PurchaseOrderModel; allItemIDsOfCart: string[]; cartId: string }) {
    try {
      this.bundledPurchaseOrderItems.next(bundledPOitemsAndItemID)
    }
    catch (e) {
      throw e
    }

  }

  getAllUserPurchaseOrders(userId: string, loginAccounttype: string): Observable<PurchaseOrderModel[]> {
    const queryParams = `?userId=${userId}&loginAccounttype=${loginAccounttype}`;
    // const queryParams = `?pagesize=${userId}&pagesize=${listingsPerPage}&page=${currentPage}`;
    return new Observable(
      userPOsObserver => {
        if (this.purchaseOrders && this.purchaseOrders.length > 0) {
          userPOsObserver.next(this.purchaseOrders)
          return userPOsObserver.complete()
        }
        this.http.get<{
          allPurchaseOrders: PurchaseOrderModel[];
          status: number
        }>(`${this.domain}api/purchaseOrderRequest/getAllPO/` + queryParams)
          .subscribe((fetchedUserPOs) => {
            this.purchaseOrders = fetchedUserPOs.allPurchaseOrders;
            userPOsObserver.next(this.purchaseOrders);
            userPOsObserver.complete();
          });
      }
    )

  }

  getSinglePurchaseOrder(purchaseOrderID: string): Observable<any> {
    return this.http.get<any>(`${this.domain}api/purchaseOrderRequest/getSinglePO/` + purchaseOrderID)
  }

  async getNumberPOsPlaced(usrId: string): Promise<any> {
    const queryParams = `?usr=${usrId}`;
    const placedPO = await this.http.get<any>(`${this.domain}api/purchaseOrderRequest/getPoPlaced/` + queryParams).toPromise()
    return placedPO
  }
  async getNumberPOsSOLD(usrId: string): Promise<any> {
    const queryParams = `?usr=${usrId}`;
    const soldPO = await this.http.get<any>(`${this.domain}api/purchaseOrderRequest/getPoSold/` + queryParams).toPromise()
    return soldPO
  }
  async getNumberPOsPaid(usrId: string): Promise<any> {
    const queryParams = `?usr=${usrId}`;
    const paidPO = await this.http.get<any>(`${this.domain}api/purchaseOrderRequest/getPoPaid/` + queryParams).toPromise()
    return paidPO
  }
  async createNewPO(purchaseOrderDetails: PurchaseOrderModel, purchid: string): Promise<{ message: string; createdPO: PurchaseOrderModel; poLink: string; status: number; }> {
    try {
      const newPOData = new FormData()
      for (let poDataItem in purchaseOrderDetails) {
        // each new object should be conditioned in If, or else will be skipped
        //append nested ob from loops

        //maybe blank po id ?
        if (poDataItem === '_id') {
          newPOData.append(`_id`, purchaseOrderDetails[poDataItem])
        }
        if (poDataItem === 'purchaseOrderNumber') {
          newPOData.append(`purchaseOrderNumber`, purchid)
        }
        if (poDataItem === 'buyerDetails') {
          for (let buyerDetailKey in purchaseOrderDetails[poDataItem]) {
            if (buyerDetailKey === 'buyerAddress') {
              //poDataItem is sellerDetails here
              for (let buyerAddressKey in purchaseOrderDetails[poDataItem][buyerDetailKey]) {
                // append two depth nested objects
                newPOData.append(`buyerDetails[${buyerDetailKey}][${buyerAddressKey}]`, purchaseOrderDetails[poDataItem][buyerDetailKey][buyerAddressKey])
              }
            }
            if (buyerDetailKey === 'buyerContactDetails') {
              //poDataItem is sellerDetails here
              // append two depth nested objects
              for (let buyerContactDetailKey in purchaseOrderDetails[poDataItem][buyerDetailKey]) {
                // for numbers, we need to stringify
                buyerContactDetailKey === 'alternatePhoneContact' || buyerContactDetailKey === 'phone' ?
                  newPOData.append(`buyerDetails[${buyerDetailKey}][${buyerContactDetailKey}]`, JSON.stringify(purchaseOrderDetails[poDataItem][buyerDetailKey][buyerContactDetailKey]))
                  : newPOData.append(`buyerDetails[${buyerDetailKey}][${buyerContactDetailKey}]`, purchaseOrderDetails[poDataItem][buyerDetailKey][buyerContactDetailKey])
              }
            }
            if (buyerDetailKey !== 'buyerAddress' && buyerDetailKey !== 'buyerContactDetails')
              newPOData.append(`buyerDetails[${buyerDetailKey}]`, purchaseOrderDetails[poDataItem][buyerDetailKey])
          }
        }
        // sellerDetails fetched from backend
        if (poDataItem === 'sellerDetails') {
          newPOData.append(`sellerDetails`, null)
        }

        if (poDataItem === 'itemDetails') {
          const resolvedItemDetails = []
          for (let itemDetailKey in purchaseOrderDetails[poDataItem]) {
            resolvedItemDetails.push(purchaseOrderDetails[poDataItem][itemDetailKey])
          }
          newPOData.append(`itemDetails`, JSON.stringify(resolvedItemDetails))
          // newPOData.append(`itemDetails`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'shipToBuyerLocation') {
          for (let shipToBuyerLocationKey in purchaseOrderDetails[poDataItem]) {
            newPOData.append(`shipToBuyerLocation[${shipToBuyerLocationKey}]`, purchaseOrderDetails[poDataItem][shipToBuyerLocationKey])
          }
        }
        if (poDataItem === 'modePickup') {
          for (let modePickupKey in purchaseOrderDetails[poDataItem]) {
            newPOData.append(`modePickup[${modePickupKey}]`, purchaseOrderDetails[poDataItem][modePickupKey])
          }
        }
        if (poDataItem === 'dateOfPOGenerated') {
          newPOData.append(`dateOfPOGenerated`, JSON.stringify(new Date(purchaseOrderDetails[poDataItem])))
        }
        if (poDataItem === 'dateOfPurchaseOrderExpiry') {
          newPOData.append(`dateOfPurchaseOrderExpiry`, JSON.stringify(new Date(purchaseOrderDetails[poDataItem])))
        }
        if (poDataItem === 'validityOfPurchaseOrderExpiry') {
          newPOData.append(`validityOfPurchaseOrderExpiry`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 's3POpdfLink') {
          newPOData.append(`s3POpdfLink`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'orderValue') {
          newPOData.append(`orderValue`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'orderValueIncludingTax') {
          newPOData.append(`orderValueIncludingTax`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'orderQuantity') {
          newPOData.append(`orderQuantity`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'orderTax') {
          newPOData.append(`orderTax`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'isPOStatusActive') {
          newPOData.append(`isPOStatusActive`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'poStatus') {
          newPOData.append(`poStatus`, purchaseOrderDetails[poDataItem])
        }
        if (poDataItem === 'isInvoiceGenerated') {
          newPOData.append(`isInvoiceGenerated`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'invoiceStatus') {
          newPOData.append(`invoiceStatus`, purchaseOrderDetails[poDataItem])
        }
        if (poDataItem === 'invoiceVersions') {
          for (let invoiceVersionKey in purchaseOrderDetails[poDataItem]) {
            // invoiceNumberKey === 'version' || invoiceNumberKey === 'invoiceAmount' || invoiceNumberKey === 'isActive' ?
            // newPOData.append(`invoiceNumbers[${ invoiceNumberKey }]`, JSON.stringify(purchaseOrderDetails[poDataItem][invoiceNumberKey])) :
            newPOData.append(`invoiceVersions[${invoiceVersionKey}]`, JSON.stringify(purchaseOrderDetails[poDataItem][invoiceVersionKey]))
          }
        }
        if (poDataItem === 'returnVersions') {
          for (let returnVersionKey in purchaseOrderDetails[poDataItem]) {
            // invoiceNumberKey === 'version' || invoiceNumberKey === 'invoiceAmount' || invoiceNumberKey === 'isActive' ?
            // newPOData.append(`invoiceNumbers[${ invoiceNumberKey }]`, JSON.stringify(purchaseOrderDetails[poDataItem][invoiceNumberKey])) :
            newPOData.append(`returnVersions[${returnVersionKey}]`, JSON.stringify(purchaseOrderDetails[poDataItem][returnVersionKey]))
          }
        }
        if (poDataItem === 'additionalCharges') {
          const resolvedadditionalCharges = []
          for (let additionalChargesKey in purchaseOrderDetails[poDataItem]) {
            resolvedadditionalCharges.push(purchaseOrderDetails[poDataItem][additionalChargesKey])
          }
          newPOData.append(`additionalCharges`, JSON.stringify(resolvedadditionalCharges))
        }
        if (poDataItem === 'amountPaidAtCheckout') {
          newPOData.append(`amountPaidAtCheckout`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'amountRemainingForInvoices') {
          newPOData.append(`amountRemainingForInvoices`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
      }
      const createdPurchaseOrder = await this.http.post<{ message: string, createdPO: PurchaseOrderModel, poLink: string, sellerDetails: UserAuthModel, status: number }>(`${this.domain}api/purchaseOrderRequest/createPO/`, newPOData).toPromise()
      return createdPurchaseOrder
    }
    catch (e) {
      throw e
    }

  }

  async createInstantPO(purchaseOrderDetails: PurchaseOrderModel): Promise<{ message: string; createdPO: PurchaseOrderModel; poLink: string; status: number; }> {
    try {
      const newPOData = new FormData()
      for (let poDataItem in purchaseOrderDetails) {
        // each new object should be conditioned in If, or else will be skipped
        //append nested ob from loops

        //maybe blank po id ?
        if (poDataItem === '_id') {
          newPOData.append(`_id`, purchaseOrderDetails[poDataItem])
        }
        if (poDataItem === 'purchaseOrderNumber') {
          newPOData.append(`purchaseOrderNumber`, purchaseOrderDetails[poDataItem])
        }
        if (poDataItem === 'buyerDetails') {
          for (let buyerDetailKey in purchaseOrderDetails[poDataItem]) {
            if (buyerDetailKey === 'buyerAddress') {
              //poDataItem is sellerDetails here
              for (let buyerAddressKey in purchaseOrderDetails[poDataItem][buyerDetailKey]) {
                // append two depth nested objects
                newPOData.append(`buyerDetails[${buyerDetailKey}][${buyerAddressKey}]`, purchaseOrderDetails[poDataItem][buyerDetailKey][buyerAddressKey])
              }
            }
            if (buyerDetailKey === 'buyerContactDetails') {
              //poDataItem is sellerDetails here
              // append two depth nested objects
              for (let buyerContactDetailKey in purchaseOrderDetails[poDataItem][buyerDetailKey]) {
                // for numbers, we need to stringify
                buyerContactDetailKey === 'alternatePhoneContact' || buyerContactDetailKey === 'phone' ?
                  newPOData.append(`buyerDetails[${buyerDetailKey}][${buyerContactDetailKey}]`, JSON.stringify(purchaseOrderDetails[poDataItem][buyerDetailKey][buyerContactDetailKey]))
                  : newPOData.append(`buyerDetails[${buyerDetailKey}][${buyerContactDetailKey}]`, purchaseOrderDetails[poDataItem][buyerDetailKey][buyerContactDetailKey])
              }
            }
            if (buyerDetailKey !== 'buyerAddress' && buyerDetailKey !== 'buyerContactDetails')
              newPOData.append(`buyerDetails[${buyerDetailKey}]`, purchaseOrderDetails[poDataItem][buyerDetailKey])
          }
        }
        // sellerDetails fetched from backend
        if (poDataItem === 'sellerDetails') {
          newPOData.append(`sellerDetails`, null)
        }

        if (poDataItem === 'itemDetails') {
          const resolvedItemDetails = []
          for (let itemDetailKey in purchaseOrderDetails[poDataItem]) {
            resolvedItemDetails.push(purchaseOrderDetails[poDataItem][itemDetailKey])
          }
          newPOData.append(`itemDetails`, JSON.stringify(resolvedItemDetails))
          // newPOData.append(`itemDetails`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'shipToBuyerLocation') {
          for (let shipToBuyerLocationKey in purchaseOrderDetails[poDataItem]) {
            newPOData.append(`shipToBuyerLocation[${shipToBuyerLocationKey}]`, purchaseOrderDetails[poDataItem][shipToBuyerLocationKey])
          }
        }
        if (poDataItem === 'modePickup') {
          for (let modePickupKey in purchaseOrderDetails[poDataItem]) {
            newPOData.append(`modePickup[${modePickupKey}]`, purchaseOrderDetails[poDataItem][modePickupKey])
          }
        }
        if (poDataItem === 'dateOfPOGenerated') {
          newPOData.append(`dateOfPOGenerated`, JSON.stringify(new Date(purchaseOrderDetails[poDataItem])))
        }
        if (poDataItem === 'dateOfPurchaseOrderExpiry') {
          newPOData.append(`dateOfPurchaseOrderExpiry`, JSON.stringify(new Date(purchaseOrderDetails[poDataItem])))
        }
        if (poDataItem === 'validityOfPurchaseOrderExpiry') {
          newPOData.append(`validityOfPurchaseOrderExpiry`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 's3POpdfLink') {
          newPOData.append(`s3POpdfLink`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'orderValue') {
          newPOData.append(`orderValue`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'orderValueIncludingTax') {
          newPOData.append(`orderValueIncludingTax`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'orderQuantity') {
          newPOData.append(`orderQuantity`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'orderTax') {
          newPOData.append(`orderTax`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'isPOStatusActive') {
          newPOData.append(`isPOStatusActive`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'poStatus') {
          newPOData.append(`poStatus`, purchaseOrderDetails[poDataItem])
        }
        if (poDataItem === 'isInvoiceGenerated') {
          newPOData.append(`isInvoiceGenerated`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'invoiceStatus') {
          newPOData.append(`invoiceStatus`, purchaseOrderDetails[poDataItem])
        }
        if (poDataItem === 'invoiceVersions') {
          for (let invoiceVersionKey in purchaseOrderDetails[poDataItem]) {
            // invoiceNumberKey === 'version' || invoiceNumberKey === 'invoiceAmount' || invoiceNumberKey === 'isActive' ?
            // newPOData.append(`invoiceNumbers[${ invoiceNumberKey }]`, JSON.stringify(purchaseOrderDetails[poDataItem][invoiceNumberKey])) :
            newPOData.append(`invoiceVersions[${invoiceVersionKey}]`, JSON.stringify(purchaseOrderDetails[poDataItem][invoiceVersionKey]))
          }
        }
        if (poDataItem === 'returnVersions') {
          for (let returnVersionKey in purchaseOrderDetails[poDataItem]) {
            // invoiceNumberKey === 'version' || invoiceNumberKey === 'invoiceAmount' || invoiceNumberKey === 'isActive' ?
            // newPOData.append(`invoiceNumbers[${ invoiceNumberKey }]`, JSON.stringify(purchaseOrderDetails[poDataItem][invoiceNumberKey])) :
            newPOData.append(`returnVersions[${returnVersionKey}]`, JSON.stringify(purchaseOrderDetails[poDataItem][returnVersionKey]))
          }
        }
        if (poDataItem === 'additionalCharges') {
          const resolvedadditionalCharges = []
          for (let additionalChargesKey in purchaseOrderDetails[poDataItem]) {
            resolvedadditionalCharges.push(purchaseOrderDetails[poDataItem][additionalChargesKey])
          }
          newPOData.append(`additionalCharges`, JSON.stringify(resolvedadditionalCharges))
        }
        if (poDataItem === 'amountPaidAtCheckout') {
          newPOData.append(`amountPaidAtCheckout`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
        if (poDataItem === 'amountRemainingForInvoices') {
          newPOData.append(`amountRemainingForInvoices`, JSON.stringify(purchaseOrderDetails[poDataItem]))
        }
      }
      const createdPurchaseOrder = await this.http.post<{ message: string, createdPO: PurchaseOrderModel, poLink: string, sellerDetails: UserAuthModel, status: number }>(`${this.domain}api/purchaseOrderRequest/createInstantPO/`, newPOData).toPromise()
      return createdPurchaseOrder
    }
    catch (e) {
      throw e
    }

  }

  async updatePurchaseOrderStatusOf(purchaseOrderId: string, purchaseOrderNumber: string): Promise<{ message: string; purchaseOrderstatusUpdated: PurchaseOrderModel; status: number }> {
    // const queryParams = `?poID=${purchOrderId}&poNumber=${purchOrderNumber}`;
    const updatePOwithData = {
      purchaseOrderId,
      purchaseOrderNumber
    }
    const updatedPurchaseOrder = await this.http.put<{ message: string, purchaseOrderstatusUpdated: PurchaseOrderModel, status: number }>(`${this.domain}api/purchaseOrderRequest/updatePurchaseOrderStatus`, updatePOwithData).toPromise();
    return updatedPurchaseOrder
  }

  async cancelPurchaseOrderStatusOf(cancelPOdata: CancelPurchaseOrderModel): Promise<{ message: string; purchaseOrderCancelled: PurchaseOrderModel; status: number }> {
    const cancelPOFormData = new FormData()
    for (let cancelPODataItem in cancelPOdata) {
      if (cancelPODataItem === 'accountTypeOfUser') {
        cancelPOFormData.append(`accountTypeOfUser`, cancelPOdata[cancelPODataItem])
      }
      if (cancelPODataItem === 'poStatusBeforeCancel') {
        cancelPOFormData.append(`poStatusBeforeCancel`, cancelPOdata[cancelPODataItem])
      }
      if (cancelPODataItem === 'purchaseOrderID') {
        cancelPOFormData.append(`purchaseOrderID`, cancelPOdata[cancelPODataItem])
      }
      if (cancelPODataItem === 'purchaseOrderNumber') {
        cancelPOFormData.append(`purchaseOrderNumber`, cancelPOdata[cancelPODataItem])
      }
      if (cancelPODataItem === 'purchaseOrderGenerateDate') {
        cancelPOFormData.append(`purchaseOrderGenerateDate`, JSON.stringify(new Date(cancelPOdata[cancelPODataItem])))
      }
      if (cancelPODataItem === 'userIDcancelling') {
        cancelPOFormData.append(`userIDcancelling`, cancelPOdata[cancelPODataItem])
      }
      if (cancelPODataItem === 'userActingAsBuyer') {
        cancelPOFormData.append(`userActingAsBuyer`, JSON.stringify(cancelPOdata[cancelPODataItem]))
      }
      if (cancelPODataItem === 'userActingAsSeller') {
        cancelPOFormData.append(`userActingAsSeller`, JSON.stringify(cancelPOdata[cancelPODataItem]))
      }
      if (cancelPODataItem === 'cancelReason') {
        for (let cancelReasonKey in cancelPOdata[cancelPODataItem]) {
          cancelPOFormData.append(`cancelReason[${cancelReasonKey}]`, cancelPOdata[cancelPODataItem][cancelReasonKey])
        }
      }
    }
    //  Display the key/value pairs
    // for (var pair of cancelPOFormData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }

    const updatedPurchaseOrder = await this.http.put<{ message: string, purchaseOrderCancelled: PurchaseOrderModel, status: number }>(`${this.domain}api/purchaseOrderRequest/cancelPurchaseOrderStatus`, cancelPOFormData).toPromise();
    return updatedPurchaseOrder
  }

  async updatePurchaseOrderWithInvoiceVersion(invoiceVersion: VersionInvoicing[], purchOrderId: string, purchOrderNumber: string): Promise<{ message: string; status: number }> {
    const queryParams = `?pOid=${purchOrderId}&pOnumber=${purchOrderNumber}`
    const invVersionObjectParsing = {
      invoiceVersions: invoiceVersion
    }
    const invVersionFormData = new FormData()
    for (let invObjectKey in invVersionObjectParsing) {
      if (invObjectKey === 'invoiceVersions') {
        for (let invoiceItemsRaisedKey in invVersionObjectParsing[invObjectKey]) {
          if (invoiceItemsRaisedKey === 'itemsAddedForInvoice') {
            invVersionFormData.append(`invoiceVersions[${invoiceItemsRaisedKey}]`, invoiceVersion[invObjectKey])
          }
          if (invoiceItemsRaisedKey === 'itemsRemainingForInvoice') {
            invVersionFormData.append(`itemsRemainingForInvoice[${invoiceItemsRaisedKey}]`, invoiceVersion[invObjectKey])
          }
        }
        invVersionFormData.append(`invoiceVersions`, JSON.stringify(invoiceVersion))
      }
    }


    // if (poDataItem === 'buyerDetails') {
    //   for (let buyerDetailKey in purchaseOrderDetails[poDataItem]) {
    //     if (buyerDetailKey === 'buyerAddress') {
    //       //poDataItem is sellerDetails here
    //       for (let buyerAddressKey in purchaseOrderDetails[poDataItem][buyerDetailKey]) {
    //         newPOData.append(`buyerDetails[${ buyerDetailKey }][${ buyerAddressKey }]`, purchaseOrderDetails[poDataItem][buyerDetailKey][buyerAddressKey])
    const createdInvoVersion = await this.http.put<{ message: string, status: number }>(`${this.domain}api/purchaseOrderRequest/updatePurchaseOrderWithINVOnumber/` + queryParams, invVersionFormData).toPromise();
    return createdInvoVersion

  }

  async demandInvoice(poId: string): Promise<{ message: string }> {
    const queryParams = `?purchaseOrderID=${poId}`
    const messageSent = await this.http.get<{ message: string }>(`${this.domain}api/purchaseOrderRequest/demandInvoice/` + queryParams).toPromise()
    return messageSent
  }

}
