import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { Product } from '../datamodels/MainProductModel';
import { Observable } from 'rxjs';
import { SettingsService } from './settings.service';
// import {ALLPRODUCTS} from '../datamodels/allproducts'

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  filterCategory: any;
  divideCategoryId: string[]
  modalValue: any[]
  ParamHolder: any[]
  products: Product[] = []
  private domain: string | undefined
  constructor(private http: HttpClient,
    private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }

  getProducts(): Observable<Product[]> {
    return new Observable(
      productObserver => {
        if (this.products && this.products.length > 0) {
          productObserver.next(this.products)
          return productObserver.complete()
        }
        this.http.get<{
          allproducts: Product[]; featureproducts: Product[]; nofeatureproducts: Product[]; status: number
        }>(`${this.domain}api/products/findAll`)
          .subscribe((fetchedProducts) => {
            this.products = fetchedProducts.allproducts;
            productObserver.next(this.products);
            productObserver.complete();
          });
      }
    )
  }

  getVarietiesforProductUniqueSelected(productUnique: string): Observable<any> {
    let params1 = new HttpParams().set("productUnique", productUnique)
    return this.http.get<any>(`${this.domain}api/varieties/`, { params: params1 })
  }

  async updateProductListing(id: string, stateName: string): Promise<{ status: number }> {
    // let productName = new HttpParams().set("productUnique", productName)
    const productData = new FormData();
    productData.append("productState", stateName);
    const awaitUpdatedProductStates = await this.http.put<{ status: number }>(`${this.domain}api/products/` + id, productData).toPromise()
    return awaitUpdatedProductStates
  }
}
