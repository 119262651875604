import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AllstatesService } from 'src/app/services/allstates.service';
import { SignUpPageShiftService } from 'src/app/services/sign-up-page-shift.service';

@Component({
  selector: 'app-three-company-info',
  templateUrl: './three-company-info.component.html',
  styleUrls: ['./three-company-info.component.css']
})
export class ThreeCompanyInfoComponent implements OnInit {
  isLoading: boolean = false;
  signupform: FormGroup
  validationMessages = {
    'companyName': {
      'required': 'Business name is required',
      'maxlength': 'Business name cannot have more than 100 characters!'
    },
    'pinCode': {
      'required': 'Pincode is required',
    },
    'GST_Id': {
      'required': 'Pincode is required',
    }
  }
  //js object to display form errors
  formErrors = {
    'companyName': '',
    'pinCode': '',
    'GST_Id': '',
  }
  selectedPincode: string = '';
  twoAccountData: any
  pincodeFullInfo: any = [];
  pinLocalities;
  stateName: string = '';
  districtName: string = '';
  isRegistered: boolean = false
  isGSTAvailable: boolean = false
  localityName: string;
  constructor(
    private pageShift: SignUpPageShiftService,
    private _snackBar: MatSnackBar,
    private stateService: AllstatesService,
    private router: Router,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.signupform = new FormGroup({
      //generic
      companyName: new FormControl('', {
        validators: [Validators.maxLength(100)]
      }),
      pinCode: new FormControl(null, {
        validators: [Validators.required, Validators.maxLength(6)]
      }),
      GST_Id: new FormControl('', {
        validators: [Validators.minLength(15), Validators.maxLength(15)]
      }),
    });
    this.pageShift.currentData.subscribe(data => {
      if (!data) {
        this.router.navigate(['/signup'])
      }
      this.twoAccountData = data
    });


    this.signupform.valueChanges.subscribe(data => {
      this.onValueChanged(data)
    });
    this.onValueChanged();
  }
  onValueChanged(data?: any) {
    if (!this.signupform) {
      return;
    }
    const sform = this.signupform;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        //clear previous messages if any
        this.formErrors[field] = '';
        const control = sform.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              if (messages[key] === undefined) {
                messages[key] = ''
              }
              this.formErrors[field] += messages[key] + '';
            }
          }
        }
      }
    }
  }

  async getPincodeDetails() {
    if (this.selectedPincode.length == 6) {
      const pincodeInfo = await this.stateService.getPincodeDetails(this.selectedPincode)
      this.pincodeFullInfo = pincodeInfo.pinCodeInfo
      this.stateName = this.pincodeFullInfo[0].State
      this.districtName = this.pincodeFullInfo[0].District

      // console.log(
      //   'locval',
      //   this.signupform.get('addressLocality').value
      // )

      // on new pincode enter - change form value for locality - add new from newly fetched data
      // this.signupform.controls['addressLocality'].setValue("");
      //find all different localities from pincode
      const localities = [...new Set(this.pincodeFullInfo.map((obj: any) => obj.Name))];
      this.pinLocalities = localities
      this.localityName = ""
    }
  }
  onSignup() {
    try {

      if (this.twoAccountData.accountTypeName === 'Seller') {
        this.signupform.controls['companyName'].setValidators([Validators.required]);
        this.signupform.controls['pinCode'].setValidators([Validators.required]);
      } else if (this.twoAccountData.accountTypeName === 'Buyer') {
        this.signupform.value.companyName = ""
        this.signupform.value.pinCode = null
        this.signupform.value.GST_Id = ""
        this.signupform.controls['companyName'].clearValidators();
        this.signupform.controls['pinCode'].clearValidators();
        this.signupform.controls['GST_Id'].clearValidators();
        //later update GST and PAN too 
      }

      this.signupform.controls['companyName'].updateValueAndValidity();
      this.signupform.controls['pinCode'].updateValueAndValidity();
      this.signupform.controls['GST_Id'].updateValueAndValidity();

      if (this.signupform.invalid) {
        this.signupError("Add Verification Details", "")
        return
      }
      if (this.signupform.valid) {
        if (this.signupform.value.GST_Id && this.signupform.value.GST_Id !== null && this.signupform.value.GST_Id !== undefined) {
          this.isGSTAvailable = true
        }
        this.isLoading = true; //spinner starts
        this.authService.createUser(
          this.twoAccountData.accountTypeName,
          this.twoAccountData.subAccountTypesChosen,
          this.twoAccountData.personName,

          this.twoAccountData.accountEmail,
          this.twoAccountData.phone,
          this.twoAccountData.password,

          this.signupform.value.companyName,
          this.signupform.value.pinCode,
          this.stateName,
          this.districtName,
          this.isGSTAvailable,
          false,
          this.signupform.value.GST_Id,
        ).subscribe(response => {
          this.signupSuccessful(response.message, "Ok")
          this.isLoading = false; //spinner starts
          this.router.navigate(["/"]);
        },
          //second argument of subscribe
          error => {
            this.isLoading = false; //spinner starts
            // this.authStatusListener.next(false);
            let sendErrmessage = ''
            if (error) {
              if (error.status = 400) {
                if (this.twoAccountData.accountEmail && this.twoAccountData.accountEmail !== "") {
                  sendErrmessage = `${this.twoAccountData.accountEmail} is already registered ! Choose Another`
                }
                if (this.twoAccountData.phone && this.twoAccountData.phone !== "") {
                  sendErrmessage = `Already registered with number ${this.twoAccountData.phone}! Choose Another`
                }
                if (this.twoAccountData.phone && this.twoAccountData.accountEmail && this.twoAccountData.phone !== "" && this.twoAccountData.accountEmail !== "") {
                  sendErrmessage = `${this.twoAccountData.accountEmail} is already registered with number ${this.twoAccountData.phone}! Choose Another`
                }
              }
              this.signupError(`${sendErrmessage}`, "Retry")
            }
          });
      }
    }

    catch (e) {
    }
  }

  signupSuccessful(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }

  signupError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }
}
