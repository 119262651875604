<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Premium Products </h1>
            <h3>Buy at Mandi Price </h3>
        </div>
        <mat-spinner></mat-spinner>
    </div>
</div>
<div class="featured-banner" *ngIf="!isLoading">
    <div class="wrapper">
        <div class="product-grid">
            <div *ngFor="let featuredListing of this.featuredListings  | slice:0:6; let i=index"
                class="product-card small-card">
                <img [routerLink]="['/Listing/Place_Bulk_Order',featuredListing._id,featuredListing.categoryUnique,featuredListing.productName]"
                    [src]="featuredListing.imageUrls[0]" alt="{{featuredListing.title}}">
                <div [routerLink]="['/Listing/Place_Bulk_Order',featuredListing._id,featuredListing.categoryUnique,featuredListing.productName]"
                    class="info">
                    <h3>{{featuredListing.title}}</h3>
                    <p>{{(featuredListing.bulkPrice).toLocaleString('en-IN')}} ₹ /{{featuredListing.useUnitShort}}</p>
                </div>
            </div>
            <div *ngFor="let featuredListing of this.featuredListings  | slice:6:12; let i=index"
                class="product-card small-card">
                <img [routerLink]="['/Listing/Place_Bulk_Order',featuredListing._id,featuredListing.categoryUnique,featuredListing.productName]"
                    [src]="featuredListing.imageUrls[0]" alt="{{featuredListing.title}}">
                <div [routerLink]="['/Listing/Place_Bulk_Order',featuredListing._id,featuredListing.categoryUnique,featuredListing.productName]"
                    class="info">
                    <h3>{{featuredListing.title}}</h3>
                    <p>{{(featuredListing.bulkPrice).toLocaleString('en-IN')}} ₹ /{{featuredListing.useUnitShort}}</p>
                </div>
            </div>
        </div>
    </div>
</div>