import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { SubscriptionModel } from 'src/app/datamodels/Subscription/subscriptionModel';
import { HandlePaymentsService } from 'src/app/services/handle-payments.service';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-view-subscription',
  templateUrl: './view-subscription.component.html',
  styleUrls: ['./view-subscription.component.css']
})
export class ViewSubscriptionComponent implements OnInit {
  subId: number;
  allSubs: SubscriptionModel[] = [];
  loginUserInfo: any
  currentUserId: any;
  generatedSubsOrderID: string;
  isLoading: boolean = false;
  displayPostSuccess: boolean = false;
  reqReceivedMessage: string
  constructor(
    private subs: SubscriptionsService,
    private authService: AuthService,
    private hpmnts: HandlePaymentsService,
    private utils: UtilsService) { }

  ngOnInit(): void {
    this.isLoading = true
    this.loadAllSubs()
    this.setLoginInfoData()
  }
  setLoginInfoData() {
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
  }
  loadAllSubs() {
    try {
      this.subs.getAllSubs().then(totalSubs => {
        this.allSubs = totalSubs.subs
        this.isLoading = false
        // const ChangArr = this.allSubs.forEach(subApps => {
        //   console.info('subApps', subApps)
        //   const newarr = this.renameKeys(
        //     {
        //       appFeats: 'App Features',
        //       digitalFeats: 'Digital Features',
        //       salesFeats: 'Sales Features',
        //     }
        //     , subApps.subAppFeatures);
        //   console.info('newarr', newarr)
        // })
        // console.info('ChangArr', ChangArr)

      })
    }
    catch (e) {
      throw e
    }
  }
  bookSubs(selectedSub: SubscriptionModel) {
    this.isLoading = true
    const vyayak = selectedSub.subPrice
    const dayakid = this.currentUserId
    const dayakemail = this.loginUserInfo.email || ''
    const dayakphone = this.loginUserInfo.phone || null
    const byoraId = ''
    const requestedUserInfo = {
      userId: this.currentUserId,
      fullName: this.loginUserInfo.firstname + ' ' + this.loginUserInfo.lastname,
      companyName: this.loginUserInfo.companyName,
      GST: this.loginUserInfo.GST_Id
    }
    this.subs.newSubscription(selectedSub, requestedUserInfo).then(res => {
      this.isLoading = false
      if (res && res.status === 201) {
        this.displayPostSuccess = true
        this.utils.scrollTop()
        this.reqReceivedMessage = res.message
      }
      // this.generatedSubsOrderID = res.createdSubsID._id
      // this.removePmntLink()
      // this.isPaymentWindow = false
      // this.isLoading = false
      // this.router.navigate([`viewPurchaseOrder/${this.generatedPOID}`])
      // return
      // this.incartService.deleteMultipleItemfromCart(this.allItemIDsOfCart, this.cartId).subscribe(
      //   deleted => {
      //     this.isLoading = false; //spinner starts
      //     if (deleted && deleted.status === "201") {
      //       this.userCart = deleted.cart
      //       if (this.userCart) {
      //         this.authService.createCacheCart(this.userCart)
      //         this.router.navigate([
      //           `viewPurchaseOrder/${this.generatedPOID}`
      //         ])
      //       }
      //     }
      //   }
      // )
    })
  }

}
