<div class="content">
    <div class="banner">
        <div class="overlay">
            <h1 class="fade-in-text">Get Verified ✔</h1>
        </div>
    </div>
    <div class="signUpBox">
        <mat-card>
            <mat-spinner *ngIf="isLoading"></mat-spinner>
            <form id="formSignUp" [formGroup]="this.signupform" (submit)="onSignup()" *ngIf="!isLoading">
                <div class="conditionalRequired">
                    <mat-form-field appearance="outline">
                        <input matInput type="text" name="companyName" formControlName="companyName"
                            placeholder="Name of your Business">
                        <mat-error *ngIf="formErrors.companyName">{{formErrors.companyName}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>PIN Code</mat-label>
                        <input [(ngModel)]="selectedPincode" (ngModelChange)="getPincodeDetails()" matInput
                            formControlName="pinCode" autocomplete="off" maxlength="6" pattern="[0-9]*" />
                        <mat-hint style="margin-right: 13px;">
                            {{this.stateName
                            }}
                            &nbsp;&nbsp; {{this.districtName}}
                        </mat-hint>

                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <input matInput type="text" name="GST" formControlName="GST_Id" placeholder="GST Number"
                            maxlength="15">
                        <mat-error *ngIf="formErrors.GST_Id">{{formErrors.GST_Id}}</mat-error>
                        <mat-hint>GST Verification</mat-hint>
                    </mat-form-field>
                </div>
                <div class="actions">
                    <div class="actionButton">
                        <button raised-button type="submit" mat-raised-button>Sign Up</button>
                    </div>
                </div>
            </form>
        </mat-card>
    </div>
</div>