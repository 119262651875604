<div class="wrapper">
    <div class="escapefilter">
        <button class="closebutton" mat-stroked-button color="primary" tabindex="1" (click)="onNoClick()">
            <mat-icon style="font-size: 20px;">close</mat-icon>
        </button>
    </div>
    <mat-form-field class="dialog-form-field">
        <mat-label>Filter</mat-label>
        <input tabindex="0" type="text" autocomplete="off" [(ngModel)]="SearchName" (ngModelChange)="SearchTerm()"
            matInput placeholder=" Search Category / State">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="myvalue()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    
            <span class="example-list-section" style="color: rgb(0, 0, 0); font-weight:normal;">
            </span>
            <div *ngFor='let statesCategory of StateFilterArray '>
                <span style="color: rgb(37, 37, 37); font-weight:light;">
                    <mat-checkbox name="{{statesCategory.id}}" [value]="statesCategory.id"
                        [(ngModel)]="statesCategory.selected" (ngModelChange)="updateAllCompleteStates()">
                        {{
                        statesCategory.name
                    }}
                    </mat-checkbox>
                </span>
            </div>

        
    <mat-dialog-actions>
        <button mat-button mat-dialog-close="true" (click)="onApplyFilter()">Apply</button>
        <button mat-button mat-dialog-close="false" (click)="onNoClick()">Cancel</button>
        <button mat-button (click)="onReset()">Reset</button>
    </mat-dialog-actions>
</div>