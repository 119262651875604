import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { VarietyofproductsComponent } from '../components/varietyofproducts/varietyofproducts.component';
import { FilterVarietiesComponent } from '../components/filter-varieties/filter-varieties.component';


@Injectable({
  providedIn: 'root'
})
export class VarietydialogmanagerService {

  
  constructor(private newdialog:MatDialog) { }
  public checkVariety(data:any=[]):Observable<any>{

  var threedialogConfig=new MatDialogConfig()

  threedialogConfig.data=data;
  let threedialogRef:MatDialogRef<FilterVarietiesComponent>

  threedialogRef=this.newdialog.open(FilterVarietiesComponent,threedialogConfig);
    return threedialogRef.afterClosed();
  }
}
