import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HandleListingsService } from '../../../services/handle-listings.service'

@Component({
  selector: 'app-delete-listing-dialog',
  templateUrl: './delete-listing-dialog.component.html',
  styleUrls: ['./delete-listing-dialog.component.css']
})
export class DeleteListingDialogComponent implements OnInit {
  deleteListing: string;
  isLoading: boolean;
  listingId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public editCatData: any,
    public dialogRef: MatDialogRef<DeleteListingDialogComponent>,
    private handlingListing: HandleListingsService,
    private router: Router
    ) { }

  ngOnInit(): void {
  }
  async onDeleteListing() {
    //spinner starts
    // this.isLoading = true; 
    this.listingId=this.editCatData.data
    await this.handlingListing.deleteListing(this.listingId).then(res => {
      //spinner stops
      this.isLoading = false;
      this.router.navigate(['/viewListing']);
    })
  }
}
