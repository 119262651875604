import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }
    canActivate(route: import("@angular/router").ActivatedRouteSnapshot,
        state: import("@angular/router").RouterStateSnapshot): boolean
        | import("@angular/router").UrlTree
        | import("rxjs").Observable<boolean
            | import("@angular/router").UrlTree> | Promise<boolean
                | import("@angular/router").UrlTree> {
        //getisauth gets current loggedin or loggedout information as boolean from authservice
        const isAuth = this.authService.getIsAuth();
        if (!isAuth) {
            //if not authenticated
            this.router.navigate(['/login'])
        }
        return isAuth
        //router lets access when return true, router denies access to route if returned false
    }
}