import { Component, Input, OnInit } from '@angular/core';
import { VarietyItems } from 'src/app/datamodels/varietyModel';
import { Router } from '@angular/router';
import { HandleListingsService } from 'src/app/services/handle-listings.service';
import { ListingModel } from 'src/app/datamodels/ListingModel';
import { VarietyServiceService } from 'src/app/services/variety-service.service';
interface titSub {
  id: number
  title: string;
}
@Component({
  selector: 'app-landingvarieties',
  templateUrl: './landingvarieties.component.html',
  styleUrls: ['./landingvarieties.component.css']
})
export class LandingvarietiesComponent implements OnInit {
  @Input() appBannerslandingvarieties: string[];
  featuredVarietyListings: ListingModel[] = [];
  selectednonfeaturedVariety: VarietyItems[]
  HoldnonFeatureVar: VarietyItems[] = []
  nonfeaturedVariety: VarietyItems;
  featureTrue: boolean;
  toggleProperty = false;
  isLoading = false; //spinner initialized
  titleSubtitle: titSub[] = [
    {
      id: 1,
      title: "Long Grain Basmati Rice From Punjab and Haryana",
    },
    {
      id: 2,
      title: "Large Green Cardamom From Kerela and Black Cardamom from Sikkim",
    },
    {
      id: 3,
      title: "Hottest Chilli From Kashmir and Andhra",
    },
    {
      id: 4,
      title: "Fresh Peppermint Oil From Uttar Pradesh",
    },
    {
      id: 5,
      title: "Original Mustard Oil From Rajasthan",
    },
  ]
  LoadingText: string = ''
  constructor(public productvarietyService: VarietyServiceService,
    private handlingListing: HandleListingsService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.LoadingText = this.titleSubtitle[Math.floor(Math.random() * this.titleSubtitle.length)].title;
    this.loadRecentVarietyListings();
  }
  // LoadNonFeaturedVarieties() {
  //   this.isLoading = true; //spinner starts
  //   this.productvarietyService.getProductVarieties().subscribe((res) => {
  //     this.isLoading = false; //spinner stops
  //     this.featuredVarietyListings = res.nofeaturevarieties
  //   })
  // }

  loadRecentVarietyListings() {
    this.isLoading = true
    this.handlingListing.getAllListings().subscribe(res => {
      if (res) {
        const useFeaturedProds = res.filter(listings => {
          return listings.varietyName != "" && listings.varietyName != "null" && listings.varietyName != null
        })
        const key1 = '_id';
        const last10UniqueListings = [...new Map(useFeaturedProds.map(listing =>
          [listing[key1], listing])).values()]
        const key2 = 'varietyName';
        this.featuredVarietyListings = [...new Map(last10UniqueListings.map(listing =>
          [listing[key2], listing])).values()].slice(-15)
        this.isLoading = false
      }

    })
  }

  onSelectVariety(varietyName: string) {
    this.router.navigate(['/showListingVariety', varietyName])
  }

}
