import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payhandlingfees',
  templateUrl: './payhandlingfees.component.html',
  styleUrls: ['./payhandlingfees.component.css']
})
export class PayhandlingfeesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
