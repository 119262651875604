<mat-sidenav-container class="side-nav-container">
    <mat-sidenav mode="side" opened class="sidenav">
        <mat-action-list>
            <button mat-list-item (click)="index = 0"> tab 1 </button>
            <button mat-list-item (click)="index = 1"> tab 2 </button>
          </mat-action-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <app-stateproductcategory [(index)]=index></app-stateproductcategory>
    </mat-sidenav-content>
  </mat-sidenav-container>