<div class="contentAbout">
    <div *ngIf="this.usePost">
        <div class="serviceCard part1">
            <div class="serviceBanner1">
                <div class="centered">
                    <h1 class="heading"> {{this.usePost.imgH1}}</h1>
                </div>
                <img [src]="this.usePost.imgLink1" alt="Myapaar about platform" style="width:100%;">
            </div>
            <div class="contentAboutusteam">
                <div class="contentHeading">
                    <h1 class="mainHead">
                        {{this.usePost.head1}}
                    </h1>
                    <h3 class="subHead">
                        {{this.usePost.subhead1}}
                    </h3>
                </div>
                <div class="mainContent">
                    <p>
                        {{this.usePost.p1}}
                    </p>
                </div>
            </div>
        </div>
        <div class="serviceCard part2">
            <div class="contentAboutusteam">
                <div class="contentHeading">
                    <h1 class="mainHead">
                        {{this.usePost.head2}}
                    </h1>
                    <h3 class="subHead">
                        {{this.usePost.subhead2}}
                    </h3>
                </div>
                <div class="mainContent">
                    <p>
                        {{this.usePost.p2}}
                    </p>
                </div>
            </div>
            <div class="serviceBanner2">
                <div class="centered">
                    <h1 class="heading"> {{this.usePost.imgH2}}</h1>
                </div>
                <img [src]="this.usePost.imgLink2" alt="Myapaar Buy Now Pay Later" style="width:100%;">
            </div>
        </div>
        <div class="serviceCard part3">
            <div class="serviceBanner3">
                <div class="centered">
                    <h1 class="heading"> {{this.usePost.imgH3}}</h1>
                </div>
                <img [src]="this.usePost.imgLink3" alt="myapaar bulk selling bulk buying savings" style="width:100%;">
            </div>
            <div class="contentAboutusteam">
                <div class="contentHeading">
                    <h1 class="mainHead">
                        {{this.usePost.head3}}
                    </h1>
                    <h3 class="subHead">
                        {{this.usePost.subhead3}}
                    </h3>
                </div>
                <div class="mainContent">
                    <p> {{this.usePost.p3}}</p>
                </div>
            </div>
        </div>
        <div class="serviceCard part4">
            <div class="contentAboutusteam2">
                <div *ngFor="let seelist of this.usePost.list" class="servicesList">
                    <p class="desc de1">
                        {{seelist}}
                    </p>
                </div>
                <div class="finalClosing">
                    <h1>
                        {{this.usePost.answer}}
                    </h1>
                </div>
            </div>
        </div>
    </div>
</div>