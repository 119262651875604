import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class ChatServiceService {
  private domain: string | undefined
  constructor(private http: HttpClient, private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }

  async firstOptionChosen(optionType): Promise<any> {
    const widgetOp = optionType
    const responseSubmitted = await this.http.put<any>(`${this.domain}api/userChatRoutes/firstInteraction/`, widgetOp).toPromise()
    return responseSubmitted
  }

  async getOldInteractions(userid: string): Promise<any> {
    const allInteractions = await this.http.get<any>(`${this.domain}api/userChatRoutes/allInteractions/` + userid).toPromise()
    return allInteractions
  }
}
