import { Injectable } from '@angular/core';
import { ProductCategoryModel } from '../datamodels/productcategory';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from './settings.service';
@Injectable({
  providedIn: 'root'
})
export class ProductcategoryService {
  private categories: ProductCategoryModel[] = [];
  private categoriesSend = new Subject<ProductCategoryModel[]>();
  private domain: string | undefined

  constructor(private http: HttpClient,
    private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }
  // getProductCategories():Observable<ProductCategoryModel[]>{
  //   return this.http.get<ProductCategoryModel[]>(baseURL+'ProductCategories')
  // }
  getCategories(): Observable<ProductCategoryModel[]> {
    return new Observable(
      categoryObserver => {
        if (this.categories && this.categories.length > 0) {
          categoryObserver.next(this.categories)
          return categoryObserver.complete()
        }
        this.http.get<{
          allCategories: ProductCategoryModel[];
          status: number
        }>(`${this.domain}api/categories/findAll/`)
          .subscribe((fetchedCategories) => {
            this.categories = fetchedCategories.allCategories;
            categoryObserver.next(this.categories);
            categoryObserver.complete();
          });
      }
    )
  }

  getCategoriesListener() {
    return this.categoriesSend.asObservable();
  }

  getProductsforCategoryUniqueSelected(categoryUnique: string): Observable<any> {
    let params1 = new HttpParams().set("categoryUnique", categoryUnique)
    return this.http.get<any>(`${this.domain}api/products/`, { params: params1 })
  }

  async updateProductCategoryListing(id: string, stateName: string): Promise<{ status: number }> {
    // let productName = new HttpParams().set("productUnique", productName)
    const CategoryData = new FormData();
    CategoryData.append("categoryStates", stateName);
    const updateCategoryState = await this.http.put<{ status: number }>(`${this.domain}api/categories/` + id, CategoryData).toPromise()
    return updateCategoryState
  }
}
