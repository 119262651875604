<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Purchase Order </h1>
            <h3>Complete 10 Orders Successfully to improve Ratings</h3>
        </div><mat-spinner></mat-spinner>
    </div>
</div>

<div class="contentPurchase">
    <div *ngIf="this.currentPurchaseOrderwithId">
        <div *ngIf="this.currentPurchaseOrderwithId.poStatus==='New'" class="action">
            <div class="buttonset">
                <div class="act download">
                    <a href="{{this.currentPurchaseOrderwithId.s3POpdfLink}}" target="_blank" rel="noopener noreferrer"
                        mat-raised-button style="background-color: rgb(35, 130, 246); font-weight: 450;color: white;">
                        <span>Download</span>
                    </a>
                </div>

                <div *ngIf="this.sellerAccountType">
                    <div *ngIf="!this.isUserActingBuyer && this.isUserActingSeller" class="act gen-invoice">
                        <button mat-raised-button (click)="acceptPurchaseOrder(this.currentPurchaseOrderwithId)"
                            style="background-color: rgb(116, 186, 31); font-weight: 450;color: rgb(255, 255, 255);">
                            <span>Accept Purchase Order</span>
                        </button>
                    </div>
                </div>
            </div>


            <!-- a seller can have generate inoice towards orders that other buyers created
            but also have a download invoice button depending upon if 
            the order 1sellerAccount created towards 2sellerAccount -->

            <!-- we need to bifurcate 
            - if 1seller/sellertyp-thisaccount - need to show 
            generate Inovoice button if the userId matches with sellerId on view-p-o pageBanner
            - if 1seller/buyer-type-thisAccount - show status of invoice generated towards his order
            on this page and give button to download invoice/viewInvoice -->
            <div class="buttonset">
                <!-- cannot cancel purchase order if invoice status is active  -->
                <div *ngIf="this.currentPurchaseOrderwithId.invoiceVersions.length === 0" class="act cancel">
                    <button mat-raised-button style="background-color: rgb(193, 21, 21); font-weight: 450;color: white;"
                        (click)="cancelPurchaseOrderStatus(this.currentPurchaseOrderwithId)">
                        <span>Cancel Order</span>
                    </button>
                </div>
                <div class="act dispute">
                    <button [routerLink]=" ['/reportPOproblem',this.currentPurchaseOrderwithId._id]" mat-raised-button
                        style=" background-color: #171717; font-weight: 450;color: white;">
                        <span>Report Problem</span>
                    </button>
                </div>
            </div>

        </div>

        <div *ngIf="this.currentPurchaseOrderwithId.poStatus==='Active'" class=" action">

            <!-- if Po is active and not yet delivered
            seller can - download PO,viewAllPos, cancelPO, raiseDispute
            buyer can - download PO, viewAllPos,cancelPO, raiseDisputeButtons -->
            <div class="buttonset">
                <div class="act download">
                    <a href="{{this.currentPurchaseOrderwithId.s3POpdfLink}}" target="_blank" rel="noopener noreferrer"
                        mat-raised-button style="background-color: rgb(35, 130, 246); font-weight: 450;color: white;">
                        <span>Download</span>
                    </a>
                </div>
                <div class="act dispute">
                    <button [routerLink]=" ['/reportPOproblem',this.currentPurchaseOrderwithId._id]" mat-raised-button
                        style="
                        background-color: #171717; font-weight: 450;color: white;">
                        <span>Report Problem</span>
                    </button>
                </div>
            </div>

            <div class="buttonset">

                <!-- Seller login   -->
                <div *ngIf="this.sellerAccountType">

                    <!-- Seller Acting as Seller if balance is due - allow to create more invoices / else view all invoices page -->
                    <div *ngIf="this.isUserActingSeller" class="act gen-invoice">
                        <div *ngIf="this.allowMoreInvoices">
                            <button mat-raised-button
                                [routerLink]=" ['/createInvoice',this.currentPurchaseOrderwithId._id]"
                                style="background-color: rgb(116, 186, 31); font-weight: 450;color: rgb(255, 255, 255);">
                                <span *ngIf="this.currentPurchaseOrderwithId.invoiceVersions.length===0">Ship and
                                    Invoice</span>
                                <span *ngIf="this.currentPurchaseOrderwithId.invoiceVersions.length>0">Ship and Invoice
                                    V{{this.currentPurchaseOrderwithId.invoiceVersions.length + 1}}</span>
                            </button>
                        </div>
                    </div>
                    <!-- Seller Acting as Buyer - if balance is due - allow to demand invoices / else view all invoices page -->
                </div>

                <!-- Buyer Login  -->
                <div *ngIf="this.buyerAccountType || this.isUserActingBuyer">
                    <button *ngIf="this.currentPurchaseOrderwithId.invoiceVersions.length===0 && this.allowMoreInvoices"
                        mat-raised-button
                        style="background-color: rgb(116, 186, 31); font-weight: 450;color: rgb(255, 255, 255);">
                        <!-- no invoice raised but po active - buyer can demand invoice  -->
                        <span (click)="demandInvoice(this.currentPurchaseOrderwithId._id)">Demand
                            Invoice</span>
                        <!-- all items delivered with invoices  -->
                    </button>

                    <!-- <div class="act return">
                        <button [routerLink]="['/createReturn/Completed',this.currentPurchaseOrderwithId._id]"
                            mat-raised-button
                            style="background-color: rgb(167, 3, 136); font-weight: 450;color: white;">
                            <span>Return Items</span>
                        </button>
                    </div> -->
                </div>

                <!-- cannot cancel purchase order if invoice status is active  -->
                <div *ngIf="this.currentPurchaseOrderwithId.invoiceVersions.length === 0" class="act cancel">
                    <button mat-raised-button style="background-color: rgb(193, 21, 21); font-weight: 450;color: white;"
                        (click)="cancelPurchaseOrderStatus(this.currentPurchaseOrderwithId)">
                        <span>Cancel Order</span>
                    </button>
                </div>
            </div>
        </div>

        <!-- Delivered PO cannot have download Invoice, generate Inv or cancel buttons, just download PO and allPOs buttons -->
        <!-- for a seller-asBuyer  , delivered PO will have download Invoice , download PO, viewAllPO, raiseDisputeButtons -->
        <!-- for a seller-asSeller  , delivered PO will have download Invoice , download PO,generate Invoice, viewAllPO, raiseDisputeButtons -->
        <!-- logged in account is seller type  -->
        <div *ngIf="this.currentPurchaseOrderwithId.poStatus==='Delivered'" class=" action">
            <div class="buttonset">
                <div class="act download">
                    <a href="{{this.currentPurchaseOrderwithId.s3POpdfLink}}" target="_blank" rel="noopener noreferrer"
                        mat-raised-button style="background-color: rgb(35, 130, 246); font-weight: 450;color: white;">
                        <span>Download</span>
                    </a>
                </div>

                <div *ngIf="this.buyerAccountType || this.isUserActingBuyer">
                    <button *ngIf="this.currentPurchaseOrderwithId.invoiceVersions.length===0 && this.allowMoreInvoices"
                        mat-raised-button
                        style="background-color: rgb(116, 186, 31); font-weight: 450;color: rgb(255, 255, 255);">
                        <span (click)="demandInvoice(this.currentPurchaseOrderwithId._id)">Demand
                            Invoice</span>
                    </button>

                    <div *ngIf="atleast1InvoDeliveredMode && checkIfReturnableIn7 && checkIfReturnableIn7 < 7"
                        class="act return">
                        <button [routerLink]="['/createReturn/Completed',this.currentPurchaseOrderwithId._id]"
                            mat-raised-button
                            style="background-color: rgb(167, 3, 136); font-weight: 450;color: white;">
                            <span>Return Items</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="buttonset">
                <div class="act dispute">
                    <button [routerLink]=" ['/reportPOproblem',this.currentPurchaseOrderwithId._id]" mat-raised-button
                        style="background-color: #171717; font-weight: 450;color: white;">
                        <span>Report Problem</span>
                    </button>
                </div>
            </div>

        </div>

        <div *ngIf="this.currentPurchaseOrderwithId.poStatus==='Cancelled'" class=" action">
            <!-- Cancelled PO cannot have download Invoice, generate Inv or cancel buttons, just download PO and allPOs buttons -->
            <div class="buttonset">
                <div class="act download">
                    <a href="{{this.currentPurchaseOrderwithId.s3POpdfLink}}" target="_blank" rel="noopener noreferrer"
                        mat-raised-button style="background-color: rgb(35, 130, 246); font-weight: 450;color: white;">
                        <span>Download</span>
                    </a>
                </div>
            </div>
            <div class="buttonset">
                <div class="act dispute">
                    <button [routerLink]=" ['/reportPOproblem',this.currentPurchaseOrderwithId._id]" mat-raised-button
                        style="background-color: #171717; font-weight: 450;color: white;">
                        <span>Report Problem</span>
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="this.currentPurchaseOrderwithId.poStatus==='Paid'" class="action">
            <div class="buttonset">
                <div class="act download">
                    <a href="{{this.currentPurchaseOrderwithId.s3POpdfLink}}" target="_blank" rel="noopener noreferrer"
                        mat-raised-button style="background-color: rgb(35, 130, 246); font-weight: 450;color: white;">
                        <span>Download</span>
                    </a>
                </div>
            </div>
            <div class="buttonset">
                <div class="act dispute">
                    <button [routerLink]=" ['/reportPOproblem',this.currentPurchaseOrderwithId._id]" mat-raised-button
                        style=" background-color: #171717; font-weight: 450;color: white;">
                        <span>Report Problem</span>
                    </button>
                </div>
            </div>

        </div>


        <div class="issuesFound" *ngIf="this.issueForPOExist">

            <button routerLink="/viewIssues" mat-stroked-button color="primary" class="issueCount">
                {{this.issuesFound.length}} Issues Exist
            </button>
        </div>
        <div class="currentPurchaseOrder">
            <div class="sec poDetails">
                <div>
                    <p class="key POI">
                        Purchase Order ID/Number
                    </p>
                    <p class="value-L POI">
                        {{this.currentPurchaseOrderwithId.purchaseOrderNumber}}
                    </p>
                </div>

                <div>
                    <p class="key POS"> Status</p>
                    <p class="value-R POS">
                        <span style="color: rgb(200, 7, 7);"
                            *ngIf="this.currentPurchaseOrderwithId.poStatus==='Cancelled'">
                            {{this.currentPurchaseOrderwithId.poStatus}}
                        </span>
                        <span style="color: rgb(1, 142, 46);"
                            *ngIf="this.currentPurchaseOrderwithId.poStatus==='Active'">
                            {{this.currentPurchaseOrderwithId.poStatus}} <span
                                *ngIf="this.currentPurchaseOrderwithId.dateOfPurchaseOrderAccepted">on
                                {{this.currentPurchaseOrderwithId.dateOfPurchaseOrderAccepted| date}}</span>
                        </span>
                        <span style="color: rgb(0, 34, 229);" *ngIf="this.currentPurchaseOrderwithId.poStatus==='New'">
                            {{this.currentPurchaseOrderwithId.poStatus}}
                        </span>
                        <span style="color: rgb(0, 34, 229);" *ngIf="this.currentPurchaseOrderwithId.poStatus==='Paid'">
                            {{this.currentPurchaseOrderwithId.poStatus}}
                        </span>
                        <span style="color: rgb(24, 111, 2);"
                            *ngIf="this.currentPurchaseOrderwithId.poStatus==='Delivered'">
                            {{this.currentPurchaseOrderwithId.poStatus}}
                            <span *ngIf="this.currentPurchaseOrderwithId.dateOfPurchaseOrderDelivery">on
                                {{this.currentPurchaseOrderwithId.dateOfPurchaseOrderDelivery | date}}</span>
                        </span>
                    </p>
                </div>

            </div>
            <div class="sec poValuePaidAndRemaining">
                <div>
                    <p class="key POC">Amount Paid</p>
                    <p class="value-L POC">
                        {{(this.currentPurchaseOrderwithId.amountPaidAtCheckout).toLocaleString('en-IN')}} ₹</p>
                </div>
                <div>
                    <p class="key RAP">Amount Remaining</p>
                    <p class="value-R RAP">
                        {{(this.currentPurchaseOrderwithId.amountRemainingForInvoices).toLocaleString('en-IN')}} ₹</p>
                </div>
            </div>
            <div class="sec poValueAmount">
                <div>
                    <p class="key POP">Order Price Incl. Tax</p>
                    <p class="value-L POP">
                        {{(this.currentPurchaseOrderwithId.orderValueIncludingTax).toLocaleString('en-IN')}} ₹</p>
                </div>
                <div>
                    <p class="key POQ">Order Quantity</p>
                    <p *ngIf="this.currentPurchaseOrderwithId.orderQuantity > 1" class="value-R POQ">
                        {{this.currentPurchaseOrderwithId.orderQuantity}} ql.</p>
                    <p *ngIf="this.currentPurchaseOrderwithId.orderQuantity < 1" class="value-R POQ">
                        {{this.currentPurchaseOrderwithId.orderQuantity * 100000}} gm.</p>
                </div>
            </div>

            <div class="sec invoices">
                <div>
                    <p class="key invS">Invoice Status</p>
                    <p class="value-L invS">
                        <span *ngIf="this.currentPurchaseOrderwithId.invoiceStatus === 'Inactive'">
                            {{this.currentPurchaseOrderwithId.invoiceStatus}}</span>
                        <span *ngIf="this.currentPurchaseOrderwithId.invoiceStatus === 'Active'"
                            style="color:rgba(11, 155, 4, 0.915)">
                            {{this.currentPurchaseOrderwithId.invoiceStatus}}</span>
                        <span *ngIf="this.currentPurchaseOrderwithId.invoiceStatus === 'Cancelled'"
                            style="color:red">{{this.currentPurchaseOrderwithId.invoiceStatus}}</span>
                        <span *ngIf="this.currentPurchaseOrderwithId.invoiceStatus === 'Delivered'"
                            style="color: rgb(24, 111, 2);">{{this.currentPurchaseOrderwithId.invoiceStatus}}</span>
                        <span *ngIf="this.currentPurchaseOrderwithId.invoiceStatus === 'Paid'"
                            style="color: rgb(0, 34, 229);">{{this.currentPurchaseOrderwithId.invoiceStatus}}</span>
                    </p>
                </div>
                <div>
                    <p class="key-R invId">Invoice Nos.</p>
                    <!-- update invoiceVersions -->
                    <div *ngIf="this.allInvoiceNumbers && this.allInvoiceNumbers.length">
                        <div class="eachVersionInv" *ngFor="let invVer of this.allInvoiceNumbers">
                            <button mat-stroked-button>
                                <span [routerLink]="['/viewInvoiceOrder',invVer.invoiceID]" class="medium invId">
                                    V{{invVer.invoiceVersion}}
                                </span>
                                <span [routerLink]="['/viewInvoiceOrder',invVer.invoiceID]" class="toosmall invId">
                                    {{invVer.invoiceNumber}}
                                </span>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!this.allInvoiceNumbers">
                        <p class="value-R invId">Not Generated</p>
                    </div>
                </div>
            </div>
            <div *ngIf="this.currentPurchaseOrderwithId.returnVersions && this.currentPurchaseOrderwithId.returnVersions.length > 0"
                class="sec returns">
                <div>
                    <p class="key invS">Return Status</p>
                    <p class="value-L invS">
                        <span style="color:rgba(11, 155, 4, 0.915)">
                            Active</span>
                    </p>
                </div>
                <div>
                    <p class="key-R invId">Return Nos.</p>
                    <!-- update invoiceVersions -->
                    <div
                        *ngIf="this.currentPurchaseOrderwithId.returnVersions && this.currentPurchaseOrderwithId.returnVersions.length">
                        <div class="eachVersionReturn"
                            *ngFor="let retVr of this.currentPurchaseOrderwithId.returnVersions">
                            <button mat-stroked-button>
                                <span [routerLink]="['/viewReturnOrder',retVr.returnID]" class="medium invId">
                                    V{{retVr.version}}
                                </span>
                                <span [routerLink]="['/viewReturnOrder',retVr.returnID]" class="toosmall invId">
                                    {{retVr.returnNumber}}
                                </span>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!this.allInvoiceNumbers">
                        <p class="value-R invId">Not Generated</p>
                    </div>
                </div>
            </div>

            <div class="sec poDates">
                <div>
                    <p class="key PODG">Created Date</p>
                    <p class="value-L PODG"> {{this.currentPurchaseOrderwithId.dateOfPOGenerated | date}}</p>
                </div>
                <div *ngIf="this.currentPurchaseOrderwithId.poStatus==='New'">
                    <p class="key PODE"> Expiry Date</p>
                    <p class="value-R PODE"> {{this.currentPurchaseOrderwithId.dateOfPurchaseOrderExpiry |
                        date}}</p>
                </div>
            </div>
            <mat-divider> </mat-divider>
            <br>
            <div class="sec addressDetails">

                <div class="vendor_buy_address_details">
                    <div class="vendorDetail">
                        <p class="headingL sellerAddress">Seller Details &nbsp;&nbsp;
                        </p>
                        <span
                            class="highlightL businessName">{{this.currentPurchaseOrderwithId.sellerDetails.companyName
                            }} </span>
                        <span class="value-L sellerName">{{this.currentPurchaseOrderwithId.sellerDetails.firstname}}
                            {{this.currentPurchaseOrderwithId.sellerDetails.lastname}} </span>
                        <span class="value-L addressBuildingINfo ">
                            {{this.currentPurchaseOrderwithId.sellerDetails.addressBuildingNameAndFloor
                            }}</span>
                        <span class="value-L sellerPhone">{{this.currentPurchaseOrderwithId.sellerDetails.phone
                            }}</span>
                        <span class="value-L sellerEmail">{{this.currentPurchaseOrderwithId.sellerDetails.email
                            }}</span>
                        <div class="value-L sellerStreetPincode">
                            <span> {{this.currentPurchaseOrderwithId.sellerDetails.addressLocality}},</span>
                            <span> {{this.currentPurchaseOrderwithId.sellerDetails.pinCode}},</span>
                        </div>
                        <div class="value-L sellersCityState">
                            <span> {{this.currentPurchaseOrderwithId.sellerDetails.city }},</span>
                            <span> {{this.currentPurchaseOrderwithId.sellerDetails.state }} </span>
                        </div>
                        <div *ngIf="this.currentPurchaseOrderwithId.sellerDetails.isGSTVerified"
                            class="sellerGSTVerify">
                            <p class="value-L sellerGST">{{this.currentPurchaseOrderwithId.sellerDetails.GST_Id }}</p>
                            <p class="value-L sellerGST"> GST Verified <mat-icon>check_small</mat-icon></p>
                        </div>
                        <div *ngIf="!this.currentPurchaseOrderwithId.sellerDetails.isGSTVerified"
                            class="value-L sellerGSTVerify">
                            <p> GST Verification Pending <mat-icon>error_outline_small</mat-icon></p>
                        </div>
                    </div>
                    <div class="buyerDetail">
                        <div class="buyerAddressDetails">
                            <p class="headingR buyerAddress">Buyer Details</p>
                            <span class="highlightR businessName">
                                {{this.currentPurchaseOrderwithId.buyerDetails.buyerCompanyName
                                }}
                            </span>
                            <span
                                class="value-R buyerName">{{this.currentPurchaseOrderwithId.buyerDetails.buyerName}}</span>
                            <span class="value-R addressBuildingINfo ">
                                {{this.currentPurchaseOrderwithId.buyerDetails.buyerAddress.buildingName
                                }}</span>
                            <span class="value-R buyerPhone">
                                {{this.currentPurchaseOrderwithId.buyerDetails.buyerContactDetails.phone
                                }}</span>
                            <span class="value-R buyerEmail">
                                {{this.currentPurchaseOrderwithId.buyerDetails.buyerContactDetails.email
                                }}</span>
                            <div class="value-R buyerStreetPincode">
                                <span> {{this.currentPurchaseOrderwithId.buyerDetails.buyerAddress.streetName }},</span>
                                <span>{{this.currentPurchaseOrderwithId.buyerDetails.buyerAddress.pinCode }}</span>
                            </div>
                            <div class="value-R buyerCityState">
                                <span class="value-R buyerCity">
                                    {{this.currentPurchaseOrderwithId.buyerDetails.buyerAddress.city}},</span>
                                <span class="value-R buyerState">
                                    {{this.currentPurchaseOrderwithId.buyerDetails.buyerAddress.state }}</span>
                            </div>
                        </div>
                        <div class="shipToAddress">
                            <span class="headingR shipToAddress">Ship To Address</span>
                            <span
                                class="highlightR shipToName">{{this.currentPurchaseOrderwithId.buyerDetails.buyerName}}
                            </span>
                            <span class="value-R addressBuildingINfo ">
                                {{this.currentPurchaseOrderwithId.shipToBuyerLocation.buildingName}}</span>
                            <div class="value-R shipToStreetPincode">
                                <span>
                                    {{this.currentPurchaseOrderwithId.shipToBuyerLocation.streetName}}</span>
                                <span> {{this.currentPurchaseOrderwithId.shipToBuyerLocation.pinCode}}</span>
                            </div>
                            <div class="value-R shipToCityState">
                                <span> {{this.currentPurchaseOrderwithId.shipToBuyerLocation.city}}</span>
                                <span> {{this.currentPurchaseOrderwithId.shipToBuyerLocation.state}} </span>
                            </div>
                            <span class="value-R shipToLandmark">
                                {{this.currentPurchaseOrderwithId.shipToBuyerLocation.landMark}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sec itemDetails">
                <div class="Items">
                    <h1>Item Details</h1>
                    <div class="item" *ngFor="let item of this.itemDetailsinPO">
                        <div class="itemDescription">
                            <h2 class="itemTitle">{{item.itemTitle}}</h2>
                            <div class="itemDet">
                                <div class="details">
                                    <div class="value itemProdwithVar" *ngIf="item.productVariety!==''">
                                        {{item.productCategory}} |
                                        {{item.productName}} |
                                        {{item.productVariety}}
                                    </div>
                                    <div class="value itemProdnoVar" *ngIf="item.productVariety===''">
                                        {{item.productCategory}}
                                        |
                                        {{item.productName}}
                                    </div>
                                    <div class="value itemPackaging" *ngIf="item.optedPackagingType">
                                        {{item.optedPackagingType.packagingType}}
                                    </div>
                                </div>
                                <div class="packagingImg">
                                    <a class="packImg" [href]="item.optedPackagingType.packagingImg"
                                        target="_blank"><img [src]="item.optedPackagingType.packagingImg" /></a>
                                    <a class="packdesign" [href]="item.optedPackagingType.packagingImg"
                                        target="_blank">Packaging
                                        Design</a>
                                </div>
                            </div>
                            <div class="itmk orderQuant">Ordered Quantity
                                <span *ngIf="item.itemQuantity > 1" class="value orderQuant">{{item.itemQuantity}}
                                    {{item.useUnitShort}}</span>
                                <span *ngIf="item.itemQuantity < 1" class="value orderQuant">{{item.itemQuantity *
                                    100000}}
                                    gm.</span>
                                <span class="value itemPriceUnit"> {{item.itemPricePerUnit}} ₹ / {{item.useUnitShort}}
                                </span>
                            </div>
                            <div class="itmk totalPriceOfItemOrdered">Item Price incl Taxes
                                <!-- <span class="value totalPriceOfItemOrdered">{{item.itemPricePerUnit*item.itemQuantity}} -->
                                <span class="value totalPriceOfItemOrdered">{{(item.itemPriceWithTaxes
                                    ).toLocaleString('en-IN')}}
                                    ₹</span>
                            </div>
                        </div>
                        <mat-divider> </mat-divider>
                    </div>

                    <!-- add delivery charge if order Quantity is less than 1 quintal -->
                </div>
                <div class="deliveryCharges">
                    <div *ngIf="this.additionalBuyerCharges && this.additionalBuyerCharges.length > 0"
                        class="deliveryChargesOfBuyer">
                        <div>
                            <div *ngFor="let charges of this.additionalBuyerCharges" class="rowDelivery mainCol1">
                                <span class="col1">
                                    {{charges.chargeType}}
                                </span>
                                <span class="col2 LdeliveryChargestitle">
                                    {{charges.percentage}} %
                                </span>
                                <span class="col3 L deliveryChargesvalue">
                                    + {{charges.amount}} ₹
                                </span>
                            </div>
                            <div class="rowDelivery mainCol1">
                                <span class="col1">
                                    Total
                                </span>
                                <span class="col2 LdeliveryChargestitle">
                                </span>
                                <span class="col3 L deliveryChargesvalue">
                                    {{(this.totalDeliverycharges).toLocaleString('en-IN')}} ₹
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>