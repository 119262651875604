import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { CancelPurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/cancelPurchaseOrder';
interface PurchaseOrderCancelling {
  cancelCategory: number;
  cancelReasonDescription: string;
}

interface CancelPurchaseOrder {
  accountTypeOfUser: string;
  poStatusBeforeCancel: string;
  purchaseOrderGenerateDate: Date;
  purchaseOrderID: string;
  purchaseOrderNumber: string;
  userActingAsBuyer: boolean;
  userActingAsSeller: boolean;
  userIDcancelling: string;
}

@Component({
  selector: 'app-delete-purchase-order',
  templateUrl: './delete-purchase-order.component.html',
  styleUrls: ['./delete-purchase-order.component.css'],
})
export class DeletePurchaseOrderComponent implements OnInit {
  private userIsBuyerAndCancelling: PurchaseOrderCancelling[] = [

    {
      cancelCategory: 1,
      cancelReasonDescription: 'Items not required anymore'
    },
    {
      cancelCategory: 2,
      cancelReasonDescription: 'Ordered from other place'
    },
    {
      cancelCategory: 3,
      cancelReasonDescription: 'High price of items',
    },
    {
      cancelCategory: 4,
      cancelReasonDescription: 'Ratings are low',
    },
    {
      cancelCategory: 5,
      cancelReasonDescription: 'Advance payment issue',
    },
    {
      cancelCategory: 6,
      cancelReasonDescription: 'Packaging type not available',
    },
    {
      cancelCategory: 7,
      cancelReasonDescription: 'Others',
    },

  ]

  private userIsSellerAndCancelling: PurchaseOrderCancelling[] = [
    {
      cancelCategory: 1,
      cancelReasonDescription: 'Items Unvailable for Delivery'
    },
    {
      cancelCategory: 2,
      cancelReasonDescription: 'Delivery Address too Far'
    },
    {
      cancelCategory: 3,
      cancelReasonDescription: 'Advance Payment not made by Buyer',
    },
    {
      cancelCategory: 4,
      cancelReasonDescription: 'Buyer Credit Ratings are Low',
    },
    {
      cancelCategory: 5,
      cancelReasonDescription: 'Transport Issues',
    },
    {
      cancelCategory: 6,
      cancelReasonDescription: 'Packaging type not available',
    },
    {
      cancelCategory: 7,
      cancelReasonDescription: 'Others',
    }
  ]
  listReasonsForCancel: PurchaseOrderCancelling[] = []

  cancelPurchaseOrderForm: FormGroup;

  sellerIsSeller: boolean = false
  sellerIsBuyer: boolean = false
  buyerIsBuyer: boolean = false
  cancelPurchaseOrderResponse: CancelPurchaseOrderModel
  isPOCancelled: boolean = false
  constructor(@Inject(MAT_DIALOG_DATA) public cancelPurchaseOrderdata: any,
    public dialogRef: MatDialogRef<DeletePurchaseOrderComponent>,
    private handlePurchaseOrder: HandlePurchaseOrderService,

  ) {

  }


  formErrors = {
    'cancelCategory': 'Select Reason',
  }
  ngOnInit(): void {
    this.cancelPurchaseOrderForm = new FormGroup({
      cancelCategory: new FormControl(null, {
        validators: [Validators.required]
      }),
      cancelDescription: new FormControl(null, {
        validators: [Validators.required, Validators.maxLength(100)]
      }),
    })
    this.listcancelReasonsForUserTypes(this.cancelPurchaseOrderdata)
  }

  listcancelReasonsForUserTypes(cancelPurchaseOrderdata: CancelPurchaseOrderModel) {
    //Mainseller as seller
    if (cancelPurchaseOrderdata.accountTypeOfUser === 'Seller'
      && cancelPurchaseOrderdata.userActingAsSeller && !cancelPurchaseOrderdata.userActingAsBuyer) {
      this.sellerIsSeller = true

      this.sellerIsBuyer = false
      this.buyerIsBuyer = false
      this.listReasonsForCancel = this.userIsSellerAndCancelling
    }
    //Mainseller as buyer
    if (cancelPurchaseOrderdata.accountTypeOfUser === 'Seller'
      && cancelPurchaseOrderdata.userActingAsBuyer && !cancelPurchaseOrderdata.userActingAsSeller) {
      this.sellerIsBuyer = true

      this.sellerIsSeller = false
      this.buyerIsBuyer = false

      this.listReasonsForCancel = this.userIsBuyerAndCancelling
    }
    //MainBuyer
    if (cancelPurchaseOrderdata.accountTypeOfUser === 'Buyer') {
      this.buyerIsBuyer = true

      this.sellerIsSeller = false
      this.sellerIsBuyer = false

      this.listReasonsForCancel = this.userIsBuyerAndCancelling
    }

  }

  onCancelPurchaseOrder(cancelReasons: PurchaseOrderCancelling[]) {
    // get Reason Arrays according to buyertype
    // since value of dropdown is bound to category, list filter category and description from listed reasons
    const selectedReasonOfallReasonWithUserTypes = cancelReasons.filter(allListedReasonTypes => { return allListedReasonTypes.cancelCategory === this.cancelPurchaseOrderForm.value.cancelCategory })
    // patch value for formcontrol
    this.cancelPurchaseOrderForm.patchValue({
      'cancelDescription': selectedReasonOfallReasonWithUserTypes[0].cancelReasonDescription
    })

    const reasonForCanel: PurchaseOrderCancelling = {
      cancelCategory: this.cancelPurchaseOrderForm.value.cancelCategory,
      cancelReasonDescription: this.cancelPurchaseOrderForm.value.cancelDescription,
    }

    try {
      if (this.cancelPurchaseOrderForm.invalid || this.cancelPurchaseOrderForm == undefined) {
        return
      }
      if (this.cancelPurchaseOrderForm.valid) {
        // this.cancelPurchaseOrderForm.value.cancelReasonDescription = cancelReasons.cancelReasonDescription
        const cancelPurchaseOrderWithReason: CancelPurchaseOrderModel = {
          accountTypeOfUser: this.cancelPurchaseOrderdata.accountTypeOfUser,
          poStatusBeforeCancel: this.cancelPurchaseOrderdata.poStatusBeforeCancel,
          purchaseOrderGenerateDate: this.cancelPurchaseOrderdata.purchaseOrderGenerateDate,
          purchaseOrderID: this.cancelPurchaseOrderdata.purchaseOrderID,
          purchaseOrderNumber: this.cancelPurchaseOrderdata.purchaseOrderNumber,
          userActingAsBuyer: this.cancelPurchaseOrderdata.userActingAsBuyer,
          userActingAsSeller: this.cancelPurchaseOrderdata.userActingAsSeller,
          userIDcancelling: this.cancelPurchaseOrderdata.userIDcancelling,
          cancelReason: reasonForCanel
        }


        this.handlePurchaseOrder.cancelPurchaseOrderStatusOf(
          cancelPurchaseOrderWithReason
        ).then(responseCancelPO => {
          if (responseCancelPO && responseCancelPO.status === 201) {
            this.isPOCancelled = true
          }
        })
        // console.log('cancelPurchaseOrderResponse', this.cancelPurchaseOrderResponse)
        // if (this.cancelPurchaseOrderResponse.status === 201) {
        //   // this.isdiscountRequestPlaced = true
        // }
        // console.log('useThis?', this.cancelPurchaseOrderResponse)
      }
    }
    catch (e) {
      throw e
    }
  }
  onOKClick(isPOCancelled: boolean) {
    this.dialogRef.close(isPOCancelled);
  }
}
