import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry, delay, retryWhen, take, catchError } from "rxjs/operators";
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class HandlePaymentsService {
  private domain: string | undefined
  constructor(
    private http: HttpClient, private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }

  async startPay(docType: string, byoraId: string, vyayak: number, dayak: string, dayakemail: string, dayakphone: number): Promise<any> {
    const vyayform = new FormData()
    vyayform.append('docType', docType)
    vyayform.append('byoraId', byoraId)
    vyayform.append('mudraa', JSON.stringify(vyayak))
    vyayform.append('dayak', dayak)
    vyayform.append('dayakemail', dayakemail)
    vyayform.append('dayakphone', JSON.stringify(dayakphone))
    vyayform.append('vyay_sthit', 'Pending')
    // return this.http.get(`${this.domain}api/paymyapaar/vyay/` + vyayinfo, { responseType: 'text' }).toPromise()
    // const startVyay = await this.http.post(`${this.domain}api/vyayPO/vyay/`, vyayform, { responseType: 'text' }).toPromise()
    const startVyay = await this.http.post(`${this.domain}api/paymyapaar/startpayment/`, vyayform).toPromise()
    return startVyay
  }

  vyaysthiti(merch: string, purch: string, dayak: string, orderType: string): Observable<{ status: number }> {
    const vyayinfo = `?merch=${merch}&byora=${purch}&dayakid=${dayak}&orderType=${orderType}`;
    return this.http.get<any>(`${this.domain}api/paymyapaar/vyaySampoornSthithi/` + vyayinfo)
      .pipe(retryWhen(errors => errors.pipe(delay(10000), take(18), catchError((err, caught) =>
        caught))))
  }
}


