<div class="content">
    <div class="loginBox">
        <div class="loginText">
            <h1>
                Login to continue
            </h1>
        </div>
        <mat-card>
            <mat-spinner *ngIf="isLoading"></mat-spinner>
            <form [formGroup]="loginform" (submit)="onLogin()" *ngIf="!isLoading">
                <div class="filleither">
                    <div *ngIf="!this.loginform.value.email" class="sec1">
                        <mat-form-field>
                            <input matInput type="tel" name="phone" [(ngModel)]="this.userPhone"
                                (ngModelChange)="this.checkPhoneVerified()" formControlName="phone"
                                placeholder="Phone Number" pattern="[0-9]*" maxlength="10">
                            <mat-error *ngIf="formErrors.phone">{{formErrors.phone}}</mat-error>
                            <mat-hint>98xxxxxxx9</mat-hint>
                        </mat-form-field>
                    </div>
                    <div *ngIf="!this.loginform.value.phone && !this.loginform.value.email" class="sec2">
                        <mat-hint>
                            Or
                        </mat-hint>
                    </div>
                    <div *ngIf="!this.loginform.value.phone" class="sec3">
                        <mat-form-field>
                            <input matInput type="email" name="email" formControlName="email" placeholder="Email">
                            <mat-error *ngIf="formErrors.email">{{formErrors.email}}</mat-error>
                            <mat-hint>Paw***ar@emailprovider.com</mat-hint>
                        </mat-form-field>
                    </div>
                </div>


                <mat-form-field class="passField" appearance="outline">
                    <input matInput type="password" name="password" formControlName="password" placeholder="Password"
                        [type]="hide? 'password':'text'" required>
                    <button mat-icon-button matSuffix (click)="hide=!hide" type="button"
                        [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide? 'visibility_off' : 'visibility'}}</mat-icon></button>
                    <mat-error *ngIf="formErrors.password">{{formErrors.password}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="this.showOTPActivation">
                    <mat-label>OTP Verification</mat-label>
                    <input matInput type="number" maxlength="5" [disabled]="!this.showOTPActivation" pattern="[0-9]*"
                        formControlName="verifyOTP" autocomplete="off" />
                </mat-form-field>
                <div class="loginActions">
                    <button mat-raised-button class="login-button" type="submit">Login
                    </button>
                    <div class="forgotPass">
                        <a mat-stroked-button routerLink="/forgotPassword">Forgot password ?</a>
                    </div>
                </div>
            </form>
        </mat-card>
    </div>
</div>