import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsService } from './settings.service';
import { SellerPayout } from '../datamodels/sellerpayModel/sellerPayoutModel';
import { WithdrawalDetails } from '../datamodels/sellerpayModel/withdrawalDetailsModel';
import { BankDetails } from '../datamodels/groupedmodel/bankDetails';
interface invoiceInfoIn {
  useInvoiceNumber: string;
  useInvoiceAmount: number;
  useInvoicePaidDate: Date;
  useFundWithdrawabledate: Date;
  useIsFundWithdrawable: boolean;
  useIsWithdrawalReqAlreadyPlaced: boolean;
  usefundWithdrawnDate: Date;
  usetotalDeducDone: number;
  useWithdrawableAmount: number
}
interface ordersAccountingIn {
  invoiceInfo: invoiceInfoIn[]
  orderCreditInterest: number;
  orderCreditInterestShareOfSeller: number;
  orderFinalSellerShareOfOrder: number;
  orderFinalValue: number;
  orderHandlingFeesFromBuyer: number;
  orderNumber: string;
  orderStatus: string;
  orderPlatformCreditInterestShare: number;
  orderTCSHandlingFeesAndFromSeller: number;
  invoicedTimes: number;
  orderFinalPayout: number
}
interface withdrawalInfoIn {
  orderNumb: string;
  invoiceNumb: string;
  invoiceAmount: number;
  finalDeduct: number;
  withdrawalAmount: number;
  bankDetails: {
    accountName: string;
    accountNumber: string;
    accountIFSC: string;
    accountBankBranch: string;
  }
}
@Injectable({
  providedIn: 'root'
})
export class HandlesellerCentralService {
  private domain: string | undefined
  private bundledAccountingItems = new BehaviorSubject(null)
  private invoWithdrawalInfo = new BehaviorSubject(null)
  sharedBundledAccounting = this.bundledAccountingItems.asObservable()
  shareInvoWithdrawal = this.invoWithdrawalInfo.asObservable()
  sellerpayouts: SellerPayout[] = [];
  withdrawalrequests: WithdrawalDetails[] = []
  constructor(
    private http: HttpClient
    , private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }
  updateBundledAccounting(bundleOfAccountingItems: ordersAccountingIn[]) {
    try {
      this.bundledAccountingItems.next(bundleOfAccountingItems)
    }
    catch (e) {
      throw e
    }
  }

  updateInvoiceWithdrawal(invoWithdrawaldata: invoiceInfoIn) {
    try {
      this.invoWithdrawalInfo.next(invoWithdrawaldata)
    }
    catch (e) {
      throw e
    }
  }

  getMYPRpayments(sellerId: string): Observable<SellerPayout[]> {
    const queryParams = `?sellerId=${sellerId}`;
    // const queryParams = `?pagesize=${sellerId}&pagesize=${listingsPerPage}&page=${currentPage}`;
    return new Observable(
      sellerPaysObserver => {
        if (this.sellerpayouts && this.sellerpayouts.length > 0) {
          sellerPaysObserver.next(this.sellerpayouts)
          return sellerPaysObserver.complete()
        }
        this.http.get<{
          sellerPayout: SellerPayout[];
          status: number
        }>(`${this.domain}api/sellerBalanceRequest/myprPayments/` + queryParams)
          .subscribe((fetchedPayments) => {
            if (fetchedPayments && fetchedPayments.status === 200) {
              this.sellerpayouts = fetchedPayments.sellerPayout;
              sellerPaysObserver.next(this.sellerpayouts);
              sellerPaysObserver.complete();
            }
            else {
              throw new Error
            }

          });
      }
    )

  }

  getAllWithdrawalsSeller(sellerId: string): Observable<WithdrawalDetails[]> {
    const queryParams = `?sellerId=${sellerId}`;
    return new Observable(
      withdrawalObserver => {
        if (this.withdrawalrequests && this.withdrawalrequests.length > 0) {
          withdrawalObserver.next(this.withdrawalrequests)
          return withdrawalObserver.complete()
        }
        this.http.get<{
          sellerWithdrawals: WithdrawalDetails[];
          status: number
        }>(`${this.domain}api/sellerBalanceRequest/allWithdrawals/` + queryParams)
          .subscribe((fetchedWithdrawals) => {
            if (fetchedWithdrawals && fetchedWithdrawals.status === 200) {
              this.withdrawalrequests = fetchedWithdrawals.sellerWithdrawals;
              withdrawalObserver.next(this.withdrawalrequests);
              withdrawalObserver.complete();
            }
            else {
              throw new Error
            }
          });
      }
    )

  }

  async createNewWithdrawal(withdrawalOfInfo: withdrawalInfoIn, sellerId: string): Promise<{ status: number; message: string }> {
    const withdrawalFormdata = new FormData()
    withdrawalFormdata.append('orderNumb', withdrawalOfInfo.orderNumb)
    withdrawalFormdata.append('invoiceNumb', withdrawalOfInfo.invoiceNumb)
    withdrawalFormdata.append('invoiceAmount', JSON.stringify(withdrawalOfInfo.invoiceAmount))
    withdrawalFormdata.append('finalDeduct', JSON.stringify(withdrawalOfInfo.finalDeduct))
    withdrawalFormdata.append('withdrawalAmount', JSON.stringify(withdrawalOfInfo.withdrawalAmount))
    withdrawalFormdata.append('bankDetails', JSON.stringify(withdrawalOfInfo.bankDetails))
    withdrawalFormdata.append('sellerId', sellerId)
    const newWithdrawal = await this.http.post<{
      status: number; message: string
    }>(`${this.domain}api/sellerBalanceRequest/newWithdrawal/`, withdrawalFormdata).toPromise()
    return newWithdrawal
  }

  async getInvoiceBankingInfo(invoiceNumb: string, sellerId: string): Promise<{ status: number; bankingInfo: BankDetails; orderNumber: string }> {
    try {
      const queryParams = `?invNum=${invoiceNumb}&selId=${sellerId}`;
      const bankInfo = await this.http.get<{ status: number; bankingInfo: BankDetails; orderNumber: string }>(`${this.domain}api/sellerBalanceRequest/invoBankInf/` + queryParams).toPromise()
      return bankInfo
    }
    catch (e) {

    }
  }
}
