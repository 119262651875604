import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Review } from 'src/app/datamodels/productreview';
import { CommentpostService } from 'src/app/services/commentpost.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { mimeType } from '../../directives/mime-type.validator'
import { AuthService } from 'src/app/auth/auth.service';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageHandlingService } from 'src/app/services/image-handling.service';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
  selector: 'app-post-create',
  templateUrl: './post-create.component.html',
  styleUrls: ['./post-create.component.css'],
})
export class PostCreateComponent implements OnInit {
  @Output() reviewSubmitted = new EventEmitter<string>();
  currentdate = new Date();
  date: any;
  isLoading = false;
  private mode = 'create';
  private reviewId: string;
  private review: Review;
  reviewform: FormGroup;
  reviewImagePreview: string;
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  public currentUserId: any

  loginUserInfo: any
  createPostComments: boolean = false;

  useListingId: string
  listingId: string;
  constructor(private commentservice: CommentpostService,
    private authService: AuthService,
    public route: ActivatedRoute,
    private router: Router,
    private imgHandler: ImageHandlingService,
    private _snackBar: MatSnackBar,
    private utils: UtilsService,
    private location: Location,
  ) {

  }

  ngOnInit(): void {
    try {
      this.reviewform = new FormGroup({
        listingId: new FormControl(null, { validators: [Validators.required] }),
        author: new FormControl(null, { validators: [Validators.required] }),
        comment: new FormControl(null, { validators: [Validators.required] }),
        rating: new FormControl(null, { validators: [Validators.required, Validators.min(1), Validators.minLength(1), Validators.max(5)] }),
        reviewImage: new FormControl(null,
          { validators: [Validators.required], asyncValidators: [mimeType] })
      })
      this.doAuth()
      //for builtin observables we don't need to unsubscribe - paramMap is built in
      this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap && paramMap.has('reviewId')) {
          //review id is in routes - editreview
          this.mode = "edit"
          this.reviewId = paramMap.get('reviewId');
          this.isLoading = true; //spinner starts
          this.commentservice.getSingleReview(this.reviewId).subscribe(reviewData => {
            this.isLoading = false;     //spinner stops
            this.review = {
              id: reviewData._id,
              listingId: reviewData.listingId,
              author: reviewData.author,
              comment: reviewData.comment,
              rating: reviewData.rating,
              date: reviewData.date,
              reviewImage: reviewData.reviewImage,
              creatorID: reviewData.creatorID
              // creatorName:reviewData.creatorName
            }
            this.useListingId = this.review.listingId
            this.reviewform.setValue({
              'listingId': this.useListingId,
              'author': this.review.author, 'comment': this.review.comment,
              'rating': this.review.rating,
              'reviewImage': this.review.reviewImage,
            })
          });
        }
        else if (paramMap && paramMap.has('listingId')) {
          this.mode = "create"
          this.loginUserInfo = this.authService.getUserLoginInfo();
          this.listingId = paramMap.get('listingId');
          this.reviewform.value.author = this.loginUserInfo.firstname + " " + this.loginUserInfo.lastname
          this.reviewId = null;
          this.reviewform.setValue({
            'listingId': this.listingId,
            'author': this.reviewform.value.author,
            'comment': this.reviewform.value.comment,
            'rating': this.reviewform.value.rating,
            'reviewImage': this.reviewform.value.reviewImage,
          })
        }
      });
    }
    catch (e) {
      throw e
    }

  }

  doAuth() {
    try {
      // keep checking for user auth status
      this.userIsAuthenticated = this.authService.getIsAuth();
      if (this.userIsAuthenticated) {
        this.loginUserInfo = this.authService.getUserLoginInfo()
      }
      this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        //if auth status changes, get user info again
        this.loginUserInfo = this.authService.getUserLoginInfo()

      });
      if (this.loginUserInfo) {
        this.currentUserId = this.loginUserInfo?.userId
        if (this.currentUserId == undefined || this.currentUserId == null) {
          const parsedUserDetails = JSON.parse(this.loginUserInfo)
          this.currentUserId = parsedUserDetails.userId
        }
      }
      else if (this.loginUserInfo == undefined) {
        this.createPostComments = true
      }
    }
    catch (e) {
      throw e
    }
    //when login First time, Javascript Object is returned , which can be used unparsed, 
    //for already loggedin user (on page reload),JSON string object is returned which needs to be parsed

  }
  onSavePost() {
    try {

      if (this.reviewform.invalid && this.loginUserInfo === undefined) {
        window.alert('Please Login to leave comments')
        return;
      }
      this.isLoading = true; //spinner starts
      if (this.mode === 'create') {
        this.reviewform.value.date = this.currentdate
        this.commentservice.addReview
          (
            this.listingId,
            this.reviewform.value.author,
            this.reviewform.value.comment,
            this.reviewform.value.rating,
            this.reviewform.value.date,
            this.reviewform.value.reviewImage,
            // this.reviewform.value.creatorID
          ).then(response => {
            if (response && response.status === 201) {
              this.reviewSubmitted.emit(response.status.toString())
            }
          })
      } else {
        this.commentservice.updateReview(
          this.reviewId,
          this.useListingId,
          this.reviewform.value.author,
          this.reviewform.value.comment,
          this.reviewform.value.rating,
          this.reviewform.value.date = this.currentdate,
          this.reviewform.value.reviewImage,
          // this.reviewform.value.creatorID
        ).then((response) => {
          if (response && response.status === 201) {
            this.location.back()
            // this.router.navigate(['/Listing', response.reviewOnlistingId])
          }
        });
      }
      //emit post created to array , then array gets fetched to list
      this.reviewform.reset();
      this.isLoading = false;
    }
    catch (e) {

    }

  }
  onImagePicked(event: Event) {
    try {
      //type conversion to tell typescript that event.target registers an HtmlInp ,
      //  which typescript by default doesn't know
      const file = (event.target as HTMLInputElement).files[0];
      const fileName = file.name
      const fileFormat = file.type
      //create file url for image preview
      const reader = new FileReader();
      reader.onload = async () => {
        const useWidth = 620
        const useHeight = 620
        const checkResizedImg = await this.imgHandler.resizeSingleImage(reader.result as string, file.type, useWidth, useHeight)
        this.reviewImagePreview = checkResizedImg
        const imageName = fileName.replace(/\s/g, '')
        const compressedfile = new File([this.utils.convertDataUrlToBlob(this.reviewImagePreview)], imageName, { type: fileFormat });
        this.onImageUpload(compressedfile)
      };
      reader.readAsDataURL(file)
    }
    catch (e) {
      throw e
    }

  }

  onImageUpload(file: File) {
    try {
      this.isLoading = true;
      this.commentservice.addImageforReview(file)
        .subscribe((ImagesPostResponse) => {
          // mat spinner stops when result comes
          this.isLoading = false;
          if (ImagesPostResponse && ImagesPostResponse.status === "ok") {
            this.reviewform.patchValue({
              'reviewImage': ImagesPostResponse.fileLocationS3
            })
          }
        },
          error => {
            //uploadimages unsuccessful,
            this.isLoading = true;
            this.errorForm("Image upload fail - Invalid Format & Size")
          })
      //set values for Variety and Description
    }
    catch (e) {
      throw e
    }
  }
  errorForm(err: string) {
    const action = "Ok"
    try {
      this._snackBar.open(err, action, {
        duration: 5000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
      })
      this.isLoading = false
    }
    catch (e) {
      throw e
    }
  }
}
