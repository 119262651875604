<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Loading Your Listings </h1>
            <h3>Complete 10 Orders Successfully to imrpove Ratings</h3>
        </div>
        <mat-spinner></mat-spinner>
    </div>
</div>
<div class="contentListings">
    <div class="listingPageActions">
        <div class="searchMessage" *ngIf="this.searchmessage !== '' ">
            <p>{{this.searchmessage}}</p>
        </div>
        <div class="filterListings">
            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Filter
                        </mat-panel-title>
                        <mat-panel-description>
                            State and Variety
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="header">
                        States
                    </div>
                    <div class="stateNames" *ngIf="this.allStates && this.allStates.length > 0">
                        <div class="chips" *ngFor="let state of this.allStates">
                            <button mat-button class="inactiveFilter" (click)="selectedStates(state)">{{state}}</button>
                        </div>
                    </div>
                    <hr>
                    <div class="header">
                        Varieties
                    </div>
                    <div class="varietyNames" *ngIf="this.allVarieties && this.allVarieties.length > 0">
                        <div class="chips" *ngFor="let variety of this.allVarieties">
                            <button mat-button class="inactiveFilter"
                                (click)="selectedVarieties(variety)">{{variety}}</button>
                        </div>
                    </div>
                    <mat-action-row>

                        <div class="actionRow">

                            <div class="selectedItems">
                                <div class="selectedHeader"
                                    *ngIf="this.statesToFilter && this.statesToFilter.length > 0">
                                    States Selected</div>
                                <div class="selectStates" *ngIf="this.statesToFilter && this.statesToFilter.length > 0">
                                    <div class="selectedStateChips" *ngFor="let stf of this.statesToFilter">
                                        <span class="activeFilter">{{stf}} </span>
                                    </div>
                                </div>
                                <div class="selectedHeader"
                                    *ngIf="this.varietiesToFilter && this.varietiesToFilter.length > 0">Varieties
                                    Selected
                                </div>
                                <div class="selectVarieties"
                                    *ngIf="this.varietiesToFilter && this.varietiesToFilter.length > 0">
                                    <div class="selectedVarietyChips" *ngFor="let vtf of this.varietiesToFilter">
                                        <span class="activeFilter">{{vtf}}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="action">
                                <button class="reset" mat-raised-button color="warn"
                                    (click)="resetFilter()">Reset</button>
                                <button class="applyFilter" mat-raised-button color="primary"
                                    (click)="applySelectedFilter()">Apply</button>
                            </div>

                        </div>
                    </mat-action-row>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="sortListings">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Sort Listings
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <section matSort (matSortChange)="sortData($event)">
                        <h4 id="sortItems" mat-sort-header="listingCreateDate">Created </h4>
                        <h4 id="sortItems" mat-sort-header="bulkprice">Price</h4>
                    </section>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="loadListings" *ngIf="!isLoading">
        <div *ngIf="this.fetchedListings && this.fetchedListings.length>0" fxLayout="row wrap" fxLayoutGap="16px grid">
            <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" *ngFor="let listing of this.fetchedListings">
                <mat-card
                    [routerLink]="['/Listing/Place_Bulk_Order',listing._id,listing.categoryUnique,listing.productName]"
                    class="mat-elevation-z4">
                    <div class="listingcontainer">
                        <div class="titleVariety">
                            <div [routerLink]="['/Listing/Place_Bulk_Order',listing._id,listing.categoryUnique,listing.productName]"
                                class="listingtitle">
                                {{listing.title}}
                            </div>
                            <div>
                                <mat-card-subtitle *ngIf="listing.isVarietyAvailable">
                                    {{listing.varietyName}}
                                </mat-card-subtitle>
                            </div>
                        </div>
                        <div class="spacerDiv"></div>
                        <div class="listingDetails">
                            <div class="listingPrice">
                                <span>{{(listing.bulkPrice).toLocaleString('en-IN')}}
                                    <span style="font-size: 12px;">₹
                                        / {{listing.useUnitShort}}</span>
                                </span>
                            </div>
                            <div class="listingState">{{listing.packetSize}} Kg Packaging</div>
                        </div>
                    </div>
                    <img [routerLink]="['/Listing/Place_Bulk_Order',listing._id,listing.categoryUnique,listing.productName]"
                        mat-card-image [src]="listing.imageUrls[0]" />
                    <!-- <img mat-card-image src="https://ik.imagekit.io/ikmedia/backlit.jpg"> -->
                    <div id="subtitle">
                        <div class="categoryProduct">
                            <div id="categoryName"> {{listing.categoryName}} </div>
                            <div id="productName"> {{listing.productName}} </div>
                        </div>
                        <div class="soldBy">
                            <div> <span class="subtitle-spacer">Supplied from</span> <span
                                    class="listState">{{listing.state}}</span>
                            </div>
                            <div class="sellerName">{{listing.companyName}}</div>
                        </div>
                    </div>
                    <mat-card-content>
                        <p>
                            {{listing.description}}
                        </p>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div *ngIf="this.filterOn && this.noResultsOnFilter">
            <p class="noresults">No results found ! Use other options to filter. </p>
            <!-- <button mat-raised-button color="warn" (click)="resetFilter()">Reset</button> -->
        </div>
    </div>
</div>