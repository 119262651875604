import { Component, OnInit } from '@angular/core';
import { AllstatesService } from 'src/app/services/allstates.service';

import { StateModel } from 'src/app/datamodels/Statemodel';

import { Router } from '@angular/router';

@Component({
  selector: 'app-landingstates',
  templateUrl: './landingstates.component.html',
  styleUrls: ['./landingstates.component.css']
})
export class LandingstatesComponent implements OnInit {
  allstates: StateModel[]
  isLoading = false; //spinner initialized
  constructor(
    private router: Router,
    public allStatesService: AllstatesService,
  ) { }

  ngOnInit(): void {
    this.onLoadStates();
  }
  onSelectState(stateUnique: string) {
    this.router.navigate(['/viewStateProducts', stateUnique], { queryParamsHandling: "preserve" })
      .then
  }
  //LoadStates  
  onLoadStates() {
    this.isLoading = true; //spinner starts
    this.allStatesService.getAllStates().subscribe((resstate) => {
      this.isLoading = false; //spinner stops
      this.allstates = resstate.states
    })
  }
}
