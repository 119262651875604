import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators'

@Component({
  selector: 'app-book-demo',
  templateUrl: './book-demo.component.html',
  styleUrls: ['./book-demo.component.css']
})
export class BookDemoComponent implements OnInit {

  Breakpoints = Breakpoints;
  currentBreakpoint: string = '';
  breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  isMoved = false;
  boxes = Array(4).fill(0); // Creates 5 divs
  currentTranslateX = 0;
  currentIndex = 0;
  boxWidth: number; // Width of each box (including margin-right)
  boxHeight: number
  totalBoxes = 4;
  constructor(
    private breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit(): void {
    this.breakpoint$.subscribe(() =>
      this.breakpointChanged()
    );
    this.startCarousel();
  }
  startCarousel() {
    setInterval(() => {
      this.currentIndex++;
      if (this.currentIndex >= this.totalBoxes) {
        this.currentIndex = 0;
      }
      // Update the translateX to move the carousel
      this.currentTranslateX = -(this.boxWidth * 0.85 * this.currentIndex);
    }, 2000); // Change the box every 2 seconds
  }
  private breakpointChanged() {
    this.boxWidth = window.innerWidth * 0.95;
    // if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
    //   this.currentBreakpoint = Breakpoints.Large;
    //   // this.boxWidth = 1200
    // } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
    //   this.currentBreakpoint = Breakpoints.Medium;
    //   // this.boxWidth = 800
    // } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
    //   this.currentBreakpoint = Breakpoints.Small;
    //   // this.boxWidth = 350
    // } else if (this.breakpointObserver.isMatched('(min-width: 500px)')) {
    //   this.currentBreakpoint = '(min-width: 500px)';
    // }
  }
}
