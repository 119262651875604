




<div class="nonfeatured-banner">

    <div fxLayout="row wrap" class="page-actions">


    </div>
   
    <div fxLayout="row wrap" fxLayoutGap="10px grid">
        <!-- (click)="onClickMain(this.variety)" -->
  <div class="wrapper"  fxLayout="row wrap" fxLayoutGap="5px grid">
  <div class="cards" *ngFor="let variety of this.VarietiesofProduct " >
    
   <div class="product-img">
      <img alt="" src="{{variety.image}}" width="240" >
    </div >
    <div (click)="onSelectVariety(variety.varietyUnique)"   class="product-info">
      <div class="product-text">
        <h1>{{variety.varietyName}}</h1>
        <h2>{{variety.parentProductName}}</h2>
        <p> {{variety.varietyDescription}}</p>
        <button mat-stroked-button class="primary">View</button>
  
      </div>
     
  </div>
  </div>
  
  </div>
  
  </div>
  
  
  