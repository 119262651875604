import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { newroutes } from '../../modules/app-routing/routes'
import { AuthGuard } from 'src/app/auth/auth.guard';


@NgModule({
  imports: [RouterModule.forRoot(newroutes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
