import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ProductCategoryModel } from '../../datamodels/productcategory';
// import { PRODUCTCATEGORIES } from '../../datamodels/productcategories';
import { Product } from 'src/app/datamodels/MainProductModel';
import { ProductcategoryService } from 'src/app/services/productcategory.service';
import { switchMap } from 'rxjs/operators';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, combineLatest } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProductService } from 'src/app/services/product.service';
// import { ALLPRODUCTS } from 'src/app/datamodels/allproducts';

@Component({
  selector: 'app-singlecategoryproducts',
  templateUrl: './singlecategoryproducts.component.html',
  styleUrls: ['./singlecategoryproducts.component.css']
})


export class SinglecategoryproductsComponent implements OnInit, OnDestroy {
  categoryIDs: string[]
  productcategory: ProductCategoryModel
  itemsincat: any;
  productcategoryNames: string[];
  productName: any;
  finalCatProducts = [];
  products$ = new Observable<any[]>()
  isAlive = true;
  search$ = new Subject<string>();
  loadProducts: any
  ProductsOfCategory: Product[] = [];
  constructor(private httpclient: HttpClient,
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private productcategoryservice: ProductcategoryService) { }

  ngOnDestroy(): void {
    // this.isAlive=false;
  }


  ngOnInit(): void {
    this.LoadProductsofCateogry();
  }

  LoadProductsofCateogry() {

    // this.route.params.pipe(switchMap((params: Params) =>
    //   this.productService.getProductsforproductcategoriesSelected(params['categoryUnique'])))
    //   .subscribe(res => {
    //      this.productCategoryWise = res
    //     console.log('see res 1', this.productCategoryWise)
    //   })
    this.route.params.pipe(switchMap((params: Params) =>
      this.productcategoryservice.getProductsforCategoryUniqueSelected(params['categoryUnique'])))
      .subscribe(categoryhasProducts => {
        this.loadProducts = categoryhasProducts
        this.ProductsOfCategory = this.loadProducts.filteredproductbycategoryunique
        if (this.loadProducts.filteredproductbycategoryunique) {
          console.log('ParamId', this.loadProducts.query)
          console.log('filterprodsfinal', this.ProductsOfCategory)
        }
        else {
          console.log('Show No products page')
        }
      })
  }

  // LoadCategoryProducts() {

  //   this.LoadCategoryWiseProducts();

  //   this.productcategoryservice.getProductCategoriesName()
  //     .subscribe(productcategoryNames => this.productcategoryNames = productcategoryNames);

  //   this.route.params.pipe(switchMap((params: Params) => this.productcategoryservice.getProductCategory(params['id'])))
  //     .subscribe(productcategory => { this.productcategory = productcategory }

  //     )

  // }
  // SearchProduct() {
  //   if (this.productName == "") {
  //     this.ngOnInit();
  //   } else {
  //     this.productCategoryWise = this.productCategoryWise.filter(res => {
  //       return res.productName.toLocaleLowerCase().match(this.productName.toLocaleLowerCase());
  //     })

  //   } console.log('prod.all', this.finalCatProducts)
  // }
  onSelectProduct(productUnique: string) {
    this.router.navigate(['/viewVarietyItems', productUnique], { queryParamsHandling: "preserve" })
      .then
  }
}
