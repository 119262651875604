<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Processing Payment </h1>
            <h3>Generating Purchase Order </h3>
            <h5>Payment Verification... {{timeLeft}} Seconds</h5>
        </div>
        <mat-spinner></mat-spinner>
    </div>
</div>

<div class="contentCreatePO">
    <mat-card *ngIf="!isLoading">
        <div class="mainCol deliveryAndPayment">
        <div class="sectionHeading valid addressDetailsPresent">
            <h5>Pickup Mode</h5>
        </div>
        <div class="pickupModeForm">
            <div class="addressForm" *ngIf="!isLoading">
                <div class="rowWrap createPO">
                    <div class="togglecontainer">
                        <div *ngIf="!isBuyerSelfPickup" class="checkedleft">
                            <mat-icon>check</mat-icon>
                        </div>
                        <div *ngIf="isBuyerSelfPickup" class="disabled">
                            <h2 matTooltip="Pick Up and Delivery by Myapaar Vaayu Delivery Team">
                                <span class="toggleText">
                                    Vaayu Delivery
                                </span>
                            </h2>
                        </div>
                        <div *ngIf="!isBuyerSelfPickup" class="selectedVaayu">
                            <h2 matTooltip="Pick Up and Delivery by Myapaar Vaayu Delivery Team">
                                <span class="toggleText">
                                    Vaayu Delivery
                                </span>
                            </h2>
                        </div>
                        <div class="toggle">
                            <h3>
                                <mat-slide-toggle (change)="toggleFees($event,this.additionalChargesOfBuyer.amount)"
                                    [(ngModel)]="isBuyerSelfPickup">
                                </mat-slide-toggle>
                            </h3>
                        </div>
                        <div *ngIf="isBuyerSelfPickup">
                            <h2 matTooltip="Buyer will Pick up items on own">
                                <span class="toggleText">
                                    Self Pick Up
                                </span>
                            </h2>
                        </div>
                        <div *ngIf="!isBuyerSelfPickup" class="disabled">
                            <h2 matTooltip="Buyer will Pick up items on own">
                                <span class="toggleText">
                                    Self Pick Up
                                </span>
                            </h2>
                        </div>
                        <div *ngIf="isBuyerSelfPickup" class="checkedright">
                            <mat-icon>check</mat-icon>
                        </div>
                        <!-- <div class="sellerIssuedTitle">
                                <h2>
                                    <span *ngIf="isBuyerSelfPickup" class="toggleText">
                                        <mat-icon *ngIf="isBuyerSelfPickup" class="checked">check</mat-icon>
                                    </span>
                                    <span *ngIf="!isBuyerSelfPickup" class="disabled">
                                        Self Pick Up
                                    </span>
                                </h2>
                            </div> -->
                    </div>
                </div>
            </div>
        </div>
        <mat-hint class="center">Select Myapaar Vaayu Delivery for fast and reliable bulk order pickup, handling &
            delivery
        </mat-hint>
</div>
<div class="mainCol deliveryAndPayment">
    <div class="sectionHeading pending addressDetails" *ngIf="!userInfoForm.valid">
        <h5>Delivery Address Pending</h5>
    </div>
    <div class="sectionHeading valid addressDetailsPresent" *ngIf="userInfoForm.valid">
        <h5>Delivery Address</h5>
    </div>
    <div class="deliveryForm">
        <button class="deleteitem" mat-icon-button [disabled]="!(userInfoForm.valid)" color="warn"
            (click)="resetAdress()"><mat-icon>clear</mat-icon>
        </button>
        <div class="addressForm">
            <form [formGroup]="userInfoForm" *ngIf="!isLoading">
                <div class="rowWrap createPO">
                    <div class="createPO personalDetails">
                        <mat-form-field>
                            <input matInput type="text" name="fullName" formControlName="fullName"
                                placeholder="Full Name" required>
                            <mat-error *ngIf="formErrors.fullName">{{formErrors.fullName}}</mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="tel" name="phone" formControlName="phone" placeholder="Phone Number"
                                pattern="[0-9]*" maxlength="10" required>
                            <mat-error *ngIf="formErrors.phone">{{formErrors.phone}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="createPO conditionalRequiredPin">
                        <mat-form-field>

                            <mat-label>PIN Code</mat-label>
                            <input [(ngModel)]="selectedPincode" (ngModelChange)="getPincodeDetails()" matInput
                                formControlName="pinCode" autocomplete="off" maxlength="6" pattern="[0-9]*" />
                            <mat-error *ngIf="formErrors.pinCode">{{formErrors.pinCode}}</mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="text" [(ngModel)]="stateName" name="state" formControlName="state"
                                placeholder="State">
                            <mat-error *ngIf="formErrors.state">{{formErrors.state}}</mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="text" name="city" [(ngModel)]="districtName" formControlName="city"
                                placeholder="City">
                            <mat-error *ngIf="formErrors.city">{{formErrors.city}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="createPO conditionalRequiredBuilding">
                        <mat-form-field>
                            <mat-label>Locality</mat-label>
                            <input matInput placeholder="Search" [(ngModel)]="localityName" [matAutocomplete]="auto"
                                formControlName="addressLocality" />
                            <mat-autocomplete #auto="matAutocomplete">
                                <ng-container>
                                    <mat-option *ngFor="let locality of this.pinLocalities" [value]="locality || null">
                                        <span>{{locality}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                            <mat-error *ngIf="formErrors.addressLocality">{{formErrors.addressLocality}}</mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Building/Shop Name</mat-label>
                            <input matInput formControlName="addressBuildingNameAndFloor" autocomplete="off" />
                            <mat-error
                                *ngIf="formErrors.addressBuildingNameAndFloor">{{formErrors.addressBuildingNameAndFloor}}</mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Landmark</mat-label>
                            <input matInput formControlName="landmark" autocomplete="off" />
                            <mat-error *ngIf="formErrors.landmark">{{formErrors.landmark}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <div *ngIf="!(userInfoForm.valid)">
                <button class="submit-button" mat-raised-button color="accent" (click)="setFormValuesOnEdit()">Default
                    Address
                </button>
            </div>
        </div>
    </div>
</div>
<div class="mainCol itemContainer">
    <div class="sectionHeading valid itemDetails">
        <h5>Order Details</h5>
    </div>
    <div class="itemsofPo"
        *ngIf="this.purchaseOrderFromCart.itemDetails && this.purchaseOrderFromCart.itemDetails.length >0">
        <div class="headersItem">
            <div class="col1 header">
                ID
            </div>
            <div class="col2 header">
                Listing Detail
            </div>
            <div class="col3 header">
                Shipped From
            </div>
            <div class="col4 header">
                Quantity
            </div>
            <div class="col5 header">
                Price
            </div>
        </div>
        <mat-divider></mat-divider>
        <div *ngFor="let items of this.purchaseOrderFromCart.itemDetails ; index as i">
            <div class="infoItem">
                <div class="col index">
                    <span class="sub index">{{i+1}}</span>
                </div>
                <div class="col listingTitleDetails">
                    <div class="row title">
                        <div class="bold title">
                            <span>{{items.itemTitle}}</span>
                            <div>
                                <span>HSN -</span>
                                <span>[ {{items.taxSummaryTerms.hsnCodeItem}} ]</span>
                            </div>
                        </div>
                    </div>

                    <div class="prodInfo">
                        <span class="cat">
                            {{items.productCategory}}
                        </span><span class="prod">
                            ,{{items.productName}}
                        </span><span
                            *ngIf="items.productVariety && items.productVariety !== '' && items.productVariety !== 'null'"
                            class="var">
                            ,{{items.productVariety}}
                        </span>
                    </div>
                </div>
                <div class="col location">
                    <div class="shipfromBuilding">
                        <span>{{items.sellerDetails.sellerAddress.buildingName}}</span>
                    </div>
                    <div class="sellerAddress">
                        <span class="pin">{{items.sellerDetails.sellerAddress.pinCode}}</span>
                        <span class="city">, {{items.sellerDetails.sellerAddress.city}}</span>
                        <span class="state">, {{items.sellerDetails.sellerAddress.state}} </span>
                    </div>
                    <div class="orderShipmentType">
                        <span class="type">{{items.shipmentType}} Order
                        </span>
                        <span *ngIf="items.shipmentType === 'sample'">
                            Shipped Faster
                        </span>
                    </div>
                </div>
                <div class="col priceDetails">
                    <div class="bold quantity">
                        <span>{{items.itemQuantity}}
                            {{items.useUnitShort}}</span>
                    </div>
                    <div class="price">
                        <span>{{(items.itemPricePerUnit).toLocaleString('en-IN')}}₹
                            /{{items.useUnitShort}}</span>
                    </div>
                    <div class="pktSize">
                        <span>{{items.packetSize}} KG Packets</span>
                    </div>
                </div>
                <div class="col totalDetails">
                    <div class="bold totalprice">
                        <span class="chargeType"> {{(items.itemPriceWithTaxes).toLocaleString('en-IN')}}
                            ₹</span>
                    </div>
                    <div class="total">
                        <span class="chargeType">Applied {{items.taxSummaryTerms.taxSlab}} % G.S.T of
                            {{(items.itemTaxes).toLocaleString('en-IN')}} ₹
                        </span> <br>
                        <div *ngIf="items.optedPaymentTerms.creditTermPercentage > 1">
                            <span class="chargeType">And
                                {{items.optedPaymentTerms.creditTermPercentage}} % Credit Interest of
                                {{(items.itemCreditInterestAmount).toLocaleString('en-IN')}} ₹
                            </span> <br>
                            <span class="chargeType" style="font-weight: 500; color: rgb(33, 102, 231);">
                                Payment anytime in {{items.optedPaymentTerms.creditTermDays}} Days after
                                Delivery
                            </span>
                        </div>
                        <div *ngIf="items.optedPaymentTerms.creditTermPercentage <= 1">
                            <span class="chargeType">And
                                {{items.optedPaymentTerms.creditTermPercentage}} % Credit Interest of
                                {{(items.itemCreditInterestAmount).toLocaleString('en-IN')}} ₹
                            </span> <br>
                            <span class="chargeType" style="font-weight: 500; color: rgb(67, 174, 0);"
                                *ngIf="items.optedPaymentTerms.creditTermStatement == 'Check and Pay on delivery'">
                                {{items.optedPaymentTerms.creditTermStatement}} <br> [ 30 % Advance & 70% on
                                Delivery
                                after Checking Items ]
                            </span>
                            <span class="chargeType" style="font-weight: 500; color: rgb(67, 174, 0);"
                                *ngIf="items.optedPaymentTerms.creditTermStatement == 'Cash and Carry'">
                                {{items.optedPaymentTerms.creditTermStatement}}<br> [ 100 % Advance before
                                Shipment
                                ]
                            </span>
                        </div>
                        <span>
                        </span>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>
        <div class="footersItem">
            <div class="col1 footer2">
            </div>
            <div class="col2 footer2">
            </div>
            <div class="col3 footer2 chargeType">
                Items Total Quantity
            </div>
            <div class="col4 footer2">
                <span>{{this.purchaseOrderFromCart.orderQuantity | number:'0.3-3'}} Ql.</span>
            </div>
            <div class="col5 footer2">
            </div>
        </div>
        <div class="footersItem">
            <div class="col1 footer2">
            </div>
            <div class="col2 footer2">
            </div>
            <div class="col3 footer2 chargeType">
                Items Total Price with Taxes
            </div>
            <div class="col4 footer2 percentage">
            </div>
            <div class="col5 footer2">
                <span>{{(this.purchaseOrderFromCart.orderValue).toLocaleString('en-IN')}} ₹</span>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="footersItem">
            <div class="col1 footer2">
            </div>
            <div class="col2 footer2">
            </div>
            <div class="col3 footer2 chargeType">
                Seller Cancel anytime <br>
                Buyer Cancel pre Shipment <br>
                Buyer Cancel post Shipment <br>
                - Deducted Shipment cost
            </div>
            <div class="col4 footer2 percentage chargeType">
                100% Refund<br>
                100% Refund<br>
                Paid Amount
            </div>
            <div class="col5 footer2 pricerefund chargeType">
                [{{(this.payNowCharges).toLocaleString('en-IN')}} ₹]<br>
                [{{(this.payNowCharges).toLocaleString('en-IN')}} ₹]<br>
                {{(this.totalAdvanceCandCarryAmount + this.pay30AdvanceForCheckAndPay).toLocaleString('en-IN')}}
                ₹ <br>
                [- each invoice delivery cost ]
            </div>
        </div>
        <div *ngIf="this.isThisASmallOrder" class="footersItem">
            <div class="col1 footer1">

            </div>
            <div class="col2 footer1">

            </div>
            <div class="col3 footer1 poTotal">
                {{this.smallOrderChargesOfBuyer.chargeType}}
            </div>
            <div class="col4 footer1 chargeType">
                Below 5,000 ₹
            </div>
            <div class="col5 footer1">
                + {{(this.smallOrderChargesOfBuyer.amount).toLocaleString('en-IN')}}
                ₹
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="footersItem">
            <div class="col1 footer1">

            </div>
            <div class="col2 footer1">

            </div>
            <div class="col3 footer1 major">
                P.O Total
            </div>
            <div class="col4 footer1 chargeType">
            </div>
            <div class="col5 footer1 major">
                {{(this.displayPOTotalCharges).toLocaleString('en-IN')}} ₹
            </div>
        </div>

        <div *ngIf="this.activateDiscount" class="offerCheck valid">
            <h5>Flat 50% applied on Sample
            </h5>
        </div>
        <div *ngIf="this.isBuyerSelfPickup" class="offerCheck valid">
            <h5>No handling fees and charges on Buyer Self Pickup
            </h5>
        </div>
        <div class="footersItem">
            <div class="col1 footer3">

            </div>
            <div class="col2 footer3">

            </div>
            <div class="col3 footer3">
                <span *ngIf="!this.isBuyerSelfPickup" class="chargeType">
                    <!-- {{this.additionalChargesOfBuyer.chargeType}} -->
                </span>
                <span style="text-decoration:line-through;" *ngIf="this.isBuyerSelfPickup" class="chargeType">
                    <!-- {{this.additionalChargesOfBuyer.chargeType}} -->
                </span>
                <br>
                <span class="chargeType">
                    Cash and Carry
                </span> <br>
                <span class="chargeType">
                    Check and Pay on delivery
                </span><br>
                <span class="major">
                    Pay Now
                </span>
            </div>
            <div class="col4 footer3">
                <span *ngIf="!this.isBuyerSelfPickup" class="chargeType">
                    {{this.additionalChargesOfBuyer.chargeType}}
                </span>
                <span style="text-decoration:line-through;" *ngIf="this.isBuyerSelfPickup" class="chargeType">
                    {{this.additionalChargesOfBuyer.chargeType}}
                </span>
                <br>

                <!-- <span *ngIf="!this.isBuyerSelfPickup" class="chargeType bold">
                            {{this.additionalChargesOfBuyer.percentage}} %</span>
                        <span style="text-decoration:line-through;" *ngIf="this.isBuyerSelfPickup"
                            class="chargeType bold">
                            {{this.additionalChargesOfBuyer.percentage}} %</span> -->
                <!-- <br> -->
                <span class="chargeType bold"> 100 %</span><br>
                <span class="chargeType bold"> 30 %</span><br>
            </div>
            <div class="col5 footer3">
                <span *ngIf="!this.isBuyerSelfPickup" class="chargeType bold">
                    {{(this.additionalChargesOfBuyer.amount).toLocaleString('en-IN')}} ₹
                </span>
                <span style="text-decoration:line-through;" *ngIf="this.isBuyerSelfPickup" class="chargeType bold">
                    {{(this.additionalChargesOfBuyer.amount).toLocaleString('en-IN')}} ₹
                </span><br>
                <span class="chargeType bold">
                    {{(this.totalAdvanceCandCarryAmount).toLocaleString('en-IN')}} ₹
                </span> <br>
                <span class="chargeType bold">
                    {{(this.pay30AdvanceForCheckAndPay.toLocaleString('en-IN'))}} ₹
                </span><br>
                <span class="major">{{(this.payNowCharges).toLocaleString('en-IN')}}
                    ₹</span>
            </div>
        </div>
    </div>
    <div class="actionOnAddress">
        <button [disabled]="!(userInfoForm.valid)" class="submit-button" mat-raised-button color="accent"
            (click)="addShippingAddress()">Confirm Purchase Order Details
        </button>
    </div>
</div>

</mat-card>
</div>