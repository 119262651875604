import { Injectable } from '@angular/core';
import { PurchaseOrderModel } from '../datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SharePurchaseOrderComponent } from '../components/view-purchase-order/share-purchase-order/share-purchase-order.component';
import { DeletePurchaseOrderComponent } from '../components/view-purchase-order/delete-purchase-order/delete-purchase-order.component';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderDialogService {

  constructor(private dialog: MatDialog,) { }

  public cancelPurchaseOrder(
    purchaseOrderModaldata: any
  ): Observable<any> {
    let cancelDialogref: MatDialogRef<DeletePurchaseOrderComponent>
    var cancelDialogConfig = new MatDialogConfig()
    cancelDialogConfig.data = purchaseOrderModaldata;
    cancelDialogref = this.dialog.open(DeletePurchaseOrderComponent, {
      data: purchaseOrderModaldata,
      position: { top: '80px' }
    })
    return cancelDialogref.afterClosed();
  }
}
