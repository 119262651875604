import { Injectable } from '@angular/core';
import { UsersCartItem } from '../datamodels/cartModel';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ListingModel } from '../datamodels/ListingModel';
import { SettingsService } from './settings.service';
import { CreditTermsModel } from '../datamodels/groupedmodel/creditTermsDetails';
import { PackagingTypeModel } from '../datamodels/groupedmodel/packagingTypeModel';
@Injectable({
  providedIn: 'root'
})
export class InCartService {

  private _shoppingCart$ = new BehaviorSubject<any>({});
  cartforUser: UsersCartItem
  private _itemCount$ = new BehaviorSubject<any>({
    cartTotal: 0,
  });
  loginUserInfo: any
  isGeneric: boolean = true;
  cartItemsforUser: ListingModel[];
  extendedCart: UsersCartItem
  private domain: string | undefined

  constructor(private http: HttpClient
    , private settingsService: SettingsService

  ) {
    const rawUserCart = localStorage.getItem("cachedCart")
    const userCart = JSON.parse(rawUserCart)
    if (userCart) {
      this._shoppingCart$.next(userCart)
      this._itemCount$.next(userCart.itemListings.length)
    }
    this.domain = settingsService.cherryURL
  }

  //create and allocate a cart for user 
  async getUserCart(userId: string): Promise<any> {
    const cartLoad = await this.http.get<{
      message: string,
      cart: UsersCartItem
    }>(`${this.domain}api/cartitems/getUserCartItems/` + userId).toPromise();
    return cartLoad
  }
  getItems(): Observable<any> {
    return this._shoppingCart$.asObservable();
  }

  getItemCount(): Observable<any> {
    return this._itemCount$;
  }

  async saveCartItems(type: string, cartIdforuser: string, listingId: string,
    isListingSortexType: boolean,
    useUnit: string,
    useUnitShort: string, creditTerms: CreditTermsModel, packagingType: PackagingTypeModel,
    quantityAdded: string, addedDate: Date): Promise<{
      message: string;
      cart: UsersCartItem
    }> {
    const cartData = new FormData();
    // cartData.append("_id", itemId);
    cartData.append("type", type);
    cartData.append("listingId", listingId);
    cartData.append("isListingSortexType", JSON.stringify(isListingSortexType));
    cartData.append("useUnit", useUnit);
    cartData.append("useUnitShort", useUnitShort);
    cartData.append("creditTermDays", JSON.stringify(creditTerms.value));
    cartData.append("creditTermPercentage", JSON.stringify(creditTerms.percentage));
    cartData.append("creditTermStatement", creditTerms.viewValue);

    cartData.append("packagingTypeValue", JSON.stringify(packagingType.value));
    cartData.append("packagingImg", packagingType.packagingImg);
    cartData.append("packagingTypeUse", packagingType.packagingType);

    cartData.append("quantityAdded", quantityAdded);
    cartData.append("addedDate", addedDate.toString());

    const addItemsToCart = await this.http.put<{
      message: string,
      cart: UsersCartItem,
    }>
      (`${this.domain}api/cartitems/updateCart/` + cartIdforuser,
        cartData).toPromise()
    return addItemsToCart
  }

  async deleteItemfromCart(cartId: string, itemId: string, listingId: string): Promise<{
    message: string;
    cart: UsersCartItem;
    status: number
  }> {
    const queryParams = `?cartId=${cartId}&&itemId=${itemId}&&listingId=${listingId}`;
    const updatedCart = await this.http.get<{
      message: string,
      cart: UsersCartItem,
      status: number
    }>(`${this.domain}api/cartitems/deleteItemfromcart/` + queryParams).toPromise()
    return updatedCart
  }

  deleteMultipleItemfromCart(allItemIDsOfCart: string[], cartId: string): Observable<any> {
    const deleteItemsInfo = new FormData()
    if (allItemIDsOfCart && allItemIDsOfCart.length >= 1) {
      allItemIDsOfCart.forEach(itemID => {
        deleteItemsInfo.append("itemIDs[]", itemID)
      })
    }
    else {
      deleteItemsInfo.append("itemIDs", allItemIDsOfCart[0])
    }

    return this.http.put<{
      message: string,
      cart: UsersCartItem,
    }>(`${this.domain}api/cartitems/deleteMultipleItemfromcart/` + cartId, deleteItemsInfo)
  }

}
