<mat-spinner *ngIf="isLoading"></mat-spinner>
<div class="nonfeatured-banner" *ngIf="!isLoading">

    <div class="bannerData">
        <div class="info">
            <h1>Prouct</h1><br>
            <h2>& States</h2>
        </div>
        <img alt="" src="../../../assets/NonFeaturedBanner/landingProducts.jpg">
    </div>
    <button mat-raised-button  class="nonfeatured-button"  routerLink="/viewItems/All"
[queryParams]="{'searchTerm':'ViewAllItems'}" queryParamsHandling="merge">View All States</button>

    <div class="wrapper" fxLayout="row wrap" >
        <div class="cards" *ngFor="let state of allstates | slice:0:8; let i=index">
            <img class="item" (click)="onSelectState(state.stateUnique)"  
                src="{{state.productImage}}">
            <div class="cards-info">
                <h1>{{state.stateName}}</h1>
            </div>
        </div>
    </div>
</div>