<div *ngIf="isLoading" class="content-loading">

    <div id="loadingScreen">
        <mat-spinner></mat-spinner>
    </div>
</div>
<div class="content" *ngIf="!this.isLoading">
    <div *ngIf="!this.isDisputeResponseAvailable" class="title">
        <p class="header"> Report Problem </p>
        <br>
        <p class="subheader"> Select A Reason </p>
        <mat-list>
            <mat-list-item>
                {{this.currentReturnOrderwithId.returnNumber}}
            </mat-list-item>
        </mat-list>
        <form [formGroup]="reportProblemReturnOrderForm" id="reportPO">
            <div class="reportForm" *ngIf="this.listReasonsForReporting && this.listReasonsForReporting.length>0">
                <div *ngIf="this.sellerIsSeller" class="SellerAsSeller">
                    <mat-form-field appearance="outline">
                        <mat-label>Reason</mat-label>
                        <div class="cancelReasons">
                            <mat-select name="disputeCategory" formControlName="disputeCategory">
                                <mat-option *ngFor="let reasons of this.listReasonsForReporting"
                                    [value]="reasons.disputeCategory">
                                    {{reasons.disputeDescription}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </mat-form-field>
                </div>

                <div *ngIf="this.sellerIsBuyer" class="SellerAsBuyer">
                    <mat-form-field appearance="outline">
                        <mat-label>Reason</mat-label>
                        <div class="cancelReasons">
                            <mat-select name="disputeCategory" formControlName="disputeCategory">
                                <mat-option *ngFor="let reasons of this.listReasonsForReporting"
                                    [value]="reasons.disputeCategory">
                                    {{reasons.disputeDescription}}
                                </mat-option>
                            </mat-select>
                        </div>

                    </mat-form-field>
                </div>

                <div *ngIf="this.buyerIsBuyer" class="Buyer">
                    <mat-form-field appearance="outline">
                        <mat-label>Reason</mat-label>
                        <div class="cancelReasons">
                            <mat-select name="disputeCategory" formControlName="disputeCategory">
                                <mat-option *ngFor="let reasons of this.listReasonsForReporting"
                                    [value]="reasons.disputeCategory">
                                    {{reasons.disputeDescription}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </mat-form-field>
                </div>
            </div>
            <div class="contactNumber">
                <mat-list>
                    <mat-list-item class="toggle phone">
                        <mat-slide-toggle formControlName="isAllowPhoneCall" [(ngModel)]="isAllowPhoneCall">Call Me 10
                            a.m. To 9 p.m.</mat-slide-toggle>
                    </mat-list-item>
                    <mat-list-item class="input phone" *ngIf="isAllowPhoneCall">
                        <mat-form-field>
                            <input matInput type="tel" name="phone" formControlName="phone" placeholder="Phone Number"
                                pattern="[0-9]*" maxlength="10" required>
                            <mat-error *ngIf="formErrors.phone">{{formErrors.phone}}</mat-error>
                            <mat-hint>98xxxxxxx9</mat-hint>
                        </mat-form-field>
                    </mat-list-item>
                </mat-list>
            </div>
        </form>
        <button class="createReport" mat-stroked-button color="warn"
            (click)="onReportProblem(this.listReasonsForReporting)">Report
            Problem</button>
    </div>
    <div *ngIf="this.isDisputeResponseAvailable">
        <br>
        <p class="subheader">{{this.disputeResponseWithIssueID}}</p><br>
        <p class="subheader">{{this.disputeResponseWithIssueStatus}}</p>
        <br>
        <button [routerLink]="['/viewReturnOrder', this.returnOrderID ]" class="goback" mat-stroked-button
            color="primary">Go Back</button>
    </div>
</div>