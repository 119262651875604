<!-- <div class="content">
  
    <div class="signUpBox">
        <mat-card>
            <div class="img-slider">
                <div class="slide">
                    <mat-carousel class="mat-carousel" timings="300ms ease-in" [autoplay]="true" interval="4000" color="accent"
                        [loop]="true" maxWidth="auto" [hideIndicators]="true" [hideArrows]="true" [useKeyboard]="true"
                        [maintainAspectRatio]="false" orientation="ltr">
                        <mat-carousel-slide *ngFor="let slide of this.appBannerslandingproducts; let i = index" [image]="slide"
                            overlayColor="rgba(0, 0, 0, 0.1)" [hideOverlay]="true"></mat-carousel-slide>
                    </mat-carousel>
                </div>
            </div>
            <mat-spinner *ngIf="isLoading"></mat-spinner>
            
            <form id="formSignUp" [formGroup]="signupform" (submit)="onSignup()" *ngIf="!isLoading">
                <mat-form-field appearance="outline" appearance="outline">
                    <mat-label>Select Account Type</mat-label>
                    <mat-select [(ngModel)]="selectedAccountType" (ngModelChange)="accountTypeFilter()"
                        name="accountType" formControlName="accounttype">
                        <mat-option *ngFor="let accountType of this.accountTypes" [value]="accountType.accountTypeName">
                            {{accountType?.accountTypeName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="filleither">
                    <div *ngIf="!this.signupform.value.email" class="sec1">
                        <mat-form-field>
                            <input matInput type="tel" name="phone" formControlName="phone" placeholder="Phone Number"
                                pattern="[0-9]*" maxlength="10">
                            <mat-error *ngIf="formErrors.phone">{{formErrors.phone}}</mat-error>
                            <mat-hint>98xxxxxxx9</mat-hint>
                        </mat-form-field>
                    </div>
                    <div *ngIf="!this.signupform.value.phone && !this.signupform.value.email" class="sec2">
                        <mat-hint>
                            Or
                        </mat-hint>
                    </div>
                    <div *ngIf="!this.signupform.value.phone" class="sec3">
                        <mat-form-field>
                            <input matInput type="email" name="email" formControlName="email" placeholder="Email">
                            <mat-error *ngIf="formErrors.email">{{formErrors.email}}</mat-error>
                            <mat-hint>Paw***ar@emailprovider.com</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <mat-form-field appearance="outline">
                    <input matInput type="password" name="password" formControlName="password" placeholder="Password"
                        [type]="hide? 'password':'text'" required>
                    <button mat-icon-button matSuffix (click)="hide=!hide" type="button"
                        [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide? 'visibility_off' : 'visibility'}}</mat-icon></button>
                    <mat-error *ngIf="formErrors.password">{{formErrors.password}}</mat-error>
                </mat-form-field>
                <div class="signRow">
                    <mat-form-field appearance="outline">
                        <input [(ngModel)]="this.viralityReferral" formControlName="referral" matInput type="text"
                            name="referral" placeholder="Merchant Referral Code ( optional )">
                        <mat-hint>Become 4 Star Account Instantly</mat-hint>
                    </mat-form-field>
                    <mat-list class="toggleRegister">
                        <mat-list-item>
                            <mat-slide-toggle class="toggleButton" formControlName="isRegistered"
                                [(ngModel)]="isRegistered">Verify
                                Account</mat-slide-toggle>
                        </mat-list-item>
                    </mat-list>
                </div>


                <div class="conditionalRequired" *ngIf="isRegistered">
                    <mat-form-field appearance="outline" appearance="outline">
                        <mat-hint>Producer - Distributor - Wholesaler - Retailer</mat-hint>
                        <mat-label>Select Account Subtype</mat-label>
                        <mat-select [(ngModel)]="selectedAccountSubtype" name="accountSubtype"
                            formControlName="accountSubtype">
                            <mat-option *ngFor="let subType of accountSubtypeList" [value]="subType">
                                {{subType}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" name="firstname" formControlName="firstname"
                            placeholder="First Name" required>
                        <mat-error *ngIf="formErrors.firstname">{{formErrors.firstname}}</mat-error>
                        <mat-hint>Mahendra</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" name="lastname" formControlName="lastname" placeholder="Last Name">
                        <mat-error *ngIf="formErrors.lastname">{{formErrors.lastname}}</mat-error>
                        <mat-hint>Singh</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" name="companyName" formControlName="companyName"
                            placeholder="Company Registered Name">
                        <mat-error *ngIf="formErrors.companyName">{{formErrors.companyName}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>PIN Code</mat-label>
                        <input [(ngModel)]="selectedPincode" (ngModelChange)="getPincodeDetails()" matInput
                            formControlName="pinCode" autocomplete="off" maxlength="6" pattern="[0-9]*" />
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" [(ngModel)]="stateName" name="state" formControlName="state"
                            placeholder="State">
                        <mat-error *ngIf="formErrors.state">{{formErrors.state}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" name="city" [(ngModel)]="districtName" formControlName="city"
                            placeholder="City">
                        <mat-error *ngIf="formErrors.city">{{formErrors.city}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Locality</mat-label>
                        <input matInput placeholder="Search" [(ngModel)]="localityName" [matAutocomplete]="auto"
                            formControlName="addressLocality" />
                        <mat-autocomplete #auto="matAutocomplete">
                            <ng-container>
                                <mat-option *ngFor="let locality of this.pinLocalities" [value]="locality || null">
                                    <span>{{locality}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Building/Shop Name</mat-label>
                        <input matInput formControlName="addressBuildingNameAndFloor" autocomplete="off" />
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Landmark</mat-label>
                        <input matInput formControlName="landmark" autocomplete="off" />
                    </mat-form-field>
                    <div class="left">
                        <mat-slide-toggle formControlName="isGSTAvailable" [(ngModel)]="isGSTAvailable">GST
                            ID
                        </mat-slide-toggle>
                    </div>
                    <mat-form-field appearance="outline" *ngIf="isGSTAvailable">
                        <input matInput type="text" name="GST" formControlName="GST_Id" placeholder="GST Number"
                            maxlength="15">
                        <mat-error *ngIf="formErrors.GST_Id">{{formErrors.GST_Id}}</mat-error>
                        <mat-hint>GST Verification</mat-hint>
                    </mat-form-field>
                    <div class="left">
                        <mat-slide-toggle formControlName="isPANAvailable" [(ngModel)]="isPANAvailable">Company PAN
                        </mat-slide-toggle>
                    </div>
                    <mat-form-field appearance="outline" *ngIf="isPANAvailable">
                        <input matInput type="text" name="PAN" formControlName="PAN_Id" placeholder="PAN Number"
                            maxlength="10">
                        <mat-error *ngIf="formErrors.PAN_Id">{{formErrors.PAN_Id}}</mat-error>
                        <mat-hint>PAN Verification</mat-hint>
                    </mat-form-field>
                </div>


                <button class="submit-button" mat-raised-button type="submit">Sign Up
                </button>
            </form>

        </mat-card>
    </div>

</div> -->
<app-one-accountype></app-one-accountype>