import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { HandleInvoiceService } from 'src/app/services/handle-invoice.service';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { MatTableDataSource } from '@angular/material/table';
import { BuyerDetails } from 'src/app/datamodels/groupedmodel/buyerDetails';
import { SellerDetails } from 'src/app/datamodels/groupedmodel/sellerDetails';
import { ItemDetails } from 'src/app/datamodels/groupedmodel/itemDetails';
interface IDisplayPayments {
  buyerDetails: BuyerDetails;
  sellerDetails: any;
  itemDetails: ItemDetails;
  dateOfINVDelivered: string;
  dateOfINVGenerated: string;
  dateOfINVPaid: string;
  invoID: string;
  invoiceRaiseValue: number;
  invoiceStatus: string;
  invoiceVersionNumber: number;
  invoNumber: string;
  linkedPO: string;
}
@Component({
  selector: 'app-viewpayments',
  templateUrl: './viewpayments.component.html',
  styleUrls: ['./viewpayments.component.css']
})
export class ViewpaymentsComponent implements OnInit {
  private loginUserInfo: any
  private currentUserId: string
  isLoading: boolean = false
  userIdPurchaseOrders: PurchaseOrderModel[] = []
  sellerTypePurchaseOrders: PurchaseOrderModel[] = []
  buyerTypePurchaseOrders: PurchaseOrderModel[] = []
  sellerTypeDataSource: any
  buyerTypeDataSource: any
  sellerAccountType: boolean = false
  buyerAccountType: boolean = false
  gstDetailsPending: boolean = false;
  gstVerificationPending: boolean = false;
  fetchAccountPOsByAccountType: string;
  displayedSellerTypeTableColumns: string[] = []
  displayedBuyerTypeTableColumns: string[] = []
  isSellerCheckingINVOtypeAsBuyer: boolean = false
  preseveSellerTypeData: PurchaseOrderModel[] = []
  preserveBuyerTypeData: PurchaseOrderModel[] = []
  sellerTypeSearch: string
  buyerTypeSearch: string
  isInvosAvailable: boolean = true;
  invoiceVersionsAvailableForPO: boolean = false
  allBuyerTypeInvoiceVers: any
  allSellerTypeInvoiceVers: any
  sellerTypeINVOS: IDisplayPayments[] = []
  buyerTypeINVOS: IDisplayPayments[] = []
  isSellerCheckingPOtypeAsBuyer: boolean = false

  constructor(
    private authService: AuthService,
    private router: Router,
    private handlePurchaseOrder: HandlePurchaseOrderService,
    private handleInvoiceService: HandleInvoiceService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.fetchAccountPOsByAccountType = this.loginUserInfo.accounttype
    this.currentUserId && this.currentUserId !== '' ? this.getUserInvoiceOrders(this.currentUserId, this.fetchAccountPOsByAccountType) : this.router.navigate(['/'])
    this.loginUserInfo.accounttype === 'Seller' ? this.sellerAccountType = true : this.buyerAccountType = true
    if (this.loginUserInfo.GST_Id === '') {
      this.gstDetailsPending = true
    }
    if (this.loginUserInfo.isGSTVerified === false) {
      this.gstVerificationPending = true
    }
  }

  getUserInvoiceOrders(currentUserId: string, loginAccounttype: string) {
    try {
      this.handleInvoiceService.getAllUserInvoiceOrders(currentUserId, loginAccounttype).then(
        userInvos => {
          if (userInvos && userInvos.status === 200) {
            this.isLoading = false
            this.isInvosAvailable = true
            this.sellerTypeINVOS = userInvos.userInvoices.filter(sinvo => { return sinvo.sellerDetails._id === this.currentUserId })
            this.sellerTypeDataSource = new MatTableDataSource(this.sellerTypeINVOS)
            this.displayedSellerTypeTableColumns = ['pon', 'invon', 'invoamt', 'invostat', 'date']

            this.buyerTypeINVOS = userInvos.userInvoices.filter(binvo => { return binvo.buyerDetails.buyerId === this.currentUserId })
            this.buyerTypeDataSource = new MatTableDataSource(this.buyerTypeINVOS)
            this.displayedBuyerTypeTableColumns = ['pon', 'invon', 'invoamt', 'invostat', 'date']

          }
          else {
            this.isInvosAvailable = false
          }
        }
      )
    }
    catch (e) { }
  }


}
