import { Component, OnInit } from '@angular/core';
import { ItemPushcartService } from 'src/app/services/item-pushcart.service';
import { OrderType } from 'src/app/datamodels/orderTypeModel/orderModel';
import { UsersCartItem } from 'src/app/datamodels/cartModel';
import { InCartService } from 'src/app/services/in-cart.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { AddressBook } from 'src/app/datamodels/groupedmodel/addressBook';
import { ContactBook } from 'src/app/datamodels/groupedmodel/contactBook';
import { ItemDetails } from 'src/app/datamodels/groupedmodel/itemDetails';
import { Subscription } from 'rxjs';
import { ItemListingsInCart } from 'src/app/datamodels/itemListingsinCartModel';
import { AdditionalCharges } from 'src/app/datamodels/groupedmodel/additionalCharges';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  totalValueHolder: any;
  cartTotal: number;
  cartItems: any[] = [];
  orderTypeSelects: [];
  holderType: OrderType
  allListingsInCart: any;
  cartId: string;
  userCart: UsersCartItem
  cartisEmpty: boolean = false
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  loginUserInfo: any
  public currentUserId: any
  groupedCartItemsWithSellerID: {}
  groupedSampleCartItems: {}
  itemDetails: ItemDetails[] = [];
  sampleItemsOfCartIsEmpty: boolean = false;
  bulkItemsOfCartIsEmpty: boolean = false;
  sellerID: string;
  isShowLimit: boolean = false;
  constructor(
    private pushcartService: ItemPushcartService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private handlePurchaseOrder: HandlePurchaseOrderService,
    private incartService: InCartService,
    private utl: UtilsService
  ) { }

  ngOnInit(): void {
    try {
      this.userIsAuthenticated = this.authService.getIsAuth();
      this.userIsAuthenticated === false ? this.cartItems = [] :
        this.doAuth()
      this.authService.userCart$.subscribe(
        res => {
          if (res) {
            if (res.itemListings.length > 0) {
              this.cartId = res._id
              this.cartItems = res.itemListings
              this.groupBySellerId(this.cartItems)
              this.cartisEmpty = false
            }
            if (res.itemListings.length === 0) {
              this.cartId = res._id
              this.cartisEmpty = true
            }
          }
          else if (!res) {
            this.cartisEmpty = true

          }
          else if (res === null) {
            this.cartisEmpty = true

          }

        }
      )
    }
    catch (e) {
      throw e
    }

  }

  groupBySellerId(cartItems: ItemListingsInCart[]) {
    try {
      const bulkcartItems = cartItems.filter(citems => citems.type === 'bulk' || citems.type === 'sample')
      this.groupedCartItemsWithSellerID = bulkcartItems.reduce((r, a) => {
        r[a.sellerId] = r[a.sellerId] || [];
        r[a.sellerId].push(a);
        return r;
      }, Object.create(null))

    }
    catch (e) {
      throw e
    }
  }
  doAuth() {
    // use real deal of auth from creaListing - no need to parse/unparse
    try {     // keep checking for user auth status
      this.userIsAuthenticated = this.authService.getIsAuth();
      if (!this.userIsAuthenticated) {
        return !this.userIsAuthenticated
      }
      if (this.userIsAuthenticated) {
        this.loginUserInfo = this.authService.getUserLoginInfo()
      }
      this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        //if auth status changes, get user info again
        this.loginUserInfo = this.authService.getUserLoginInfo()

      });
      if (this.loginUserInfo) {
        this.currentUserId = this.loginUserInfo?.userId
        if (this.currentUserId == undefined || this.currentUserId == null) {
          const parsedUserDetails = JSON.parse(this.loginUserInfo)
          this.currentUserId = parsedUserDetails.userId
        }
      }
      else if (this.loginUserInfo == undefined) {
        this.cartisEmpty = true
      }
      if (!this.loginUserInfo.isGSTAvailable) {
        this.isShowLimit = true
      }
      return this.loginUserInfo
    }
    //when login First time, Javascript Object is returned , which can be used unparsed, 
    //for already loggedin user (on page reload),JSON string object is returned which needs to be parsed}
    catch (e) {
      throw e
    }


  }

  deleteItem(itemId: string, listingId: string) {
    try {
      // localStorage.removeItem('userCart');
      this.incartService.deleteItemfromCart(this.cartId, itemId, listingId).then(savedCart => {
        this.userCart = savedCart.cart
        this.cartItems = this.userCart.itemListings
        localStorage.setItem('userCart', JSON.stringify(this.userCart));
        //save cached cart from items that are in cart 
        if (this.userCart) {
          this.authService.createCacheCart(this.userCart)
          this.numberofItemsinCart(this.cartItems)
        }
      })
    }
    catch (e) {
      throw e
    }

  }


  numberofItemsinCart(cartItems: any) {
    try {
      cartItems && cartItems.length > 0 ? this.cartisEmpty = false : this.cartisEmpty = true;
      return this.cartisEmpty
    }
    catch (e) {
      throw e
    }
  }

  createBundledPurchaseOrder(product: any) {
    try {
      const allItemIDsOfCart = product.map(allProds => allProds._id)
      this.itemDetails = []
      const buyerAddress: AddressBook = {
        name: this.loginUserInfo.firstname + ' ' + this.loginUserInfo.lastname,
        phone: this.loginUserInfo.phone,
        companyType: this.loginUserInfo.accounttype + '-' + this.loginUserInfo.accountSubtype,
        streetName: this.loginUserInfo.addressLocality,
        buildingName: this.loginUserInfo.addressBuildingNameAndFloor,
        pinCode: this.loginUserInfo.pinCode,
        landMark: this.loginUserInfo.landmark,
        state: this.loginUserInfo.state,
        city: this.loginUserInfo.city,
      }
      const buyerContactDetails: ContactBook = {
        phone: this.loginUserInfo.phone,
        alternatePhoneContact: this.loginUserInfo.alternatePhoneContact || '',
        email: this.loginUserInfo.email,
      }
      const shipToBuyerLocation: AddressBook = {
        //give choice
        //change these details - before creating PO - make buyer select an address
        name: this.loginUserInfo.firstname + ' ' + this.loginUserInfo.lastname,
        phone: this.loginUserInfo.phone,
        companyType: this.loginUserInfo.accounttype + '-' + this.loginUserInfo.accountSubtype,
        streetName: this.loginUserInfo.addressLocality,
        buildingName: this.loginUserInfo.addressBuildingNameAndFloor,
        pinCode: this.loginUserInfo.pinCode,
        landMark: this.loginUserInfo.landmark,
        state: this.loginUserInfo.state,
        city: this.loginUserInfo.city,
      }
      for (let prod = 0; prod < product.length; prod++) {
        this.sellerID = product[prod].currentListing.listingCreatorID
        const sellerAddress: AddressBook = {
          name: product[prod].currentListing.listingCreator,
          phone: product[prod].currentListing.phone,
          companyType: product[prod].currentListing.companyName,
          streetName: product[prod].currentListing.addressStreet,
          buildingName: product[prod].currentListing.addressBuildingNameAndFloor,
          pinCode: product[prod].currentListing.pinCode,
          landMark: product[prod].currentListing.landmark,
          state: product[prod].currentListing.state,
          city: product[prod].currentListing.city,
        }
        const sellerContactDetails: ContactBook = {
          phone: product[prod].currentListing.phone,
          email: product[prod].currentListing.email,
          alternatePhoneContact: product[prod].currentListing.alternatePhoneContact
        }
        const taxSummaryTerms = {
          hsnCodeItem: product[prod].currentListing.hsnCodeItem,
          taxSlab: product[prod].currentListing.taxSlab,
          buyerPincode: this.loginUserInfo.pinCode,
          buyerState: 'replaceFromShippingAddressOnCreatePOPage',
          sellerPincode: product[prod].currentListing.pinCode,
          sellerState: product[prod].currentListing.state,
          IGSTperc: 'tbd-createPO',
          CGSTperc: 'tbd-createPO',
          SGSTperc: 'tbd-createPO',
          UGSTperc: 'tbd-createPO',
          TCSperc: 'tbd-createPO',
          IGSTamount: 0,
          CGSTamount: 0,
          SGSTamount: 0,
          UGSTamount: 0,
          TCSamount: 0,
        }
        const itemTotalValue = parseInt(Number((product[prod].quantityAdded) * (product[prod].currentListing.bulkPrice)).toFixed(2))
        const itemTaxes = parseInt(Number(((product[prod].currentListing.taxSlab / 100) * itemTotalValue)).toFixed(2))
        const today = new Date()
        const halfOfCreditDays = Math.round(product[prod].creditTerms.creditTermDays / 2)
        const midDateCredit = this.utl.addDays(today, halfOfCreditDays)
        const lastDateOfPay = this.utl.addDays(today, product[prod].creditTerms.creditTermDays)
        const itemPriceWithTaxes = itemTotalValue + itemTaxes
        const itemCreditInterestAmount = parseInt(Number(((product[prod].creditTerms.creditTermPercentage / 100) * itemPriceWithTaxes)).toFixed(2))
        const itemFinaltotalvalTaxesCt = itemPriceWithTaxes + itemCreditInterestAmount
        let usePaidAmount: number = 0
        let useRemainingAmountItem: number = 0
        if (product[prod].creditTerms.creditTermStatement == 'Instant') {
          usePaidAmount = 0
          useRemainingAmountItem = 0
        }
        if (product[prod].creditTerms.creditTermStatement == 'Cash and Carry') {
          usePaidAmount = itemFinaltotalvalTaxesCt
          useRemainingAmountItem = 0
        }
        if (product[prod].creditTerms.creditTermStatement == 'Check and Pay on delivery') {
          usePaidAmount = itemFinaltotalvalTaxesCt * (30 / 100)
          useRemainingAmountItem = itemFinaltotalvalTaxesCt - usePaidAmount
        }
        else if (product[prod].creditTerms.creditTermStatement != 'Cash and Carry'
          && product[prod].creditTerms.creditTermStatement != 'Check and Pay on delivery') {
          usePaidAmount = 0
          useRemainingAmountItem = itemFinaltotalvalTaxesCt
        }
        const item: ItemDetails = {
          //use listingId added from sent product , not current listing
          listingId: product[prod].listingId,
          itemTitle: product[prod].currentListing.title,
          productName: product[prod].currentListing.productName,
          productCategory: product[prod].currentListing.categoryName,
          productVariety: product[prod].currentListing.varietyName,
          //use quantity added from sent product , not current listing
          itemQuantity: product[prod].quantityAdded,
          itemPricePerUnit: product[prod].currentListing.bulkPrice,
          itemTaxes,
          itemTotalValue,
          itemPriceWithTaxes: itemFinaltotalvalTaxesCt,
          itemPaidAmount: usePaidAmount,
          itemRemainingAmount: useRemainingAmountItem,
          itemCreditInterestAmount: itemCreditInterestAmount,
          shipmentType: product[prod].type,
          varietyId: product[prod].currentListing.varietyId,
          productId: product[prod].currentListing.productId,
          categoryId: product[prod].currentListing.categoryId,
          productCity: product[prod].currentListing.city,
          useUnit: product[prod].currentListing.useUnit,
          useUnitShort: product[prod].currentListing.useUnitShort,
          isListingSortexType: product[prod].currentListing.isListingSortexType,
          packetSize: product[prod].currentListing.packetSize,
          sellerDetails: {
            sellerId: this.sellerID,
            sellerName: product[prod].currentListing.listingCreator,
            // seller company name is missing from currentListing
            sellerCompanyName: product[prod].currentListing.companyName,
            sellerAddress,
            sellerContactDetails,
            sellerGST: product[prod].currentListing.GST_Id,
          },
          optedPaymentTerms: {
            //use creditTerms added from sent product , not current listing
            creditTermDays: product[prod].creditTerms.creditTermDays,
            creditTermPercentage: product[prod].creditTerms.creditTermPercentage,
            creditTermStatement: product[prod].creditTerms.creditTermStatement,
            //give choice
            preferredDateofPayment: midDateCredit.toISOString(),
            lastDateOfPayment: lastDateOfPay.toISOString(),
          },
          optedPackagingType: {
            //use creditTerms added from sent product , not current listing
            value: product[prod].packagingType.packagingTypeValue,
            packagingImg: product[prod].packagingType.packagingImg,
            packagingType: product[prod].packagingType.packagingType
          },
          taxSummaryTerms
        }
        this.itemDetails.push(item)
      }
      // sum of all itemValues of PO -> Quantity * pricePerUnit
      const totalPrice = this.itemDetails.reduce((sum, item) => sum + item.itemTotalValue, 0);
      const quantityLooseItemsinQL = this.itemDetails.filter(allItems => { return !allItems.isListingSortexType }).reduce((sum, item) => sum + item.itemQuantity, 0)
      const quantitySortexItemsInKG = this.itemDetails.filter(allItems => { return allItems.isListingSortexType }).map(eachItem => { return eachItem.packetSize * eachItem.itemQuantity }).reduce((sum, item) => sum + item, 0)
      const quantitySortexItemsInQL = (quantitySortexItemsInKG / 100)
      const totalQuantity = quantityLooseItemsinQL + quantitySortexItemsInQL
      const totalInterestCTitems = this.itemDetails.reduce((sum, item) => sum + item.itemCreditInterestAmount, 0);
      // itemTaxes are subjected to the product and taxslab they fall under according to HSN code
      const totalTax = this.itemDetails.reduce((sum, item) => sum + item.itemTaxes, 0);
      //values added item wise
      const poValueIncludingTax = totalPrice + totalTax + totalInterestCTitems
      let sellerSurchargePerc: number
      let buyerSurchargePerc: number
      let trnsptCostPer100Qnt: number = 30000
      let useQuant: number
      if (totalQuantity <= 50) {
        useQuant = totalQuantity
      }
      if (totalQuantity > 50 && totalQuantity <= 180) {
        useQuant = Math.ceil(totalQuantity / 100) * 100
      }
      if (totalQuantity > 180) {
        trnsptCostPer100Qnt = 39000
        useQuant = Math.ceil(totalQuantity / 100) * 100
      }
      const useQuantMultiplier = Number(Number((useQuant / 95)).toFixed(3))
      if (useQuant <= 0.5) {
        trnsptCostPer100Qnt = 0
        const Xprc = Number(Number((trnsptCostPer100Qnt * 100) / poValueIncludingTax).toFixed(4))
        buyerSurchargePerc = Number(Number(Xprc * 8.5 / 10).toFixed(3))
        sellerSurchargePerc = Number(Number(Xprc - buyerSurchargePerc).toFixed(3))
      }
      if (useQuant > 0.5 && useQuant <= 1) {
        trnsptCostPer100Qnt = (trnsptCostPer100Qnt) * useQuantMultiplier
        // trnsptCostPer100Qnt = 0
        const Xprc = Number(Number((trnsptCostPer100Qnt * 100) / poValueIncludingTax).toFixed(4))
        buyerSurchargePerc = Number(Number(Xprc * 8.5 / 10).toFixed(3))
        sellerSurchargePerc = Number(Number(Xprc - buyerSurchargePerc).toFixed(3))
      }
      if (useQuant >= 1) {
        trnsptCostPer100Qnt = (trnsptCostPer100Qnt) * useQuantMultiplier
        const Xprc = Number(Number((trnsptCostPer100Qnt * 100) / poValueIncludingTax).toFixed(4))
        buyerSurchargePerc = Number(Number(Xprc * 9.1 / 10).toFixed(3))
        sellerSurchargePerc = Number(Number(Xprc - buyerSurchargePerc).toFixed(3))
      }
      // after onboarding 1000 sellers
      // if (totalQuantity <= 100) {
      //   sellerSurchargePerc = 2.1
      //   buyerSurchargePerc = 2.1
      // }
      // if (totalQuantity > 100 && totalQuantity <= 250) {
      //   sellerSurchargePerc = 1.7
      //   buyerSurchargePerc = 2.6
      // }
      // if (totalQuantity > 250 && totalQuantity <= 1000) {
      //   sellerSurchargePerc = 1.3
      //   buyerSurchargePerc = 3.1
      // }
      // if (totalQuantity > 1000) {
      //   sellerSurchargePerc = 0.8
      //   buyerSurchargePerc = 3.5
      // }

      // Additional Charges that seller pays -> this needs to be cut from sellers end, so dont add here
      const sellerSurcharge = parseInt(Number(((sellerSurchargePerc / 100) * poValueIncludingTax)).toFixed(2))
      const tcsSurcharge = parseInt(Number(((1 / 100) * poValueIncludingTax)).toFixed(2))
      // Additional Charges that buyer pays
      const buyerSurcharge = parseInt(Number(((buyerSurchargePerc / 100) * poValueIncludingTax)).toFixed(2))
      const smallorderSurcharge = poValueIncludingTax < 1000 ? buyerSurcharge + sellerSurcharge + 39 : 0
      let purchaseOrderValueFinal = poValueIncludingTax + buyerSurcharge + smallorderSurcharge
      //values added item independent
      const additionalChargesApply: AdditionalCharges[] =
        [
          {
            account: 'Buyer',
            payByUser: this.loginUserInfo.userId,
            chargeType: 'Handling Fees',
            percentage: buyerSurchargePerc,
            amount: buyerSurcharge
          },
          // {
          //   account: 'Seller',
          //   payByUser: this.sellerID,
          //   chargeType: 'Shipment and Platform Fees',
          //   percentage: sellerSurchargePerc,
          //   amount: sellerSurcharge
          // },
          // 0 commission
          {
            account: 'Seller',
            payByUser: this.sellerID,
            chargeType: 'Shipment and Platform Fees',
            percentage: 0,
            amount: 0
          },
          {
            account: 'Buyer',
            payByUser: this.loginUserInfo.userId,
            chargeType: 'Small Order',
            percentage: 0,
            amount: smallorderSurcharge
          },
          {
            account: 'Seller',
            payByUser: this.sellerID,
            chargeType: 'TCS',
            percentage: 1,
            amount: tcsSurcharge
          }
        ]
      purchaseOrderValueFinal && purchaseOrderValueFinal < 5000 ? purchaseOrderValueFinal + smallorderSurcharge : purchaseOrderValueFinal
      const purchaseOrderDetails: PurchaseOrderModel = {
        _id: '',
        purchaseOrderNumber: '',
        sellerDetails: null,
        buyerDetails: {
          buyerId: this.loginUserInfo.userId,
          buyerName: this.loginUserInfo.firstname + ' ' + this.loginUserInfo.lastname,
          buyerCompanyName: this.loginUserInfo.companyName,
          buyerAddress,
          buyerContactDetails,
          buyerGST: this.loginUserInfo.GST_Id,
        },
        itemDetails: this.itemDetails,
        shipToBuyerLocation,
        modePickup: {
          isBuyerPickup: null,
          isVaayuPickup: null,
          pickUpOtp: null,
        },
        dateOfPOGenerated: null,
        dateOfPurchaseOrderExpiry: null,
        dateOfPurchaseOrderDelivery: null,
        dateOfPurchaseOrderAccepted: null,
        validityOfPurchaseOrderExpiry: null,
        s3POpdfLink: null,
        //changed from- order value before was total value of sum of items without tax 
        //changed to- order value before now is total value of sum of items with Tax tax
        orderQuantity: Math.round(totalQuantity * 10000) / 10000,
        orderTax: parseInt(Number(totalTax).toFixed(2)),
        orderValue: parseInt(Number(poValueIncludingTax).toFixed(2)),
        // 2.49 % platform fees added to orderValueIncludingTax in sense of purchaseOrderValueForBuyer
        orderValueIncludingTax: parseInt(Number(purchaseOrderValueFinal).toFixed(2)),
        isPOStatusActive: true,
        poStatus: "",
        isInvoiceGenerated: false,
        invoiceStatus: "Inactive",
        invoiceVersions: null,
        returnVersions: null,
        isbalanceRemainingForNewInvoice: true,
        additionalCharges: additionalChargesApply,
        amountPaidAtCheckout: null,
        amountRemainingForInvoices: null
        // invoiceIds: null,
      }
      // console.info('this.loginUserInfo.isGSTAvailable', this.loginUserInfo.isGSTAvailable)
      // console.info('this.loginUserInfo.isPANAvailable', this.loginUserInfo.isPANAvailable)
      // console.info('this.loginUserInfo.isGSTVerified', this.loginUserInfo.isGSTVerified)
      // console.info('this.loginUserInfo.isPANVerified', this.loginUserInfo.isPANVerified)
      // console.info('purchaseOrderDetails.orderQuantity', purchaseOrderDetails.orderQuantity)
      // this.loginUserInfo.GST_Id === "" || this.loginUserInfo.GST_Id === null ? isGSTAvailable = false : isGSTAvailable = true
      if (this.loginUserInfo.isGSTAvailable
        && this.loginUserInfo.isGSTVerified) {
        this.handlePurchaseOrder.updateBundledPOitems({ purchaseOrderDetails, allItemIDsOfCart, cartId: this.cartId })
        return this.router.navigate(['/createPurchaseOrder'])

      }
      if (!this.loginUserInfo.isGSTAvailable && purchaseOrderDetails.orderQuantity <= 5) {
        this.isShowLimit = true
        this.handlePurchaseOrder.updateBundledPOitems({ purchaseOrderDetails, allItemIDsOfCart, cartId: this.cartId })
        return this.router.navigate(['/createPurchaseOrder'])
      }
      else
      //  (!this.loginUserInfo.isGSTVerified
      //   && !this.loginUserInfo.isPANVerified &&
      //   purchaseOrderDetails.orderQuantity > 5) 
      {
        return this.verifyProfileError("eKYC Pending. Complete to create Purchase Order", "Verify")
      }
    }
    catch (e) {
      throw e
    }

  }
  // accountInformation
  verifyProfileError(message: string, action: string) {
    try {
      // give snackBar error then route to profule page on action
      // login error notific
      let snackbarRef = this._snackBar.open(message, action, {
        duration: 6000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
      })
      snackbarRef.onAction().subscribe(() => {
        this.router.navigate([`/accountInformation/${this.loginUserInfo.userId}`])
      })
      // go to profile page - the user can see all the info here and edit it - like upload GST and PAN as well
    }
    catch (e) {
      throw e
    }

  }
  ratingsAsStars(stars: any) {
    return Array(stars)
  }
}
