<div class="contentFAQ">
    <div class="faq-header">Ask us and we answer</div>
    <div class="faq-content">
        <div class="questionByUser">
            <mat-form-field appearance="outline">
                <textarea [(ngModel)]="this.questionUser" matInput name="itemName"
                    placeholder="Post your query"></textarea>
            </mat-form-field>
            <button class="done" (click)="this.postQuestion()" mat-stroked-button>Post</button>
        </div>
        <div *ngFor="let eachFAQ of useFaq">
            <div (click)="goToPosts(eachFAQ._id,eachFAQ.metaTag)" class="thisFAQ">
                <span class="useQuestion">
                    {{eachFAQ.question}}
                </span>
                <span class="icon">
                    <mat-icon>open_in_new</mat-icon>
                </span>
            </div>
        </div>
    </div>
</div>