<div id="scroll" class="wrapper">
    <div class="header row justify">
        <a target="_blank" href="https://www.myapaar.com/Posts/6690fe91b85fd49ac0eeff8d/What_is_Myapaar_Vaayu_Service"
            class="addHelp">Myapaar Vaayu Service</a>
        <mat-icon (click)="openLink($event)" class="clearIcon">clear</mat-icon>
    </div>
    <div [routerLink]="['/accountInformation',this.currentuserId]" class="verifiedID" *ngIf="!this.useClientLogo">
        <span>Add business logo on your profile</span>
    </div>
    <div class="chatMyapaar">

        <!-- prev chat  -->
        <div *ngIf="this.allOldInteractions">
            <div class="normalChat" *ngFor="let inter of this.allOldInteractions">
                <div *ngIf="inter.clickedOn">
                    <div class="row">
                        <div class="user">

                        </div>
                        <mat-nav-list class="chatContent yellowBorder">
                            <div class="row">
                                <p>{{inter.clickedOn}}</p>
                            </div>
                        </mat-nav-list>
                    </div>
                    <div *ngIf="inter.customerResponse && inter.customerResponse != '' " class="row flexEnd">
                        <mat-nav-list class="chatContent blueBorder">
                            <div class="row">
                                <p>{{inter.customerResponse}}</p>
                            </div>
                            <!-- <a href="https://www.myapaar.com/MandiRate" mat-list-item (click)="openLink($event)">
                            Latest Mandi Rates
                        </a> -->
                        </mat-nav-list>
                        <img matTooltip="Change Logo" [routerLink]="['/accountInformation',this.currentuserId]"
                            *ngIf="useClientLogo" class="user2 client" [src]="this.userInfo.logoImage"
                            (click)="closeChat($event)" />
                        <img matTooltip="Add Business Logo" [routerLink]="['/accountInformation',this.currentuserId]"
                            *ngIf="!useClientLogo" class="user2 client" (click)="closeChat($event)"
                            src="../../../assets/productcategoryimages/account-add.svg" />
                    </div>
                </div>
            </div>
        </div>

        <!-- template text by admin  -->
        <div class="byAdmin">
            <div class="row">
                <div class="user admin">

                </div>
                <mat-nav-list class="chatContent yellowBorder">
                    <p class="chatAdmin">Hello ! How may I help you ?</p>
                    <div (click)="firstResponse(this.optionOne)" class="row">
                        <a target="_blank" href="https://www.myapaar.com/requirement/NewRequirement" mat-list-item>
                            <mat-icon class="adminPoint">check_circle_outline</mat-icon> {{this.optionOne}}
                        </a>
                    </div>
                    <div (click)="firstResponse(this.optionTwo)" class="row">
                        <a target="_blank" href="https://www.myapaar.com/MandiRate" mat-list-item>
                            <mat-icon class="adminPoint">check_circle_outline</mat-icon> {{this.optionTwo}}
                        </a>
                    </div>
                    <div (click)="firstResponse(this.optionThree)" class="row">
                        <a target="_blank" href="https://www.myapaar.com/viewItems/All" mat-list-item>
                            <mat-icon class="adminPoint">check_circle_outline</mat-icon> {{this.optionThree}}
                        </a>
                    </div>
                    <div (click)="openCustomerChat()" class="row">
                        <a mat-list-item>
                            <mat-icon class="adminPoint">youtube_searched_for</mat-icon> {{this.optionFour}}
                        </a>
                    </div>

                    <!-- <a href="https://www.myapaar.com/MandiRate" mat-list-item (click)="openLink($event)">
                        Latest Mandi Rates
                    </a> -->
                </mat-nav-list>
            </div>
        </div>

        <app-loader *ngIf="this.loadingOldChat && this.allOldInteractions && this.allOldInteractions.length>4" class="loader"></app-loader>

        <div *ngIf="this.latestInteraction">
            <div class="normalChat">
                <div *ngIf="this.latestInteraction.clickedOn">
                    <div class="row">
                        <div class="user">

                        </div>
                        <mat-nav-list class="chatContent yellowBorder">
                            <div class="row">
                                <p>{{this.latestInteraction.clickedOn}}</p>
                            </div>
                        </mat-nav-list>
                    </div>
                    <div class="row flexEnd">
                        <mat-nav-list class="chatContent blueBorder">
                            <div class="row">
                                <p>{{this.latestInteraction.customerResponse}}</p>
                            </div>
                            <!-- <a href="https://www.myapaar.com/MandiRate" mat-list-item (click)="openLink($event)">
                            Latest Mandi Rates
                        </a> -->
                        </mat-nav-list>
                        <img matTooltip="Change Logo" [routerLink]="['/accountInformation',this.currentuserId]"
                            *ngIf="useClientLogo" class="user2 client" [src]="this.userInfo.logoImage"
                            (click)="closeChat($event)" />
                        <img matTooltip="Add Business Logo" [routerLink]="['/accountInformation',this.currentuserId]"
                            *ngIf="!useClientLogo" class="user2 client" (click)="closeChat($event)"
                            src="../../../assets/productcategoryimages/account-add.svg" />
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="this.otherResponseRecorded">
            <div class="normalChat">
                <div>
                    <div *ngIf="this.userIsAuthenticated" class="row">
                        <div class="user">

                        </div>
                        <mat-nav-list class="chatContent yellowBorder">
                            <div class="row">
                                <span>{{this.customHelpMessage0}}</span>
                            </div>
                        </mat-nav-list>
                    </div>
                    <div *ngIf="!this.userIsAuthenticated" class="row">
                        <div class="user">

                        </div>
                        <mat-nav-list class="chatContent yellowBorder">
                            <div class="row">
                                <span>{{this.customHelpMessage1}}</span>
                            </div>
                        </mat-nav-list>
                    </div>
                    <div class="row">
                        <div class="user">

                        </div>
                        <mat-nav-list class="chatContent yellowBorder">
                            <div class="row">
                                <span>{{this.customHelpMessage2}}</span>
                            </div>
                        </mat-nav-list>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="this.customerChatBox" class="byCustomer">
            <div class="row flexEnd">
                <div class="userContent">
                    <mat-icon (click)="otherResponse(this.optionFour)" class="send">send</mat-icon>
                    <mat-form-field class="userChatBox" appearance="outline">
                        <textarea [(ngModel)]="this.userMessage" matInput rows="5" cols="50"
                            autocomplete="off"> </textarea>
                    </mat-form-field>
                </div>
                <img *ngIf="useClientLogo" matTooltip="Change Logo" (click)="closeChat($event)"
                    [routerLink]="['/accountInformation',this.currentuserId]" class="user2 client"
                    [src]="this.userInfo.logoImage" />
                <img matTooltip="Add Business Logo" [routerLink]="['/accountInformation',this.currentuserId]"
                    *ngIf="!useClientLogo" class="user2 client" (click)="closeChat($event)"
                    src="../../../assets/productcategoryimages/account-add.svg" />
            </div>
        </div>

    </div>
</div>