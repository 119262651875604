import { Component, OnInit } from '@angular/core';
interface RefundPolicy {
  subject: string;
  statement: string;
}
@Component({
  selector: 'app-refund-policy',
  templateUrl: './refund-policy.component.html',
  styleUrls: ['./refund-policy.component.css']
})
export class RefundPolicyComponent implements OnInit {

  refundPolicyPoints: RefundPolicy[] = [
    {
      subject: "Creating Returns",
      statement: "Buyers can easily create returns in 5 days after delivery."
    },
    {
      subject: "Condition",
      statement: "All items should be packed and sealed as delivered, except for 1 bag / packet / container for each listing item. For example, if 10 Bags of Wheat and 5 Bags of Rice were delivered, only 1 Bag of Wheat and 1 Bag of Rice can be opened and inspected for return. Rest items must remain packed and sealed."
    },
    {
      subject: "Quantity & Quality Matching",
      statement: "The quantity and quality of delivered items should be equal to the quantity and quality of return and to-be-returned items."
    },
    {
      subject: "Refund Processing",
      statement: "Refund will be issued to the source bank account from which payment was made, within 5 working days after the items are returned to the seller and verified."
    }, {
      subject: "Return Tracking",
      statement: "Customers can track return orders made on the Manage Orders > Returns section of the Myapaar App."
    },
    {
      subject: "Premium Users Return",
      statement: "Cost of return shipment is free for Premium Account Users."
    },
    {
      subject: "Standard Users Return",
      statement: "Cost of return shipment is levied for Standard Account Users."
    },
    {
      subject: "Contact Us",
      statement: "Mail and Write to us at solutions@myapaar.com or help@myapaar.in with Subject - #PurchaseOrderNumber# or #ReturnOrderNumber#, to get assistance from our Vaayu team for delivery and returns."
    },
  ]





  constructor() { }
  ngOnInit(): void {
  }

}
