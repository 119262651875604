import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ImageHandlingService {


  constructor() { }
  compressMultiple(files: FileList) {
    let compressedDataURLs: File[] = []
    return new Promise(resolve => {
      Array.prototype.forEach.call(files, (file) => {
        const fileName = file.name
        const fileType = file.type
        const reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = async () => {
          const useWidth = 1080
          const useHeight = 940
          const compressedImageDataURI = await this.resizeSingleImage(reader.result as string, file.type, useWidth, useHeight)
          const imageName = fileName.replace(/\s/g, '')
          const compressFile: File = new File([this.convertDataUrlToBlob(compressedImageDataURI)], imageName, { type: fileType });
          compressedDataURLs.push(compressFile)
          if (files.length === compressedDataURLs.length)
            resolve(compressedDataURLs)
        };
      });
    });
  }
  convertDataUrlToBlob(dataUrl: string): Blob {
    try {
      const arr = dataUrl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    }
    catch (e) {
      throw e
    }
  }

  resizeSingleImage(imageURL: any, format: any, wid: number, hei: number): Promise<any> {
    try {
      return new Promise((resolve) => {
        const image = new Image();
        image.onload = function () {
          const canvas = document.createElement('canvas');
          canvas.width = wid;
          canvas.height = hei;
          const ctx = canvas.getContext('2d');
          if (ctx != null) {
            ctx.drawImage(image, 0, 0, wid, hei);
          }
          var data = canvas.toDataURL(format, 1);
          resolve(data);
        };
        image.src = imageURL;
      });
    }
    catch (e) {

    }
  }
}

