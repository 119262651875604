import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { BankDetails } from 'src/app/datamodels/groupedmodel/bankDetails';
import { WithdrawalDetails } from 'src/app/datamodels/sellerpayModel/withdrawalDetailsModel';
import { HandleInvoiceService } from 'src/app/services/handle-invoice.service';
import { HandlesellerCentralService } from 'src/app/services/handleseller-central.service';
interface invoiceInfoIn {
  useInvoiceNumber: string;
  useInvoiceAmount: number;
  useInvoicePaidDate: Date;
  useFundWithdrawabledate: Date;
  useIsFundWithdrawable: boolean;
  useIsWithdrawalReqAlreadyPlaced: boolean;
  usefundWithdrawnDate: Date;
  usetotalDeducDone: number;
  useWithdrawableAmount: number
}
interface withdrawalInfoIn {
  orderNumb: string;
  invoiceNumb: string;
  invoiceAmount: number;
  finalDeduct: number;
  withdrawalAmount: number;
  bankDetails: {
    accountName: string;
    accountNumber: string;
    accountIFSC: string;
    accountBankBranch: string;
  }
}
@Component({
  selector: 'app-create-withdrawal',
  templateUrl: './create-withdrawal.component.html',
  styleUrls: ['./create-withdrawal.component.css']
})
export class CreateWithdrawalComponent implements OnInit {
  isLoading: boolean = false;
  private loginUserInfo: any;
  currentUserId: string;
  myWithdrawals: WithdrawalDetails[];
  amountWithdrawVal: number = 0;
  withdrawalFromInvo: invoiceInfoIn;
  useBankingInfo: BankDetails;
  useOrderNumber: string;
  bankDetailsForm: FormGroup
  formErrors = {
    'accountName': 'Account Name is required',
    'accountNumber': 'Account Number is required',
    'accountIFSC': 'IFSC Code is required',
    'accountBankBranch': 'Bank Branch Name is required',
  }
  constructor(
    private hsc: HandlesellerCentralService,
    public router: Router,
    private _snackBar: MatSnackBar,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.bankDetailsForm = new FormGroup({
      // selectItems: new FormControl(null),
      accountName: new FormControl(null, {
        validators: [Validators.required, Validators.maxLength(100)]
      }),
      accountNumber: new FormControl(null, { validators: [Validators.required, Validators.minLength(8), Validators.maxLength(25)] }),
      accountIFSC: new FormControl(null, { validators: [Validators.required, Validators.minLength(11), Validators.maxLength(11)] }),
      accountBankBranch: new FormControl(null, { validators: [Validators.required] }),
    })
    this.isLoading = true;//spinner starts
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.getInvoiceWithdrawalInfo()
    this.getWithdrawals(this.currentUserId)
  }

  getInvoiceWithdrawalInfo() {
    this.hsc.shareInvoWithdrawal.subscribe((invoiceWithdrawal: invoiceInfoIn) => {
      this.withdrawalFromInvo = invoiceWithdrawal
      if (!this.withdrawalFromInvo) {
        this.router.navigate(['/SellerCentral', this.currentUserId])
      }
      this.amountWithdrawVal = this.withdrawalFromInvo.useWithdrawableAmount
      const invoNumber = this.withdrawalFromInvo.useInvoiceNumber
      this.getOrderAndBankingInfo(invoNumber)
    })
  }

  getOrderAndBankingInfo(invoNumber: string) {
    try {
      this.isLoading = true
      this.hsc.getInvoiceBankingInfo(invoNumber, this.currentUserId).then(OrderAndbankingInfoFromInvoice => {
        if (OrderAndbankingInfoFromInvoice && OrderAndbankingInfoFromInvoice.status == 200) {
          this.useBankingInfo = OrderAndbankingInfoFromInvoice.bankingInfo
          this.useOrderNumber = OrderAndbankingInfoFromInvoice.orderNumber
          this.setBankFormValues()
        }
        else {
          const errMess = 'Bank Account On Invoice Not found. Enter again to recieve funds'
          this.withdrawalError(errMess, "")
        }
      }
      )
    }
    catch (e) { }
  }

  setBankFormValues() {
    try {
      this.bankDetailsForm.setValue({
        'accountName': this.useBankingInfo.accountName || "",
        'accountNumber': this.useBankingInfo.accountNumber || "",
        'accountIFSC': this.useBankingInfo.ifscNumber || "",
        'accountBankBranch': this.useBankingInfo.branchName || "",
      })
    }
    catch (e) {
      throw e
    }
  }

  resetForm() {
    try {
      this.bankDetailsForm.reset()
    }
    catch (e) {
      throw e
    }
  }

  getWithdrawals(currentUserId: string) {
    try {
      this.hsc.getAllWithdrawalsSeller(currentUserId).subscribe(allWithdrawals => {
        if (allWithdrawals && allWithdrawals.length >= 0) {
          this.isLoading = false;
          this.myWithdrawals = allWithdrawals
        }
      })
    }
    catch (e) { }
  }

  createWithdrawal() {
    try {
      this.isLoading = true; //spinner starts
      if (this.bankDetailsForm.invalid) {
        this.withdrawalError("Add bank details", "Ok")
        return
      }
      if (this.bankDetailsForm.valid) {
        const withdrawalInfo: withdrawalInfoIn = {
          orderNumb: this.useOrderNumber,
          invoiceNumb: this.withdrawalFromInvo.useInvoiceNumber,
          invoiceAmount: this.withdrawalFromInvo.useInvoiceAmount,
          finalDeduct: this.withdrawalFromInvo.usetotalDeducDone,
          withdrawalAmount: this.amountWithdrawVal,
          bankDetails: {
            accountName: this.bankDetailsForm.value.accountName,
            accountNumber: this.bankDetailsForm.value.accountNumber,
            accountIFSC: this.bankDetailsForm.value.accountIFSC,
            accountBankBranch: this.bankDetailsForm.value.accountBankBranch
          }
        }
        this.hsc.createNewWithdrawal(
          withdrawalInfo, this.currentUserId
        ).then(response => {
          if (response && response.status === 201) {
            this.withdrawalSuccessful(response.message, "Ok")
            this.isLoading = false; //spinner starts
          }
        },
        ).catch(
          //second argument of subscribe
          error => {
            this.isLoading = false; //spinner starts
            // this.authStatusListener.next(false);
            if (error) {
              let sendErrmessage = ''
              if (error.status = 400) {
                sendErrmessage = `${this.amountWithdrawVal} is more than your Withdrawable balance`
              }
              this.withdrawalError(`${sendErrmessage}`, "Ok")
            }
          });
      }
    }
    catch (e) {
    }
  }

  withdrawalSuccessful(message: string, action: string) {
    let resSuccess = this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
    resSuccess.onAction().subscribe(() => {
      this.getWithdrawals(this.currentUserId)
    })
    resSuccess.afterDismissed().subscribe(() => {
      this.getWithdrawals(this.currentUserId)
    })
  }

  withdrawalError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }
}
