import { Component, OnInit } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MyvyapaariChatComponent } from '../myvyapaari-chat/myvyapaari-chat.component';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {

  constructor(
    private _bottomSheet: MatBottomSheet,
    private utils: UtilsService
  ) { }

  ngOnInit(): void {
  }

  openBottomSheet(): void {
    this.utils.playWidgetAudio();
    this._bottomSheet.open(MyvyapaariChatComponent, {
      panelClass: 'custom-width'
    });
  }
}
