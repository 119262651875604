import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';
import { StateModel } from '../datamodels/Statemodel';
import { ProductCategoryModel } from '../datamodels/productcategory';
import { SettingsService } from './settings.service';
@Injectable({
  providedIn: 'root'
})
export class AllstatesService {
  checkString: any
  private domain: string | undefined
  constructor(private http: HttpClient
    , private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }
  async getPincodeDetails(pinCode: string): Promise<any> {
    const usePin = await this.http.get<any>(`${this.domain}api/states/pincode/` + pinCode).toPromise()
    return usePin
  }

  async getAllCommodityRates(stateName: string): Promise<any> {
    const useComms = await this.http.get<any>(`${this.domain}api/states/commodityChart/` + stateName).toPromise()
    return useComms
  }

  getAllStates(): Observable<any> {
    return this.http.get<any>(`${this.domain}api/states`)
  }
  async getStates() {
    return this.http.get<any>(`${this.domain}api/states`).toPromise()
  }
}
