<div *ngIf="isLoading" class="content-loading">

    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Creating Invoice Order </h1>
            <h3>Generating Invoice Order Document </h3>
        </div>
        <mat-spinner></mat-spinner>
    </div>
</div>

<div class="contentInvoiceCreate">
    <div *ngIf="this.allItemsCoveredForInvoicing">
        <span> </span>

        <h1 class="titNoPO">All Items covered for Invoicing </h1>
        <h3 class="subNoPO"> {{this.currentPurchaseOrderwithId.invoiceVersions.length}} Invoice Bills are Created
        </h3>
        <div *ngFor="let invoVs of this.currentPurchaseOrderwithId.invoiceVersions" class="invoiceItems">
            <div [routerLink]="['/viewInvoiceOrder',invoVs.invoiceID]" class="versionInvoice">
                <a mat-stroked-button>
                    V {{invoVs.version}} &nbsp;&nbsp; {{invoVs.invoiceNumber}}
                </a>
            </div>
        </div>
    </div>
    <div *ngIf="this.currentPurchaseOrderwithId && !this.allItemsCoveredForInvoicing">
        <div *ngIf="!isLoading">
            <div class="itemsCreditTerms" *ngIf="this.itemsInPoForInvoice">
                <h1 class="heading">
                    Select Items for Shipment & Invoicing
                </h1>
                <div class="item">
                    <ul>
                        <div class="selectAll list-section">
                            <mat-checkbox class="example-margin" [checked]="allComplete"
                                [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                <span>Select All</span>
                            </mat-checkbox>
                            <mat-error *ngIf="this.itemsNotSelectedShowError">{{formErrors.selectItems}}</mat-error>
                        </div>
                        <br>
                        <li *ngFor="let items of this.itemsInPoForInvoice">
                            <mat-checkbox [(ngModel)]="items.checked" (ngModelChange)="updateAllComplete()">
                                <div class="daysRemaining">
                                    <div class="itemRD days">
                                        <div *ngIf="items.interestAndCT &&
                                        items.interestAndCT !== 'Check and Pay on delivery'
                                        && items.interestAndCT !== 'Cash and Carry'">
                                            <span class="warning pay"
                                                *ngIf="items.daysLeftInPaymentSincePOCreated <= 0">
                                                {{-1 *
                                                items.daysLeftInPaymentSincePOCreated}} Days Late for Payment
                                            </span>
                                            <span class="reminder pay "
                                                *ngIf="items.daysLeftInPaymentSincePOCreated > 0">
                                                Buyer pays in {{items.daysLeftInPaymentSincePOCreated}} days
                                            </span>
                                        </div>
                                    </div>
                                </div> <br>
                                <div class="mainItem eachItem">
                                    <div class="itemGenInfo">
                                        <div class="itemTitle">
                                            <span class="title">{{items.itemTitle}}
                                            </span>
                                        </div>
                                        <div class="itemDesc ProdCatVar">
                                            <span class="itmhead Cat">{{items.itemCategory}}
                                            </span>
                                            <span class="itmhead Prod">| {{items.itemProduct}}
                                            </span>
                                            <span *ngIf="items.itemVariety !== ''" class="itmhead Var">|
                                                {{items.itemVariety}}
                                            </span>
                                        </div>

                                        <div class="TotalQuantity">
                                            <span class="itemQuant">Total Quantity {{items.itemQuantity}}
                                                {{items.unitShort}}
                                            </span>
                                        </div>
                                        <div class="StatedCreditTerms">
                                            <span class="itemCT">Payment Terms : {{items.interestAndCT}}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="itemPrices">
                                        <div class="itemTP TotalPrice">
                                            <span class="keyitm"> Items Value </span> <span>
                                                {{(items.itemTotalPrice).toLocaleString('en-IN')}} ₹
                                            </span>
                                        </div>
                                        <div class="itemTX Taxes">
                                            <span class="keyitm"> Tax </span> <span>
                                                {{(items.itemTaxes).toLocaleString('en-IN')}} ₹
                                            </span>
                                        </div>
                                        <div class="itemTX Taxes">
                                            <span class="keyitm"> Interest</span> <span>
                                                {{(items.itemCreditInterestAmount).toLocaleString('en-IN')}} ₹
                                            </span>
                                        </div>
                                        <div class="itemTXTP totalTaxedPrice">
                                            <span class="keyitm">Total Price (incl. tax)</span>
                                            <span>
                                                {{(items.itemTotalPriceWithTaxes).toLocaleString('en-IN')}} ₹
                                            </span>
                                        </div>
                                        <div class="itemTXTP totalTaxedPrice">
                                            <span class="keyitm">Item Amount Paid</span>
                                            <span>
                                                {{(items.paidAmount).toLocaleString('en-IN')}} ₹
                                            </span>
                                        </div>
                                        <div class="itemTXTP totalTaxedPrice">
                                            <span class="keyitm">Item Amount Remaining</span>
                                            <span>
                                                {{(items.remainingAmount).toLocaleString('en-IN')}} ₹
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <br>
                            </mat-checkbox>
                            <hr>
                            <br>
                        </li>
                    </ul>
                </div>
            </div>

            <div *ngIf="atleastOneItemSelected" class="selectedItemsSumValue">
                <div class="atleastOneItemSelected">
                    <span class="subheading">Total Purchase Order Value </span>
                    <span class="itemofinvoice orderValueIncludingTax">
                        {{(this.currentPurchaseOrderwithId.orderValueIncludingTax).toLocaleString('en-IN')}} ₹</span>
                </div>
                <div class="atleastOneItemSelected">
                    <span class="subheading">Already Paid</span>
                    <span class="itemofinvoice orderValueIncludingTax">
                        {{(this.currentPurchaseOrderwithId.amountPaidAtCheckout).toLocaleString('en-IN')}} ₹</span>
                </div>
                <div *ngIf="this.currentPurchaseOrderwithId.invoiceVersions.length > 0" class="atleastOneItemSelected">
                    <span class="subheading">Other Invoice Versions Amount</span>
                    <span class="itemofinvoice pricesOfItemInvoices">
                        {{(this.sumOfAllInvoiceAmountsRaisedForPO).toLocaleString('en-IN')}} ₹</span>
                </div>
                <div class="atleastOneItemSelected">
                    <span class="subheading">Current Invoice Amount</span>
                    <span class="itemofinvoice raised">
                        {{(this.displayTotalPriceInclTaxForSelectedItems).toLocaleString('en-IN')}} ₹</span>
                </div>
                <div class="atleastOneItemSelected">
                    <span class="subheading"> Remaining Invoice Amount</span>
                    <!-- if invoice versions are available - also deduct their invoise raised value from total order value -->
                    <span class="itemofinvoice raised">{{(this.invoiceBalanceRemainingToPay).toLocaleString('en-IN')}}
                        ₹</span>
                    <!-- <span *ngIf="this.currentPurchaseOrderwithId.invoiceVersions.length > 0"
                        class="itemofinvoice balance">
                        {{(this.currentPurchaseOrderwithId.orderValue -
                        this.currentPurchaseOrderwithId.amountPaidAtCheckout -
                        this.sumOfAllInvoiceAmountsRaisedForPO -
                        this.displayTotalPriceInclTaxForSelectedItems).toLocaleString('en-IN')}} ₹</span>
                    <span *ngIf="this.currentPurchaseOrderwithId.invoiceVersions.length === 0"
                        class="itemofinvoice balance">
                        {{(this.currentPurchaseOrderwithId.orderValue -
                        this.currentPurchaseOrderwithId.amountPaidAtCheckout -
                        this.displayTotalPriceInclTaxForSelectedItems).toLocaleString('en-IN')}} ₹</span> -->
                </div>
            </div>
            <br>
            <hr>
            <form [formGroup]="invoiceForm" (ngSubmit)="createInvoice(this.currentPurchaseOrderwithId)">

                <div class="bankAccountSection">
                    <div class="bankDetails">
                        <h1 class="heading">
                            Bank Details
                        </h1>
                        <mat-hint class="subheading">Payment will be credited to this account</mat-hint>

                        <mat-form-field appearance="outline">
                            <input matInput type="text" name="accountName" formControlName="accountName"
                                placeholder="Account Name" minlength="1" maxlength="100" required>
                            <mat-error *ngIf="formErrors.accountName">{{formErrors.accountName}}</mat-error>
                            <mat-hint>Name of business or personal Account Holder</mat-hint>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <input matInput type="text" name="accountNumber" formControlName="accountNumber"
                                placeholder="Account Number" minlength="8" maxlength="25" required>
                            <mat-error *ngIf="formErrors.accountNumber">{{formErrors.accountNumber}}</mat-error>
                            <mat-hint>05110xxxxxxxx</mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <input matInput type="text" name="accountIFSC" minlength="11" maxlength="11"
                                formControlName="accountIFSC" placeholder="IFSC" required>
                            <mat-error *ngIf="formErrors.accountIFSC">{{formErrors.accountIFSC}}</mat-error>
                            <mat-hint>SBIN0511xxxx</mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <input matInput type="text" name="accountBankBranch" formControlName="accountBankBranch"
                                placeholder="Branch Name" required>
                            <mat-error *ngIf="formErrors.accountBankBranch">{{formErrors.accountBankBranch}}</mat-error>
                            <mat-hint>Kalyan Nagar</mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <input matInput type="text" name="paymentRemarks" formControlName="paymentRemarks"
                                placeholder="Payment Remarks" maxlength="50" required>
                            <mat-error *ngIf="formErrors.paymentRemarks">{{formErrors.paymentRemarks}}</mat-error>
                            <mat-hint>Remarks</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="dueBy">
                        <mat-form-field appearance="outline" appearance="outline">
                            <mat-label>Preferred Payment</mat-label>
                            <mat-select [(ngModel)]="deadLineDateValue" name="paymentDeadline"
                                formControlName="paymentDeadline">
                                <mat-option *ngFor="let date of deadLineDate" [value]="date.value">
                                    {{date.viewValue}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>Number of days for payment</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

            </form>
            <div class="actionButton">
                <button [disabled]="!this.invoiceForm.valid || this.itemsNotSelectedShowError" class="createINV"
                    mat-raised-button type="button" (click)="createInvoice(this.currentPurchaseOrderwithId)">
                    <span>Ship & Invoice</span>
                </button>
                <!-- <button class="createINV" mat-raised-button (click)="createInvoice(this.currentPurchaseOrderwithId)">
                    <span>Create Invoice</span>
                </button> -->
            </div>
        </div>

    </div>
</div>