import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { BuyerDetails } from 'src/app/datamodels/groupedmodel/buyerDetails';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { AddressBook } from '../../datamodels/groupedmodel/addressBook';
import { ItemDetails } from 'src/app/datamodels/groupedmodel/itemDetails';
import { SellerDetails } from 'src/app/datamodels/groupedmodel/sellerDetails';
import { HandleInvoiceService } from 'src/app/services/handle-invoice.service';
import { InvoiceModel } from 'src/app/datamodels/invoiceOrderModel/invoiceDetailsModel';
import { VersionInvoicing } from 'src/app/datamodels/invoiceOrderModel/invoiceVersionDataModel';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RaiseDisputeModel } from 'src/app/datamodels/groupedmodel/raiseDisputeModel';
import { ReportProblemService } from 'src/app/services/report-problem.service';
import { HandlereturnService } from 'src/app/services/handlereturn.service';
import { ReturnModel } from 'src/app/datamodels/returnOrders/returnDetailsModel';

@Component({
  selector: 'app-view-return-order',
  templateUrl: './view-return-order.component.html',
  styleUrls: ['./view-return-order.component.css']
})
export class ViewReturnOrderComponent implements OnInit {
  private loginUserInfo: any
  private currentUserId: string
  purchaseOrderID: string;
  returnOrderID: string;
  isLoading: boolean = false
  linkedPurchaseOrderwithId: PurchaseOrderModel;
  currentReturnOrderwithId: ReturnModel;
  itemDetailsofRETn: ItemDetails[] = [];
  sellerDetails: SellerDetails[] = [];
  sellerAccountType: boolean = false
  // if this PO is created by 1seller for some order he wants from another 2seller
  isUserActingBuyer: boolean = false
  // if this PO is created by other buyers for some order they want from this 1seller
  isUserActingSeller: boolean = false
  buyerAccountType: boolean = false
  gstDetailsPending: boolean = false;
  gstVerificationPending: boolean = false;
  fetchAccountPOsByAccountType: string;
  displayedSellerTypeTableColumns: string[] = []
  displayedBuyerTypeTableColumns: string[] = []
  invoiceVersionsOfPO: VersionInvoicing[] = []
  allowMoreInvoices: boolean = true;
  totalAmountRaisedInAllVersions: number;
  lastVersionInvoice: VersionInvoicing;
  poIssues: RaiseDisputeModel[] = [];
  issueForPOExist: boolean = false;
  issuesFound: RaiseDisputeModel[] = [];
  lastActiveVersionActions: boolean = false;
  allowShipping: boolean = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private handlePurchaseOrder: HandlePurchaseOrderService,
    private hins: HandleInvoiceService,
    private _snackBar: MatSnackBar,
    private rps: ReportProblemService,
    private hrs: HandlereturnService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    // this.purchaseOrderID = this.route.snapshot.params.poId;
    this.returnOrderID = this.route.snapshot.params.retnId;
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.getCurrentReturnOrder(this.returnOrderID);
    // this.getLinkedPurchaseOrder(this.purchaseOrderID);
    this.loginUserInfo.accounttype === 'Seller' ? this.sellerAccountType = true : this.buyerAccountType = true
    if (this.loginUserInfo.GST_Id === '') {
      this.gstDetailsPending = true
    }
    if (this.loginUserInfo.isGSTVerified === false) {
      this.gstVerificationPending = true
    }
  }

  getCurrentReturnOrder(returnOrderID: string) {
    try {
      this.hrs.getReturnOrder(returnOrderID).subscribe(
        returnOrder => {
          this.currentReturnOrderwithId = returnOrder.findReturn
          this.itemDetailsofRETn = this.currentReturnOrderwithId.itemDetails
          this.getLinkedPurchaseOrder(this.currentReturnOrderwithId.purchaseOrderID)
          this.getReturnOrderIssues(this.currentReturnOrderwithId._id)
        }
      )
    }
    catch (e) { }
  }

  getLinkedPurchaseOrder(purchaseOrderID: string) {
    try {
      this.handlePurchaseOrder.getSinglePurchaseOrder(purchaseOrderID).subscribe(
        purchaseOrder => {
          this.linkedPurchaseOrderwithId = purchaseOrder
          this.isLoading = false;
        }
      )

    }
    catch (e) { }
  }

  cancelReturnOrderStatus(returnOrder: ReturnModel) {
    const message = `Confirm to cancel this Return Order Version${returnOrder.returnVersionNumber}`
    const action = `Confirm`
    this.confirmCancelReturnOrder(message, action, returnOrder)
  }

  confirmCancelReturnOrder(message: string, action: string, returnOrder: ReturnModel) {
    try {
      let snackbarRef = this._snackBar.open(message, action, {
        duration: 6000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      snackbarRef.onAction().subscribe(() => {
        this.isLoading = true
        this.hrs.cancelReturnOrder(returnOrder._id).then(cancelledReturn => {
          if (cancelledReturn) {
            const deletedAndUpdatedReturns = this.linkedPurchaseOrderwithId.returnVersions.filter(allReturns => {
              return allReturns.returnID !== returnOrder._id
            })
            this.hrs.updatePurchaseOrderWithReturnVersion(deletedAndUpdatedReturns, this.linkedPurchaseOrderwithId._id, this.linkedPurchaseOrderwithId.purchaseOrderNumber).then(
              (removedCancelledVersionFromPO) => {
                this.getCurrentReturnOrder(returnOrder._id)
              }
            )
          }
        })

      })
      // go to profile page - the user can see all the info here and edit it - like upload GST and PAN as well
    }
    catch (e) {
      throw e
    }
  }



  getReturnOrderIssues(returnOrderID: string) {
    try {
      this.rps.getCurrentOrderissue(returnOrderID).then(
        (allINVIssues) => {
          if (allINVIssues && allINVIssues.status === 200) {
            this.issuesFound = allINVIssues.issues
            this.issuesFound.length && this.issuesFound.length > 0 ? this.issueForPOExist = true : this.issueForPOExist = false
            this.rps.shareFoundIssues(this.issuesFound)
          }
        }
      )
    }
    catch (e) {
      throw e
    }
  }






}
