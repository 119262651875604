import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { VaayuputraService } from 'src/app/services/vaayuputra.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HandleInvoiceService } from 'src/app/services/handle-invoice.service';

@Component({
  selector: 'app-delivery-invordera-vaayuputra',
  templateUrl: './delivery-invordera-vaayuputra.component.html',
  styleUrls: ['./delivery-invordera-vaayuputra.component.css']
})
export class DeliveryInvorderaVaayuputraComponent implements OnInit {
  isLoading: boolean = false;
  vaayuDeliveryForm: FormGroup;
  hide = true;
  validationMessages = {
    'name': {
      'required': 'name is required',
      'name': 'name not in valid format'
    },
    'phone': {
      'required': 'otpVaayu is required'
    },
    'otpVaayu': {
      'required': 'otpVaayu is required'
    }
  }
  formErrors = {
    'name': '',
    'phone': '',
    'otpVaayu': '',
  }
  invoID: string;
  currentInvoiceOrderStatus: string;
  isDeliveryAllowed: boolean = true;
  constructor(private vaayuPutra: VaayuputraService,
    public route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private hins: HandleInvoiceService,
  ) { }

  ngOnInit(): void {
    this.vaayuDeliveryForm = new FormGroup({
      name: new FormControl('', { validators: [Validators.required, Validators.maxLength(50)] }),
      phone: new FormControl('', {
        validators: [Validators.required, Validators.minLength(10), Validators.maxLength(10)]
      }),
      otpVaayu: new FormControl('', { validators: [Validators.required] }),
    });
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.invoID = paramMap.get('invoId');
      this.getCurrentInvoiceOrderStatus(this.invoID)
    });
  }
  getCurrentInvoiceOrderStatus(invoiceOrderID: string) {
    try {
      this.isLoading = true
      this.hins.getInvoiceOrderStatus(invoiceOrderID).then(
        invoiceOrder => {
          this.currentInvoiceOrderStatus = invoiceOrder.invoStatus
          this.currentInvoiceOrderStatus === 'Paid' || this.currentInvoiceOrderStatus === 'Delivered' ? this.isDeliveryAllowed = false : this.isDeliveryAllowed = true
          this.isLoading = false
        }
      )
    }
    catch (e) { }
  }
  onDelivery() {
    if (this.vaayuDeliveryForm.invalid) {
      return;
    }
    this.isLoading = true; //spinner starts
    {
      this.vaayuPutra.deliveryInvo(
        this.vaayuDeliveryForm.value.name,
        JSON.parse(this.vaayuDeliveryForm.value.phone),
        this.vaayuDeliveryForm.value.otpVaayu,
        this.invoID
      ).then(successDelivery => {
        this.successDeliveryStatusChanged(successDelivery.message)
      }).catch(err => {
        this.errorDeliveryStatusChanged(err.error.message)
      })
    }
  }

  errorDeliveryStatusChanged(err: string) {
    const action = "Retry"
    // give snackBar error then route to profule page on action
    // login error notific
    this._snackBar.open(err, action, {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
    })
    this.isLoading = false

  }

  successDeliveryStatusChanged(success: string) {
    try {
      const action = "Ok"
      // give snackBar error then route to profule page on action
      // login error notific
      let snackbarref = this._snackBar.open(success, action, {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      snackbarref.afterDismissed().subscribe(() => {
        this.getCurrentInvoiceOrderStatus(this.invoID)
      })
      this.isLoading = false
      // go to profile page - the user can see all the info here and edit it - like upload GST and PAN as well
    }
    catch (e) {
      throw e
    }
  }
}
