<div class="notposted" *ngIf="!this.displayPostSuccess">
    <div *ngIf="this.isNewRequirement" class="isNewRequirement">
        <div class="BannerSec">
            <div class="simrow">
                <mat-icon class="ic1">check_circle</mat-icon> <span class="step 1s">
                    Post Requirement</span>
            </div>
            <div class="simrow">
                <mat-icon class="ic2">phone_forwarded</mat-icon> <span class="step 2s">
                    Our team contacts you</span>
            </div>
            <div class="simrow">
                <mat-icon class="ic3">thumb_up</mat-icon><span class="step 3s">
                    We get you profitable deals</span>
            </div>
        </div>
        <mat-form-field appearance="outline" appearance="outline">
            <mat-hint>Buyer - Seller</mat-hint>
            <mat-label>Select Account Type</mat-label>
            <mat-select [(ngModel)]="selectedAccountType" name="accountType">
                <mat-option *ngFor="let accountType of this.accountTypes" [value]="accountType">
                    {{accountType}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <input [(ngModel)]="this.itemName" matInput type="text" name="itemName" placeholder="Add Item Details">
            <mat-hint *ngIf="this.selectedAccountType=='Seller'">What do you want to sell</mat-hint>
            <mat-hint *ngIf="this.selectedAccountType=='Buyer'">What do you want to buy</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <input [(ngModel)]="this.itemQuantity" matInput type="tel" name="itemQuantity"
                placeholder="Quantity in Quintal" pattern="[0-9]*" maxlength="10" required>
            <mat-hint>Quintals</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <input [(ngModel)]="this.targetPrice" matInput type="tel" name="targetPrice"
                placeholder="Target Price per Kg" pattern="[0-9]*" maxlength="10" required>
            <mat-hint>Rs per Kg</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <input [(ngModel)]="this.phoneContact" matInput type="tel" name="phone" placeholder="Phone Number"
                pattern="[0-9]*" maxlength="10" required>
            <mat-hint>98xxxxxxx9</mat-hint>
        </mat-form-field>
        <button class="done" (click)="this.doneForm()" mat-stroked-button>Done</button>
    </div>

</div>
<div class="posted" *ngIf="this.displayPostSuccess">
    <h1 class="headPosted">Requirement Received</h1>
</div>