import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RaiseDisputeModel } from 'src/app/datamodels/groupedmodel/raiseDisputeModel';
import { ReportProblemService } from 'src/app/services/report-problem.service';
import { HandleInvoiceService } from 'src/app/services/handle-invoice.service';
import { InvoiceModel } from 'src/app/datamodels/invoiceOrderModel/invoiceDetailsModel';

interface InvoiceOrderReportingProblem {
  disputeCategory: number;
  disputeDescription: string;
}
@Component({
  selector: 'app-raise-dispute-inv',
  templateUrl: './raise-dispute-inv.component.html',
  styleUrls: ['./raise-dispute-inv.component.css']
})
export class RaiseDisputeINVComponent implements OnInit {
  private loginUserInfo: any
  private currentUserId: string
  invoiceOrderID: string;
  isLoading: boolean = false
  currentInvoiceOrderwithId: InvoiceModel;
  sellerAccountType: boolean = false
  // if this PO is created by 1seller for some order he wants from another 2seller
  isUserActingBuyer: boolean = false
  // if this PO is created by other buyers for some order they want from this 1seller
  isUserActingSeller: boolean = false
  buyerAccountType: boolean = false
  gstDetailsPending: boolean = false;
  gstVerificationPending: boolean = false;
  listReasonsForReporting: InvoiceOrderReportingProblem[] = []
  reportProblemInvoiceOrderForm: FormGroup;
  sellerIsSeller: boolean = false
  sellerIsBuyer: boolean = false
  buyerIsBuyer: boolean = false
  isAllowPhoneCall: boolean = false


  private userIsBuyerAndReportingActive: InvoiceOrderReportingProblem[] = [

    {
      disputeCategory: 1,
      disputeDescription: 'Items not required anymore - Cancel Invoice Order'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Multiple Invoice Orders Active for Same Items'
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Incorrect Contact / Items Information in Invoice Order',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Invoice Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Lost Delivery Verification Code',
    },
    {
      disputeCategory: 6,
      disputeDescription: 'Others',
    }
  ]

  private userIsBuyerAndReportingShipped: InvoiceOrderReportingProblem[] = [

    {
      disputeCategory: 1,
      disputeDescription: 'Status of Items and Shipment'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Seller Not Creating New Invoice Order'
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Incorrect Contact / Items Information in Invoice Order',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Invoice Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Others',
    }
  ]


  private userIsBuyerAndReportingDelivered: InvoiceOrderReportingProblem[] = [

    {
      disputeCategory: 1,
      disputeDescription: 'Unable To Pay for All items'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Seller Not Creating New Invoice Order'
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Incorrect Contact / Items Information in Invoice Order',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Invoice Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Others',
    }
  ]


  private userIsBuyerAndReportingPaid: InvoiceOrderReportingProblem[] = [
    {
      disputeCategory: 1,
      disputeDescription: 'Incorrect Payment'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Incorrect / Low Quality Items Delivered',
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Invoice Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Lost Credit Ratings After Delivery',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Others',
    }
  ]

  private userIsBuyerAndReportingCancelled: InvoiceOrderReportingProblem[] = [
    {
      disputeCategory: 1,
      disputeDescription: 'Missing Items from Invoice order'
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Invoice Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Lost Credit Ratings After Delivery',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Others',
    }
  ]



  private userIsSellerAndReportingActive: InvoiceOrderReportingProblem[] = [
    {
      disputeCategory: 1,
      disputeDescription: 'Items Unvailable for Delivery'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Delivery Address too Far Need Help with Transport'
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Incorrect Contact / Items Information in Invoice Order',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Invoice Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Others',
    }
  ]

  private userIsSellerAndReportingShipped: InvoiceOrderReportingProblem[] = [

    {
      disputeCategory: 1,
      disputeDescription: 'Status of Items and Shipment'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Change in Delivery Address'
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Incorrect Contact / Items Information in Invoice Order',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Invoice Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Others',
    }
  ]

  private userIsSellerAndReportingDelivered: InvoiceOrderReportingProblem[] = [
    {
      disputeCategory: 1,
      disputeDescription: 'Wrong Delivery Address Of Buyer'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Incorrect Contact / Items Information in Invoice Order',
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Invoice Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Others',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Others',
    }
  ]

  private userIsSellerAndReportingPaid: InvoiceOrderReportingProblem[] = [
    {
      disputeCategory: 1,
      disputeDescription: 'Payment Not Received for Invoice Order - Demand Payment'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Buyer Not Responding'
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Invoice Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Lost Credit Ratings After Delivery',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Others',
    },

  ]

  private userIsSellerAndReportingCancelled: InvoiceOrderReportingProblem[] = [
    {
      disputeCategory: 1,
      disputeDescription: 'Payment Not Received for Invoice Order - Demand Payment'
    },
    {
      disputeCategory: 2,
      disputeDescription: 'Buyer Not Responding'
    },
    {
      disputeCategory: 3,
      disputeDescription: 'Invoice Order Document Link and PDF Not Opening',
    },
    {
      disputeCategory: 4,
      disputeDescription: 'Lost Credit Ratings After Delivery',
    },
    {
      disputeCategory: 5,
      disputeDescription: 'Others',
    },

  ]

  reportProblemData: RaiseDisputeModel
  isDisputeResponseAvailable: boolean = false
  disputeResponseWithIssueStatus: string;
  disputeResponseWithIssueID: string;
  constructor(
    private authService: AuthService,
    private router: ActivatedRoute,
    private hins: HandleInvoiceService,
    private rps: ReportProblemService
  ) { }
  formErrors = {
    'disputeCategory': 'Select Reason',
    'phone': '',
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.reportProblemInvoiceOrderForm = new FormGroup({
      disputeCategory: new FormControl(null),
      disputeDescription: new FormControl(null, {
        validators: [Validators.maxLength(100)]
      }),
      isAllowPhoneCall: new FormControl(false),
      phone: new FormControl('', { validators: [Validators.minLength(10), Validators.maxLength(10)] }),
    })
    // this.listcancelReasonsForUserTypes(this.cancelPurchaseOrderdata)
    this.invoiceOrderID = this.router.snapshot.params.invoId;
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.getCurrentInvoiceOrder(this.invoiceOrderID);
    this.loginUserInfo.accounttype === 'Seller' ? this.sellerAccountType = true : this.buyerAccountType = true
    if (this.loginUserInfo.GST_Id === '') {
      this.gstDetailsPending = true
    }
    if (this.loginUserInfo.isGSTVerified === false) {
      this.gstVerificationPending = true
    }

  }

  getCurrentInvoiceOrder(invoiceOrderID: string) {
    try {
      this.hins.getSingleInvoiceOrder(invoiceOrderID).subscribe(
        (invoiceOrderFound) => {
          this.currentInvoiceOrderwithId = invoiceOrderFound.findINVO
          if (this.currentInvoiceOrderwithId) {
            this.isLoading = false;
            if (this.currentUserId === this.currentInvoiceOrderwithId.buyerDetails.buyerId) {
              this.isUserActingBuyer = true
              this.isUserActingSeller = false
              this.reportProblemData = {
                accountTypeOfUser: this.loginUserInfo.accounttype,
                issueStatus: null,
                phone: null,
                orderType: 'Invoice Order',
                orderGenerateDate: this.currentInvoiceOrderwithId.dateOfINVGenerated,
                orderID: this.currentInvoiceOrderwithId._id,
                orderNumber: this.currentInvoiceOrderwithId.invoiceNumber,
                orderStatus: this.currentInvoiceOrderwithId.invoiceStatus,
                userActingAsBuyer: this.isUserActingBuyer,
                userActingAsSeller: this.isUserActingSeller,
                userID: this.currentUserId,
                disputeReason: null
              }
            }
            if (this.currentUserId === this.currentInvoiceOrderwithId.sellerDetails._id) {
              this.isUserActingBuyer = false
              this.isUserActingSeller = true

              this.reportProblemData = {
                accountTypeOfUser: this.loginUserInfo.accounttype,
                issueStatus: null,
                phone: null,
                orderType: 'Invoice Order',
                orderGenerateDate: this.currentInvoiceOrderwithId.dateOfINVGenerated,
                orderID: this.currentInvoiceOrderwithId._id,
                orderNumber: this.currentInvoiceOrderwithId.invoiceNumber,
                orderStatus: this.currentInvoiceOrderwithId.invoiceStatus,
                userActingAsBuyer: this.isUserActingBuyer,
                userActingAsSeller: this.isUserActingSeller,
                userID: this.currentUserId,
                disputeReason: null
              }
            }
            this.listcancelReasonsForUserTypes(this.reportProblemData)
          }
        }
      )
    }
    catch (e) { }
  }

  listcancelReasonsForUserTypes(reportProblemData: RaiseDisputeModel) {
    //Mainseller as seller
    if (reportProblemData.accountTypeOfUser === 'Seller'
      && reportProblemData.userActingAsSeller && !reportProblemData.userActingAsBuyer) {
      this.sellerIsSeller = true
      this.sellerIsBuyer = false
      this.buyerIsBuyer = false
      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Delivered') {
        this.listReasonsForReporting = this.userIsSellerAndReportingDelivered
      }
      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Shipped') {
        this.listReasonsForReporting = this.userIsSellerAndReportingShipped
      }
      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Active') {
        this.listReasonsForReporting = this.userIsSellerAndReportingActive
      }
      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Paid') {
        this.listReasonsForReporting = this.userIsSellerAndReportingPaid
      }

      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Cancelled') {
        this.listReasonsForReporting = this.userIsSellerAndReportingCancelled
      }
    }
    //Mainseller as buyer
    if (reportProblemData.accountTypeOfUser === 'Seller'
      && reportProblemData.userActingAsBuyer && !reportProblemData.userActingAsSeller) {
      this.sellerIsBuyer = true

      this.sellerIsSeller = false
      this.buyerIsBuyer = false
      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Delivered') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingDelivered
      }
      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Shipped') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingShipped
      }
      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Active') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingActive
      }
      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Paid') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingPaid
      }
      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Cancelled') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingCancelled
      }

    }
    //MainBuyer
    if (reportProblemData.accountTypeOfUser === 'Buyer') {
      this.buyerIsBuyer = true
      this.sellerIsSeller = false
      this.sellerIsBuyer = false
      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Delivered') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingDelivered
      }
      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Active') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingActive
      }
      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Shipped') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingShipped
      }
      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Paid') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingPaid
      }
      if (this.currentInvoiceOrderwithId.invoiceStatus === 'Cancelled') {
        this.listReasonsForReporting = this.userIsBuyerAndReportingCancelled
      }
    }

  }

  onReportProblem(reportingReasonsData: InvoiceOrderReportingProblem[]) {
    // get Reason Arrays according to buyertype
    // since value of dropdown is bound to category, list filter category and description from listed reasons
    let selectedReasonOfallReasonWithUserTypes = reportingReasonsData.filter(allListedReasonTypes => { return allListedReasonTypes.disputeCategory === this.reportProblemInvoiceOrderForm.value.disputeCategory })

    if (!selectedReasonOfallReasonWithUserTypes || selectedReasonOfallReasonWithUserTypes.length === 0) {
      const othersCategory = [{
        disputeCategory: 5,
        disputeDescription: 'Others',
      }]
      selectedReasonOfallReasonWithUserTypes = othersCategory
    }
    // patch value for formcontrol
    this.reportProblemInvoiceOrderForm.patchValue({
      'disputeDescription': selectedReasonOfallReasonWithUserTypes[0].disputeDescription
    })

    this.isAllowPhoneCall === false ? this.reportProblemInvoiceOrderForm.value.phone = '' : this.reportProblemInvoiceOrderForm.value.phone

    const reasonForReporting: InvoiceOrderReportingProblem = {
      disputeCategory: this.reportProblemInvoiceOrderForm.value.disputeCategory,
      disputeDescription: this.reportProblemInvoiceOrderForm.value.disputeDescription,
    }
    try {
      if (this.reportProblemInvoiceOrderForm.invalid || this.reportProblemInvoiceOrderForm == undefined) {
      }
      if (this.reportProblemInvoiceOrderForm.valid) {
        this.reportProblemInvoiceOrderForm.value.disputeDescription = reasonForReporting.disputeDescription
        const reportInvoiceOrderWithReason: RaiseDisputeModel = {
          accountTypeOfUser: this.reportProblemData.accountTypeOfUser,
          issueStatus: this.reportProblemData.issueStatus,
          phone: this.reportProblemInvoiceOrderForm.value.phone,
          orderType: this.reportProblemData.orderType,
          orderGenerateDate: this.reportProblemData.orderGenerateDate,
          orderID: this.reportProblemData.orderID,
          orderNumber: this.reportProblemData.orderNumber,
          orderStatus: this.reportProblemData.orderStatus,
          userActingAsBuyer: this.reportProblemData.userActingAsBuyer,
          userActingAsSeller: this.reportProblemData.userActingAsSeller,
          userID: this.reportProblemData.userID,
          disputeReason: reasonForReporting
        }
        this.rps.reportINVOproblem(
          reportInvoiceOrderWithReason
        ).then(responseReporting => {
          if (responseReporting && responseReporting.status === 201) {
            this.isDisputeResponseAvailable = true
            this.disputeResponseWithIssueID = `Issue ID : ${responseReporting.issueID}`
            this.disputeResponseWithIssueStatus = `${responseReporting.message}`
          }
        })
      }
    }
    catch (e) {
      throw e
    }
  }
}