import { Component, OnInit, ViewChild, ElementRef, Inject, ComponentFactoryResolver } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Product } from 'src/app/datamodels/MainProductModel';
import { toBase64String } from '@angular/compiler/src/output/source_map';

@Component({
  selector: 'app-quick-view-dialog',
  templateUrl: './quick-view-dialog.component.html',
  styleUrls: ['./quick-view-dialog.component.css']
})
export class QuickViewDialogComponent implements OnInit {

  @ViewChild('prodInput') prodInput:ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutoComplete:MatAutocomplete

receivedProduct=[]
  constructor(@Inject(MAT_DIALOG_DATA) public editProdData:any) { }

  ngOnInit(): void {

 this.receivedProduct=Object.assign([],this.editProdData.data)
  }

}
