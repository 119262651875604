import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { BidRequest } from 'src/app/datamodels/bidnegotiateRequestModel';

@Component({
  selector: 'app-bidsort',
  templateUrl: './bidsort.component.html',
  styleUrls: ['./bidsort.component.css']
})
export class BidsortComponent implements OnInit {
  sortBidData: BidRequest[];

  constructor(@Inject(MAT_DIALOG_DATA) public bidSortData: any,
    public dialogRef: MatDialogRef<BidsortComponent>) {
    this.sortBidData = this.bidSortData.data.currentUserBidstoSort
  }


  ngOnInit(): void {
  }

  sortData(sort: Sort) {
    const data = this.bidSortData.data.currentUserBidstoSort;
    if (!sort.active || sort.direction === '') {
      this.sortBidData = data;
      return;
    }
    this.sortBidData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'bidCreateDate':
          return compare(a.bidCreateDate, b.bidCreateDate, isAsc);
        case 'oldPriceListed':
          return compare(a.oldPriceListed, b.oldPriceListed, isAsc);
        case 'newPriceOffered':
          return compare(a.newPriceOffered, b.newPriceOffered, isAsc);
        case 'quantityValue':
          return compare(a.quantityValue, b.newPriceOffered, isAsc);
        case 'bidRate':
          return compare(a.bidRate, b.bidRate, isAsc);
      }
    })
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}