import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { BuyerDetails } from 'src/app/datamodels/groupedmodel/buyerDetails';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { AddressBook } from '../../datamodels/groupedmodel/addressBook';
import { ItemDetails } from 'src/app/datamodels/groupedmodel/itemDetails';
import { SellerDetails } from 'src/app/datamodels/groupedmodel/sellerDetails';
import { HandleInvoiceService } from 'src/app/services/handle-invoice.service';
import { InvoiceModel } from 'src/app/datamodels/invoiceOrderModel/invoiceDetailsModel';
import { VersionInvoicing } from 'src/app/datamodels/invoiceOrderModel/invoiceVersionDataModel';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RaiseDisputeModel } from 'src/app/datamodels/groupedmodel/raiseDisputeModel';
import { ReportProblemService } from 'src/app/services/report-problem.service';
import { HandlePaymentsService } from 'src/app/services/handle-payments.service';

@Component({
  selector: 'app-view-invoice-order',
  templateUrl: './view-invoice-order.component.html',
  styleUrls: ['./view-invoice-order.component.css']
})
export class ViewInvoiceOrderComponent implements OnInit {
  private loginUserInfo: any
  currentUserId: string
  purchaseOrderID: string;
  invoiceOrderID: string;
  isLoading: boolean = false
  pickUpOtp: string
  linkedPurchaseOrderwithId: PurchaseOrderModel;
  currentInvoiceOrderwithId: InvoiceModel;
  itemDetailsofINVO: ItemDetails[] = [];
  sellerDetails: SellerDetails[] = [];
  sellerAccountType: boolean = false
  // if this PO is created by 1seller for some order he wants from another 2seller
  isUserActingBuyer: boolean = false
  // if this PO is created by other buyers for some order they want from this 1seller
  isUserActingSeller: boolean = false
  buyerAccountType: boolean = false
  gstDetailsPending: boolean = false;
  gstVerificationPending: boolean = false;
  fetchAccountPOsByAccountType: string;
  displayedSellerTypeTableColumns: string[] = []
  displayedBuyerTypeTableColumns: string[] = []
  itemDetailsOfINVO: ItemDetails[];
  invoiceVersionsOfPO: VersionInvoicing[] = []
  allowMoreInvoices: boolean = true;
  totalAmountRaisedInAllVersions: number;
  lastVersionInvoice: VersionInvoicing;
  poIssues: RaiseDisputeModel[] = [];
  issueForPOExist: boolean = false;
  issuesFound: RaiseDisputeModel[] = [];
  lastActiveVersionActions: boolean = false;
  allowShipping: boolean = false;
  paymentWindow: Window;
  interval: NodeJS.Timeout;
  timeLeft: number = 180;
  isPaymentWindow: boolean = false;
  useMerchantId: string;
  usedocId: string;
  usedayakid: string;
  usevyayak: number;
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private handlePurchaseOrder: HandlePurchaseOrderService,
    private hins: HandleInvoiceService,
    private _snackBar: MatSnackBar,
    private rps: ReportProblemService,
    private hpmnts: HandlePaymentsService,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    // this.purchaseOrderID = this.route.snapshot.params.poId;
    this.invoiceOrderID = this.route.snapshot.params.invoId;
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    // console.log('purchaseOrderID', this.purchaseOrderID)
    this.getCurrentInvoiceOrder(this.invoiceOrderID);
    // this.getLinkedPurchaseOrder(this.purchaseOrderID);
    this.loginUserInfo.accounttype === 'Seller' ? this.sellerAccountType = true : this.buyerAccountType = true
    if (this.loginUserInfo.GST_Id === '') {
      this.gstDetailsPending = true
    }
    if (this.loginUserInfo.isGSTVerified === false) {
      this.gstVerificationPending = true
    }
  }
  getCurrentInvoiceOrder(invoiceOrderID: string) {
    try {
      this.isLoading = true
      this.hins.getSingleInvoiceOrder(invoiceOrderID).subscribe(
        invoiceOrder => {
          this.currentInvoiceOrderwithId = invoiceOrder.findINVO
          this.itemDetailsofINVO = this.currentInvoiceOrderwithId.itemDetails
          this.getLinkedPurchaseOrder(this.currentInvoiceOrderwithId.purchaseOrderID)
          this.getInvoiceOrderIssues(this.currentInvoiceOrderwithId._id)
        }
      )
    }
    catch (e) {
      throw e
    }
  }

  getLinkedPurchaseOrder(purchaseOrderID: string) {
    try {
      this.handlePurchaseOrder.getSinglePurchaseOrder(purchaseOrderID).subscribe(
        purchaseOrder => {
          this.linkedPurchaseOrderwithId = purchaseOrder
          if (this.linkedPurchaseOrderwithId) {
            this.isLoading = false;
            if (this.currentUserId === this.linkedPurchaseOrderwithId.buyerDetails.buyerId) {
              this.isUserActingBuyer = true
              this.isUserActingSeller = false
            }
            if (this.currentUserId === this.linkedPurchaseOrderwithId.sellerDetails._id) {
              this.isUserActingBuyer = false
              this.isUserActingSeller = true
            }
            this.invoiceVersionsOfPO = this.linkedPurchaseOrderwithId.invoiceVersions
            this.totalAmountRaisedInAllVersions = this.invoiceVersionsOfPO.reduce((sum, item) => sum + (item.invoiceAmount), 0)
            const activeVersions = this.invoiceVersionsOfPO.filter(eachV => {
              return eachV.invoiceStatus === 'Active'
            })
            if (activeVersions.length === 0) {
              this.lastActiveVersionActions = false
            }
            if (activeVersions.length > 0) {
              const lastActiveVersion = activeVersions.pop()
              this.currentInvoiceOrderwithId.invoiceNumber === lastActiveVersion.invoiceNumber
                && this.currentInvoiceOrderwithId._id === lastActiveVersion.invoiceID ? this.lastActiveVersionActions = true : this.lastActiveVersionActions = false
            }
            this.currentInvoiceOrderwithId.invoiceVersionNumber === this.linkedPurchaseOrderwithId.invoiceVersions.length && this.currentInvoiceOrderwithId.invoiceBalanceDueValue > 0 ?
              this.allowMoreInvoices = true : this.allowMoreInvoices = false
            for (let i = 0; i < this.linkedPurchaseOrderwithId.invoiceVersions.length; i++) {
              if (this.currentInvoiceOrderwithId.invoiceVersionNumber === this.linkedPurchaseOrderwithId.invoiceVersions[i].version) {
                if (this.linkedPurchaseOrderwithId.invoiceVersions[i].version > 1 && this.linkedPurchaseOrderwithId.invoiceVersions[i - 1].invoiceStatus === 'Shipped' || 'Delivered') {
                  this.allowShipping = true
                }
                if (this.linkedPurchaseOrderwithId.invoiceVersions[i].version === 1) {
                  this.allowShipping = true
                }
              }
            }
          }
        }
      )

    }
    catch (e) { }
  }

  cancelInvoiceOrderStatus(invoiceOrder: InvoiceModel) {
    const message = `Confirm to cancel this Invoice Order Version${invoiceOrder.invoiceVersionNumber}`
    const action = `Confirm`
    this.confirmCancelInvoiceOrder(message, action, invoiceOrder)
  }

  confirmCancelInvoiceOrder(message: string, action: string, invoiceOrder: InvoiceModel) {
    try {
      let snackbarRef = this._snackBar.open(message, action, {
        duration: 6000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      snackbarRef.onAction().subscribe(() => {
        this.isLoading = true
        this.hins.cancelInvoiceOrder(invoiceOrder._id).then(cancelledINVO => {
          if (cancelledINVO) {
            let sendUpdatedVersion = this.linkedPurchaseOrderwithId.invoiceVersions
            const poppedLastVersion = sendUpdatedVersion.pop()
            this.handlePurchaseOrder.updatePurchaseOrderWithInvoiceVersion(sendUpdatedVersion, this.linkedPurchaseOrderwithId._id, this.linkedPurchaseOrderwithId.purchaseOrderNumber).then(
              (removedCancelledVersionFromPO) => {
                this.getCurrentInvoiceOrder(invoiceOrder._id)
              }
            )
          }
        })

      })
      // go to profile page - the user can see all the info here and edit it - like upload GST and PAN as well
    }
    catch (e) {
      throw e
    }
  }

  changeInvoiceVersion(version: VersionInvoicing) {
    this.router.navigate([`/viewInvoiceOrder/${version.invoiceID}`])
    this.getCurrentInvoiceOrder(version.invoiceID)
  }

  getInvoiceOrderIssues(invoiceOrderId: string) {
    try {
      this.rps.getCurrentOrderissue(invoiceOrderId).then(
        (allINVIssues) => {
          if (allINVIssues && allINVIssues.status === 200) {
            this.issuesFound = allINVIssues.issues
            this.issuesFound.length && this.issuesFound.length > 0 ? this.issueForPOExist = true : this.issueForPOExist = false
            this.rps.shareFoundIssues(this.issuesFound)
          }
        }
      )
    }
    catch (e) {
      throw e
    }
  }

  remindBuyer(invoiceId: string, invoiceNumber: string) {
    try {
      this.isLoading = true
      if (this.currentInvoiceOrderwithId.invoiceStatus !== 'Active' && !this.isUserActingSeller) {
        return
      }
      this.hins.remindBuyerToPayInvoice(invoiceId, invoiceNumber).then(
        messageResponse => {
          if (messageResponse) {
            this.isLoading = false
            const message = "Payment notification sent to buyer"
            const Action = "Ok"

            this.reminderSentSuccessfull(message, Action)
          }
        }
      )
    }
    catch (e) {
      throw e
    }
  }

  reminderSentSuccessfull(message: string, action: string) {
    try {
      this._snackBar.open(message, action, {
        duration: 6000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
      })
    }
    catch (e) {
      throw e
    }
  }

  itemsShipped(invoiceId: string, invoiceNumber: string) {
    try {
      if (this.currentInvoiceOrderwithId.invoiceStatus !== 'Active' && !this.isUserActingSeller) {
        return
      }
      let otpCheck = false
      if (this.pickUpOtp && this.currentInvoiceOrderwithId.modePickup.pickUpOtp
        && this.pickUpOtp == this.currentInvoiceOrderwithId.modePickup.pickUpOtp) {
        otpCheck = true
      }
      if (this.currentInvoiceOrderwithId.modePickup.isVaayuPickup || otpCheck) {
        const message = `Confirm all Items in Good Quality ready to be Shipped`
        const action = `Confirm`
        this.confirmShipmentInvoiceOrder(message, action, invoiceId, invoiceNumber)
      }
      else {
        const message = `Wrong Pickup OTP`
        const action = `Call +91-8076808373`
        this.confirmShipmentInvoiceOrder(message, action, invoiceId, invoiceNumber)
      }
    }
    catch (e) {
      throw e
    }
  }
  confirmShipmentInvoiceOrder(message: string, action: string, invoiceId: string, invoiceNumber: string) {
    let snackbarRef = this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
    })
    snackbarRef.onAction().subscribe(() => {
      this.isLoading = true
      this.hins.shipInvoiceOrder(invoiceId, invoiceNumber).then(shippedInvo => {
        if (shippedInvo) {
          const invoVersionsOfPO = [];
          this.linkedPurchaseOrderwithId.invoiceVersions.forEach(
            eachV => {
              if (eachV.invoiceID === invoiceId && eachV.invoiceNumber === invoiceNumber) {
                eachV.invoiceStatus = 'Shipped'
                invoVersionsOfPO.push(eachV)
              }
              if (eachV.invoiceID !== invoiceId && eachV.invoiceNumber !== invoiceNumber) {
                invoVersionsOfPO.push(eachV)
              }
            }
          )
          this.handlePurchaseOrder.updatePurchaseOrderWithInvoiceVersion(invoVersionsOfPO, this.linkedPurchaseOrderwithId._id, this.linkedPurchaseOrderwithId.purchaseOrderNumber).then(
            (updatedInvoiceOrderStatusInPOversion) => {
              this.getCurrentInvoiceOrder(invoiceId)
            }
          )
        }
      })

    })
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 180;
      }
    }, 1000)
  }

  payInvoCreateReciept() {
    this.isLoading = true
    const vyayak = this.currentInvoiceOrderwithId.invoiceRaiseValue
    const byoraId = this.currentInvoiceOrderwithId.invoiceNumber
    const dayakid = this.currentInvoiceOrderwithId.buyerDetails.buyerId
    const dayakemail = this.currentInvoiceOrderwithId.buyerDetails.buyerContactDetails.email || null;
    const dayakphone = this.currentInvoiceOrderwithId.buyerDetails.buyerContactDetails.phone || null;
    // this.hpmnts.checkPayment(vyayak, dayakid, dayakemail).then((response: any) => {
    this.hpmnts.startPay('InvoiceOrder', byoraId, vyayak, dayakid, dayakemail, dayakphone).then((response: any) => {
      if (response && response.status === 200) {
        this.isLoading = true
        this.startTimer()
        this.isPaymentWindow = true
        const tokenUrl = response.rdrurl.data.instrumentResponse.redirectInfo.url
        this.useMerchantId = response.rdrurl.data.merchantId
        this.usedocId = response.docId
        this.usevyayak = vyayak
        this.usedayakid = dayakid
        const createInvoice = () =>
          this.zone.run(() =>
            this.hins.completeInvoicePaymentStatus(this.usedocId, this.currentInvoiceOrderwithId._id).then(sentReciept => {
              if (sentReciept && sentReciept.status === 201) {
                this.isPaymentWindow = false
                this.isLoading = false
                this.getCurrentInvoiceOrder(this.currentInvoiceOrderwithId._id)
              }
            })
          )
        function callback(response) {
          if (response === 'USER_CANCEL') {
            return;
          } else if (response === 'CONCLUDED') {
            createInvoice()
            return;
          }
        }
        window.PhonePeCheckout.transact({ tokenUrl, callback, type: "IFRAME" });
        return
      }
      else {
        this.isLoading = false
      }
    }
    )
  }
  // usePmntWindow(merchantId: string, docId: string, dayakid: string, vyayak: number) {
  //   this.isLoading = true
  //   this.ifPaidSendINVrecieptCompletePayment(merchantId, docId, dayakid)
  // }
  // ifPaidSendINVrecieptCompletePayment(merchid: string, invNumber: string, dayakid: string) {
  //   this.hpmnts.vyaysthiti(merchid, invNumber, dayakid, "InvoiceOrder").subscribe(
  //     checkResp => {
  //       if (checkResp && checkResp.status === 201) {
  //         this.hins.completeInvoicePaymentStatus(invNumber, this.currentInvoiceOrderwithId._id).then(sentReciept => {
  //           if (sentReciept && sentReciept.status === 201) {
  //             this.removePmntLink()
  //             this.isPaymentWindow = false
  //             this.getCurrentInvoiceOrder(this.currentInvoiceOrderwithId._id)
  //           }
  //         })
  //       }
  //     }
  //   )
  // }

}
