import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-bidfilter',
  templateUrl: './bidfilter.component.html',
  styleUrls: ['./bidfilter.component.css']
})
export class BidfilterComponent implements OnInit {
  filterProductNames: any;
  filterbidRequestStatus: any;
  allProductsSelected: boolean = false;
  filterBidStatus: any;
  allBidStatusSelected: boolean = false;
  allBidTypesSelected: boolean = false;
  filterBidTypes: any;
  listofBidFilters: Array<{ productFilters: any[] }> = [];
  filterUserBids: any;
  constructor(@Inject(MAT_DIALOG_DATA) public bidFilterData: any,
    public dialogRef: MatDialogRef<BidfilterComponent>) { }

  ngOnInit(): void {
    this.filterProductNames = this.bidFilterData.data.uniqueProductsFilter
    this.filterbidRequestStatus = this.bidFilterData.data.uniqueRequestStatusFilter
    this.filterUserBids = this.bidFilterData.data.currentUserBidstoFilter
    this.filterBidTypes = this.bidFilterData.data.uniqueBidTypes
    console.log('wholeData', this.bidFilterData.data)
    console.log('wholeDataFUB', this.filterUserBids)

  }

  updateAllProductsComplete() {
    this.allProductsSelected = this.filterProductNames != null && this.filterProductNames.every(t => t.checked);
  }
  someProductsComplete(): boolean {
    if (this.filterProductNames == null) {
      return false;
    }
    return this.filterProductNames.filter(t => t.checked).length > 0 && !this.allProductsSelected;
  }
  setAllProducts(checked: boolean) {
    this.allProductsSelected = checked;
    if (this.filterProductNames == null) {
      return;
    }
    this.filterProductNames.forEach(t => t.checked = checked);
  }
  updateAllStatusComplete() {
    this.allBidStatusSelected = this.filterProductNames != null && this.filterProductNames.every(t => t.checked);
  }
  someStatusComplete(): boolean {
    if (this.filterbidRequestStatus == null) {
      return false;
    }
    return this.filterbidRequestStatus.filter(t => t.checked).length > 0 && !this.allBidStatusSelected;
  }
  setAllStatus(checked: boolean) {
    this.allBidStatusSelected = checked;
    if (this.filterbidRequestStatus == null) {
      return;
    }
    this.filterbidRequestStatus.forEach(t => t.checked = checked);
  }


  updateAllBidTypesComplete() {
    this.allBidTypesSelected = this.filterBidTypes != null && this.filterBidTypes.every(t => t.checked);
  }
  someBidTypesComplete(): boolean {
    if (this.filterBidTypes == null) {
      return false;
    }
    return this.filterBidTypes.filter(t => t.checked).length > 0 && !this.allBidTypesSelected;
  }
  setAllBidTypes(checked: boolean) {
    this.allBidTypesSelected = checked;
    if (this.filterBidTypes == null) {
      return;
    }
    this.filterBidTypes.forEach(t => t.checked = checked);
  }


  onApplyFilter() {

    const checkedProducts = this.filterProductNames.filter(p => {
      return p.checked === true
    })
    console.log('checkedProds', checkedProducts)
    const checkedBidStatus = this.filterbidRequestStatus.filter(s => {
      return s.checked === true
    })
    console.log('checkedstat', checkedBidStatus)
    const checkedBidType = this.filterBidTypes.filter(s => {
      return s.checked === true
    })
    console.log('checkedbidty', checkedBidType)

    if (!checkedProducts.length && !checkedBidStatus.length && !checkedBidType.length) {
      console.log('no Filter used')
      return
    }
    //onlyProductsFilter
    else if (checkedProducts.length && !checkedBidStatus.length && !checkedBidType.length) {
      console.log('Prod Filter used')
      this.filterUserBids = this.filterUserBids.filter((cbids) => {
        return checkedProducts.some((prod) => {
          return cbids.listingProduct === prod.productName
        })
      })
      console.log('filteredProducts from CB', this.filterUserBids)
    }
    //onlyBidStatusFilter
    else if (checkedBidStatus.length && !checkedProducts.length && !checkedBidType.length) {
      console.log('Status Filter used')
      this.filterUserBids = this.filterUserBids.filter((cbids) => {
        return checkedBidStatus.some((status) => {
          return cbids.requestStatus === status.requestStatus
        })
      })
      console.log('filteredStatus from CB', this.filterUserBids)
    }
    //onlyBidTypeFilter
    else if (checkedBidType.length && !checkedProducts.length && !checkedBidStatus.length) {
      console.log('Status Filter used')
      this.filterUserBids = this.filterUserBids.filter((cbids) => {
        return checkedBidType.some((type) => {
          return cbids.isDiscount === type.isDiscount
        })
      })
      console.log('filteredStatus from CB', this.filterUserBids)
    }
    //ProductsAndStatusFilter
    else if (checkedProducts.length && checkedBidStatus.length && !checkedBidType.length) {
      console.log('Prod and Status')
      const productsFiltered = this.filterUserBids.filter((cbids) => {
        return checkedProducts.some((prod) => {
          return cbids.listingProduct === prod.productName
        })
      })
      this.filterUserBids = productsFiltered.filter((cbids) => {
        return checkedBidStatus.some((status) => {
          return cbids.requestStatus === status.requestStatus
        })
      })
      console.log('filtered P and S ', this.filterUserBids)
    }
    //ProductsAndBidType
    else if (checkedProducts.length && checkedBidType.length && !checkedBidStatus.length) {
      console.log('Prod and Bid Type')
      const productsFiltered = this.filterUserBids.filter((cbids) => {
        return checkedProducts.some((prod) => {
          return cbids.listingProduct === prod.productName
        })
      })
      this.filterUserBids = productsFiltered.filter((cbids) => {
        return checkedBidType.some((bidType) => {
          return cbids.isDiscount === bidType.isDiscount
        })
      })
      console.log('filtered P and B ', this.filterUserBids)
    }
    //StatusAndBidType
    else if (checkedBidStatus.length && checkedBidType.length && !checkedProducts.length) {
      console.log('Status and Bid Type')
      const productsFiltered = this.filterUserBids.filter((cbids) => {
        return checkedProducts.some((prod) => {
          return cbids.listingProduct === prod.productName
        })
      })
      this.filterUserBids = productsFiltered.filter((cbids) => {
        return checkedBidType.some((bidType) => {
          return cbids.isDiscount === bidType.isDiscount
        })
      })
      console.log('filtered S and B ', this.filterUserBids)
    }
    //allthree-product-status-bidtype
    else if (checkedBidStatus.length && checkedBidType.length && checkedProducts.length) {
      console.log('Product , Status and Bid Type')
      const productsFiltered = this.filterUserBids.filter((cbids) => {
        return checkedProducts.some((prod) => {
          return cbids.listingProduct === prod.productName
        })
      })
      console.log('productFilt', productsFiltered)
      const bidTypesFiltered = productsFiltered.filter((cbids) => {
        return checkedBidType.some((bidType) => {
          return cbids.isDiscount === bidType.isDiscount
        })
      })
      console.log('bidTypesFiltered', bidTypesFiltered)

      this.filterUserBids = bidTypesFiltered.filter((cbids) => {
        return checkedBidStatus.some((status) => {
          return cbids.requestStatus === status.requestStatus
        })
      })
      console.log('filtered P , S and B ', this.filterUserBids)
    }
    this.listofBidFilters.push({ productFilters: this.filterUserBids })
    console.log('productAndStatusFilters', this.listofBidFilters)
    this.dialogRef.close(this.listofBidFilters)
  }
  onNoClick(): void {
    this.dialogRef.close();
    console.log('closing')
  }
  onReset() {
    this.setAllStatus(false);
    this.setAllProducts(false);
  }
}
