<div class="mandiSearch">
    <div class="head">
        <h2>
            Current Mandi Rate of Items
        </h2>
        <div>
            <mat-hint>
                From Various Markets on {{this.dateToday | date}}
            </mat-hint>
        </div>
        <mat-hint>Source : data.gov.in
            <span *ngIf="this.stateName">
                for <b>{{ this.stateName}}</b>
            </span>
        </mat-hint>
    </div>
    <div class="filleither">
        <div class="sec1">
            <mat-form-field appearance="outline">
                <mat-label>PIN Code</mat-label>
                <input [(ngModel)]="selectedPincode" (ngModelChange)="getMyPincodeDetails()" matInput autocomplete="off"
                    maxlength="6" pattern="[0-9]*" />
            </mat-form-field>
        </div>
        <div *ngIf="this.selectedPincode.length <= 4" class="sec2">
            <mat-hint>
                Or
            </mat-hint>
        </div>
        <div *ngIf="this.selectedPincode.length <= 4" class="sec3">
            <mat-form-field>
                <mat-label>State</mat-label>
                <mat-select [(ngModel)]="this.selectedState" (ngModelChange)="changedStateName()">
                    <mat-option *ngFor="let state of useStates" [value]="state">
                        {{state}}
                    </mat-option>
                </mat-select>
                <mat-hint>Rajasthan</mat-hint>
            </mat-form-field>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <h3 class="center">Today's Rates</h3>
    <mat-spinner></mat-spinner>
</div>
<div>
    <mat-card *ngIf="this.mandiCommodities">
        <div class="center">
            <mat-form-field appearance="outline">
                <mat-label>Search Item</mat-label>
                <input matInput (keyup)="applySearchInMandi($event)" placeholder="Jeera, Chilli, Arhar etc." #input>
            </mat-form-field>
        </div>
        <div *ngIf="this.allCommoditiesOfState.length && this.allCommoditiesOfState.length > 0">
            <table mat-table [dataSource]="mandiCommodities">
                <!-- Position Column -->
                <!-- Order Column -->
                <ng-container matColumnDef="comm">
                    <th mat-header-cell *matHeaderCellDef> Item </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.commodity}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="variety">
                    <th mat-header-cell *matHeaderCellDef> Variety
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.variety}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="district">
                    <th mat-header-cell *matHeaderCellDef>City
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.district}}</span>
                    </td>
                </ng-container>
                <!-- Generate Invoice Column -->
                <ng-container matColumnDef="market">
                    <th mat-header-cell *matHeaderCellDef>Market
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.market}}</span>
                    </td>
                </ng-container>
                <!-- Download Column -->
                <ng-container matColumnDef="min">
                    <th mat-header-cell *matHeaderCellDef> Lowest </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.min_price}} ₹</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="modal">
                    <th mat-header-cell *matHeaderCellDef> Maximum </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.modal_price}} ₹</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="max">
                    <th mat-header-cell *matHeaderCellDef> Highest </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.max_price}} ₹</span>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="mandiTableCols"></tr>
                <tr mat-row *matRowDef="let row; columns: mandiTableCols;">
                </tr>
            </table>
        </div>
        <div *ngIf="!this.allCommoditiesOfState.length || this.allCommoditiesOfState.length===0">
            <mat-card>
                <h2 class="center">Data not updated for State Mandi</h2>
            </mat-card>
        </div>
    </mat-card>
</div>