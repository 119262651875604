<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Myapaar se Vyapaar </h1>
            <h3>Streamline your procurement business</h3>
        </div>
        <mat-spinner></mat-spinner>
    </div>
</div>

<div class="pageview">
    <div class="wrapper" *ngIf="!this.displayPostSuccess">
        <div class="banner">
            <div class="rowBanner">
                <div class="row r1">
                    <h1>
                        Get The App
                    </h1>
                    <h2>
                        Today
                    </h2>
                </div>
                <div class="r2">
                    <h2>
                        <span class="bold">Build</span> Merchant Network
                    </h2>
                    <h2>
                        <span class="bold">Grow</span> Monthly Sales
                    </h2>
                    <h2>
                        <span class="bold">Agri-Commodity</span> Procurement Application
                    </h2>
                </div>
            </div>
        </div>
        <div class="pricing-content">
            <div class="PageWidth">
                <div class="pricing-detail-content">
                    <div class="pricing-content-items-tabs">
                        <div class="pricing-yearly">
                            <div *ngFor="let subs of this.allSubs" class="pricing-item">
                                <div [ngClass]="{'freePillar': subs.subName === 'Free Trial', 'startupPillar' : subs.subName === 'Startup', 'establishedPillar' : subs.subName === 'Established', 'enterprisePillar' : subs.subName === 'Enterprise'}"
                                    class="pricing-item-box">
                                    <span *ngIf="subs.subName == 'Established'" class="ribbon"></span>
                                    <div class="plan-name">{{subs.subName}}</div>
                                    <!-- <div class="plan-text"><span class="text-bold">Best for</span> Startup agencies who
                                        want
                                        to automate their reporting</div> -->
                                    <div class="plan-price-content">
                                        <div class="plan-price">
                                            <span *ngIf="subs.subName != 'Free Trial'">
                                                ₹ {{(subs.subPrice).toLocaleString()}}
                                            </span>
                                            <span *ngIf="subs.subName == 'Free Trial'">
                                                13 Days</span>
                                            <span *ngIf="subs.subName != 'Free Trial'" class="month-text"> /
                                                month</span>
                                        </div>
                                    </div>
                                    <div class="plan-features">
                                        <!-- <div *ngFor="let feats of this.allSubs.subAppFeatures"> -->
                                        <!-- <div *ngFor="let subFeats of this.subs.subAppFeatures  | keyvalue"
                                            class="plan-feature-heading">
                                            {{subFeats.key}}
                                            <p *ngFor="let typeSubFeats of this.subFeats.value  | keyvalue">
                                                {{typeSubFeats.key}}:{{typeSubFeats.value}}
                                            </p>
                                        </div> -->
                                        <div class="baap">
                                            <p class="plan-feature-heading">
                                                Business App
                                            </p>
                                            <div>
                                                <p class="plan-feature-item">
                                                    Seller Central Access
                                                </p>
                                                <p class="plan-feature-item">
                                                    {{subs.subAppFeatures.appFeats.acceptInboundPOs}} Inbound Purchase
                                                    Orders
                                                </p>
                                                <p class="plan-feature-item">
                                                    {{subs.subAppFeatures.appFeats.acceptOutboundPOs}} Outbound Purchase
                                                    Orders
                                                </p>
                                                <p class="plan-feature-item">
                                                    {{subs.subAppFeatures.appFeats.listingsAllowed}} Item Listings
                                                    Allowed
                                                </p>
                                                <p class="plan-feature-item">
                                                    Verified Merchant Profile
                                                </p>
                                                <p class="plan-feature-item">
                                                    Online Payments & Tracking
                                                </p>
                                            </div>
                                        </div>
                                        <div class="daap" *ngIf="subs.subAppFeatures.isDigitalAssist">
                                            <p class="plan-feature-heading">
                                                Digital App
                                            </p>
                                            <div>
                                                <!-- <p class="plan-feature-item">
                                                    {{subs.subAppFeatures.digitalFeats.posts}} Social Media Post Weekly
                                                </p>
                                                <p class="plan-feature-item">
                                                    {{subs.subAppFeatures.digitalFeats.reels}} Social Media Reel Weekly
                                                </p>
                                                <p class="plan-feature-item">
                                                    {{subs.subAppFeatures.digitalFeats.carousels}} Social Media Carousel
                                                    Weekly
                                                </p> -->
                                                <p [ngClass]="{'strikeoff': subs.subName == 'Free Trial'}"
                                                    class="plan-feature-item">
                                                    {{subs.subAppFeatures.digitalFeats.logo}} Logo
                                                </p>
                                                <p [ngClass]="{'strikeoff': subs.subName == 'Free Trial'}"
                                                    class="plan-feature-item">
                                                    {{subs.subAppFeatures.digitalFeats.banner}} Banner
                                                </p>
                                                <p [ngClass]="{'strikeoff': subs.subName == 'Free Trial' || subs.subName == 'Startup'}"
                                                    class="plan-feature-item">
                                                    {{subs.subAppFeatures.digitalFeats.businessCatalogue}} Business
                                                    Catalogue
                                                </p>
                                                <p [ngClass]="{'strikeoff': subs.subName == 'Free Trial' ||  subs.subName == 'Startup' || !subs.subAppFeatures.digitalFeats.advertVideo || subs.subAppFeatures.digitalFeats.advertVideo == 0}"
                                                    class="plan-feature-item">
                                                    1 Minute Advertisement
                                                </p>
                                                <p [ngClass]="{'strikeoff': subs.subName == 'Free Trial'  ||  subs.subName == 'Startup' || !subs.subAppFeatures.digitalFeats.socialMediaManagement}"
                                                    class="plan-feature-item">
                                                    Social Media Management
                                                </p>
                                                <p [ngClass]="{'strikeoff': subs.subName == 'Free Trial' ||  subs.subName == 'Startup' || !subs.subAppFeatures.digitalFeats.seo}"
                                                    class="plan-feature-item">
                                                    Search Engine Optimization
                                                </p>
                                            </div>
                                        </div>
                                        <div class="saap">
                                            <p class="plan-feature-heading">
                                                Sales App
                                            </p>
                                            <div>
                                                <p [ngClass]="{'strikeoff': subs.subName == 'Free Trial'}"
                                                    class="plan-feature-item">
                                                    <span
                                                        *ngIf="subs.subAppFeatures.salesFeats.allowedMerchants && subs.subAppFeatures.salesFeats.allowedMerchants > 0">
                                                        {{subs.subAppFeatures.salesFeats.allowedMerchants}} Merchants
                                                        Free Invitation
                                                    </span>
                                                </p>
                                                <p [ngClass]="{'strikeoff':subs.subName == 'Free Trial'}"
                                                    class="plan-feature-item">
                                                    Deal Matching and Support
                                                </p>
                                                <p [ngClass]="{'strikeoff': subs.subName == 'Free Trial' || subs.subName == 'Startup'}"
                                                    class="plan-feature-item">
                                                    Price Negotiation
                                                </p>
                                                <p [ngClass]="{'strikeoff': subs.subName == 'Free Trial' || subs.subName == 'Startup'}"
                                                    class="plan-feature-item">
                                                    Payment Terms Negotiation
                                                </p>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="plan-button">
                                        <div (click)="this.bookSubs(subs)">Book</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="refundable">
                        <h1 class="h1Ref">Not Happy ?</h1>
                        <h1 class="h2Ref">60% Refundable</h1>
                        <h3 class="h3Ref">No Questions Asked !</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="this.displayPostSuccess" class="container">
        <h1>{{this.reqReceivedMessage}}</h1>
        <p>We have received your request for one of the Myapaar Packages.</p>
        <p>Our team will review your request and get back to you shortly.</p>
        <div routerLink="/" class="buttonHome">Return to Home</div>
    </div>
</div>