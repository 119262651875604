//https://debugmode.net/2019/10/24/when-to-use-the-subscribe-method-or-async-pipe-in-angular/     --> Async Pipe for better onPush Changes
import { Component, OnInit, Pipe } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ListingModel } from 'src/app/datamodels/ListingModel';
import { HandleListingsService } from 'src/app/services/handle-listings.service';
import { ListingdialogmanagerServiceService } from 'src/app/services/listingdialogmanager-service.service';


@Component({
  selector: 'app-view-listing',
  templateUrl: './view-listing.component.html',
  styleUrls: ['./view-listing.component.css']
})

export class ViewListingComponent implements OnInit {
  // isScreenProcessing = false;
  currentDate = new Date()
  private userlisting: Observable<any>;
  myListings: ListingModel[] = [];
  isLoading = false;
  totalPosts = 0;
  postsPerPage = 2;
  currentPage = 1;
  pageSizeOptions = [1, 2, 3, 5];
  totalListings = 0;
  listingsPerPage = 10;
  loginUserInfo: any;
  public currentUserId: any
  panelOpenState = false;
  private authListenerSubs: Subscription;
  noListingFound: boolean = false;
  userIsAuthenticated = false;
  ListingImages: string[];
  deleteListingResponse: any;
  noListings: string;

  constructor(private authService: AuthService,
    private handlingListing: HandleListingsService,
    private _snackBar: MatSnackBar,
    private handleListing: HandleListingsService,
    public ldms: ListingdialogmanagerServiceService,
    public route: ActivatedRoute,) { }


  ngOnInit(): void {
    // keep checking for user auth status
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.isLoading = true;
    this.getUserListings(this.currentUserId)
  }
  getUserListings(currentUserId: string) {
    this.handleListing.getUserListings(currentUserId).subscribe(
      allUserListings => {
        this.myListings = allUserListings
        this.isLoading = false;
        if (!this.myListings || this.myListings.length === 0) {
          this.noListingFound = true;
          this.noListings = "No listings available. Click here to Create a Listing"
          return
        }
      }
    )
    //loadingStarts
    this.isLoading = true;
  }

  deletelistingDialog(listingId: string) {
    this.ldms.deleteListing({ data: listingId }).subscribe((deleteRes => {
      if (deleteRes === true) {
        this.deletelistingSnackBar("Your Listing was deleted", "Okay")
      }
    }))
  }
  deletelistingSnackBar(message: string, action: string) {
    let snackbarRef = this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });
    snackbarRef.onAction().subscribe(() => {
      this.getUserListings(this.currentUserId)
    })
  }
}
