import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { StateCategory } from 'src/app/datamodels/zones/states/statecategorymodel';

@Component({
  selector: 'app-stateproductcategory',
  templateUrl: './stateproductcategory.component.html',
  styleUrls: ['./stateproductcategory.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StateproductcategoryComponent implements OnInit {

  @Input() index: number = 1;
  
  constructor() { }

  ngOnInit(): void {
  }
 
}
