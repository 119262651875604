<div class="wrapper">
    <div class="escapefilter">
        <button class="closebutton" mat-stroked-button color="primary" tabindex="1" (click)="onNoClick()">
            <mat-icon style="font-size: 20px;">close</mat-icon>
        </button>
    </div>
    <mat-form-field class="dialog-form-field">
        <mat-label>Filter</mat-label>
        <input tabindex="0" type="text" autocomplete="off" [(ngModel)]="SearchName" (ngModelChange)="SearchTerm()"
            matInput placeholder=" Search Category / State">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="myvalue()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-tab-group>
        <mat-tab class="CategoryBox" label="Select Category">
            <span class="example-list-section" style="color: rgb(0, 0, 0); font-weight:normal;">

                <mat-checkbox class="example-margin" [checked]="allCompleteCategories"
                    [indeterminate]="someCompleteCategories()" (change)="setAllCategories($event.checked)">
                    {{'All Categories'}}
                </mat-checkbox>
            </span>
            <ul>
                <li *ngFor='let productCategory of this.CategoryList '>
                    <span style="color: rgb(37, 37, 37); font-weight:light;">
                        <mat-checkbox name="{{productCategory._id}}" [value]="productCategory._id"
                            [(ngModel)]="productCategory.selected" (ngModelChange)="updateAllCompleteCategories()">
                            {{
                            productCategory.categoryName
                            }}
                        </mat-checkbox>
                    </span>
                </li>
            </ul>
        </mat-tab>
        <mat-tab class="StateBox" label="Select State">

            <span class="example-list-section" style="color: rgb(0, 0, 0); font-weight:normal;">
            </span>
            <div *ngFor='let statesCategory of this.StateList'>
                <span style="color: rgb(37, 37, 37); font-weight:light;">
                    <mat-checkbox name="{{statesCategory._id}}" [value]="statesCategory._id"
                        [(ngModel)]="statesCategory.selected" (ngModelChange)="updateAllCompleteStates()">
                        {{
                        statesCategory.stateName
                        }}
                    </mat-checkbox>
                </span>
            </div>
        </mat-tab>
    </mat-tab-group>
    <mat-dialog-actions>
        <button mat-button mat-dialog-close="true" (click)="onApplyFilter()">Apply</button>
        <button mat-button mat-dialog-close="false" (click)="onNoClick()">Cancel</button>
        <button mat-button (click)="onReset()">Reset</button>
    </mat-dialog-actions>
</div>