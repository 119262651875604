<div *ngIf="this.refundPolicyPoints" class="contentRefund">
    <div>
        <h1 class="titlePage">Easy Returns & Fast Refunds</h1>
        <mat-hint class="hint">Terms and Conditions are mandatory</mat-hint>
    </div>
    <div class="points" *ngFor="let points of this.refundPolicyPoints">
        <div class="L subject">
            {{points.subject}}
        </div>
        <div class="R statement">
            {{points.statement}}
        </div>
    </div>
</div>