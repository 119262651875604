<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen"><mat-spinner></mat-spinner></div>
</div>
<div *ngIf="!isLoading" class="contentPostComment">
    <mat-accordion multi="true" *ngIf="reviews.length>0 && !isLoading ">
        <div class="reviews" *ngFor="let review of reviews">
            <div class="singlereview">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span id="reviewAuthor"> {{review.author}}</span>
                        </mat-panel-title>
                        <mat-panel-description>
                            <!-- Draw the star as a symbol and remove it from view -->
                            <mat-icon *ngFor="let ratings of ratingsAsStars(review.rating)"
                                style="color: rgb(226, 226, 7);">star</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="reviewContent">
                        <div class="post-image">
                            <img [src]="review.reviewImage" [alt]="review.author">
                        </div>
                        <div class="infoAndActions">
                            <div class="reviewerInfo">
                                <div id="reviewComment">
                                    {{review.comment}}
                                </div>
                            </div>

                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="matActions" *ngIf="userIsAuthenticated && this.loginUserInfo.userId===review.creatorID">
                        <div class="actionsComment">
                            <a mat-button color="primary" [routerLink]="['/editReview',review._id]">EDIT</a>
                            <a mat-button color="warn" (click)="onDeleteComment(review._id)">DELETE</a>
                        </div>
                        <div class="dateSec">
                            <h4 id="reviewDate">{{this.currentDate | date}}</h4>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>

        </div>

    </mat-accordion>
    <p id="Noreviews" *ngIf="reviews.length===0 && !isLoading">
        Add reviews on listing</p>
</div>