import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ReportProblemService } from 'src/app/services/report-problem.service';
import { RaiseDisputeModel } from 'src/app/datamodels/groupedmodel/raiseDisputeModel';

@Component({
  selector: 'app-view-issues-page',
  templateUrl: './view-issues-page.component.html',
  styleUrls: ['./view-issues-page.component.css']
})
export class ViewIssuesPageComponent implements OnInit {
  isLoading: boolean = false
  viewIssues: RaiseDisputeModel[] = [];
  constructor(
    private rps: ReportProblemService,
    private _location: Location

  ) { }

  ngOnInit(): void {
    this.rps.getCaughtIssues.subscribe(caughtIssues => {
      if (!caughtIssues || caughtIssues === undefined) {
        // go back to PO or Invo if page relaods and share issues get undefined or null
        this._location.back()
      }
      else {
        this.viewIssues = caughtIssues
      }
    })
  }

}
