import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PostsAndFaqFullInfoModel } from 'src/app/datamodels/PostsFaqs/faqAndPostsModel';
import { MypseoService } from 'src/app/services/mypseo.service';

@Component({
  selector: 'app-faq-posts',
  templateUrl: './faq-posts.component.html',
  styleUrls: ['./faq-posts.component.css']
})
export class FaqPostsComponent implements OnInit {
  useFaq: PostsAndFaqFullInfoModel[] = []
  questionUser: string
  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    private mypSEO: MypseoService) {      
  }

  ngOnInit(): void {
    this.mypSEO.getAllPostedFAQs().then(allFaqs => {
      if (allFaqs && allFaqs.status === 200) {
        this.useFaq = allFaqs.FAQsfetched
        return this.useFaq
      }
    })
  }
  goToPosts(id: string, metaTag: string) {
    this.router.navigate(['/Posts', id, metaTag])
  }
  postQuestion() {
    try {
      this.mypSEO.postQuestions(this.questionUser).then(postedQ => {
        if (postedQ && postedQ.status === 200) {
          this.notify(postedQ.message)
        }
      })
    }
    catch (e) {
      throw e
    }
  }
  notify(mess: string) {
    try {
      const action = "Ok"
      // give snackBar error then route to profule page on action
      // login error notific
      let snackbarref = this._snackBar.open(mess, action, {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      snackbarref.afterDismissed().subscribe(() => {
        this.router.navigate([''])
      })
      // go to profile page - the user can see all the info here and edit it - like upload GST and PAN as well
    }
    catch (e) {
      throw e
    }
  }
}
