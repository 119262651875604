import { Injectable } from '@angular/core';
import { RaiseDisputeModel } from '../datamodels/groupedmodel/raiseDisputeModel';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class ReportProblemService {

  private viewCaughtIssues = new BehaviorSubject(null);
  getCaughtIssues = this.viewCaughtIssues.asObservable()
  private domain: string | undefined

  constructor(
    private http: HttpClient
    , private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }

  async reportPOproblem(poReporting: RaiseDisputeModel): Promise<{ message: string; status: number; issueID: string; issueStatus: RaiseDisputeModel }> {
    const reportProblemFormData = new FormData()
    for (let reportProblemDataItem in poReporting) {
      if (reportProblemDataItem === 'accountTypeOfUser') {
        reportProblemFormData.append(`accountTypeOfUser`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'phone') {
        reportProblemFormData.append(`phone`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderType') {
        reportProblemFormData.append(`orderType`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderStatus') {
        reportProblemFormData.append(`orderStatus`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderID') {
        reportProblemFormData.append(`orderID`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderNumber') {
        reportProblemFormData.append(`orderNumber`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderGenerateDate') {
        reportProblemFormData.append(`orderGenerateDate`, JSON.stringify(new Date(poReporting[reportProblemDataItem])))
      }
      if (reportProblemDataItem === 'userID') {
        reportProblemFormData.append(`userID`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'userActingAsBuyer') {
        reportProblemFormData.append(`userActingAsBuyer`, JSON.stringify(poReporting[reportProblemDataItem]))
      }
      if (reportProblemDataItem === 'userActingAsSeller') {
        reportProblemFormData.append(`userActingAsSeller`, JSON.stringify(poReporting[reportProblemDataItem]))
      }
      if (reportProblemDataItem === 'disputeReason') {
        for (let reportReasonKey in poReporting[reportProblemDataItem]) {
          reportProblemFormData.append(`disputeReason[${reportReasonKey}]`, poReporting[reportProblemDataItem][reportReasonKey])
        }
      }
    }
    //  Display the key / value pairs
    // for (var pair of reportProblemFormData.entries()) {
    //   console.log('addedForm', pair[0] + ', ' + pair[1]);
    // }
    const responseReporting = await this.http.post<{ message: string, status: number, issueID: string, issueStatus: RaiseDisputeModel }>(`${this.domain}api/raiseDispute/reportProblemforPO/`, reportProblemFormData).toPromise();
    return responseReporting
  }

  async reportINVOproblem(poReporting: RaiseDisputeModel): Promise<{ message: string; status: number; issueID: string; issueStatus: RaiseDisputeModel }> {
    const reportProblemFormData = new FormData()
    for (let reportProblemDataItem in poReporting) {
      if (reportProblemDataItem === 'accountTypeOfUser') {
        reportProblemFormData.append(`accountTypeOfUser`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'phone') {
        reportProblemFormData.append(`phone`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderType') {
        reportProblemFormData.append(`orderType`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderStatus') {
        reportProblemFormData.append(`orderStatus`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderID') {
        reportProblemFormData.append(`orderID`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderNumber') {
        reportProblemFormData.append(`orderNumber`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderGenerateDate') {
        reportProblemFormData.append(`orderGenerateDate`, JSON.stringify(new Date(poReporting[reportProblemDataItem])))
      }
      if (reportProblemDataItem === 'userID') {
        reportProblemFormData.append(`userID`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'userActingAsBuyer') {
        reportProblemFormData.append(`userActingAsBuyer`, JSON.stringify(poReporting[reportProblemDataItem]))
      }
      if (reportProblemDataItem === 'userActingAsSeller') {
        reportProblemFormData.append(`userActingAsSeller`, JSON.stringify(poReporting[reportProblemDataItem]))
      }
      if (reportProblemDataItem === 'disputeReason') {
        for (let reportReasonKey in poReporting[reportProblemDataItem]) {
          reportProblemFormData.append(`disputeReason[${reportReasonKey}]`, poReporting[reportProblemDataItem][reportReasonKey])
        }
      }
    }
    //  Display the key / value pairs
    // for (var pair of reportProblemFormData.entries()) {
    //   console.log('addedForm', pair[0] + ', ' + pair[1]);
    // }
    const responseReporting = await this.http.post<{ message: string, status: number, issueID: string, issueStatus: RaiseDisputeModel }>(`${this.domain}api/raiseDispute/reportProblemforINVO/`, reportProblemFormData).toPromise();
    return responseReporting
  }


  async reportRETURNproblem(poReporting: RaiseDisputeModel): Promise<{ message: string; status: number; issueID: string; issueStatus: RaiseDisputeModel }> {
    const reportProblemFormData = new FormData()
    for (let reportProblemDataItem in poReporting) {
      if (reportProblemDataItem === 'accountTypeOfUser') {
        reportProblemFormData.append(`accountTypeOfUser`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'phone') {
        reportProblemFormData.append(`phone`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderType') {
        reportProblemFormData.append(`orderType`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderStatus') {
        reportProblemFormData.append(`orderStatus`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderID') {
        reportProblemFormData.append(`orderID`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderNumber') {
        reportProblemFormData.append(`orderNumber`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'orderGenerateDate') {
        reportProblemFormData.append(`orderGenerateDate`, JSON.stringify(new Date(poReporting[reportProblemDataItem])))
      }
      if (reportProblemDataItem === 'userID') {
        reportProblemFormData.append(`userID`, poReporting[reportProblemDataItem])
      }
      if (reportProblemDataItem === 'userActingAsBuyer') {
        reportProblemFormData.append(`userActingAsBuyer`, JSON.stringify(poReporting[reportProblemDataItem]))
      }
      if (reportProblemDataItem === 'userActingAsSeller') {
        reportProblemFormData.append(`userActingAsSeller`, JSON.stringify(poReporting[reportProblemDataItem]))
      }
      if (reportProblemDataItem === 'disputeReason') {
        for (let reportReasonKey in poReporting[reportProblemDataItem]) {
          reportProblemFormData.append(`disputeReason[${reportReasonKey}]`, poReporting[reportProblemDataItem][reportReasonKey])
        }
      }
    }
    //  Display the key / value pairs
    // for (var pair of reportProblemFormData.entries()) {
    //   console.log('addedForm', pair[0] + ', ' + pair[1]);
    // }
    const responseReporting = await this.http.post<{ message: string, status: number, issueID: string, issueStatus: RaiseDisputeModel }>(`${this.domain}api/raiseDispute/reportProblemforRETN/`, reportProblemFormData).toPromise();
    return responseReporting
  }

  async getAllMyIssues(userid: string): Promise<{ message: string; status: number; issues: RaiseDisputeModel[] }> {
    const queryParams = `?usr=${userid}`
    const myIssues = await this.http.get<{ message: string, status: number, issues: RaiseDisputeModel[] }>(`${this.domain}api/raiseDispute/getAllMyIssues/` + queryParams).toPromise();
    return myIssues
  }

  async getCurrentOrderissue(orderDocID: string): Promise<{ message: string; status: number; issues: RaiseDisputeModel[] }> {
    const queryParams = `?orderID=${orderDocID}`;
    const issueFound = await this.http.get<{ message: string, status: number, issues: RaiseDisputeModel[] }>(`${this.domain}api/raiseDispute/getIssueStatusForCurrentOrder/` + queryParams).toPromise();
    return issueFound
  }

  shareFoundIssues(issues: RaiseDisputeModel[]) {
    this.viewCaughtIssues.next(issues)
  }
}
