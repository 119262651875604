import { Component, OnInit } from '@angular/core';
import { MypseoService } from 'src/app/services/mypseo.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  constructor(
    private mySeo: MypseoService,
  ) { }

  ngOnInit(): void {
    this.mySeo.updateTitle('Flexible Payments and Guaranteed Quality | On Myapaar')
    this.mySeo.updateDescription('Revolutionize your bulk commerce with Myapaar, the premier B2B platform. Empowering 4 cr. merchants—producers, wholesalers, distributors, and retailers—in India to manage inventory, sell in bulk, and connect with buyers nationwide. Experience maximum savings and convenience today!')
  }

}
