import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import {Router } from '@angular/router';
@Directive({
  selector: '[appBackButton]'
})

export class BackButtonDirective {
  currentUrl: any;
  showBackButton: boolean = true

  constructor(private location: Location, private route: Router,) {
 
  }

  @HostListener('click')
  onClick() {
    this.location.back();
  }

}
