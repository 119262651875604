<div class="content">
    <div *ngIf="!this.isPOCancelled" class="title">
        <p>Why are you Cancelling ?</p>
        <br>
        <form [formGroup]="cancelPurchaseOrderForm" id="cancelPO">
            <div class="cancelForm" *ngIf="this.listReasonsForCancel && this.listReasonsForCancel.length>0">

                <div *ngIf="this.sellerIsSeller" class="SellerAsSeller">
                    <mat-form-field appearance="outline">
                        <mat-label>Reason</mat-label>
                        <div class="cancelReasons">
                            <mat-select name="cancelCategory" formControlName="cancelCategory">
                                <mat-option *ngFor="let reasons of this.listReasonsForCancel"
                                    [value]="reasons.cancelCategory">
                                    {{reasons.cancelReasonDescription}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </mat-form-field>
                    <div class="refundDetails">
                        <h3>
                            Refund Amount : {{(this.cancelPurchaseOrderdata.refundAmount).toLocaleString('en-IN')}}
                        </h3>
                        <h3>
                            Refunded back in 3 business days
                        </h3>
                    </div>
                </div>

                <div *ngIf="this.sellerIsBuyer" class="SellerAsBuyer">
                    <mat-form-field appearance="outline">
                        <mat-label>Reason</mat-label>
                        <div class="cancelReasons">
                            <mat-select name="cancelCategory" formControlName="cancelCategory">
                                <mat-option *ngFor="let reasons of this.listReasonsForCancel"
                                    [value]="reasons.cancelCategory">
                                    {{reasons.cancelReasonDescription}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </mat-form-field>
                    <div class="refundDetails">
                        <h3>
                            Refund Amount : {{(this.cancelPurchaseOrderdata.refundAmount).toLocaleString('en-IN')}}
                        </h3>
                        <h3>
                            Refunded back in 3 business days
                        </h3>
                    </div>
                </div>

                <div *ngIf="this.buyerIsBuyer" class="Buyer">
                    <mat-form-field appearance="outline">
                        <mat-label>Reason</mat-label>
                        <div class="cancelReasons">
                            <mat-select name="cancelCategory" formControlName="cancelCategory">
                                <mat-option *ngFor="let reasons of this.listReasonsForCancel"
                                    [value]="reasons.cancelCategory">
                                    {{reasons.cancelReasonDescription}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </mat-form-field>
                    <div class="refundDetails">
                        <h3>
                            Refund Amount : {{(this.cancelPurchaseOrderdata.refundAmount).toLocaleString('en-IN')}}
                        </h3>
                        <h3>
                            Refunded back in 3 business days
                        </h3>
                    </div>
                </div>
            </div>
            <button mat-raised-button color="warn" (click)="onCancelPurchaseOrder(this.listReasonsForCancel)">Cancel
                Purchase Order</button>
        </form>
    </div>

    <div *ngIf="this.isPOCancelled">
        <h2> Purchase Order Cancelled</h2>
        <button mat-stroked-button (click)="onOKClick(this.isPOCancelled)" color="warn">Ok</button>
    </div>

</div>