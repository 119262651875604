import { Injectable } from '@angular/core';
import { PurchaseOrderModel } from '../datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { HttpClient } from '@angular/common/http';
import { InvoiceModel } from '../datamodels/invoiceOrderModel/invoiceDetailsModel';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsService } from './settings.service';
import { ReturnModel } from '../datamodels/returnOrders/returnDetailsModel';
import { VersionReturning } from '../datamodels/returnOrders/returnVersions';

@Injectable({
  providedIn: 'root'
})
export class HandlereturnService {
  returnOrders: ReturnModel[] = []
  private domain: string | undefined
  constructor(private http: HttpClient, private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }

  getAllReturnOrders(userId: string, loginAccounttype: string): Observable<ReturnModel[]> {
    const queryParams = `?userId=${userId}&loginAccounttype=${loginAccounttype}`;
    // const queryParams = `?pagesize=${userId}&pagesize=${listingsPerPage}&page=${currentPage}`;
    return new Observable(
      userRETsObserver => {
        if (this.returnOrders && this.returnOrders.length > 0) {
          userRETsObserver.next(this.returnOrders)
          return userRETsObserver.complete()
        }
        this.http.get<{
          allReturnOrders: ReturnModel[];
          status: number
        }>(`${this.domain}api/returnOrderRequest/getAllRETsForUser/` + queryParams)
          .subscribe((fetchedUserRETs) => {
            this.returnOrders = fetchedUserRETs.allReturnOrders;
            userRETsObserver.next(this.returnOrders);
            userRETsObserver.complete();
          });
      }
    )

  }

  getReturnOrder(returnOrderID: string): Observable<{ message: string; findReturn: ReturnModel; status: number; }> {
    try {
      const queryParams = `?RETID=${returnOrderID}`
      // const allInvoicesOfPO = await 
      const returnOrderForID = this.http.get<{ message: string; findReturn: ReturnModel; status: number; }>
        (`${this.domain}api/returnOrderRequest/getCurrentRETnOrd/` + queryParams)
      return returnOrderForID
    }
    catch (e) {
      throw e
    }
  }

  async createnewReturn(newReturn: ReturnModel): Promise<{ message: string, createdReturn: ReturnModel, returnS3Link: string, status: number }> {
    try {
      const newRETData = new FormData()
      for (let newRETURNDataItem in newReturn) {
        // each new object should be conditioned in If, or else will be skipped
        //append nested ob from loops

        //maybe blank po id ?
        if (newRETURNDataItem === '_id') {
          newRETData.append(`_id`, newReturn[newRETURNDataItem])
        }
        if (newRETURNDataItem === 'returnNumber') {
          newRETData.append(`returnNumber`, newReturn[newRETURNDataItem])
        }
        if (newRETURNDataItem === 'returnQuantity') {
          newRETData.append(`returnQuantity`, JSON.stringify(newReturn[newRETURNDataItem]))
        }
        if (newRETURNDataItem === 'isEachItemReturnable') {
          newRETData.append(`isEachItemReturnable`, JSON.stringify(newReturn[newRETURNDataItem]))
        }
        if (newRETURNDataItem === 'returnCondition') {
          newRETData.append(`returnCondition`, newReturn[newRETURNDataItem])
        }
        if (newRETURNDataItem === 'returnReason') {
          newRETData.append(`returnReason`, newReturn[newRETURNDataItem])
        }
        if (newRETURNDataItem === 'returnVersionNumber') {
          newRETData.append(`returnVersionNumber`, JSON.stringify(newReturn[newRETURNDataItem]))
        }
        if (newRETURNDataItem === 'dateOfRETURNGenerated') {
          newRETData.append(`dateOfRETURNGenerated`, JSON.stringify(newReturn[newRETURNDataItem]))
        }
        if (newRETURNDataItem === 'refundValueOnReturn') {
          newRETData.append(`refundValueOnReturn`, JSON.stringify(newReturn[newRETURNDataItem]))
        }
        if (newRETURNDataItem === 'isReturnGenerated') {
          newRETData.append(`isReturnGenerated`, JSON.stringify(newReturn[newRETURNDataItem]))
        }
        if (newRETURNDataItem === 'returnStatus') {
          newRETData.append(`returnStatus`, newReturn[newRETURNDataItem])
        }
        if (newRETURNDataItem === 's3ReturnOrderLink') {
          newRETData.append(`s3ReturnOrderLink`, JSON.stringify(newReturn[newRETURNDataItem]))
        }
        //


        if (newRETURNDataItem === 'purchaseOrderNumber') {
          newRETData.append(`purchaseOrderNumber`, newReturn[newRETURNDataItem])
        }
        if (newRETURNDataItem === 'purchaseOrderID') {
          newRETData.append(`purchaseOrderID`, newReturn[newRETURNDataItem])
        }
        if (newRETURNDataItem === 'buyerDetails') {
          for (let buyerDetailKey in newReturn[newRETURNDataItem]) {
            if (buyerDetailKey === 'buyerAddress') {
              //poDataItem is sellerDetails here
              for (let buyerAddressKey in newReturn[newRETURNDataItem][buyerDetailKey]) {
                // append two depth nested objects
                newRETData.append(`buyerDetails[${buyerDetailKey}][${buyerAddressKey}]`, newReturn[newRETURNDataItem][buyerDetailKey][buyerAddressKey])
              }
            }
            if (buyerDetailKey === 'buyerContactDetails') {
              //poDataItem is sellerDetails here
              // append two depth nested objects
              for (let buyerContactDetailKey in newReturn[newRETURNDataItem][buyerDetailKey]) {
                // for numbers, we need to stringify
                buyerContactDetailKey === 'alternatePhoneContact' || buyerContactDetailKey === 'phone' ?
                  newRETData.append(`buyerDetails[${buyerDetailKey}][${buyerContactDetailKey}]`, JSON.stringify(newReturn[newRETURNDataItem][buyerDetailKey][buyerContactDetailKey]))
                  : newRETData.append(`buyerDetails[${buyerDetailKey}][${buyerContactDetailKey}]`, newReturn[newRETURNDataItem][buyerDetailKey][buyerContactDetailKey])
              }
            }
            if (buyerDetailKey !== 'buyerAddress' && buyerDetailKey !== 'buyerContactDetails')
              newRETData.append(`buyerDetails[${buyerDetailKey}]`, newReturn[newRETURNDataItem][buyerDetailKey])
          }
        }
        // sellerDetails fetched of PO
        if (newRETURNDataItem === 'sellerDetails') {
          for (let sellerDetailKey in newReturn[newRETURNDataItem]) {
            newRETData.append(`sellerDetails[${sellerDetailKey}]`, newReturn[newRETURNDataItem][sellerDetailKey])
          }
        }
        if (newRETURNDataItem === 'itemDetails') {
          const resolvedItemDetails = []
          for (let itemDetailKey in newReturn[newRETURNDataItem]) {
            resolvedItemDetails.push(newReturn[newRETURNDataItem][itemDetailKey])
          }
          newRETData.append(`itemDetails`, JSON.stringify(resolvedItemDetails))
          // newPOData.append(`itemDetails`, JSON.stringify(purchaseOrderForInv[poDataItem]))
        }
        if (newRETURNDataItem === 'shipToBuyerLocation') {
          for (let shipToBuyerLocationKey in newReturn[newRETURNDataItem]) {
            newRETData.append(`shipToBuyerLocation[${shipToBuyerLocationKey}]`, newReturn[newRETURNDataItem][shipToBuyerLocationKey])
          }
        }
        if (newRETURNDataItem === 'bankDetails') {
          for (let bankDetailsKey in newReturn[newRETURNDataItem]) {
            newRETData.append(`bankDetails[${bankDetailsKey}]`, newReturn[newRETURNDataItem][bankDetailsKey])
          }
        }
        if (newRETURNDataItem === 's3POpdfLink') {
          newRETData.append(`s3POpdfLink`, newReturn[newRETURNDataItem])
        }
        if (newRETURNDataItem === 'orderValue') {
          newRETData.append(`orderValue`, JSON.stringify(newReturn[newRETURNDataItem]))
        }
        if (newRETURNDataItem === 'orderValueIncludingTax') {
          newRETData.append(`orderValueIncludingTax`, JSON.stringify(newReturn[newRETURNDataItem]))
        }
        if (newRETURNDataItem === 'orderQuantity') {
          newRETData.append(`orderQuantity`, JSON.stringify(newReturn[newRETURNDataItem]))
        }
        if (newRETURNDataItem === 'orderTax') {
          newRETData.append(`orderTax`, JSON.stringify(newReturn[newRETURNDataItem]))
        }

        if (newRETURNDataItem === 'paymentLink') {
          newRETData.append(`paymentLink`, newReturn[newRETURNDataItem])
        }
        if (newRETURNDataItem === 'paymentStatus') {
          newRETData.append(`paymentStatus`, JSON.stringify(newReturn[newRETURNDataItem]))
        }
        if (newRETURNDataItem === 'paymentTrnsctnId') {
          newRETData.append(`paymentTrnsctnId`, newReturn[newRETURNDataItem])
        }
        if (newRETURNDataItem === 'isPOStatusActive') {
          newRETData.append(`isPOStatusActive`, JSON.stringify(newReturn[newRETURNDataItem]))
        }
        if (newRETURNDataItem === 'poStatus') {
          newRETData.append(`poStatus`, newReturn[newRETURNDataItem])
        }
      }
      // const createInvoiceOrder = await this.http.post<{ message: string, createdINVO: InvoiceModel, invoS3Link: string, status: string }>(`${this.domain}api/returnOrderRequest/createINVO/``, newRETData).toPromise()
      const createdreturn = await this.http.post<{ message: string, createdReturn: ReturnModel, returnS3Link: string, status: number }>(`${this.domain}api/returnOrderRequest/createReturn/`, newRETData).toPromise()
      return createdreturn
    }
    catch (e) {
      throw e
    }
  }

  async updatePurchaseOrderWithReturnVersion(versionReturn: VersionReturning[], purchOrderId: string, purchOrderNumber: string)
  // :Promise<{ message: string; status: number }> 
  {
    const queryParams = `?pOid=${purchOrderId}&pOnumber=${purchOrderNumber}`
    const returnVersionObjectParsing = {
      returnVersions: versionReturn
    }
    const returnVersionFormData = new FormData()
    for (let retObjectKey in returnVersionObjectParsing) {
      if (retObjectKey === 'returnVersions') {
        for (let returnItemsKey in returnVersionObjectParsing[retObjectKey]) {
          if (returnItemsKey === 'itemsAddedForReturn') {
            returnVersionFormData.append(`returnVersions[${returnItemsKey}]`, versionReturn[retObjectKey])
          }
        }
        returnVersionFormData.append(`returnVersions`, JSON.stringify(versionReturn))
      }
    }
    const createdReturnVersion = await this.http.put<{ message: string, status: number }>(`${this.domain}api/purchaseOrderRequest/updatePurchaseOrderWithReturnVersion/` + queryParams, returnVersionFormData).toPromise();
    return createdReturnVersion

  }

  async cancelReturnOrder(returnOrderID: string): Promise<{ message: string }> {
    const queryParams = `?returnOrderID=${returnOrderID}`
    const cancelledINVO = await this.http.get<{ message: string }>(`${this.domain}api/returnOrderRequest/cancelReturnOrder/` + queryParams).toPromise()
    return cancelledINVO
  }

  async shipReturnOrder(invoiceOrderId: string, invoiceOrderNumber: string): Promise<{ message: string }> {
    const queryParams = `?invoiceId=${invoiceOrderId}&invoiceNumber=${invoiceOrderNumber}`
    const updateINVO = await this.http.get<{ message: string }>(`${this.domain}api/returnOrderRequest/shipInvoiceOrder/` + queryParams).toPromise()
    return updateINVO
  }
}
