import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipSelectionChange } from '@angular/material/chips';

@Component({
  selector: 'app-filter-varieties',
  templateUrl: './filter-varieties.component.html',
  styleUrls: ['./filter-varieties.component.css']
})
export class FilterVarietiesComponent implements OnInit {
  StateFilterArray = []
  SearchName: any;
  allCompleteStates: boolean = false;
  listofStatesAndCategory: Array<{ StateID: any[] }> = [];



  @ViewChild('newInput') newInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    public threedialogRef: MatDialogRef<FilterVarietiesComponent>,
    @Inject(MAT_DIALOG_DATA) public editVarData: any) { }

  ngOnInit(): void {
    this.StateFilterArray = Object.assign([], this.editVarData.data)
  }
  myvalue() {
    this.SearchName = ""
    this.ngOnInit();
  }
  SearchTerm() {
    if (this.SearchName == "") {
      this.ngOnInit();
    }
    else {
      {
        this.StateFilterArray = this.StateFilterArray.filter(resstatSearch => {
          return resstatSearch.name.toLocaleLowerCase().match(this.SearchName.toLocaleLowerCase())
        })
      }
    }
  }
  setAllStates(selected: boolean) {
    this.StateFilterArray.forEach(h => h.selected = !h.selected);
  }
  updateAllCompleteStates() {
    this.allCompleteStates = this.StateFilterArray != null && this.StateFilterArray.every(h => h.selected);
  }
  onApplyFilter() {
    this.listofStatesAndCategory.push({ StateID: this.StateFilterArray })
    this.threedialogRef.close(this.listofStatesAndCategory)

  }
  onReset() {
    this.setAllStates(false);
    this.StateFilterArray.forEach(h => h.selected = false);
  }
  onNoClick(): void {
    this.threedialogRef.close();
  }
}
