<div class="wrapper" fxFlex="100%">
    <h1 mat-dialog-title>{{this.editProdData.data.name}}</h1>
    <div class="product-img ">
        <img src="../../../assets/{{this.editProdData.data.image}}" height="420" width="327">
    </div>
    <div class="product-info">
        <div class="product-text">
            <h1>{{'Description'}}</h1><br>

            <p>{{this.editProdData.data.description}}<br>
        </div>
        <div class="product-price-btn">
            <p><span>{{this.editProdData.data.price}}</span></p><br><br>
            <button type="button">add to cart</button>
        </div>

    </div>
    <div class="footnote ">
        <br>
        <ul>
            <li> Order a free Sample for any Product.
            </li>
            <li> Limit of 5 Sample Orders per User.
            </li>
            <li>Taxes and Delivery is on us!
        </ul>
    </div>


</div>