<div class="orderActions">
    <div class="unverifiedID" *ngIf="!this.isEnableSellerCentral">
        <h5>KYC Pending</h5>
        <h6>Email us at solutions@myapaar.com</h6>
    </div>
    <div class="action">
        <div class="eachRow">
            <div *ngIf="this.sellerAccountType" class="act request">
                <button *ngIf="this.isEnableSellerCentral" [disabled]="!this.isEnableSellerCentral"
                    [routerLink]="['/SellerCentral', this.currentUserId]" mat-raised-button
                    style="background-color: rgb(217, 191, 21); font-weight: 450;color: white;">
                    <mat-icon>grade</mat-icon>
                    <h4 id="sellcentText">Seller Central</h4>
                </button>
                <button *ngIf="!this.isEnableSellerCentral" mat-raised-button
                    style="background-color: rgb(180, 177, 177); font-weight: 450;color: white;">
                    <mat-icon>grade</mat-icon>
                    <h4 id="sellcentText">Seller Central</h4>
                </button>
            </div>
            <div class="act delivered">
                <button routerLink="/viewAllPayments" mat-raised-button
                    style="background-color: rgb(84, 107, 177); font-weight: 450;color: white;">
                    <mat-icon> show_chart</mat-icon>
                    <h4>Payments</h4>
                </button>
            </div>
        </div>

        <div class="eachRow">
            <div class="act purchaseOrders">
                <button routerLink="/viewAllPurchaseOrders" mat-raised-button
                    style="background-color: rgb(35, 130, 246); font-weight: 450;color: white;">
                    <mat-icon> list_alt</mat-icon>
                    <h4>Purchase Orders</h4>
                </button>
            </div>
            <div class="act Invoices">
                <button routerLink="/viewAllInvoices" mat-raised-button
                    style="background-color: rgb(86, 180, 27); font-weight: 450;color: white;">
                    <mat-icon>₹</mat-icon>
                    <h4>Invoice Bills</h4>
                </button>
            </div>
        </div>
        <div class="eachRow">
            <div class="act delivered">
                <button routerLink="/viewAllPurchaseOrders/Completed" mat-raised-button
                    style="background-color: rgb(24, 111, 2); font-weight: 450;color: white;">
                    <mat-icon>check</mat-icon>
                    <h4>Delivered Orders</h4>
                </button>
            </div>
            <div class="act returns">
                <button routerLink="/viewAllReturnOrders" mat-raised-button
                    style="background-color: rgb(142, 54, 171); font-weight: 450;color: white;">
                    <mat-icon>compare_arrows</mat-icon>
                    <h4>Returns</h4>
                </button>
            </div>
        </div>
    </div>
</div>