<div class="notposted" *ngIf="!this.displayPostSuccess">
    <div class="isNewRequirement">
        <div class="BannerSec">
            <div class="simrow">
                <mat-icon class='ic1'>delete_outline</mat-icon>
                <span class="step 1s">
                    Please note that this action is irreversible and deleted information cannot be retrieved.</span>
            </div>
        </div>
        <mat-form-field appearance="outline" appearance="outline">
            <mat-hint>Buyer - Seller</mat-hint>
            <mat-label>Select Account Type</mat-label>
            <mat-select [(ngModel)]="selectedAccountType" name="accountType">
                <mat-option *ngFor="let accountType of this.accountTypes" [value]="accountType" required>
                    {{accountType}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <input [(ngModel)]="this.fullName" matInput type="text" name="name" placeholder="Full Name" required>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <input [(ngModel)]="this.companyName" matInput type="text" name="name"
                placeholder="Business or Company Name">
            <mat-hint>Optional</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <input [(ngModel)]="this.phone" matInput type="text" name="phone" placeholder="Phone or Email" required>
            <mat-hint>Registered with Account</mat-hint>
        </mat-form-field>
        <mat-form-field class="descriptionArea" appearance="outline">
            <mat-label>Reason to Delete Account</mat-label>
            <textarea [(ngModel)]="reasonToDelete" matInput required></textarea>
            <mat-hint>Atleast 70 words</mat-hint>
        </mat-form-field>
        <button class="done" (click)="this.doneForm()" mat-stroked-button>Request Deletion</button>
    </div>

</div>
<div class="posted" *ngIf="this.displayPostSuccess">
    <h1 class="headPosted">Requirement Received</h1>
</div>