import { Injectable } from '@angular/core';
import { Review } from '../datamodels/productreview';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SettingsService } from './settings.service';
import { BlogPostModel } from '../datamodels/blogPostModel';

@Injectable({
  providedIn: 'root'
})
export class CommentpostService {
  private domain: string | undefined
  private reviews: Review[]
  constructor(private http: HttpClient, private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }

  async getReviews(listingId: string): Promise<Review[]> {
    const queryParams = `?listingId=${listingId}`;
    const resReviews = await this.http.get<{
      allReviews: Review[];
      status: number;
    }>(`${this.domain}api/posts/getAllReviews/forListing/` + queryParams).toPromise()
    return resReviews.allReviews

  }


  getSingleReview(id: string) {
    return this.http.get<{
      _id: string, listingId: string, author: string, comment: string, rating: number, date: Date,
      reviewImage: string, creatorID: string
    }>(`${this.domain}api/posts/` + id);
  }

  addImageforReview(ImagePicked: File): Observable<any> {
    const imageData = new FormData()
    imageData.append('Photos', ImagePicked)
    return this.http.post<any>(`${this.domain}api/posts/uploadReviewImage/`, imageData)
  }

  async updateReview(id: string, listingId: string, author: string, comment: string, rating: number, date: Date,
    reviewImage: string): Promise<{ message: string; reviewOnlistingId: string; status: number; }> {
    let reviewData: FormData;
    reviewData = new FormData();
    reviewData.append("id", id);
    reviewData.append("listingId", listingId);
    reviewData.append("author", author);
    reviewData.append("comment", comment);
    reviewData.append("rating", rating.toString());
    reviewData.append("date", date.toString());
    reviewData.append("reviewImage", reviewImage);
    const updatereview = await this.http.put<{ message: string; reviewOnlistingId: string, status: number }>(`${this.domain}api/posts/updateReview/` + id, reviewData).toPromise()
    return updatereview
  }

  async addReview(listingId: string, author: string, comment: string, rating: number, date: Date,
    reviewImage: string): Promise<{ message: string; review: Review; status: number }> {
    const reviewData = new FormData();
    reviewData.append("listingId", listingId);
    reviewData.append("author", author);
    reviewData.append("comment", comment);
    reviewData.append("rating", rating.toString());
    reviewData.append("date", date.toString());
    reviewData.append("reviewImage", reviewImage);
    const createdReview = await this.http.post<{
      message: string,
      status: number,
      review: Review
    }>
      (`${this.domain}api/posts/createReview/`,
        reviewData).toPromise()
    return createdReview
  }

  async deleteReview(reviewId: string): Promise<{ status: number }> {
    const deletedPost = await this.http.get<{ status: number }>(`${this.domain}api/posts/deletePost/` + reviewId).toPromise()
    return deletedPost
  }

  async getAllBlogs(): Promise<{
    status: number;
    blogsfetched: BlogPostModel[]
  }> {
    const allBlogs = await this.http.get<{ status: number; blogsfetched: BlogPostModel[] }>(`${this.domain}api/posts/findAllBlogsStyle1`).toPromise()
    return allBlogs
  }

  async getSingleBlog(blogTitle: string): Promise<{
    status: number;
    singleBlogfetched: BlogPostModel
  }> {
    const matchBlogTitle = { blogTitle }
    const singleBlog = await this.http.post<{ status: number; singleBlogfetched: BlogPostModel }>(`${this.domain}api/posts/getSingleBlogStyle1`, matchBlogTitle).toPromise()
    return singleBlog
  }
}
