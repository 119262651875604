import { Injectable } from '@angular/core';
import { VarietyItems } from '../datamodels/varietyModel';
import { SettingsService } from './settings.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VarietyServiceService {
  private domain: string | undefined
  varieties: VarietyItems[] = []
  constructor(private http: HttpClient,
    private settingsService: SettingsService
  ) { 
    this.domain = settingsService.cherryURL
  }


  getVarieties(): Observable<VarietyItems[]> {
    return new Observable(
      varietyObserver => {
        if (this.varieties && this.varieties.length > 0) {
          varietyObserver.next(this.varieties)
          return varietyObserver.complete()
        }
        this.http.get<{
          allVarieties: VarietyItems[]; status: number
        }>(`${this.domain}api/varieties/findAll`)
          .subscribe((fetchedVarieties) => {
            this.varieties = fetchedVarieties.allVarieties;
            varietyObserver.next(this.varieties);
            varietyObserver.complete();
          });
      }
    )
  }

}
