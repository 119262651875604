import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-manage-orders',
  templateUrl: './manage-orders.component.html',
  styleUrls: ['./manage-orders.component.css']
})
export class ManageOrdersComponent implements OnInit {
  accountType: any;
  sellerAccountType: boolean = false;
  buyerAccountType: boolean = false;
  gstDetailsPending: boolean = false
  gstVerificationPending: boolean = false
  isEnableSellerCentral: boolean = false;

  constructor(
    private authService: AuthService,

  ) { }
  currentUserId: string;
  loginUserInfo: any

  ngOnInit(): void {
    try {
      this.loginUserInfo = this.authService.getUserLoginInfo();
      this.currentUserId = this.loginUserInfo.userId
      this.loginUserInfo.accounttype === 'Seller' ? this.sellerAccountType = true : this.buyerAccountType = true
      if (this.loginUserInfo.GST_Id !== '' && this.loginUserInfo.isGSTVerified === true) {
        this.isEnableSellerCentral = true
        this.gstDetailsPending = true
      }
    }
    catch (e) {
      throw e
    }
  }
}
