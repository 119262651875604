<div class="contentDelivery">
    <div class="deliveredBox" *ngIf="!this.isDeliveryAllowed">
        <h4 class="invoId">{{this.invoID}} </h4>
        <div class="deliveredText">
            <h1>{{this.currentInvoiceOrderStatus}}</h1> <mat-icon>done_all</mat-icon>
        </div>
    </div>
    <div *ngIf="this.isDeliveryAllowed" class="markDeliverBox">
        <div class="markDeliverText">
            <h1>
                Deliver Invoice Order
            </h1>
            <h4 class="invoId">{{this.invoID}}</h4>
        </div>
        <mat-card>
            <mat-spinner *ngIf="isLoading"></mat-spinner>
            <form [formGroup]="vaayuDeliveryForm" (submit)="onDelivery()" *ngIf="!isLoading">
                <mat-form-field>
                    <input matInput type="name" name="name" formControlName="name" placeholder="Vaayu Name" required>
                    <mat-error *ngIf="formErrors.name">{{formErrors.name}}</mat-error>
                    <mat-hint>Pawan Kumar</mat-hint>
                </mat-form-field>
                <mat-form-field>
                    <input matInput type="tel" name="phone" formControlName="phone" placeholder="Vaayu Phone Number"
                        pattern="[0-9]*" maxlength="10" required>
                    <mat-error *ngIf="formErrors.phone">{{formErrors.phone}}</mat-error>
                    <mat-hint>98xxxxxxx9</mat-hint>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>OTP from buyer</mat-label>
                    <input matInput type="number" maxlength="5" pattern="[0-9]*" formControlName="otpVaayu"
                        autocomplete="off" />
                    <mat-hint>226020</mat-hint>
                </mat-form-field>
                <div class="loginActions">
                    <button mat-raised-button color="accent" type="submit">Deliver Items
                    </button>
                </div>
            </form>
        </mat-card>
    </div>
</div>