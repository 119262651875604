import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  loginform: FormGroup;
  hide = true;
  userPhone: string
  isUserFoundActive: boolean = false
  private authListenerSubs: Subscription;
  validationMessages = {
    'phone': {
      'required': 'Mobile number is required',
      'minlength': 'Atleast 10 digits required. ',
      'pattern': 'Only numbers are allowed',
    },
    'email': {
      'required': 'Email is required',
      'email': 'Email not in valid format'
    },
    'password': {
      'required': 'Password is required'
    }
  }
  formErrors = {
    'phone': '',
    'email': '',
    'password': '',
  }
  showOTPActivation: boolean = false;
  constructor(public authService: AuthService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(
      //we get auth status which is a boolean
      authStatus => {
        this.isLoading = false;
      }
    )
    this.loginform = new FormGroup({
      phone: new FormControl('', { validators: [Validators.minLength(10), Validators.maxLength(10)] }),
      email: new FormControl('', { validators: [Validators.email] }),
      password: new FormControl('', { validators: [Validators.required] }),
      verifyOTP: new FormControl(null, {
        validators: [Validators.maxLength(5)]
      }),
    });
    this.loginform.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged();
  }
  ngOnDestroy() {
    //prevent any data leak
    this.authListenerSubs.unsubscribe();
  }
  onValueChanged(data?: any) {
    if (!this.loginform) {
      return;
    }
    const lform = this.loginform
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        //clear previous messages if any
        this.formErrors[field] = '';
        const control = lform.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + '';
            }
          }
        }
      }
    }
  }

  checkPhoneVerified() {
    try {
      if (!this.userPhone) {
        return
      }
      if (this.userPhone.length === 10) {
        this.authService.checkVerifiedIDUsingPhone(this.userPhone).then(resVer => {
          if (resVer && resVer.status === 200) {
            if (resVer.activatedID === 'Active') {
              this.showOTPActivation = false
            }
            if (resVer.activatedID === 'Inactive') {
              this.showOTPActivation = true
            }
          }
        })
      }
    }
    catch (e) {
      throw e
    }

  }
  onLogin() {
    try {
      if (!this.loginform.get('phone').value && !this.loginform.get('email').value) {
        const errMEssage = "Add Phone number or Email"
        this.notifyError(errMEssage, "Ok")
        return
      }
      if (this.loginform.invalid) {
        return;
      }
      this.isLoading = true; //spinner starts
      {
        this.authService.loginUser(
          this.loginform.value.phone,
          this.loginform.value.email,
          this.loginform.value.password,
          this.loginform.value.verifyOTP
        )
      }
    }
    catch (e) {
      throw e
    }



  }
  notifyError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }

}
