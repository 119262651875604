import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/datamodels/MainProductModel';
import { ProductService } from 'src/app/services/product.service';
import { Router } from '@angular/router';
interface LoadingObj {
  quote: string; quotedBy: string
}

@Component({
  selector: 'app-landingproducts',
  templateUrl: './landingproducts.component.html',
  styleUrls: ['./landingproducts.component.css']
})

export class LandingproductsComponent implements OnInit {
  @Input() appBannerslandingproducts: string[];

  quotes = [
    { quote: "Dream big, act boldly, succeed.", quotedBy: "Ratan Tata" },
    { quote: "Innovation distinguishes leaders from followers.", quotedBy: "Narendra Modi" },
    { quote: "Hard work beats talent, always.", quotedBy: "Dhirubhai Ambani" },
    { quote: "Fail fast, learn fast, succeed.", quotedBy: "Narayana Murthy" },
    { quote: "Believe in yourself and persist.", quotedBy: "Azim Premji" },
    { quote: "Success requires relentless pursuit and perseverance.", quotedBy: "Mukesh Ambani" },
    { quote: "Your vision is your power.", quotedBy: "Sundar Pichai" },
    { quote: "Adaptability is the key to survival.", quotedBy: "Kiran Mazumdar-Shaw" },
    { quote: "Think big, start small, scale.", quotedBy: "Cyrus Mistry" },
    { quote: "Integrity is the foundation of leadership.", quotedBy: "Uday Kotak" },
    { quote: "Focus on solutions, not problems.", quotedBy: "Rajeev Chandrasekhar" },
    { quote: "Passion drives excellence in everything.", quotedBy: "Vineet Nayar" },
    { quote: "Embrace change, drive innovation forward.", quotedBy: "Kumar Mangalam Birla" }
  ];
  selectedOBJ: LoadingObj;
  allnonfeaturedproducts: Product[] = [];
  AllProducts: Product[] = []
  HoldnonFeature: Product[] = []
  nonfeaturedProd: Product;
  featureTrue: boolean;
  toggleProperty = false;
  holdingarray: Product[] = [];
  isLoading = false; //spinner stops
  constructor(public productService: ProductService,
    private router: Router) { }

  ngOnInit(): void {
    this.LoadNonFeaturedProducts();
    this.selectedOBJ = this.quotes[Math.floor(Math.random() * this.quotes.length)];
  }
  toggle() {
    this.toggleProperty = !this.toggleProperty;
  }
  LoadNonFeaturedProducts() {
    this.isLoading = true; //spinner starts
    this.productService.getProducts().subscribe((res) => {
      this.isLoading = false; //spinner stops
      // normal display when all products have listings
      // this.allnonfeaturedproducts = res

      // bhari dukan to show products with listings available only
      const populatedProducts = res.filter(allProds => {
        return allProds.productState && allProds.productState.length >= 1
      })
      this.allnonfeaturedproducts = populatedProducts.sort((a, b) => b.productState.length - a.productState.length);
      //removeBelow and replace with above liner
    })

  }

  onSelectProduct(productName: string) {
    this.router.navigate(['/showListingProduct', productName])
  }
}
