import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy, Directive, HostListener, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { AuthService } from '../auth/auth.service';
import { ProductCategoryModel } from 'src/app/datamodels/productcategory';
import { ProductcategoryService } from 'src/app/services/productcategory.service';
import { InCartService } from '../services/in-cart.service';
import { AllstatesService } from '../services/allstates.service';
import { HandleListingsService } from '../services/handle-listings.service';
import { UtilsService } from '../services/utils.service';
interface userCurrentLocation {
  userPincode: string,
  userCity: string,
  userState: string
}
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})

export class NavComponent implements OnInit, OnDestroy {
  cartItemsCount: number;
  showLocationPopup: boolean = false;
  selectedPincode: string = '';
  pincodeFullInfo: any = [];
  stateName: string;
  districtName: string;
  iscurrentLocation: boolean = false;
  currentLocation: userCurrentLocation;
  openPanel: boolean = false
  listingsCreatedBySeller: number;
  businessName: string;
  companyNameSeller: string;
  ngOnChanges(changes: SimpleChanges) {
  }


  @Input() backButtonVisibility: boolean;

  public currentUserId: string
  private authListenerSubs: Subscription
  showBackButton: boolean = true
  userIsAuthenticated = false;
  @ViewChild("sidenavContainer", { static: false })
  sideNavContainer: MatSidenavContainer;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  loginUserInfo: any;
  genericUserInfo: any;
  isGeneric: boolean = true
  isLoading = false; //spinner initialized
  productcategories: ProductCategoryModel[] = [];
  constructor(private breakpointObserver: BreakpointObserver,
    private route: Router,
    private authService: AuthService,
    private productcategoryService: ProductcategoryService,
    private cartservice: InCartService,
    private stateService: AllstatesService,
    private hls: HandleListingsService,
    private util: UtilsService,
    private cdr: ChangeDetectorRef,
  ) { }


  ngOnInit() {
    this.doAuth()
    this.cdr.detectChanges();
    this.loadCategories()
    this.getCartItemsCount()
    this.getCurrentLocation()
    //when login First time, Javascript Object is returned , which can be used unparsed, 
    //for already loggedin user (on page reload),JSON string object is returned which needs to be parsed
    // this.currentUserId = this.loginUserInfo.userId
  }

  doAuth() {
    try {   //add conditions to if where backbutton should not be visible
      //since app component is loading first and then other components- we need to manually check with authservice for latest auth status
      // keep checking for user auth status
      // this.authService.autoAuthUser();
      this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        //if auth status changes, get user info again
        this.loginUserInfo = this.authService.getUserLoginInfo()
        this.currentUserId = this.loginUserInfo.userId
      });
      this.userIsAuthenticated = this.authService.getIsAuth();
      this.loginUserInfo = this.authService.getUserLoginInfo()
      this.companyNameSeller = this.loginUserInfo.companyName
      this.businessName = this.util.getBusinessNameForURL(this.companyNameSeller)
      this.currentUserId = this.loginUserInfo.userId
      if (this.loginUserInfo?.accounttype == 'Seller') {
        this.getAllListingsCountBySeller(this.currentUserId)
      }
      if (this.userIsAuthenticated) {
        this.isGeneric = false
        // this.loginUserInfo = await this.authService.getUserLoginInfo()
        // console.log('authenticated user', this.loginUserInfo.userId)
        // this.currentUserId = this.loginUserInfo.userId
        // console.log('authenticated userId', this.currentUserId)
      }
      else if (!this.userIsAuthenticated) {
        this.isGeneric = true
        // this.loginUserInfo = await this.authService.getUserLoginInfo()
        // console.log('general user', this.loginUserInfo.userId)
        // this.currentUserId = this.loginUserInfo.userId
      }

    }
    catch (e) { }

  }

  getCartItemsCount() {
    try {
      this.cartservice.getItemCount().subscribe(
        itemCount => {
          if (itemCount && itemCount > 0) {
            this.cartItemsCount = itemCount
          }
        }
      )
    }
    catch (e) {
    }
  }
  getCurrentLocation() {
    this.iscurrentLocation = this.authService.isCurrentLocationAvailable()
    if (!this.iscurrentLocation) {
      setTimeout(() => {                           // <<<---using ()=> syntax
        this.openPanel = true
      }, 61000);
    }
    else {
      this.openPanel = false
      this.currentLocation = this.authService.getCurrentLocation()
      this.selectedPincode = this.currentLocation.userPincode
      this.districtName = this.currentLocation.userCity
      this.stateName = this.currentLocation.userState
      // this.getAllCommodities(this.districtName)
    }
  }
  onActivate(event) {
    // window.scroll(0,0);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  async getMyPincodeDetails() {
    try {
      if (this.selectedPincode.length == 6) {
        const pincodeInfo = await this.stateService.getPincodeDetails(this.selectedPincode)
        this.pincodeFullInfo = pincodeInfo.pinCodeInfo
        this.stateName = this.pincodeFullInfo[0].State
        this.districtName = this.pincodeFullInfo[0].District
        this.authService.setCurrentLocation(this.selectedPincode, this.districtName, this.stateName)
        this.openPanel = false
        this.getCurrentLocation()
      }
    }
    catch (e) {
      throw e
    }

  }

  loadCategories() {
    try {
      this.isLoading = true; //spinner starts
      this.productcategoryService.getCategories().subscribe((res) => {
        this.productcategories = res
        this.isLoading = false; //spinner stops
      })
    }
    catch (e) {
      throw e
    }
  }

  hasRoute(route: string) {
    try {
      return this.route.url == route;
    }
    catch (e) {
      throw e
    }
  }

  // async doAuth() {
  //   try {   //add conditions to if where backbutton should not be visible
  //     //since app component is loading first and then other components- we need to manually check with authservice for latest auth status
  //     // keep checking for user auth status
  //     this.authService.autoAuthUser();
  //     this.userIsAuthenticated = this.authService.getIsAuth();
  //     console.log('chekcAuth', this.userIsAuthenticated)
  //     if (this.userIsAuthenticated) {
  //       this.isGeneric = false

  //     }
  //     this.isGeneric = true
  //     this.loginUserInfo = await this.authService.getUserLoginInfo()
  //     console.log('useRInfo', this.loginUserInfo.userId)
  //     this.currentUserId = this.loginUserInfo.userId
  //     this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
  //       this.userIsAuthenticated = isAuthenticated;
  //       //if auth status changes, get user info again
  //       console.log('authIT', this.userIsAuthenticated)
  //       this.loginUserInfo = this.authService.getUserLoginInfo()
  //       this.currentUserId = this.loginUserInfo.userId
  //     });
  //     console.log('final userId', this.currentUserId)
  //   }
  //   catch (e) { }


  // }
  ngOnDestroy() {
    //subscription for observables or objects managed by us or created by us (like authstatuslistner in auth.ts) also need to be destroyed by us  
    this.authListenerSubs.unsubscribe();
  }
  onLogout() {
    this.authService.logoutUser();
  }
  getAllListingsCountBySeller(userId: string) {
    this.hls.getSellerListingsCount(userId).then(
      res => {
        this.listingsCreatedBySeller = res.count
      }
    )
  }

}
