<div>
    <mat-tab-group>
        <mat-tab class="productBox" label="Product">
            <section class="example-section">
                <span class="example-list-section">
                    <mat-checkbox class="example-margin" [checked]="allProductsSelected"
                        [indeterminate]="someProductsComplete()" (change)="setAllProducts($event.checked)">
                        All Products
                    </mat-checkbox>
                </span>
                <span class="example-list-section">
                    <ul>
                        <li *ngFor="let product of this.filterProductNames">
                            <mat-checkbox [(ngModel)]="product.checked" (ngModelChange)="updateAllProductsComplete()">
                                {{product.productName}}
                            </mat-checkbox>
                        </li>
                    </ul>
                </span>
            </section>
        </mat-tab>
        <mat-tab class="statusBox" label="Status">
            <section class="example-section">
                <span class="example-list-section">
                    <mat-checkbox class="example-margin" [checked]="allBidStatusSelected"
                        [indeterminate]="someStatusComplete()" (change)="setAllStatus($event.checked)">
                        All Status
                    </mat-checkbox>
                </span>
                <span class="example-list-section">
                    <ul>
                        <li *ngFor="let status of this.filterbidRequestStatus">
                            <mat-checkbox [(ngModel)]="status.checked" (ngModelChange)="updateAllStatusComplete()">
                                {{status.requestStatus}}
                            </mat-checkbox>
                        </li>
                    </ul>
                </span>
            </section>
        </mat-tab>
        <mat-tab class="bidType" label="Bid">
            <section class="example-section">
                <span class="example-list-section">
                    <mat-checkbox class="example-margin" [checked]="allBidTypesSelected"
                        [indeterminate]="someBidTypesComplete()" (change)="setAllBidTypes($event.checked)">
                        Bid Types
                    </mat-checkbox>
                </span>
                <span class="example-list-section">
                    <ul>
                        <li *ngFor="let bidtype of this.filterBidTypes">
                            <mat-checkbox [(ngModel)]="bidtype.checked" (ngModelChange)="updateAllBidTypesComplete()">
                                <span *ngIf="bidtype.isDiscount">Discount</span>
                                <span *ngIf="!bidtype.isDiscount">Bid Up</span>
                            </mat-checkbox>
                        </li>
                    </ul>
                </span>
            </section>
        </mat-tab>
    </mat-tab-group>
    <mat-dialog-actions>
        <button mat-button mat-dialog-close="true" (click)="onApplyFilter()">Apply</button>
        <button mat-button mat-dialog-close="false" (click)="onNoClick()">Cancel</button>
        <button mat-button (click)="onReset()">Reset</button>
    </mat-dialog-actions>
</div>