<div class="balance sec">
    <mat-card>
        <div class="cardstate">
            <span>Accounting & Withdrawal</span>
        </div>
        <div *ngIf="this.accountingInfo && this.accountingInfo.length > 0" class="placedOrders">
            <div class="eachOrder" *ngFor="let useOrder of this.accountingInfo">
                <div class="accountingOrder">
                    <div class="orderAndCharges">
                        <div class="orderInfoAmount">
                            <div class="orderAndInvoice">
                                <span class="accounting aheading">{{useOrder.orderNumber}}</span>
                            </div>
                            <span class="accounting aheading small"
                                *ngIf="!useOrder.invoiceInfo || useOrder.invoiceInfo && useOrder.invoiceInfo.length == 0">
                                No Invoice Bill Paid
                            </span>
                        </div>
                        <div class="orderValue">
                            <span class="accounting akeys"> order value </span>
                            <span
                                class="accounting warn avalues">{{(useOrder.orderFinalValue).toLocaleString('en-IN')}}</span>
                        </div>
                        <div class="orderTotalCt">
                            <span class="accounting akeys">including credit interest</span>
                            <span class="accounting dontcount avalues">
                                ({{(useOrder.orderCreditInterest).toLocaleString('en-IN')}})</span>
                        </div>
                        <div class="sellerChargesHandlingAndTCS">
                            <span class="accounting akeys">seller handling and platform fees</span>
                            <span class="accounting debit avalues">
                                -{{(useOrder.orderTCSHandlingFeesAndFromSeller).toLocaleString('en-IN')}}</span>
                        </div>
                        <div class="buyerChargesHandling">
                            <span class="accounting akeys">buyer handling and platform fees</span>
                            <span class="accounting debit avalues">
                                -{{(useOrder.orderHandlingFeesFromBuyer).toLocaleString('en-IN')}}</span>
                        </div>
                        <div class="orderPlatformCt">
                            <span class="accounting akeys"> credit terms finance fees</span>
                            <span class="accounting debit avalues">
                                -{{(useOrder.orderPlatformCreditInterestShare).toLocaleString('en-IN')}}</span>
                        </div>
                        <div class="orderFinancing">
                            <span class="accounting aheading payout">Payout Details</span>
                        </div>
                        <div class="orderFinalShare">
                            <span class="accounting akeys">payout</span>
                            <span class="accounting credit avalues">
                                {{(useOrder.orderFinalSellerShareOfOrder).toLocaleString('en-IN')}}</span>
                        </div>
                        <div class="orderSellerCt">
                            <span class="accounting akeys">seller share of credit interest</span>
                            <span class="accounting credit avalues">
                                +{{(useOrder.orderCreditInterestShareOfSeller).toLocaleString('en-IN')}}</span>
                        </div>
                        <div class="orderSellerCt">
                            <span class="accounting akeys">final payout</span>
                            <span class="accounting finalPayout avalues">₹
                                {{(useOrder.orderFinalPayout).toLocaleString('en-IN')}}</span>
                        </div>
                    </div>
                    <div class="invoicesAndPayments" *ngIf="useOrder.invoiceInfo && useOrder.invoiceInfo.length > 0">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Withdraw from
                                    </mat-panel-title>
                                    <mat-panel-description>
                                        {{useOrder.invoicedTimes}} Paid Invoices
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <section class="invoices">
                                    <div *ngFor="let useInvoice of useOrder.invoiceInfo" class="eachInvoice">
                                        <div class="invoiceNumb">
                                            <span class="accounting akeys">
                                                Invoice
                                            </span>
                                            <span>{{useInvoice.useInvoiceNumber}}
                                            </span>
                                        </div>
                                        <div class="paidDate">
                                            <span class="accounting akeys">
                                                paid on
                                            </span>
                                            <span>{{useInvoice.useInvoicePaidDate | date}} </span>
                                        </div>
                                        <div class="withdrawableDate">
                                            <span class="accounting akeys">
                                                withdrawable date
                                            </span>
                                            <span class="accounting warn avalues">
                                                {{useInvoice.useFundWithdrawabledate | date}}</span>
                                        </div>
                                        <div class="invoiceAmount">
                                            <span class="accounting akeys">
                                                invoice amount
                                            </span>
                                            <span
                                                class="accounting credit avalues">{{(useInvoice.useInvoiceAmount).toLocaleString('en-IN')}}
                                            </span>
                                        </div>
                                        <div *ngIf="useInvoice.usetotalDeducDone" class="deduction">
                                            <span class="accounting akeys">
                                                deductions ( S.P.C + C.T.I)
                                            </span>
                                            <span
                                                class="accounting debit avalues">{{(useInvoice.usetotalDeducDone).toLocaleString('en-IN')}}
                                            </span>
                                        </div>
                                        <div *ngIf="useInvoice.usetotalDeducDone" class="withdrawable">
                                            <span class="accounting akeys">
                                                withdrawable
                                            </span>
                                            <span
                                                class="accounting finalPayout avalues">{{(useInvoice.useWithdrawableAmount).toLocaleString('en-IN')}}
                                            </span>
                                        </div>
                                        <div class="withDrawButton">
                                            <button
                                                *ngIf="useInvoice.useIsFundWithdrawable && useInvoice.useWithdrawableAmount > 0 "
                                                class="createWithdrawals" (click)="this.newWithdrawInvoice(useInvoice)"
                                                mat-stroked-button>
                                                Withdraw
                                            </button>
                                            <div class="warn" *ngIf="!useInvoice.useIsFundWithdrawable">
                                                <span class="hint" *ngIf="!useInvoice.useIsWithdrawalReqAlreadyPlaced">
                                                    Fund
                                                    cannot be
                                                    withdrawn before Withdrawal Date.
                                                </span>
                                                <br>
                                                <span class="hint" *ngIf="useInvoice.useWithdrawableAmount <=0">
                                                    Deductions are more than this invoice amount
                                                </span>
                                                <br>
                                                <span class="hint" *ngIf="useInvoice.useIsWithdrawalReqAlreadyPlaced">
                                                    Withdrawal
                                                    Request is in process
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="this.accountingInfo && this.accountingInfo.length == 0" class="noPlacedOrders">
            <div class="none">
                <span>No Orders Placed</span>
            </div>
        </div>
    </mat-card>
</div>