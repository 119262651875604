import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { FilterComponent } from '../components/filter/filter.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductsdialogmanagerService {
  private _categoryUniqueParam = new Subject<string[]>();
  categoryUniqueParam$ = this._categoryUniqueParam
  filterCategory: any;
  filterProduct: any
  filterState: any;
  //for dependent dropdown in create listing
  constructor(private dialog: MatDialog, private http: HttpClient) { }
  public checkCategory(data: any = []): Observable<any> {
    var dialogConfig = new MatDialogConfig()
    // dialogConfig.panelClass="add more prop";
    dialogConfig.data = data;
    let dialogRef: MatDialogRef<FilterComponent>

    dialogRef = this.dialog.open(FilterComponent, dialogConfig);
    return dialogRef.afterClosed();
  }

  // DeriveProductsofCategoryFilterApi(IdsOfCategories: string[]): Observable<any> {
  //   console.log('entered in service,products for category')
  //   let params1 = new HttpParams()
  //   IdsOfCategories.forEach(categoryName => {
  //     params1 = params1.append("categoryName", categoryName);
  //   });
  //   this.filterCategory = params1.toString()
  //   console.log("filterCategoryProducts:", this.filterCategory)
  //   return this.http.get(baseURL + 'api/products', { params: params1 });
  // }

  //create listing
  // GetProductsofCategory(IdsOfCategories: string[]) {
  //   console.log('entered in service,products for category')
  //   let params1 = new HttpParams()
  //   console.log('ids', IdsOfCategories, 'length', IdsOfCategories.length)
  //   IdsOfCategories.forEach(categoryUnique => {
  //     params1 = params1.append("categoryUnique", categoryUnique);
  //   });
  //   this.filterCategory = params1.toString()
  //   console.log("filterCategory:", this.filterCategory)
  //   this.http.get<{ query: string, ProductbyCategoryUnique: any }>(baseURL + 'api/products', { params: params1 })
  //     .pipe(map((productData) => {
  //       console.log('receive productdata', productData)
  //       return {
  //         FilterProductbyCategory: productData.ProductbyCategoryUnique.map(
  //           ProductsOfCategory => {
  //             return {
  //               _id: ProductsOfCategory._id,
  //               productSku: ProductsOfCategory.productSku,
  //               productUnique: ProductsOfCategory.productUnique,
  //               categoryUnique: ProductsOfCategory.categoryUnique,
  //               productName: ProductsOfCategory.productName,
  //               productScientificName: ProductsOfCategory.productScientificName,
  //               categoryName: ProductsOfCategory.categoryName,
  //               categoryId: ProductsOfCategory.categoryId,
  //               productStartPrice: ProductsOfCategory.productStartPrice,
  //               productPriceUnit: ProductsOfCategory.productPriceUnit,
  //               productImage: ProductsOfCategory.productImage,
  //               productLabel: ProductsOfCategory.productLabel,
  //               productDescription: ProductsOfCategory.productDescription,
  //               featured: ProductsOfCategory.featured,
  //               availability: ProductsOfCategory.availability,
  //               selected: ProductsOfCategory.selected,
  //               productState: ProductsOfCategory.productState,
  //               productZone: ProductsOfCategory.productZone,
  //               productUses: ProductsOfCategory.productUses,
  //               productVarietyItems: ProductsOfCategory.productVarietyItems,
  //             }
  //           }
  //         )
  //       }
  //     }))
  //     .subscribe((responseProdPackage) => {
  //       console.log('got prod package', responseProdPackage)
  //       this.productsOfCategory = responseProdPackage.FilterProductbyCategory
  //       this.productsUpdate.next({
  //         products: [...this.productsOfCategory]
  //       });
  //     });

  //   //    .subscribe((response) => {
  //   //     console.log('res',response)
  //   //    this.ProductsfromCategoriesfilterapi = response.filteredproductbycategoryunique
  //   //    console.log('Cat see service response', this.ProductsfromCategoriesfilterapi)
  //   //    this.allproducts = this.ProductsfromCategoriesfilterapi
  //   //  });
  // }
  //createlisting
  // getFilterProductUpdateListener() {
  //   return this.productsUpdate.asObservable();
  // }
  //createlisting
  // GetVarietyofProduct(IdsOfProduct: string[]) {
  //   console.log('entered in service,variety for product')
  //   let params1 = new HttpParams()
  //   console.log('ids', IdsOfProduct, 'length', IdsOfProduct.length)
  //   IdsOfProduct.forEach(productId => {
  //     params1 = params1.append("productId", productId);
  //   });
  //   this.filterProduct = params1.toString()
  //   console.log("filterProduct:", this.filterProduct)
  //   this.http.get<{ query: string, filteredVarietyByParentProductId: any }>(baseURL + 'api/varieties/byProductId', { params: params1 })
  //     .pipe(map((varietyData) => {
  //       console.log('receive varietydata', varietyData)
  //       return {
  //         FilterVarietybyProduct: varietyData.filteredVarietyByParentProductId.map(
  //           VarietiesOfProduct => {
  //             return {
  //               _id: VarietiesOfProduct._id,
  //               varietyName: VarietiesOfProduct.varietyName,
  //               varietyScientificName: VarietiesOfProduct.varietyScientificName,
  //               varietyUnique: VarietiesOfProduct.varietyUnique,
  //               productUnique: VarietiesOfProduct.productUnique,
  //               categoryUnique: VarietiesOfProduct.categoryUnique,
  //               categoryName: VarietiesOfProduct.categoryName,
  //               categoryId: VarietiesOfProduct.categoryId,
  //               parentProductName: VarietiesOfProduct.parentProductName,
  //               parentProductId: VarietiesOfProduct.parentProductId,
  //               varietyBulkPrice: VarietiesOfProduct.varietyBulkPrice,
  //               varietyBulkSize: VarietiesOfProduct.varietyBulkSize,
  //               varietyBulkUnit: VarietiesOfProduct.varietyBulkUnit,
  //               varietySamplePrice: VarietiesOfProduct.varietySamplePrice,
  //               varietySampleSize: VarietiesOfProduct.varietySampleSize,
  //               varietySampleUnit: VarietiesOfProduct.varietySampleUnit,
  //               varietyConsumerPrice: VarietiesOfProduct.varietyConsumerPrice,
  //               varietyConsumerSize: VarietiesOfProduct.varietyConsumerSize,
  //               varietyConsumerUnit: VarietiesOfProduct.varietyConsumerUnit,
  //               varietyImages: VarietiesOfProduct.varietyImages,
  //               varietyLabel: VarietiesOfProduct.varietyLabel,
  //               varietyDescription: VarietiesOfProduct.varietyDescription,
  //               featured: VarietiesOfProduct.featured,
  //               availability: VarietiesOfProduct.availability,
  //               selected: VarietiesOfProduct.selected,
  //               varietyState: VarietiesOfProduct.varietyState,
  //               varietyOrderTypeSelect: VarietiesOfProduct.varietyOrderTypeSelect,
  //               varietyCommercialUses: VarietiesOfProduct.varietyCommercialUses,
  //               varietyIndustrialUses: VarietiesOfProduct.varietyIndustrialUses,
  //               varietySource: VarietiesOfProduct.varietySource,
  //               varietyReview: VarietiesOfProduct.varietyReview,
  //             }
  //           }
  //         )
  //       }
  //     }))
  //     .subscribe((responseVarietyPackage) => {
  //       console.log('got var package', responseVarietyPackage)
  //       this.varietiesOfProduct = responseVarietyPackage.FilterVarietybyProduct
  //       this.varietiesUpdate.next({
  //         varieties: [...this.varietiesOfProduct]
  //       });
  //     });
  // }
  //createlisting
  // getFilterVarietyUpdateListener() {
  //   return this.varietiesUpdate.asObservable();
  // }
  // DeriveProductsofStateFilterApi(IdsOfStates: string[]): Observable<any> {
  //   console.log('entered in service, products for state')
  //   let params2 = new HttpParams()
  //   IdsOfStates.forEach(productState => {
  //     params2 = params2.append("productState", productState);
  //   });
  //   this.filterState = params2.toString()
  //   console.log("filterState:", this.filterState)
  //   return this.http.get(baseURL + 'api/products/state', { params: params2 });
  // }

  // DeriveVarietiesofCategoryFilterApi(IdsOfCategories: string[]): Observable<any> {
  //   console.log('entered in service,varieties for category')
  //   let params1 = new HttpParams()
  //   IdsOfCategories.forEach(categoryUnique => {
  //     params1 = params1.append("categoryUnique", categoryUnique);
  //   });
  //   this.filterCategory = params1.toString()
  //   console.log("filterCategory:", this.filterCategory)
  //   return this.http.get(baseURL + 'api/varieties/categories', { params: params1 });
  // }
  // DeriveVarietiesofStateFilterApi(IdsOfStates: string[]): Observable<any> {
  //   console.log('entered in service,varieties for state')
  //   let params2 = new HttpParams()
  //   IdsOfStates.forEach(productState => {
  //     params2 = params2.append("productState", productState);
  //   });
  //   this.filterState = params2.toString()
  //   console.log("filterState:", this.filterState)
  //   return this.http.get(baseURL + 'api/varieties/state', { params: params2 });
  // }
}
