import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-allstates',
  templateUrl: './allstates.component.html',
  styleUrls: ['./allstates.component.css']
})
export class AllstatesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
