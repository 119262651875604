<div *ngIf="isLoading" class="content-loading">
  <div id="loadingScreen">
    <div class="loadingText">
      <h1> Loading Your Listings </h1>
      <h3>Complete 10 Orders Successfully to improve Ratings</h3>
    </div>
    <mat-spinner></mat-spinner>
  </div>
</div>

<div class="contentMyListings">
  <div *ngIf="this.myListings.length>0 && !isLoading" fxLayout="row wrap" fxLayoutGap="16px grid">
    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" *ngFor="let listing of this.myListings">
      <mat-card [routerLink]="['/Listing/Place_Bulk_Order',listing._id,listing.categoryUnique,listing.productName]"
        class="mat-elevation-z4">
        <div class="listingcontainer">
          <div class="titleVariety">
            <div [routerLink]="['/Listing/Place_Bulk_Order',listing._id,listing.categoryUnique,listing.productName]"
              class="listingtitle">
              {{listing.title}}
            </div>
            <div>
              <mat-card-subtitle *ngIf="listing.isVarietyAvailable">
                {{listing.varietyName}}
              </mat-card-subtitle>
            </div>
          </div>
          <div class="spacerDiv"></div>
          <div class="listingDetails">
            <div class="listingPrice">
              <span>{{(listing.bulkPrice).toLocaleString('en-IN')}}
                <span style="font-size: 12px;">₹
                  / {{listing.useUnitShort}}</span>
              </span>
            </div>
            <div class="listingState">{{listing.packetSize}} Kg Packaging</div>
          </div>
        </div>
        <img [routerLink]="['/Listing/Place_Bulk_Order',listing._id,listing.categoryUnique,listing.productName]"
          mat-card-image [src]="listing.imageUrls[0]" />
        <!-- <img mat-card-image src="https://ik.imagekit.io/ikmedia/backlit.jpg"> -->
        <div id="subtitle">
          <div class="categoryProduct">
            <div id="categoryName"> {{listing.categoryName}} </div>
            <div id="productName"> {{listing.productName}} </div>
          </div>
          <div class="soldBy">
            <div> <span class="subtitle-spacer">Supplied from</span> <span class="listState">{{listing.state}}</span>
            </div>
            <div class="sellerName">{{listing.companyName}}</div>
          </div>
        </div>
        <mat-card-content>
          <p>
            {{listing.description}}
          </p>
          <mat-card-actions *ngIf="userIsAuthenticated &&  this.currentUserId===listing.listingCreatorID">
            <div class="cardActions">
              <div class="actionButtons">
                <button mat-stroked-button color="primary" [routerLink]="['/editListing',listing._id]">Edit</button>
                <button mat-stroked-button color="warn" (click)="deletelistingDialog(listing._id)">Delete</button>
              </div>
              <div class="cardActions">
                <span class="listingState">{{listing.state}}</span>
              </div>
              <!-- <button mat-stroked-button color="warn" (click)="onDeleteListing(listing._id)" #delete>Delete</button> -->
              <!-- <button mat-stroked-button color="warn" (click)="deletelistingSnackBar('Listing will be deleted','Delete')">Delete</button> -->
              <!-- #Hidden  -->
              <!-- <div class="discount">
                              <button mat-stroked-button
                                  (click)="openDiscountDialog(listing.id,listing.listingCreatorID,listing.productName,listing.title,listing.bulkPrice,listing.bulkQuantity,listing.imageUrls,listing.listingCreator)"
                                  color="primary">Ask
                                  discount</button>
                          </div>
                          <div class="bidUp">
                              <button mat-stroked-button
                                  (click)="openBidDialog(listing.id,listing.listingCreatorID,listing.productName,listing.title,listing.bulkPrice,listing.bulkQuantity,listing.imageUrls,listing.listingCreator)"
                                  color="primary">Place
                                  Bid</button>
                          </div> -->
            </div>
          </mat-card-actions>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div *ngIf=" this.noListingFound">
    <h2 [routerLink]="['/createListing']">{{noListings}}</h2>
  </div>
</div>