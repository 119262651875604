import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { AllstatesService } from 'src/app/services/allstates.service';
import { MatTableDataSource } from '@angular/material/table';
import { MypseoService } from 'src/app/services/mypseo.service';
// import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
// import { Observable, Subscription } from 'rxjs';
// import { map, shareReplay } from 'rxjs/operators';
interface userCurrentLocation {
  userPincode: string,
  userCity: string,
  userState: string
}
@Component({
  selector: 'app-mandipricespage',
  templateUrl: './mandipricespage.component.html',
  styleUrls: ['./mandipricespage.component.css']
})
export class MandipricespageComponent implements OnInit {
  districtIs: string
  iscurrentLocation: boolean = false;
  openPanel: boolean = false
  currentLocation: userCurrentLocation;
  selectedPincode: string = '';
  stateName: string;
  selectedState: string;
  districtName: string;
  pincodeFullInfo: any = [];
  isLoading: boolean = false;
  mandiCommodities: any
  dateToday: Date = new Date()
  useStates: string[] =
    [
      // "Andaman and Nicobar Islands",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chattisgarh",
      "Dadra and Nagar Haveli",
      "Daman and Diu",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      // "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "NCT of Delhi",
      "Nagaland",
      "Odisha",
      "Puducherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttrakhand",
      "West Bengal",
    ]
  allCommoditiesOfState: any;
  mandiTableCols: string[] = [];
  checkForState: string;

  constructor(
    private stateService: AllstatesService,
    private mySeo: MypseoService,
  ) { }

  ngOnInit(): void {
    this.mySeo.updateTitle('Real-Time Mandi Commodity Rates | Myapaar Price Transparency')
    this.mySeo.updateDescription('Discover real-time commodity rates from Mandis near you. Myapaar ensures price transparency for bulk procurement, sourcing raw materials crucial for your processing and manufacturing needs. Trust data.gov.in for daily updates on highest, lowest, and most sold prices—your key to informed decisions.')
  }
  async getMyPincodeDetails() {
    try {
      if (this.selectedPincode.length == 6) {
        const pincodeInfo = await this.stateService.getPincodeDetails(this.selectedPincode)
        this.pincodeFullInfo = pincodeInfo.pinCodeInfo
        this.stateName = this.pincodeFullInfo[0].State
        this.districtName = this.pincodeFullInfo[0].District
        // this.authService.setCurrentLocation(this.selectedPincode, this.districtName, this.stateName)
        this.getAllCommodities(this.stateName)
        this.openPanel = false
      }
    }
    catch (e) {
      throw e
    }

  }

  changedStateName() {
    this.stateName = null
    this.selectedPincode = ''
    this.getAllCommodities(this.selectedState)
  }


  getAllCommodities(stateName: string) {
    this.isLoading = true
    this.stateService.getAllCommodityRates(stateName).then(comms => {
      this.allCommoditiesOfState = comms.allComms
      this.mandiTableCols = ["comm", "variety", "district", "market", "min", "modal", "max"]
      this.mandiCommodities = new MatTableDataSource(this.allCommoditiesOfState)
      this.isLoading = false
    })
  }

  applySearchInMandi(event: Event) {
    try {
      const filterValue = (event.target as HTMLInputElement).value;
      this.mandiCommodities.filter = filterValue.trim().toLowerCase();
    }
    catch (e) {
    }
  }

}
