import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/datamodels/MainProductModel';
import { ProductService } from 'src/app/services/product.service';
import { Router } from '@angular/router';
import { HandleListingsService } from 'src/app/services/handle-listings.service';
import { ListingModel } from 'src/app/datamodels/ListingModel';

@Component({
  selector: 'app-featuredlistingcategory',
  templateUrl: './featuredlistingcategory.component.html',
  styleUrls: ['./featuredlistingcategory.component.css']
})
export class FeaturedlistingcategoryComponent implements OnInit {
  @Input() appBannersfeaturedListings: string[];

  allfeaturedproducts: Product[] = [];
  AllProducts: Product[] = []
  HoldFeature: Product[] = []
  featuredProd: Product;
  featureTrue: boolean;
  toggleProperty = false;
  holdapiproducts: Product[] = []
  isLoading: boolean = false;
  recentListings: ListingModel[] = []
  featuredListings: ListingModel[] = [];

  constructor(public productService: ProductService,
    private handlingListing: HandleListingsService,
    private router: Router) { }

  ngOnInit(): void {
    this.loadRecentListings()
  }

  loadRecentListings() {
    this.handlingListing.getAllListings().subscribe(res => {
      this.isLoading = true
      if (res) {
        const key1 = '_id';
        const last10UniqueListings = [...new Map(res.map(listing =>
          [listing[key1], listing])).values()]

        const key2 = 'productName';
        this.featuredListings = [...new Map(last10UniqueListings.map(listing =>
          [listing[key2], listing])).values()].slice(-12)
        this.isLoading = false
      }
    })
  }


  //ClickMainProduct
  onSelectProduct(productUnique: string) {
    this.router.navigate(['/viewVarietyItems', productUnique], { queryParamsHandling: "preserve" })
      .then
  }
}
