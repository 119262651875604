<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Returns & Refunds </h1>
            <h3> Easy Returns </h3>
            <h3>Fast Refunds </h3>
        </div>
        <mat-spinner></mat-spinner>
    </div>
</div>
<div class="contentReturn">
    <div *ngIf="this.currentReturnOrderwithId && !isLoading">
        <div *ngIf="this.currentReturnOrderwithId.returnStatus==='Active'" class=" action">
            <div class="buttonset">
                <div class="act report">
                    <button [routerLink]=" ['/reportRETproblem',this.currentReturnOrderwithId._id]" mat-raised-button
                        style="background-color: #171717; font-weight: 450;color: white;">
                        <span>Report Problem</span>
                    </button>
                </div>
                <div class="act lpo">
                    <button [routerLink]="['/viewPurchaseOrder',this.currentReturnOrderwithId.purchaseOrderID]"
                        mat-raised-button style=" background-color:
                        rgb(153, 106, 5); font-weight: 450;color: white;">
                        <span>Linked Purchase Order</span>
                    </button>
                </div>
            </div>

            <div class="buttonset">
                <div class="act allShow">
                    <button routerLink="/viewAllReturnOrders" mat-raised-button
                        style="background-color: rgba(151, 150, 150, 0.733); font-weight: 450;color: white;">
                        <span>All Return Orders</span>
                    </button>
                </div>
                <div *ngIf="this.buyerAccountType" class="act cancel">
                    <button mat-raised-button style="background-color: rgb(193, 21, 21); font-weight: 450;color: white;"
                        (click)="cancelReturnOrderStatus(this.currentReturnOrderwithId)">
                        <span>Cancel Return</span>
                    </button>
                </div>
            </div>

        </div>

        <div *ngIf="this.currentReturnOrderwithId.returnStatus==='Returned'" class=" action">
            <!-- Delivered PO cannot have download return, generate Inv or cancel buttons, just download PO and allPOs buttons -->
            <!-- for a seller-asBuyer  , delivered PO will have download return , download PO, viewAllPO, raiseDisputeButtons -->
            <!-- for a seller-asSeller  , delivered PO will have download return , download PO,generate return, viewAllPO, raiseDisputeButtons -->
            <div class="buttonset">
                <div class="act download">
                    <a href="{{this.currentReturnOrderwithId.s3ReturnOrderLink}}" target="_blank"
                        rel="noopener noreferrer" mat-raised-button
                        style="background-color: rgb(35, 130, 246); font-weight: 450;color: white;">
                        <span>Download PDF</span>
                    </a>
                </div>

                <div class="act lpo">
                    <button [routerLink]="['/viewPurchaseOrder',this.currentReturnOrderwithId.purchaseOrderID]"
                        mat-raised-button style=" background-color:
                        rgb(153, 106, 5); font-weight: 450;color: white;">
                        <span>Linked Purchase Order</span>
                    </button>
                </div>
            </div>

            <div class="buttonset">
                <div class="act allShow">
                    <button routerLink="//viewAllReturnOrders" mat-raised-button
                        style="background-color: rgba(151, 150, 150, 0.733); font-weight: 450;color: white;">
                        <span>All Return Orders</span>
                    </button>
                </div>
                <div class="act dispute">
                    <button [routerLink]=" ['/reportRETproblem',this.currentReturnOrderwithId._id]" mat-raised-button
                        style="background-color: #171717; font-weight: 450;color: white;">
                        <span>Report Problem</span>
                    </button>
                </div>
            </div>

        </div>

        <div *ngIf="this.currentReturnOrderwithId.returnStatus==='Cancelled'" class=" action">
            <!-- Cancelled PO cannot have download return, generate Inv or cancel buttons, just download PO and allPOs buttons -->
            <div class="buttonset">
                <button [routerLink]="['/viewPurchaseOrder',this.currentReturnOrderwithId.purchaseOrderID]"
                    mat-raised-button style=" background-color:
                rgb(153, 106, 5); font-weight: 450;color: white;">
                    <span>Linked Purchase Order</span>
                </button>
            </div>
            <div class="buttonset">
                <div class="act allShow">
                    <button routerLink="/viewAllReturnOrders" mat-raised-button
                        style="background-color: rgba(151, 150, 150, 0.733); font-weight: 450;color: white;">
                        <span>All Return Orders</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="issuesFound" *ngIf="this.issueForPOExist">
            <button routerLink="/viewIssues" mat-stroked-button color="primary" class="issueCount">
                {{this.issuesFound.length}} Issues Exist
            </button>
        </div>
        <h1 class="title">
            <p>
                <span>
                    Return Order Detail -V{{this.currentReturnOrderwithId.returnVersionNumber}}
                </span>
            </p>

        </h1>
        <div class="currentInvoiceOrder">
            <div class="sec retnDetails">
                <div>
                    <p class="key POI">
                        Return Order ID/Number
                    </p>
                    <p class="value-L POI">
                        {{this.currentReturnOrderwithId.returnNumber}}
                    </p>
                </div>

                <div>
                    <p class="key POS"> Status</p>

                    <p class="value-R POS">
                        <span style="color: rgb(200, 7, 7);"
                            *ngIf="this.currentReturnOrderwithId.returnStatus==='Cancelled'">
                            {{this.currentReturnOrderwithId.returnStatus}}
                        </span>
                        <span style="color: rgb(1, 142, 46);"
                            *ngIf="this.currentReturnOrderwithId.returnStatus==='Active'">
                            {{this.currentReturnOrderwithId.returnStatus}}
                        </span>
                        <span style="color: rgb(0, 34, 229);"
                            *ngIf="this.currentReturnOrderwithId.returnStatus==='Picked'">
                            {{this.currentReturnOrderwithId.returnStatus}}
                        </span>
                        <span style="color: rgb(179, 173, 2);"
                            *ngIf="this.currentReturnOrderwithId.returnStatus==='Shipped'">
                            {{this.currentReturnOrderwithId.returnStatus}}
                        </span>
                        <span style="color: rgb(167, 3, 136);"
                            *ngIf="this.currentReturnOrderwithId.returnStatus==='Returned'">
                            {{this.currentReturnOrderwithId.returnStatus}}
                        </span>
                    </p>
                </div>

            </div>

            <div class="sec retnValueAmount">
                <div>
                    <p class="key POP">Return Amount</p>
                    <p class="value-L POP">{{this.currentReturnOrderwithId.refundValueOnReturn | number :
                        '1.2-2'}} ₹</p>
                </div>
                <div>
                    <p class="key POQ">Return Quantity</p>
                    <p class="value-R POQ">
                        {{this.currentReturnOrderwithId.returnQuantity}} ql.</p>
                </div>
            </div>
            <div class="sec retnDates">
                <div>
                    <p class="key PODG">Return Created Date</p>
                    <p class="value-L PODG"> {{this.currentReturnOrderwithId.dateOfRETURNGenerated
                        | date}}</p>
                </div>
                <div *ngIf="this.currentReturnOrderwithId.returnStatus !== 'Paid'">
                    <p class="headingR PODE">Return Reason</p>
                    <p class="value-R PODE"> {{this.currentReturnOrderwithId.returnReason}}</p>
                </div>
            </div>
            <div class="sec returns">
                <div>
                    <p class="key retns">P.O ID</p>
                    <p class="value-L retns">{{this.currentReturnOrderwithId.purchaseOrderNumber}}</p>
                </div>
                <div>
                    <p class="headingR retns">P.O Amount</p>
                    <p class="value-R retns">{{this.currentReturnOrderwithId.orderValueIncludingTax | number :
                        '1.2-2'}} ₹</p>
                </div>
            </div>

            <div class="sec returns">
                <div>
                    <p class="key retns">P.O Status</p>
                    <p class="value-L retns">{{this.currentReturnOrderwithId.poStatus}}</p>
                </div>
                <div>
                    <p class="key retns">P.O Quantity</p>
                    <p class="value-R retns">{{this.currentReturnOrderwithId.orderQuantity}} ql.</p>
                </div>
            </div>


            <div class="sec returns">
                <div>
                    <p class="key retns">Refund Status</p>
                    <p style="color: rgb(10, 102, 10);" *ngIf="this.currentReturnOrderwithId.paymentStatus"
                        class="value-L retns">Paid</p>
                    <p style="color: rgb(34, 33, 1);" *ngIf="!this.currentReturnOrderwithId.paymentStatus"
                        class="value-L retns">Pending</p>
                </div>
                <div>
                    <p class="key retns">Refund Amount</p>
                    <p style="color: rgb(10, 102, 10);" *ngIf="this.currentReturnOrderwithId.paymentStatus"
                        class="value-R retns">TBD</p>
                    <p style="color: rgb(34, 33, 1);" *ngIf="!this.currentReturnOrderwithId.paymentStatus"
                        class="value-R retns">Pending</p>

                </div>
            </div>

            <mat-divider> </mat-divider>
            <br>
            <div class="sec addressDetails">

                <div class="vendor_buy_address_details">
                    <div class="vendorDetail">
                        <p class="headingL sellerAddress">Return to Seller
                        </p>
                        <span class="highlightL businessName">{{this.currentReturnOrderwithId.sellerDetails.companyName
                            }} </span>
                        <span class="value-L sellerName">{{this.currentReturnOrderwithId.sellerDetails.firstname}}
                            {{this.currentReturnOrderwithId.sellerDetails.lastname}} </span>
                        <span class="value-L addressBuildingINfo ">
                            {{this.currentReturnOrderwithId.sellerDetails.addressBuildingNameAndFloor
                            }}</span>
                        <span class="value-L sellerPhone">{{this.currentReturnOrderwithId.sellerDetails.phone
                            }}</span>
                        <span class="value-L sellerEmail">{{this.currentReturnOrderwithId.sellerDetails.email
                            }}</span>
                        <div class="value-L sellerStreetPincode">
                            <span> {{this.currentReturnOrderwithId.sellerDetails.addressLocality}},&nbsp;</span>
                            <span> {{this.currentReturnOrderwithId.sellerDetails.pinCode}}&nbsp;</span>
                        </div>
                        <div class="value-L sellersCityState">
                            <span> {{this.currentReturnOrderwithId.sellerDetails.city}},&nbsp;</span>
                            <span> {{this.currentReturnOrderwithId.sellerDetails.state}} </span>
                        </div>
                        <div *ngIf="this.currentReturnOrderwithId.sellerDetails.isGSTVerified" class="sellerGSTVerify">
                            <p class="value-L sellerGST">{{this.currentReturnOrderwithId.sellerDetails.GST_Id }}</p>
                            <p class="value-L sellerGST"> GST Verified <mat-icon>check_small</mat-icon></p>
                        </div>
                        <div *ngIf="!this.currentReturnOrderwithId.sellerDetails.isGSTVerified"
                            class="value-L sellerGSTVerify">
                            <p> GST Verification Pending <mat-icon>error_outline_small</mat-icon></p>
                        </div>
                    </div>
                    <div class="buyerDetail">
                        <div class="buyerAddressDetails">
                            <p class="headingR buyerAddress">Delivered to Buyer</p>
                            <span class="highlightR businessName">
                                {{this.currentReturnOrderwithId.buyerDetails.buyerCompanyName
                                }}
                            </span>
                            <span
                                class="value-R buyerName">{{this.currentReturnOrderwithId.buyerDetails.buyerName}}</span>
                            <span class="value-R addressBuildingINfo ">
                                {{this.currentReturnOrderwithId.buyerDetails.buyerAddress.buildingName
                                }}</span>
                            <span class="value-R buyerPhone">
                                {{this.currentReturnOrderwithId.buyerDetails.buyerContactDetails.phone
                                }}</span>
                            <span class="value-R buyerEmail">
                                {{this.currentReturnOrderwithId.buyerDetails.buyerContactDetails.email
                                }}</span>
                            <div class="value-R buyerStreetPincode">
                                <span>
                                    {{this.currentReturnOrderwithId.buyerDetails.buyerAddress.streetName}},&nbsp;</span>
                                <span>{{this.currentReturnOrderwithId.buyerDetails.buyerAddress.pinCode}}</span>
                            </div>
                            <div class="value-R buyerCityState">
                                <span class="value-R buyerCity">
                                    {{this.currentReturnOrderwithId.buyerDetails.buyerAddress.city}},&nbsp;</span>
                                <span class="value-R buyerState">
                                    {{this.currentReturnOrderwithId.buyerDetails.buyerAddress.state }}</span>
                            </div>
                        </div>
                        <div class="shipToAddress">
                            <span class="headingR shipToAddress">Pickup from Address</span>
                            <span class="highlightR shipToName">{{this.currentReturnOrderwithId.buyerDetails.buyerName}}
                            </span>
                            <span class="value-R addressBuildingINfo ">
                                {{this.currentReturnOrderwithId.shipToBuyerLocation.buildingName}}</span>
                            <div class="value-R shipToStreetPincode">
                                <span>
                                    {{this.currentReturnOrderwithId.shipToBuyerLocation.streetName}},&nbsp;</span>
                                <span> {{this.currentReturnOrderwithId.shipToBuyerLocation.pinCode}}</span>
                            </div>
                            <div class="value-R shipToCityState">
                                <span> {{this.currentReturnOrderwithId.shipToBuyerLocation.city}},&nbsp;</span>
                                <span> {{this.currentReturnOrderwithId.shipToBuyerLocation.state}}</span>
                            </div>
                            <span class="value-R shipToLandmark">
                                {{this.currentReturnOrderwithId.shipToBuyerLocation.landMark}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sec itemDetails">
                <div class="Items">
                    <h1>Item Details</h1>
                    <div class="item" *ngFor="let item of this.itemDetailsofRETn">
                        <div class="itemDescription">
                            <h2 class="itemTitle">{{item.itemTitle}}</h2>
                            <div class="itemDet">
                                <div class="details">
                                    <div class="value itemProdwithVar" *ngIf="item.productVariety!==''">
                                        {{item.productCategory}} |
                                        {{item.productName}} |
                                        {{item.productVariety}}
                                    </div>
                                    <div class="value itemProdnoVar" *ngIf="item.productVariety===''">
                                        {{item.productCategory}}
                                        |
                                        {{item.productName}}
                                    </div>
                                    <div class="value itemPackaging" *ngIf="item.optedPackagingType">
                                        {{item.optedPackagingType.packagingType}}
                                    </div>
                                </div>
                                <div class="packagingImg">
                                    <a class="packImg" [href]="item.optedPackagingType.packagingImg" target="_blank"><img
                                            [src]="item.optedPackagingType.packagingImg" /></a>
                                    <a class="packdesign" [href]="item.optedPackagingType.packagingImg" target="_blank">Packaging
                                        Design</a>
                                </div>
                            </div>
                            <div class="itmk orderQuant">Ordered Quantity
                                <span *ngIf="item.itemQuantity > 1" class="value orderQuant">{{item.itemQuantity}}
                                    ql.</span>
                                <span *ngIf="item.itemQuantity < 1" class="value orderQuant">{{item.itemQuantity *
                                    100000}}
                                    gm.</span>
                                <span class="value itemPriceUnit"> {{item.itemPricePerUnit}} ₹ / ql.</span>
                            </div>
                            <div class="itmk totalPriceOfItemOrdered">Item Price incl Taxes
                                <!-- <span class="value totalPriceOfItemOrdered">{{item.itemPricePerUnit*item.itemQuantity}} -->
                                <span class="value totalPriceOfItemOrdered">{{(item.itemPriceWithTaxes
                                    ).toLocaleString('en-IN')}}
                                    ₹</span>
                            </div>
                        </div>
                        <mat-divider> </mat-divider>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>