import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { BankDetails } from 'src/app/datamodels/groupedmodel/bankDetails';
import { ItemDetails } from 'src/app/datamodels/groupedmodel/itemDetails';
import { VersionModel } from 'src/app/datamodels/groupedmodel/versionModel';
import { InvoiceModel } from 'src/app/datamodels/invoiceOrderModel/invoiceDetailsModel';
import { VersionInvoicing } from 'src/app/datamodels/invoiceOrderModel/invoiceVersionDataModel';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { ReturnModel } from 'src/app/datamodels/returnOrders/returnDetailsModel';
import { VersionReturning } from 'src/app/datamodels/returnOrders/returnVersions';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { HandlereturnService } from 'src/app/services/handlereturn.service';
import { UtilsService } from 'src/app/services/utils.service';
interface returnReasonInterface {
  value: number;
  viewValue: string;
  checked: boolean;
}
interface itemsForReturnInterface {
  itemCategory: string;
  itemCategoryId: string;
  itemProduct: string;
  itemProductId: string;
  itemVariety: string;
  itemVarietyId: string;
  itemlistingId: string;
  itemPricePerUnit: number;
  itemQuantity: number;
  itemTotalPrice: number;
  itemTotalPriceWithTaxes: number;
  itemTaxes: number;
  optedPaymentTerms: string;
  itemTitle: string;
  checked: boolean;
}
@Component({
  selector: 'app-returnorders',
  templateUrl: './returnorders.component.html',
  styleUrls: ['./returnorders.component.css']
})
export class ReturnordersComponent implements OnInit {
  purchaseOrderId: string;
  private currentUserId: string;
  private loginUserInfo: any;
  poData: PurchaseOrderModel;
  allComplete: boolean = false;
  currentPurchaseOrderwithId: PurchaseOrderModel;
  invoiceVersions: VersionModel[] = []
  itemsForInvoice = []
  returnForm: FormGroup;
  returnReason: returnReasonInterface[] = [
    { value: 1, viewValue: 'Items Of Low Quality', checked: false },
    { value: 5, viewValue: 'Incorrect Items Delivered', checked: false },
    { value: 5, viewValue: 'Incorrect Packaging', checked: false },
    { value: 3, viewValue: 'Items Not Required Anymore', checked: false },
    { value: 4, viewValue: 'Cannot Pay at this moment', checked: false },
  ];
  itemsInPoForReturn: itemsForReturnInterface[] = []
  isLoading: boolean = false;
  private checkedItemDetails: ItemDetails[] = []
  itemsNotSelectedShowError: boolean = false
  itemsSelected: itemsForReturnInterface[] = [];
  atleastOneItemSelected: boolean = false;
  displayTotalPriceInclTaxForSelectedItems: number;
  createdInvoiceOrder: InvoiceModel;
  sumOfAllInvoiceAmountsRaisedForPO: number;
  latestVersionReturn: VersionReturning;
  allItemsCoveredForInvoicing: boolean = false
  atleastOneInvoiceVersionDelivered: boolean = true;
  isInvoiceStatusDelivered: boolean = true;
  isPoStatusDelivered: boolean = true;
  completeOrderProperlyDelivered: boolean = false;
  givenReturnCondition: string = ''
  isGivenReturnConditionChecked: boolean = false
  createdReturnOrder: ReturnModel;
  isPOreturnable: boolean = true;
  totalRefundApplicable: number;
  numberOfInvoicesDelivered: number;
  numberOfInvoicesPaid: number;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private authService: AuthService,
    private hpos: HandlePurchaseOrderService,
    private hrs: HandlereturnService,
    private usc: UtilsService,) { }
  formErrors = {
    'selectItems': 'Select Items for Return',
    'accountName': 'Account Name is required',
    'accountNumber': 'Account Number is required',
    'accountIFSC': 'IFSC Code is required',
    'accountBankBranch': 'Bank Branch Name is required',
    'paymentDeadline': 'Set Payment Deadline for Invoice',
    'paymentRemarks': 'Add Remarks'
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.returnForm = new FormGroup({
      // selectItems: new FormControl(null),
      isGivenReturnConditionChecked: new FormControl(true, {
        validators: [Validators.required]
      }),
      accountName: new FormControl(null, {
        validators: [Validators.required, Validators.maxLength(100)]
      }),
      accountNumber: new FormControl(null, { validators: [Validators.required, Validators.minLength(8), Validators.maxLength(25)] }),
      accountIFSC: new FormControl(null, { validators: [Validators.required, Validators.minLength(11), Validators.maxLength(11)] }),
      accountBankBranch: new FormControl(null, { validators: [Validators.required] }),
      returnRemarks: new FormControl(null, { validators: [Validators.required, Validators.maxLength(50)] }),
    })
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('purchaseOrderId')) {
        this.purchaseOrderId = paramMap.get('purchaseOrderId')
        this.getcurrentPurchaseOrder(this.purchaseOrderId)
      }
    })
  }

  async getcurrentPurchaseOrder(purchaseOrderId: string) {
    try {
      this.hpos.getSinglePurchaseOrder(purchaseOrderId).subscribe(purchaseOrder => {
        this.currentPurchaseOrderwithId = purchaseOrder
        this.totalRefundApplicable = this.currentPurchaseOrderwithId.amountPaidAtCheckout / 2
        //total days remaining for payment for each item

        // no other invoice version in PO - First Version of INVO - so list all items of PO
        // if (this.currentPurchaseOrderwithId.invoiceVersions &&
        //   this.currentPurchaseOrderwithId.invoiceVersions.length === 0) {
        //   // create new invoice - invoiceVersion is not available so all items present that were in purchase order

        //   this.currentPurchaseOrderwithId.itemDetails.forEach((item) => {
        //     let totalDaysInCT: number = 0

        //     item.optedPaymentTerms.creditTerms === "Instant Payment" ? totalDaysInCT = 0 - dpsp :
        //       totalDaysInCT = totalDaysInCT + parseInt(item.optedPaymentTerms.creditTerms.substring(0, item.optedPaymentTerms.creditTerms.indexOf(' '))) - dpsp
        //     //create item objects to select to create Invoice
        //     const newitemInPoForInvoice = {
        //       itemCategory: item.productCategory,
        //       itemCategoryId: item.categoryId,
        //       itemProduct: item.productName,
        //       itemProductId: item.productId,
        //       itemVariety: item.productVariety,
        //       itemVarietyId: item.varietyId,
        //       itemlistingId: item.listingId,
        //       itemPricePerUnit: item.itemPricePerUnit,
        //       itemQuantity: item.itemQuantity,
        //       itemTotalPrice: item.itemTotalValue,
        //       itemTotalPriceWithTaxes: item.itemPriceWithTaxes,
        //       itemTaxes: item.itemTaxes,
        //       optedPaymentTerms: item.optedPaymentTerms.creditTerms,
        //       daysLeftInPaymentSincePOCreated: totalDaysInCT,
        //       itemTitle: item.itemTitle,
        //       checked: false
        //     }
        //     this.itemsInPoForReturn.push(newitemInPoForInvoice)
        //     this.isLoading = false;

        //   })
        //   return this.currentPurchaseOrderwithId

        // }

        this.isProperlyDelivered(this.currentPurchaseOrderwithId)
        // invoiceVersion in PO - last INVOversion has list of all itemIds remaining for INVO - so filter same from PO and show
        if (this.currentPurchaseOrderwithId.invoiceVersions &&
          this.currentPurchaseOrderwithId.invoiceVersions.length &&
          this.currentPurchaseOrderwithId.invoiceVersions.length > 0
          && this.completeOrderProperlyDelivered) {
          // reviseInvoice - select items which were not selected in first invoice
          // filter out items with listing ids which do not match with listing ids in invoiceversion 

          const invoicesWithItemsInvoiceDelivered = this.currentPurchaseOrderwithId.invoiceVersions.filter(eachVersion => {
            return eachVersion.invoiceStatus === 'Delivered'
          })

          this.numberOfInvoicesDelivered = invoicesWithItemsInvoiceDelivered.length
          const invoicesWithItemsInvoicePaid = this.currentPurchaseOrderwithId.invoiceVersions.filter(eachVersion => {
            return eachVersion.invoiceStatus === 'Paid'
          })
          this.numberOfInvoicesPaid = invoicesWithItemsInvoicePaid.length

          const listingIdsOfItemsInvoDelivered = invoicesWithItemsInvoiceDelivered.map(deliveredItems => {
            return deliveredItems.itemsAddedForInvoice
          })
          let flatArrayofallItemIdsForallDelivered = [].concat(...listingIdsOfItemsInvoDelivered)
          //show items for which invoice is not raised
          // allListingItemIdsForWhichInvoCreated -> last invoice version contains this info, since we filter and append a fresh list 
          // of all remaining items in last version while creating invoice order and add this to purchsaeOrder

          const listingIDsOfReturns = this.currentPurchaseOrderwithId.returnVersions.map(allReturns => {
            return allReturns.itemsAddedForReturn
          })

          let flatArrayofallItemIdsForallReturns = []
          flatArrayofallItemIdsForallReturns = [].concat(...listingIDsOfReturns)
          const itemsFromPODelivered = this.currentPurchaseOrderwithId.itemDetails.filter(eachItem => {
            return flatArrayofallItemIdsForallDelivered.includes(eachItem.listingId)
          })

          const itemsDeliveredButReturnsNotRaised = itemsFromPODelivered.filter(eachItem => {
            return !flatArrayofallItemIdsForallReturns.includes(eachItem.listingId)
          })
          itemsDeliveredButReturnsNotRaised && itemsDeliveredButReturnsNotRaised.length === 0 ? this.isPOreturnable = false : this.isPOreturnable = true
          !this.isPOreturnable ? this.isLoading = false :
            itemsDeliveredButReturnsNotRaised.forEach((item) => {
              //create item objects to select to create Invoice
              const newitemInPoForInvoice = {
                itemCategory: item.productCategory,
                itemCategoryId: item.categoryId,
                itemProduct: item.productName,
                itemProductId: item.productId,
                itemVariety: item.productVariety,
                itemVarietyId: item.varietyId,
                itemlistingId: item.listingId,
                itemPricePerUnit: item.itemPricePerUnit,
                itemQuantity: item.itemQuantity,
                itemTotalPrice: item.itemTotalValue,
                itemTotalPriceWithTaxes: item.itemPriceWithTaxes,
                itemTaxes: item.itemTaxes,
                optedPaymentTerms: JSON.stringify(item.optedPaymentTerms.creditTermDays),
                itemTitle: item.itemTitle,
                checked: false
              }
              this.itemsInPoForReturn.push(newitemInPoForInvoice)
              this.isLoading = false;

            })


          // after all items are raised
          // if (itemsFromPODelivered && itemsFromPODelivered.length === 0) {
          //   this.allItemsCoveredForInvoicing = true
          //   this.isLoading = false
          //   return this.allItemsCoveredForInvoicing
          // }

          return this.currentPurchaseOrderwithId
        }

      })
      // if (this.currentPurchaseOrderwithId.invoiceIds.length === 0 && this.currentPurchaseOrderwithId.invoiceNumbers.length === 0
      //   && !this.currentPurchaseOrderwithId.isInvoiceGenerated) {
      //   // no invoiceIds , or invoicenumbers, also invoiceGeneratedisFalse , so not even 1st version of invoice is made rightnow
      //   this.generatenewReturnForPO(this.currentPurchaseOrderwithId)
      // }
      // else {
      //   this.updateReviseInvoice(this.currentPurchaseOrderwithId)

      // }
    }
    catch (e) { throw e }
  }

  isProperlyDelivered(currentPurchaseOrder: PurchaseOrderModel) {
    // all versions are delivered
    this.atleastOneInvoiceVersionDelivered = currentPurchaseOrder.invoiceVersions.some(versions => {
      return versions.invoiceStatus === 'Delivered'
    })
    // main Invoice Status in PO delivered
    currentPurchaseOrder.invoiceStatus === 'Delivered' ? this.isInvoiceStatusDelivered = true : this.isInvoiceStatusDelivered = false
    currentPurchaseOrder.poStatus === 'Delivered' ? this.isPoStatusDelivered = true : this.isPoStatusDelivered = false
    if (this.atleastOneInvoiceVersionDelivered && this.isInvoiceStatusDelivered && this.isPoStatusDelivered) {
      this.completeOrderProperlyDelivered = true
      return
    }
  }

  updateAllComplete() {
    try {
      this.allComplete = this.itemsInPoForReturn != null && this.itemsInPoForReturn.every(t => t.checked);
    }
    catch (e) { throw e }
  }

  someComplete(): boolean {

    try {
      if (this.itemsInPoForReturn == null) {
        return false;
      }
      const check = this.atleastOneItemSelected && !this.allComplete;
      this.atleastOneItemSelected = this.itemsInPoForReturn.filter(t => t.checked).length > 0
      if (this.atleastOneItemSelected) {
        this.itemsSelected = this.itemsInPoForReturn.filter(t => t.checked)
        this.givenReturnCondition = `Total ${this.itemsSelected.length} bags, 1 of each item opened and checked, rest is packed and sealed as delivered by seller.`
        // refundamount
        // this.displayTotalPriceInclTaxForSelectedItems = this.itemsSelected.reduce((sum, item) => sum + item.itemTotalPriceWithTaxes, 0)
      }
      this.atleastOneItemSelected ? this.itemsNotSelectedShowError = false : this.itemsNotSelectedShowError = true
      return check
    }
    catch (e) {
      throw e
    }


  }

  setAll(completed: boolean) {
    try {
      this.allComplete = completed;
      if (this.itemsInPoForReturn == null) {
        return;
      }
      this.itemsInPoForReturn.forEach(t => (t.checked = completed));
    }
    catch (e) { throw e }
  }


  createReturn(currentPurchaseOrderwithId: PurchaseOrderModel) {

    try {
      this.isLoading = true

      //update new invoice version id doc in backend
      for (let items in this.itemsInPoForReturn) {
        let matchItemChecked = []
        //matchItemChecked filters from items in PO and items of invoice selection - condition - listingId same & mat-select is true
        if (this.itemsInPoForReturn[items].checked) {
          matchItemChecked = currentPurchaseOrderwithId.itemDetails.filter((eachItem) => {
            return eachItem.listingId === this.itemsInPoForReturn[items].itemlistingId
          })
        }
        //gives all items selected
        this.checkedItemDetails = [...this.checkedItemDetails, ...matchItemChecked];
      }
      // const totalSum = allItemsInPO.reduce((sum, item) => sum + parseInt(item.price_with_taxes, 10), 0);
      const totalReturnQuantityOfSelected = this.checkedItemDetails.reduce((sum, item) => sum + item.itemQuantity, 0)
      const valueWithTaxOfSelectedItemsForReturn = this.checkedItemDetails.reduce((sum, item) => sum + item.itemPriceWithTaxes, 0)
      if (this.returnForm.invalid) {
        return
      }

      else if (!this.itemsNotSelectedShowError && this.returnForm.valid) {
        //bankDetails from invoice form
        const useBankDetails: BankDetails = {
          accountName: this.returnForm.value.accountName,
          accountNumber: this.returnForm.value.accountNumber,
          ifscNumber: this.returnForm.value.accountIFSC,
          branchName: this.returnForm.value.accountBankBranch,
          paymentRemarks: null,
          paymentDeadline: null
        }
        let version: number
        if (!currentPurchaseOrderwithId.returnVersions || currentPurchaseOrderwithId.returnVersions && currentPurchaseOrderwithId.returnVersions.length === 0) {
          version = 1
        }

        if (currentPurchaseOrderwithId.returnVersions && currentPurchaseOrderwithId.returnVersions.length > 0) {
          this.latestVersionReturn = currentPurchaseOrderwithId.returnVersions.reduce(function (prev, current) {
            if (+current.version > +prev.version) {
              return current;
            } else {
              return prev;
            }
          });
          version = this.latestVersionReturn.version + 1
        }
        const newReturn: ReturnModel = {
          //
          _id: null,
          returnNumber: null,
          returnQuantity: totalReturnQuantityOfSelected,
          isEachItemReturnable: this.isGivenReturnConditionChecked,
          returnCondition: this.givenReturnCondition,
          returnReason: this.returnForm.value.returnRemarks,
          returnVersionNumber: version,
          dateOfRETURNGenerated: null,
          refundValueOnReturn: currentPurchaseOrderwithId.amountPaidAtCheckout / 2,
          isReturnGenerated: null,
          returnStatus: null,
          s3ReturnOrderLink: null,
          //
          //
          purchaseOrderNumber: currentPurchaseOrderwithId.purchaseOrderNumber,
          //
          purchaseOrderID: currentPurchaseOrderwithId._id,
          //
          buyerDetails: currentPurchaseOrderwithId.buyerDetails,
          //
          sellerDetails: currentPurchaseOrderwithId.sellerDetails,
          // filtered selected items of invoice from all items in PO 
          itemDetails: this.checkedItemDetails,
          //
          shipToBuyerLocation: currentPurchaseOrderwithId.shipToBuyerLocation,
          //
          bankDetails: useBankDetails,
          //
          s3POpdfLink: currentPurchaseOrderwithId.s3POpdfLink,
          //
          orderValue: currentPurchaseOrderwithId.orderValue,
          //
          orderValueIncludingTax: currentPurchaseOrderwithId.orderValueIncludingTax,

          orderQuantity: currentPurchaseOrderwithId.orderQuantity,
          //
          orderTax: currentPurchaseOrderwithId.orderTax,
          //
          paymentLink: null,
          paymentStatus: false,
          //
          paymentTrnsctnId: null,
          //
          isPOStatusActive: currentPurchaseOrderwithId.isPOStatusActive,
          // a po in process cannot be cancelled ,
          //
          poStatus: currentPurchaseOrderwithId.poStatus,
          //
        }
        this.checkedItemDetails = []
        this.hrs.createnewReturn(newReturn).then(response => {
          this.createdReturnOrder = response.createdReturn
          if (response && response.status == 201) {
            //fetch update po with invoice versions doc 
            // we will create 1st invoice version here
            // it will also have details of itemListingIds which were invoiced 
            // as well as itemlisingIds which were left for next invoice , 
            // for 1stVerOfInvoice -> we will put a list of all remaining itemlisingIds comparing to purchaseOrder itself
            // but for invoiceOrders that have multiple versions, we will concat the list of all raised itemlisingIds of all versions ,
            // and then compare against purchaseOrder

            //scenario 1 - firstpurchase order which has 5 items buy order by seller with id 1 , 2 , 3 , 4 ,5
            // in this if we created 1st invoice order and selected 2 items - 1 and 2 , then 1stVersionOfInvoice will have -
            // 1 and 2 item's itemlisingIds as selected, and 3 , 4 , 5 as remaining for next invoice order  
            // which should be added to purchaseOrder , and then shown in 2nd version of createInvoice

            // now if we created 2nd version of InvoOrder , and selected 3 and 4 , 
            //then version 2 of InvoOrder will have 3 and 4 as selected , and to calculate remaining itemlisingIds, if we compare against 
            //purchase order directly, it will save 1 , 2 and 5 , but we dont want that 

            // if the seller creates 3rd InvoVersion, it should display only 5th item remaing , so when we load PurchaseOrdder on this component,
            // we will gather the invoVersions of all invoices raised, and concat the list of all itemlisingIds in all invoices, and then compare against
            // purchase order and show remaining itemlisingIds for last version
            let useVersion: number
            if (currentPurchaseOrderwithId.returnVersions && currentPurchaseOrderwithId.returnVersions.length === 0) {
              useVersion = 1
            }

            if (currentPurchaseOrderwithId.returnVersions && currentPurchaseOrderwithId.returnVersions.length > 0) {
              this.latestVersionReturn = currentPurchaseOrderwithId.returnVersions.reduce(function (prev, current) {
                if (+current.version > +prev.version) {
                  return current;
                } else {
                  return prev;
                }
              });
              useVersion = this.latestVersionReturn.version + 1

            }
            const listingIdsInCurrentRETN: string[] = this.createdReturnOrder.itemDetails.map(eachItem => {
              return eachItem.listingId
            })
            const returnVersion: VersionReturning = {
              version: useVersion,
              returnRefundAmount: valueWithTaxOfSelectedItemsForReturn,
              isActive: true,
              returnNumber: this.createdReturnOrder.returnNumber,
              returnID: this.createdReturnOrder._id,
              returnStatus: this.createdReturnOrder.returnStatus,
              paymentLink: this.createdReturnOrder.paymentLink,
              paymentStatus: this.createdReturnOrder.paymentStatus,
              s3ReturnPDFLink: this.createdReturnOrder.s3ReturnOrderLink,
              itemsAddedForReturn: listingIdsInCurrentRETN,
            }
            const allReturnVersionsOfPO = currentPurchaseOrderwithId.returnVersions
            // append new version of INvo created on this order to POInvoVersions
            const updateVersionOfReturn: VersionReturning[] = allReturnVersionsOfPO.concat(returnVersion)
            this.updateInvoiceVersionInsidePO(updateVersionOfReturn, currentPurchaseOrderwithId._id, currentPurchaseOrderwithId.purchaseOrderNumber)
          }
        })
      }
    }
    catch (e) { throw e }


  }

  updateInvoiceVersionInsidePO(versionOfReturn: VersionReturning[], purchaseOrderId: string, purchaseOrderNumber: string) {
    try {
      this.hrs.updatePurchaseOrderWithReturnVersion(versionOfReturn, purchaseOrderId, purchaseOrderNumber).then(
        createdInvoVersion => {
          // if (createdInvoVersion && createdInvoVersion.status === 201)
          this.isLoading = false

          this.router.navigate([`viewReturnOrder/${this.createdReturnOrder._id}`])
          // this.router.navigate([`/viewAllPurchaseOrders`])

        }
      )
    }
    catch (e) {
      throw e
    }

  }



}
