<div class="contentFeaturedVarietiesFeaturedVarieties">
    <div class="bannerData">

        <div class="image">
            <!-- <img [src]=" this.currentListingwithId.imageUrls[0]" alt=""> -->
            <div class="img-slider">
                <div class="slide">
                    <mat-carousel class="mat-carousel" timings="300ms ease-in" [autoplay]="true" interval="4000"
                        color="accent" [loop]="true" maxWidth="auto" [hideIndicators]="true" [hideArrows]="true"
                        [useKeyboard]="true" [maintainAspectRatio]="false" orientation="ltr">
                        <mat-carousel-slide *ngFor="let slide of this.appBannersallproducthomegrid; let i = index"
                            [image]="slide" overlayColor="rgba(0, 0, 0, 0.1)" [hideOverlay]="true"></mat-carousel-slide>
                    </mat-carousel>
                </div>
            </div>
        </div>
        <div *ngIf="isLoading" class="content-loading">
            <div id="loadingScreen">
                <div class="loadingText" *ngIf="this.LoadingText">
                    <h1 class="loadingTitle">{{this.LoadingText}} </h1>
                </div>
                <mat-spinner></mat-spinner>
            </div>
        </div>
        <div class="categoriesOverlay" *ngIf="!isLoading && this.featuredVarietyurrentStateListings">
            <div class="categories">
                <div class="listing-card"
                    *ngFor="let listing of this.featuredVarietyurrentStateListings | slice:0:12; let i=index">
                    <div class="singleCards"
                        [routerLink]="['/Listing/Place_Bulk_Order',listing._id,listing.categoryUnique,listing.productName]">
                        <!-- <img mat-card-image src=" https://ik.imagekit.io/ikmedia/backlit.jpg"> -->
                        <div class="image_wrapper">
                            <img [src]="listing.imageUrls[0]" alt="listing.imageUrls[0]" />
                            <div class="overlay overlay_1">
                                <div class="title">
                                    <p>
                                        {{listing.title
                                        }}</p>
                                </div>
                            </div>

                        </div>
                        <div class="varietyName">
                            <p class="varname">
                                {{listing.varietyName
                                }}</p>
                            <p class="varprice">
                                {{(listing.bulkPrice).toLocaleString('en-IN')}} ₹ / {{listing.useUnitShort}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="viewCategorybutton">
                <button class="viewall" mat-raised-button routerLink="/viewItems/All">View All Categories</button>
            </div> -->
        </div>
    </div>
</div>