<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1 class="loadingTitle"> More Categories </h1>
            <h3 class="loadingSubtitle"> More Varieties </h3>
        </div>
    </div>
</div>
<div class="featured-banner" *ngIf="!isLoading">
    <div class="wrapper">
        <div class="cards" *ngFor="let category of this.productcategories ">
            <div class="categoryCheck" [routerLink]="['/viewItems',category.categoryName]">
                <img alt="Bulk wheat rice daal order category" class="item" [src]="category.categoryImages[0]">
                <div class="cards-info">
                    <div class="titleListing">
                        <h1>{{category.categoryName}}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>