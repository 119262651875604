import { Component, OnInit, Input } from '@angular/core';
import { HandleListingsService } from 'src/app/services/handle-listings.service';
import { ListingModel } from 'src/app/datamodels/ListingModel';

interface titSub {
  id: number
  title: string;
}
@Component({
  selector: 'app-featuredproductvarieties',
  templateUrl: './featuredproductvarieties.component.html',
  styleUrls: ['./featuredproductvarieties.component.css']
})
export class FeaturedproductvarietiesComponent implements OnInit {
  @Input() appBannersallproducthomegrid: string[];

  isLoading = false; //spinner initialized
  featuredVarietyurrentStateListings: ListingModel[]
  titleSubtitle: titSub[] = [
    {
      id: 1,
      title: "Healthy Brown Rice From West Bengal",
    },
    {
      id: 2,
      title: "Sharbati Wheat - Gehu From Madhya Pradesh",
    },
    {
      id: 3,
      title: "Aromatic Cumin - Jeera From Uttar Pradesh",
    },
    {
      id: 4,
      title: "Diabetic Khapli Wheat From Uttar Pradesh",
    },
    {
      id: 5,
      title: "Premium Cotton From Haryana",
    },
    {
      id: 6,
      title: "Premium Cotton From Gujurat",
    },
    {
      id: 7,
      title: "Premium Cotton From Maharashtra",
    },
    {
      id: 8,
      title: "Premium Cotton From Rajasthan",
    },
    {
      id: 9,
      title: "Premium Cotton From Haryana",
    },
    {
      id: 10,
      title: "Aromatic Lavender From Jammu and Kashmir",
    },
    {
      id: 11,
      title: "Himalyan Chitra Beans - Rajma From Himachal and Kashmir ",
    },
    {
      id: 12,
      title: "Red Kashmiri Beans - Rajma From Jammu Kashmir",
    },
  ]

  LoadingText: string = ''
  constructor(
    private handlingListing: HandleListingsService) { }

  ngOnInit(): void {
    this.LoadingText = this.titleSubtitle[Math.floor(Math.random() * this.titleSubtitle.length)].title;
    this.loadRecentVarietyListings();
  }

  loadRecentVarietyListings() {
    this.isLoading = true;
    this.handlingListing.getAllListings().subscribe(res => {
      if (res) {
        const key1 = '_id';
        const listingsWithVarietyNames = res.filter(listings => {
          return listings.varietyName != "" && listings.varietyName != null && listings.varietyName != 'null'
        })
        const last10UniqueListings = [...new Map(listingsWithVarietyNames.map(listing =>
          [listing[key1], listing])).values()]
        const key2 = 'state';
        this.featuredVarietyurrentStateListings = [...new Map(last10UniqueListings.map(listing =>
          [listing[key2], listing])).values()].slice(-12)
        this.isLoading = false;

        //  
      }

    })
  }
}
