import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PostsAndFaqFullInfoModel } from 'src/app/datamodels/PostsFaqs/faqAndPostsModel';
import { MypseoService } from 'src/app/services/mypseo.service';

@Component({
  selector: 'app-view-post',
  templateUrl: './view-post.component.html',
  styleUrls: ['./view-post.component.css']
})
export class ViewPostComponent implements OnInit {
  useFaq: PostsAndFaqFullInfoModel[] = []
  useId: string;
  useMetaInfo: string;
  usePost: PostsAndFaqFullInfoModel
  constructor(private route: ActivatedRoute, private title: Title, private meta: Meta
    , private mypseo: MypseoService) { }

  ngOnInit(): void {
    this.route.params.subscribe(getParam => {
      this.useId = getParam.id
      this.useMetaInfo = getParam.metaInfo
      this.findAnswSetMeta(this.useId, this.useMetaInfo)
    })
  }
  findAnswSetMeta(useId: string, metaTag: string) {
    this.mypseo.getAllPostedFAQs().then(allFAQs => {
      if (allFAQs && allFAQs.status === 200) {
        this.useFaq = allFAQs.FAQsfetched
        this.usePost = this.useFaq.find(eachFaq => { return eachFaq._id == useId });
        this.title.setTitle('Posts')
      }
    })
  }
}
