import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }
    //req param passed in is static type, which wraps all kinds(any) of data and requests that are outgoing
    intercept(req: HttpRequest<any>, next: HttpHandler) {

        //return next.handle(req); itself is a valid interceptor that allows req to continue journey but has no changes made
        const authToken = this.authService.getToken();
        const authRequest = req.clone({
            //req.clone creates copy of request , configuration of clone can edit the clone made and passed - like headers
            //set doesnt override old headers ,it just adds another header and sets value for it, if same header already present then overriden
            //in the middleware we are extracting this req.headers.authorization , (since its case insensitive - Authorization gives no error)
            headers: req.headers.set('Authorization', "Bearer " + authToken)
        });
        //in previous block we manipulated an incoming request, set our own token on auth header and sent it away with authrequest which now completes the journey 
        return next.handle(authRequest);
    }
}