import { Injectable } from '@angular/core';
import { StateModel } from '../datamodels/Statemodel';
import { HttpClient } from '@angular/common/http';
import { AllstatesService } from './allstates.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ListingModel } from '../datamodels/ListingModel';
@Injectable({
  providedIn: 'root'
})
export class SearchServiceService {

  private bundledSearchListings = new BehaviorSubject(null);
  sharedBundledSearchListingItems = this.bundledSearchListings.asObservable()

  public storeStates;
  allstateNames = []
  constructor(private http: HttpClient,
    private stateService: AllstatesService) { }

  updateSearchListings(bundledListings: ListingModel[]) {
    try {
      this.bundledSearchListings.next(bundledListings)
    }
    catch (e) {
      throw e
    }

  }

  async searchByState(inputstateName) {
    this.storeStates = await this.stateService.getStates()
    // this.storeStates.states.filter(statenametoFilter=>{
    //   this.allstateNames.push(statenametoFilter.stateName)
    // })
    // console.log('searchServiceStates',this.allstateNames)
    return of(this.storeStates.filter(nameInStoreStates => {
      nameInStoreStates.stateName.replace(/[\s]/g, '').toLowerCase().indexOf(inputstateName.toLowerCase()) === 0
    }))
  }

}
