import { HttpClient } from '@angular/common/http';
import { HostListener, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BidfilterComponent } from '../components/viewbids/bidfilter/bidfilter.component';
import { BidsortComponent } from '../components/viewbids/bidsort/bidsort.component';

@Injectable({
  providedIn: 'root'
})
export class BidfilterdialogmanagerService {
  public getScreenWidth: any;
  public getScreenHeight: any;
  constructor(private dialog: MatDialog, private http: HttpClient) { }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }
  public filterBids(filterdata: any = []): Observable<any> {
    let bidfilterDialogRef: MatDialogRef<BidfilterComponent>
    var bidDialogConfig = new MatDialogConfig()
    bidDialogConfig.data = filterdata;
    this.getScreenWidth = window.innerWidth;
    const discountDialogWidth = this.getScreenWidth - (25 * this.getScreenWidth) / 100
    bidfilterDialogRef = this.dialog.open(BidfilterComponent, {
      data: filterdata,
      width: `${discountDialogWidth}px`,
      position: { top: '80px' }
    });
    return bidfilterDialogRef.afterClosed();
  }

  public sortBids(sortData: any = []): Observable<any> {
    let bidsortDialogRef: MatDialogRef<BidsortComponent>
    var bidDialogConfig = new MatDialogConfig()
    bidDialogConfig.data = sortData;
    this.getScreenWidth = window.innerWidth;
    const discountDialogWidth = this.getScreenWidth - (25 * this.getScreenWidth) / 100
    bidsortDialogRef = this.dialog.open(BidsortComponent, {
      data: sortData,
      width: `${discountDialogWidth}px`,
      position: { top: '80px' }
    });
    return bidsortDialogRef.afterClosed();

  }
}
