<!-- <input matInput type="text" name="productName" [(ngModel)]="productName" (ngModelChange)="SearchProduct()"> -->

<div class="nonfeatured-banner">
    <div class="bannerData">
        <div class="wrapper" fxLayout="row wrap">
            <div class="cards" *ngFor="let products of this.ProductsOfCategory ">
                <img class="item" alt="" src="{{products.productImage}}"
                (click)="onSelectProduct(products.productUnique)">
                <div class="cards-info">
                    <h1>{{products.productName}}</h1>
                </div>
            </div>
        </div>
    </div>