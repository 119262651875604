import { Component, OnInit, Input } from '@angular/core';
import { ProductCategoryModel } from '../../datamodels/productcategory';
// import { PRODUCTCATEGORIES } from '../../datamodels/productcategories';
import { ProductcategoryService } from '../../services/productcategory.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-allproducthomegrid',
  templateUrl: './allproducthomegrid.component.html',
  styleUrls: ['./allproducthomegrid.component.css']
})

export class AllproducthomegridComponent implements OnInit {
  @Input() appBannersallproducthomegrid: string[];

  isLoading = false; //spinner initialized
  productcategories: ProductCategoryModel[] = [];

  Holdcategory: ProductCategoryModel[] = [];
  constructor(public productcategoryService: ProductcategoryService,
    private router: Router) { }

  ngOnInit(): void {
    this.loadCategories();
  }

  loadCategories() {

    try {
      this.isLoading = true; //spinner starts
      this.productcategoryService.getCategoriesListener().subscribe((res) => {
        this.productcategories = res
        this.isLoading = false; //spinner stops
        //removeBelow and replace with above liner
      })
    }
    catch (e) {
      throw e
    }

  }
  onSelectCategory(categoryUnique: string) {
    this.router.navigate(['/viewCategoryitems', categoryUnique])
  }
}
