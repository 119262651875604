import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BidRequest } from '../datamodels/bidnegotiateRequestModel';
import { Observable } from 'rxjs';
import { SettingsService } from './settings.service';
@Injectable({
  providedIn: 'root'
})
export class BidRateHandlingService {
  private domain: string | undefined
  constructor(
    private http: HttpClient, private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }
  async sendBidRequest(
    listingId: string,
    bidWriter: string,
    listingProduct: string,
    imageUrls: any,
    isDiscount: string,
    isBid: string,
    isRateSelected: string,
    bidRate: string,
    quantityValue: string,
    bidOrderValueWithoutTaxes: string,
    bidCreator: string,
    newPriceOffered: string,
    listingTitle: string,
    isPending: string,
    isApproved: string,
    isCanceled: string,
    oldPriceListed: string,
    requestStatus: string,
    bidCreatorName: string,
    bidWriterName: string,
    bidCreateDate: string,
    bidActionDate: string,
  ): Promise<any> {
    const bidRequestData = new FormData()
    bidRequestData.append("listingId", listingId);
    bidRequestData.append("bidWriter", bidWriter);
    bidRequestData.append("listingProduct", listingProduct);
    if (imageUrls && imageUrls.length >= 1) {
      imageUrls.forEach(imgUrl => {
        bidRequestData.append("imageUrls[]", imgUrl)
      })
    }
    else {
      bidRequestData.append("imageUrls", imageUrls)
    }
    bidRequestData.append("isDiscount", isDiscount);
    bidRequestData.append("isBid", isBid);
    bidRequestData.append("isRateSelected", isRateSelected);
    bidRequestData.append("bidRate", bidRate);
    bidRequestData.append("quantityValue", quantityValue);
    bidRequestData.append("bidOrderValueWithoutTaxes", bidOrderValueWithoutTaxes);
    bidRequestData.append("bidCreator", bidCreator);
    bidRequestData.append("newPriceOffered", newPriceOffered);
    bidRequestData.append("listingTitle", listingTitle);
    bidRequestData.append("isPending", isPending);
    bidRequestData.append("isApproved", isApproved);
    bidRequestData.append("isCanceled", isCanceled);
    bidRequestData.append("oldPriceListed", oldPriceListed);
    bidRequestData.append("requestStatus", requestStatus);
    bidRequestData.append("bidCreatorName", bidCreatorName);
    bidRequestData.append("bidWriterName", bidWriterName);
    bidRequestData.append("bidCreateDate", bidCreateDate);
    bidRequestData.append("bidActionDate", bidActionDate);
    return this.http.post<{ message: string, createdBidRequest: BidRequest, status: string }>(`${this.domain}api/bidrequest/createBidRequest/`, bidRequestData).toPromise()
  }

  async getUserOnlyBidRequest(currrentUser: string, accountType: string): Promise<{
    message: string,
    bidssellerPlaced: BidRequest[],
    bidssellerReceived: BidRequest[],
    bidsbuyerPlaced: BidRequest[],
    status: number,
  }> {
    const queryParams = `?currentUsId=${currrentUser}&&accTyp=${accountType}`
    const getMyBids = await this.http.get<{
      message: string,
      bidssellerPlaced: BidRequest[],
      bidssellerReceived: BidRequest[],
      bidsbuyerPlaced: BidRequest[],
      status: number,
    }>(`${this.domain}api/bidrequest/getUserBids/` + queryParams).toPromise()
    return getMyBids
  }

  updateBidRequestCancel(
    id: string,
    listingId: string,
    bidWriter: string,
    listingProduct: string,
    imageUrls: any,
    isDiscount: string,
    isBid: string,
    isRateSelected: string,
    bidRate: string,
    quantityValue: string,
    bidOrderValueWithoutTaxes: string,
    bidCreator: string,
    newPriceOffered: string,
    listingTitle: string,
    isPending: string,
    isApproved: string,
    isCanceled: string,
    oldPriceListed: string,
    requestStatus: string,
    bidCreatorName: string,
    bidWriterName: string,
    bidCreateDate: string,
    bidActionDate: string,

  ): Observable<any> {
    const bidRequestCancelData = new FormData()
    bidRequestCancelData.append("id", id);
    //listingId is Id of listing on which BID is being actioned
    bidRequestCancelData.append("listingId", listingId);
    //bidWriter is Id of USER Who is actioning the bid - approving / cancelling 

    bidRequestCancelData.append("bidWriter", bidWriter);
    bidRequestCancelData.append("listingProduct", listingProduct);
    if (imageUrls && imageUrls.length >= 1) {
      imageUrls.forEach(imgUrl => {
        bidRequestCancelData.append("imageUrls[]", imgUrl)
      })
    }
    else {
      bidRequestCancelData.append("imageUrls", imageUrls)
    }
    bidRequestCancelData.append("isDiscount", isDiscount);
    bidRequestCancelData.append("isBid", isBid);
    bidRequestCancelData.append("isRateSelected", isRateSelected);
    bidRequestCancelData.append("bidRate", bidRate);
    bidRequestCancelData.append("quantityValue", quantityValue);
    bidRequestCancelData.append("bidOrderValueWithoutTaxes", bidOrderValueWithoutTaxes);
    //bidCreator is Id of USER Who placed the bid - discount / bidUp 

    bidRequestCancelData.append("bidCreator", bidCreator);
    bidRequestCancelData.append("newPriceOffered", newPriceOffered);
    bidRequestCancelData.append("listingTitle", listingTitle);
    bidRequestCancelData.append("isPending", isPending);
    bidRequestCancelData.append("isApproved", isApproved);
    bidRequestCancelData.append("isCanceled", isCanceled);
    bidRequestCancelData.append("oldPriceListed", oldPriceListed);
    bidRequestCancelData.append("requestStatus", requestStatus);
    bidRequestCancelData.append("bidCreatorName", bidCreatorName);
    bidRequestCancelData.append("bidWriterName", bidWriterName);
    bidRequestCancelData.append("bidCreateDate", bidCreateDate);
    bidRequestCancelData.append("bidActionDate", bidActionDate);
    // let paramsUpdateBidCancel = new HttpParams().set("cancelBid", cancelBidObj)
    return this.http.put<{ message: string, canceledBid: BidRequest, status: string }>(`${this.domain}api/bidrequest/updateCancelBidRequest/` + id, bidRequestCancelData)
  }
  updateBidRequestApprove(
    id: string,
    listingId: string,
    bidWriter: string,
    listingProduct: string,
    imageUrls: any,
    isDiscount: string,
    isBid: string,
    isRateSelected: string,
    bidRate: string,
    quantityValue: string,
    bidOrderValueWithoutTaxes: string,
    bidCreator: string,
    newPriceOffered: string,
    listingTitle: string,
    isPending: string,
    isApproved: string,
    isCanceled: string,
    oldPriceListed: string,
    requestStatus: string,
    bidCreatorName: string,
    bidWriterName: string,
    bidCreateDate: string,
    bidActionDate: string,
  ): Observable<any> {
    const bidRequestApproveData = new FormData()
    bidRequestApproveData.append("id", id);
    //listingId is Id of listing on which BID is being actioned
    bidRequestApproveData.append("listingId", listingId);
    //bidWriter is Id of USER Who is actioning the bid - approving / cancelling 

    bidRequestApproveData.append("bidWriter", bidWriter);
    bidRequestApproveData.append("listingProduct", listingProduct);
    if (imageUrls && imageUrls.length >= 1) {
      imageUrls.forEach(imgUrl => {
        bidRequestApproveData.append("imageUrls[]", imgUrl)
      })
    }
    else {
      bidRequestApproveData.append("imageUrls", imageUrls)
    }
    bidRequestApproveData.append("isDiscount", isDiscount);
    bidRequestApproveData.append("isBid", isBid);
    bidRequestApproveData.append("isRateSelected", isRateSelected);
    bidRequestApproveData.append("bidRate", bidRate);
    bidRequestApproveData.append("quantityValue", quantityValue);
    bidRequestApproveData.append("bidOrderValueWithoutTaxes", bidOrderValueWithoutTaxes);
    //bidCreator is Id of USER Who placed the bid - discount / bidUp 

    bidRequestApproveData.append("bidCreator", bidCreator);
    bidRequestApproveData.append("newPriceOffered", newPriceOffered);
    bidRequestApproveData.append("listingTitle", listingTitle);
    bidRequestApproveData.append("isPending", isPending);
    bidRequestApproveData.append("isApproved", isApproved);
    bidRequestApproveData.append("isCanceled", isCanceled);
    bidRequestApproveData.append("oldPriceListed", oldPriceListed);
    bidRequestApproveData.append("requestStatus", requestStatus);
    bidRequestApproveData.append("bidCreatorName", bidCreatorName);
    bidRequestApproveData.append("bidWriterName", bidWriterName);
    bidRequestApproveData.append("bidCreateDate", bidCreateDate);
    bidRequestApproveData.append("bidActionDate", bidActionDate);
    // let paramsUpdateBidCancel = new HttpParams().set("cancelBid", cancelBidObj)
    // console.log(paramsUpdateBidCancel)
    return this.http.put<{ message: string, approvedBid: BidRequest, status: string }>(`${this.domain}api/bidrequest/updateApproveBidRequest/` + id, bidRequestApproveData)
  }
  getAllBidRequest() { }
  getListingBidRequest() { }


}
