import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { HandlesellerCentralService } from 'src/app/services/handleseller-central.service';
interface invoiceInfoIn {
  useInvoiceNumber: string;
  useInvoiceAmount: number;
  useInvoicePaidDate: Date;
  useFundWithdrawabledate: Date;
  useIsFundWithdrawable: boolean;
  useIsWithdrawalReqAlreadyPlaced: boolean;
  usefundWithdrawnDate: Date;
  usetotalDeducDone: number;
  useWithdrawableAmount: number
}
interface ordersAccountingIn {
  invoiceInfo: invoiceInfoIn[]
  orderCreditInterest: number;
  orderCreditInterestShareOfSeller: number;
  orderFinalSellerShareOfOrder: number;
  orderFinalValue: number;
  orderHandlingFeesFromBuyer: number;
  orderNumber: string;
  orderStatus: string;
  orderPlatformCreditInterestShare: number;
  orderTCSHandlingFeesAndFromSeller: number;
  invoicedTimes: number;
  orderFinalPayout: number
}
@Component({
  selector: 'app-viewallaccounting',
  templateUrl: './viewallaccounting.component.html',
  styleUrls: ['./viewallaccounting.component.css']
})
export class ViewallaccountingComponent implements OnInit {
  private loginUserInfo: any
  accountingInfo: ordersAccountingIn[]
  currentUserId: string
  constructor(
    private authService: AuthService,
    public router: Router,
    private hsc: HandlesellerCentralService
  ) { }

  ngOnInit(): void {
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    const accountType = this.loginUserInfo.accounttype
    if (accountType === 'Buyer') {
      this.router.navigate([`/`])
      return
    }
    this.getAccountingOrders()
  }
  getAccountingOrders() {
    this.hsc.sharedBundledAccounting.subscribe((accountingItems: ordersAccountingIn[]) => {
      this.accountingInfo = accountingItems
      if (!this.accountingInfo) {
        this.router.navigate(['/SellerCentral', this.currentUserId])
      }
    })
  }
  newWithdrawInvoice(withdrawalInvo: invoiceInfoIn) {
    try {
      this.hsc.updateInvoiceWithdrawal(withdrawalInvo)
      this.router.navigate(['/SellerCentral/newWithdrawal', this.currentUserId])

    }
    catch (e) {
      throw e
    }
  }
}
