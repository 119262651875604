<mat-card>
    <div class="pageBanner">
    </div>
    <div *ngIf="this.isSellerCheckingBids">
        <h1 class="poTitle">My Bids</h1>
        <div class="togglecontainer">
            <div class="buyersPlacedTitle">
                <h2 matTooltip="Created by other buyers for me">
                    <span>
                        Received Bids
                    </span>
                </h2>
            </div>
            <div class="toggle">
                <h3>
                    <mat-slide-toggle [(ngModel)]="isSellerCreatedBids">
                    </mat-slide-toggle>
                </h3>
            </div>
            <div class="iPlaceTitle">
                <h2 matTooltip="Created by me to other suppliers">
                    <span>
                        Created Bids
                    </span>
                </h2>
            </div>
        </div>
    </div>

    <!-- <div class="filterContent">
        <div class="filter">
            <button mat-raised-button class="filterButton" (click)="filterBids()">Filter Bids</button>
        </div>
        <div class="sort">
            <mat-accordion>
                <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header style=" background-color: rgb(31, 30, 30);">
                        <mat-panel-title style="color: white;font-size: 20px;
                        font-weight: 500;
                        font-family: Arial, Helvetica, sans-serif; justify-content: center !important;">
                            Sort Bids
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <section matSort (matSortChange)="sortData($event)">
                        <h4 id="sortItems" mat-sort-header="bidCreateDate">Date</h4>
                        <h4 id="sortItems" mat-sort-header="oldPriceListed">Old Price</h4>
                        <h4 id="sortItems" mat-sort-header="newPriceOffered">New Price</h4>
                        <h4 id="sortItems" mat-sort-header="quantityValue">Quantity</h4>
                        <h4 id="sortItems" mat-sort-header="bidRate">Rate</h4>
                    </section>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div> -->
</mat-card>

<div class="content">
    <div class="bidWrapper">
        <div *ngIf="this.isSellerCheckingBids">
            <div class="sellerReceivedBids" *ngIf="!this.isSellerCreatedBids">
                <div *ngFor="let bid of this.bidsOfSellerReceived">
                    <div id="listingcontainer" [ngClass]="{
                        'discountCard' : bid?.isDiscount ,
                        'bidCard' : bid?.isBid }">

                        <mat-card class="mat-info">
                            <img mat-card-image [src]="bid.imageUrls[0]" />
                            <div class="bidInfo">
                                <mat-card-content>
                                    <span class="bidTitle">{{bid.listingTitle}}</span> <br>
                                    <span class="bidProduct"> {{bid.listingProduct}}</span><br>
                                </mat-card-content>
                                <!-- <mat-card-actions> -->
                                <button [routerLink]="['/Listing',bid.listingId]" mat-stroked-button class="viewListing"
                                    color="primary">View Listing</button>
                                <div *ngIf="bid.isPending && !isLoading">
                                    <div class="perChange">
                                        <mat-list>
                                            <div class="priceChange">
                                                <mat-list-item style="padding: 0px 1px !important;"
                                                    class="bidRate"><span *ngIf="bid.isDiscount"
                                                        style="color: rgb(170, 123, 43);font-size: 25px;">-&nbsp;{{bid.bidRate}}%</span>
                                                    <span *ngIf="bid.isBid"
                                                        style="color: rgb(60, 188, 64);font-size: 25px;">+&nbsp;{{bid.bidRate}}%</span>
                                                </mat-list-item>
                                            </div>
                                        </mat-list>
                                    </div>
                                </div>
                                <!-- </mat-card-actions> -->
                            </div>
                        </mat-card>
                        <div class="bidType">
                            <mat-card-content>
                                <div class="bidPricing">
                                    <div class="bidData">
                                        <span class="key">Created
                                            By</span>
                                        <span (click)="this.viewUserProfile(bid.bidCreator,this.businessName)" class="
                                            val bidCreator">{{bid.bidCreatorName}}</span>
                                    </div>
                                    <div class="bidData">
                                        <span class="key">Quantity</span>
                                        <span class="val bidQuantity">{{bid.quantityValue}}<span
                                                style="font-size:9px; font-weight: 420;">&nbsp;&nbsp;Quintal</span></span>
                                    </div>
                                    <div class="bidData">
                                        <span class="key">Old
                                            Price</span><span
                                            class="val bidOldPrice">₹&nbsp;{{(bid.oldPriceListed).toLocaleString('en-IN')}}
                                            ₹</span>
                                    </div>
                                    <div class="bidData">
                                        <span class="key" style="color: rgb(219, 62, 67);">Bid
                                            Price</span><span
                                            class="val bidNewPrice">₹&nbsp;{{(bid.newPriceOffered).toLocaleString('en-IN')}}
                                            ₹</span>
                                    </div>
                                    <div *ngIf="bid.bidOrderValueWithoutTaxes" class="bidData">
                                        <span class="key"
                                            style="font-size:11px; font-weight: 500;width: 65px;color: rgb(0, 0, 0);">Order
                                            Value</span><span
                                            class="val totalPrice">₹&nbsp;{{(bid.bidOrderValueWithoutTaxes).toLocaleString('en-IN')}}
                                            ₹</span>
                                    </div>
                                    <div class="bidData">
                                        <div class="priceDetails">
                                            <button mat-raised-button class="canButt"
                                                (click)="cancelBid(bid)">Cancel</button>
                                            <button mat-raised-button class="appButt"
                                                (click)="approveBid(bid)">Approve</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- <span> {{listing.categoryName}} </span> &nbsp;&nbsp;|&nbsp;&nbsp;
                        <span> {{listing.productName}} </span> -->
                            </mat-card-content>
                        </div>
                        <div id="requestStatus">
                            <span *ngIf="!bid.isPending && !isLoading">
                                <span *ngIf="bid.isCanceled" class="stamp bidStatusCanceled">{{bid.requestStatus}}
                                </span>
                                <span *ngIf="bid.isApproved" class="stamp bidStatusApproved">{{bid.requestStatus}}
                                </span>
                            </span>
                        </div>
                    </div>
                    <!-- <img mat-card-image src="https://ik.imagekit.io/ikmedia/backlit.jpg"> -->
                </div>
            </div>
            <div class="sellerPlacedBids" *ngIf="this.isSellerCreatedBids">
                <div *ngFor="let bid of this.bidsOfSellerPlaced">
                    <div id="listingcontainer" [ngClass]="{
                        'discountCard' : bid?.isDiscount ,
                        'bidCard' : bid?.isBid }">
                        <mat-card class="mat-info">
                            <img mat-card-image [src]="bid.imageUrls[0]" />
                            <div class="bidInfo">
                                <mat-card-content>
                                    <span class="bidTitle">{{bid.listingTitle}}</span> <br>
                                    <span class="bidProduct"> {{bid.listingProduct}}</span><br>
                                </mat-card-content>
                                <!-- <mat-card-actions> -->
                                <button [routerLink]="['/Listing',bid.listingId]" mat-stroked-button class="viewListing"
                                    color="primary">View Listing</button>
                                <div *ngIf="bid.isPending && !isLoading">
                                    <div class="perChange">
                                        <mat-list>
                                            <div class="priceChange">
                                                <mat-list-item style="padding: 0px 1px !important;"
                                                    class="bidRate"><span *ngIf="bid.isDiscount"
                                                        style="color: rgb(170, 123, 43);font-size: 25px;">-&nbsp;{{bid.bidRate}}%</span>
                                                    <span *ngIf="bid.isBid"
                                                        style="color: rgb(60, 188, 64);font-size: 25px;">+&nbsp;{{bid.bidRate}}%</span>
                                                </mat-list-item>
                                            </div>
                                        </mat-list>
                                    </div>
                                </div>
                                <!-- </mat-card-actions> -->
                            </div>
                        </mat-card>
                        <div class="bidType">
                            <mat-card-content>
                                <div class="bidPricing">
                                    <div class="bidData">
                                        <span class="key">Created
                                            By</span>
                                        <span (click)="this.viewUserProfile(bid.bidCreator,this.businessName)" class="
                                            val bidCreator">{{bid.bidCreatorName}}</span>
                                    </div>
                                    <div class="bidData">
                                        <span class="key">Quantity</span>
                                        <span class="val bidQuantity">{{bid.quantityValue}}<span
                                                style="font-size:9px; font-weight: 420;">&nbsp;&nbsp;Quintal</span></span>
                                    </div>
                                    <div class="bidData">
                                        <span class="key">Old
                                            Price</span><span
                                            class="val bidOldPrice">₹&nbsp;{{(bid.oldPriceListed).toLocaleString('en-IN')}}
                                            ₹</span>
                                    </div>
                                    <div class="bidData">
                                        <span class="key" style="color: rgb(219, 62, 67);">Bid
                                            Price</span><span
                                            class="val bidNewPrice">₹&nbsp;{{(bid.newPriceOffered).toLocaleString('en-IN')}}
                                            ₹</span>
                                    </div>
                                    <div *ngIf="bid.bidOrderValueWithoutTaxes" class="bidData">
                                        <span class="key"
                                            style="font-size:11px; font-weight: 500;width: 65px;color: rgb(0, 0, 0);">Order
                                            Value</span><span
                                            class="val totalPrice">₹&nbsp;{{(bid.bidOrderValueWithoutTaxes).toLocaleString('en-IN')}}
                                            ₹</span>
                                    </div>
                                    <div class="bidData">
                                        <div class="priceDetails">
                                            <button mat-raised-button class="canButt"
                                                (click)="cancelBid(bid)">Cancel</button>
                                            <button mat-raised-button class="appButt"
                                                (click)="approveBid(bid)">Approve</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- <span> {{listing.categoryName}} </span> &nbsp;&nbsp;|&nbsp;&nbsp;
                        <span> {{listing.productName}} </span> -->
                            </mat-card-content>
                        </div>
                        <div id="requestStatus">
                            <span *ngIf="!bid.isPending && !isLoading">
                                <span *ngIf="bid.isCanceled" class="stamp bidStatusCanceled">{{bid.requestStatus}}
                                </span>
                                <span *ngIf="bid.isApproved" class="stamp bidStatusApproved">{{bid.requestStatus}}
                                </span>
                            </span>
                        </div>
                    </div>
                    <!-- <img mat-card-image src="https://ik.imagekit.io/ikmedia/backlit.jpg"> -->
                </div>
            </div>
        </div>
        <div *ngIf="!this.isSellerCheckingBids">
            <div class="buyerPlacedBids" *ngFor="let bid of this.bidsOfBuyerPlaced">
                <div id="listingcontainer" [ngClass]="{
                    'discountCard' : bid?.isDiscount ,
                    'bidCard' : bid?.isBid }">

                    <mat-card class="mat-info">
                        <img mat-card-image [src]="bid.imageUrls[0]" />
                        <div class="bidInfo">
                            <mat-card-content>
                                <span class="bidTitle">{{bid.listingTitle}}</span> <br>
                                <span class="bidProduct"> {{bid.listingProduct}}</span><br>
                            </mat-card-content>
                            <!-- <mat-card-actions> -->
                            <button [routerLink]="['/Listing',bid.listingId]" mat-stroked-button class="viewListing"
                                color="primary">View Listing</button>
                            <div *ngIf="bid.isPending && !isLoading">
                                <div class="perChange">
                                    <mat-list>
                                        <div class="priceChange">
                                            <mat-list-item style="padding: 0px 1px !important;" class="bidRate"><span
                                                    *ngIf="bid.isDiscount"
                                                    style="color: rgb(170, 123, 43);font-size: 25px;">-&nbsp;{{bid.bidRate}}%</span>
                                                <span *ngIf="bid.isBid"
                                                    style="color: rgb(60, 188, 64);font-size: 25px;">+&nbsp;{{bid.bidRate}}%</span>
                                            </mat-list-item>
                                        </div>
                                    </mat-list>
                                </div>
                            </div>
                            <!-- </mat-card-actions> -->
                        </div>
                    </mat-card>
                    <div class="bidType">
                        <mat-card-content>
                            <div class="bidPricing">
                                <div class="bidData">
                                    <span class="key">Created
                                        By</span>
                                    <span (click)="this.viewUserProfile(bid.bidCreator,this.businessName)" class="
                                        val bidCreator">{{bid.bidCreatorName}}</span>
                                </div>
                                <div class="bidData">
                                    <span class="key">Quantity</span>
                                    <span class="val bidQuantity">{{bid.quantityValue}}<span
                                            style="font-size:9px; font-weight: 420;">&nbsp;&nbsp;Quintal</span></span>
                                </div>
                                <div class="bidData">
                                    <span class="key">Old
                                        Price</span><span
                                        class="val bidOldPrice">₹&nbsp;{{(bid.oldPriceListed).toLocaleString('en-IN')}}
                                        ₹</span>
                                </div>
                                <div class="bidData">
                                    <span class="key" style="color: rgb(219, 62, 67);">Bid
                                        Price</span><span
                                        class="val bidNewPrice">₹&nbsp;{{(bid.newPriceOffered).toLocaleString('en-IN')}}
                                        ₹</span>
                                </div>
                                <div *ngIf="bid.bidOrderValueWithoutTaxes" class="bidData">
                                    <span class="key"
                                        style="font-size:11px; font-weight: 500;width: 65px;color: rgb(0, 0, 0);">Order
                                        Value</span><span
                                        class="val totalPrice">₹&nbsp;{{(bid.bidOrderValueWithoutTaxes).toLocaleString('en-IN')}}
                                        ₹</span>
                                </div>
                                <div class="bidData">
                                    <div class="priceDetails">
                                        <button mat-raised-button class="canButt"
                                            (click)="cancelBid(bid)">Cancel</button>
                                        <button mat-raised-button class="appButt"
                                            (click)="approveBid(bid)">Approve</button>
                                    </div>
                                </div>
                            </div>
                            <!-- <span> {{listing.categoryName}} </span> &nbsp;&nbsp;|&nbsp;&nbsp;
                    <span> {{listing.productName}} </span> -->
                        </mat-card-content>
                    </div>
                    <div id="requestStatus">
                        <span *ngIf="!bid.isPending && !isLoading">
                            <span *ngIf="bid.isCanceled" class="stamp bidStatusCanceled">{{bid.requestStatus}} </span>
                            <span *ngIf="bid.isApproved" class="stamp bidStatusApproved">{{bid.requestStatus}} </span>
                        </span>
                    </div>
                </div>
                <!-- <img mat-card-image src="https://ik.imagekit.io/ikmedia/backlit.jpg"> -->
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen"><mat-spinner></mat-spinner></div>
</div>