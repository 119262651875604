import { Injectable, HostListener } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { DeleteListingDialogComponent } from '../components/view-listing/delete-listing-dialog/delete-listing-dialog.component';
import { EditListingDialogComponent } from '../components/create-listing/edit-listing-dialog/edit-listing-dialog.component';
import { BidListingComponentComponent } from '../components/bid-listing-component/bid-listing-component.component';

@Injectable({
  providedIn: 'root'
})

export class ListingdialogmanagerServiceService {
  public getScreenWidth: any;
  public getScreenHeight: any;

  constructor(private dialog: MatDialog, private http: HttpClient) { }
  //read realtime screen size
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }
  public deleteListing(data: any = []): Observable<any> {
    let deldialogRef: MatDialogRef<DeleteListingDialogComponent>
    var deldialogConfig = new MatDialogConfig()
    deldialogConfig.data = data;
    deldialogRef = this.dialog.open(DeleteListingDialogComponent, deldialogConfig);
    return deldialogRef.afterClosed();
  }
  public askDiscountonListing(discountdata: any = []): Observable<any> {
    let discountdialogRef: MatDialogRef<BidListingComponentComponent>
    var discountdialogConfig = new MatDialogConfig()
    discountdialogConfig.data = discountdata;
    this.getScreenWidth = window.innerWidth;
    const discountDialogWidth = this.getScreenWidth - (25 * this.getScreenWidth) / 100

    discountdialogRef = this.dialog.open(BidListingComponentComponent, {
      data: discountdata,
      width: `${discountDialogWidth}px`,
      position: { top: '80px' }
    });
    return discountdialogRef.afterClosed();
  }
  public placeBidonListing(biddata: any = []): Observable<any> {
    let bidDialogRef: MatDialogRef<BidListingComponentComponent>
    var bidDialogConfig = new MatDialogConfig()
    bidDialogConfig.data = biddata;
    this.getScreenWidth = window.innerWidth;
    const discountDialogWidth = this.getScreenWidth - (25 * this.getScreenWidth) / 100
    bidDialogRef = this.dialog.open(BidListingComponentComponent, {
      data: biddata,
      width: `${discountDialogWidth}px`,
      position: { top: '80px' }
    });
    return bidDialogRef.afterClosed();
  }

}

