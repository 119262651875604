import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ListingModel } from 'src/app/datamodels/ListingModel';
import { HandleListingsService } from 'src/app/services/handle-listings.service';
import { SearchServiceService } from 'src/app/services/search-service.service';

// pcv search algo
// search algo here
// our source of truth is the listing title
// first our search results are filtered out with matching string from search box and compared against title , category , variety and product of every listing
// all the listings with matching t-c-p-v are shifted to an array -> filteredListingsForSearch from which unique objects with listingIds _id are extracted

// we will bundle the listings in filteredListingsForSearch into one mat-card that user is typing, so when the user clicks 
// on this mat card - a page opens up with all listings inside filteredListingsForSearch loaded using behaviour subject
// why do this ? if we simply display each listing alone as ngFor in single-mat-card, when user clicks they will only be opening 1 listing and 

// we also take out all unique categoryNames , productNames, varietyNames from filteredListingsForSearch , so user can search using these 



interface loadingReason {
  id: number;
  reason: string
}
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  searchField: FormControl;

  @Output() search = new EventEmitter<string>()
  SearchName: any;
  searchTerm: any;
  loadedListings: ListingModel[] = [];
  filteredListingsForSearch: ListingModel[] = [];
  values: string;
  categoryName: string;
  productName: string;
  varietyName: string;
  isLoading: boolean = false;
  searchValue: string
  LoadingText: string = ''
  // loadingSearchlabels: string = '';
  showLoadingReasons: loadingReason[] = [
    {
      id: 1,
      reason: "Search with names of Categories like - Grains , Spices , Essential Oils."
    },
    {
      id: 2,
      reason: "Search with names of products like - Wheat , Turmeric , Mint Oil."
    },
    {
      id: 3,
      reason: "Search with names of varieties like - Basmati Rice , Black Cardamom , Kashmiri Chilli."
    },
    {
      id: 4,
      reason: "Search with titles of Listings like - Fresh Chilli , High Quality Peppermint Oil , Seedless Cotton."
    }

  ]
  allCategories: string[] = [];
  allProducts: string[] = [];
  allVarieties: string[] = [];
  searchLabels: string[] = [
    'mint oil',
    'gehu',
    'lavender',
    'chilli',
    'elaichi',
    'green cardamom',
    'wheat',
    'chawal',
    'rice',
    'basmati rice',
    'sabarmati',
    'peppermint'
  ]
  noResults: string;
  loginUserInfo: any;
  currentUserId: string;
  constructor(
    public handleListing: HandleListingsService,
    public searchService: SearchServiceService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    try {
      this.searchField = new FormControl();
      this.LoadingText = this.showLoadingReasons[Math.floor(Math.random() * this.showLoadingReasons.length)].reason;
      // this.loadingSearchlabels = this.searchLabels[Math.floor(Math.random() * this.showLoadingReasons.length)];
      this.loadAllListings()
      this.loginUserInfo = this.authService.getUserLoginInfo();
      this.currentUserId = this.loginUserInfo.userId
    }
    catch (e) {

    }
  }

  myvalue() {
    this.searchTerm = ""
    this.searchValue = ''
  }

  loadAllListings() {
    try {
      this.isLoading = true
      this.handleListing.getAllListings().subscribe(allListings => {
        this.loadedListings = allListings
        this.isLoading = false
      })
    }
    catch (e) {
      throw e
    }

  }

  onSearch() {
    try {
      this.searchField.valueChanges.pipe(debounceTime(550),
        distinctUntilChanged(), startWith('')
      ).subscribe(data => {
        this.search.emit(data)
        this.searchTerm = data
        if (this.searchTerm.length > 3) {
          const useSearch = this.searchTerm
          if (useSearch.length >= 6) {
            this.saveUserSearch(useSearch)
          }
          // search all listings on basis of matching title
          this.priority1Search(this.searchTerm)
        }
      });
    }
    catch (e) { }
  }

  saveUserSearch(userSearch: string) {
    this.handleListing.saveUserSearch(userSearch, this.currentUserId)
  }

  priority1Search(searchTerm: string) {
    try {
      // compare search term against title , category , product and variety of all listings 
      const titleSearches = this.loadedListings.filter(listings => { return listings.title?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) })
      const categorySearches = this.loadedListings.filter(listings => { return listings.categoryName?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) })
      const productSearches = this.loadedListings.filter(listings => { return listings.productName?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) })
      const varietySearches = this.loadedListings.filter(listings => { return listings.varietyName?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) })
      // club all the searches of matching listing together
      const clubbedSearches: ListingModel[] = [].concat.apply([], [titleSearches, categorySearches, productSearches, varietySearches])
      //filterout unique ones
      let key = '_id'
      this.filteredListingsForSearch = [...new Map(clubbedSearches.map(listing => [listing[key], listing])).values()]
      // if listings are matched on bases of title -> find all categories, products and varities
      if (this.filteredListingsForSearch && this.filteredListingsForSearch.length > 0) {
        this.noResults = ''
        // if user wants to search using their own keywords - gather all listings with matching title and share as subject on showLising
        this.searchService.updateSearchListings(this.filteredListingsForSearch)
        const listingCategories = this.filteredListingsForSearch.map(items =>
          items.categoryName
        )
        const listingProducts = this.filteredListingsForSearch.map(items =>
          items.productName
        )
        // and replace _ with " " on variety name. On click of this mat-card we set back variety_name we will reverse " " to _
        const listingVarieties = this.filteredListingsForSearch.map(items =>
          items.varietyName.replace(/_/g, " ")
        )
        this.allCategories = [...new Set(listingCategories)]
        this.allProducts = [...new Set(listingProducts)]
        // only keep unique names
        this.allVarieties = [...new Set(listingVarieties)]
      }
      // if listings are not found on bases of title -> do priority2Search
      else if (!this.filteredListingsForSearch.length) {
        this.noResults = 'No results'
      }
    }
    catch (e) {
      throw e
    }
  }
}