import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogPostModel } from 'src/app/datamodels/blogPostModel';
import { CommentpostService } from 'src/app/services/commentpost.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-read-blogs',
  templateUrl: './read-blogs.component.html',
  styleUrls: ['./read-blogs.component.css']
})
export class ReadBlogsComponent implements OnInit {
  allBlogs: BlogPostModel[];
  isBlogsLoading: boolean = true
  mainBlog: BlogPostModel;
  useblogTitle: any;
  readThisBlog: any
  getRandomBlogs: BlogPostModel[];
  twoRandomMainBLogs: BlogPostModel[];
  otherRandomTrendBLogs: BlogPostModel[];
  shuffledBlogsRandom: BlogPostModel[];
  lastThreeBlogs: BlogPostModel[];
  useHref: string;
  blogLoading: boolean
  constructor(private hblogs: CommentpostService,
    private route: ActivatedRoute,
    private utils: UtilsService,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    try {
      this.route.params.subscribe(params => {
        this.blogLoading = true
        if (params && params.blogTitle) {
          const check = this.route.snapshot.queryParams
          // this.getRandomBlogs = this.route.snapshot.queryParamMap.get('randomBlogs');
          this.useblogTitle = params.blogTitle;
          this.getSingleBlog(this.useblogTitle)
        }
      })
    }
    catch (e) {
      throw e
    }
  }
  getSingleBlog(blogTitle: string) {
    try {
      this.hblogs.getSingleBlog(blogTitle).then(checkMainbLog => {
        if (checkMainbLog && checkMainbLog.status == 200) {
          this.readThisBlog = checkMainbLog.singleBlogfetched
          this.blogLoading = false
          this.getAllBlogs()
        }
      })
    }
    catch (e) {
      throw e
    }
  }

  getAllBlogs() {
    try {
      this.hblogs.getAllBlogs().then(allBlogs => {
        if (allBlogs && allBlogs.status == 200) {
          this.allBlogs = allBlogs.blogsfetched
          this.shuffledBlogsRandom = this.utils.getRandomBlogs(this.allBlogs)
          this.twoRandomMainBLogs = this.shuffledBlogsRandom.slice(0, 2)
          this.otherRandomTrendBLogs = this.shuffledBlogsRandom.slice(2, this.shuffledBlogsRandom.length)
          this.lastThreeBlogs = this.shuffledBlogsRandom.slice(this.shuffledBlogsRandom.length - 3, this.shuffledBlogsRandom.length)
          this.mainBlog = this.allBlogs[1]
        }
      })
    }
    catch (e) {
      throw e
    }

  }

  createHrefForBlog(blogTitle: string) {
    const pieceLink = `Blogs/Published/Article/${blogTitle}`
    this.useHref = pieceLink
    this.router.navigate([pieceLink])
  }
}
