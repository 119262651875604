import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserAuthModel } from '../datamodels/userModel';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Review } from '../datamodels/productreview';
import { LoginResponse } from '../datamodels/loginResponse';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersCartItem } from '../datamodels/cartModel';
import { ItemListingsInCart } from '../datamodels/itemListingsinCartModel';
import { ItemDetails } from '../datamodels/groupedmodel/itemDetails';
import { SettingsService } from '../services/settings.service';
interface GenericUserInterface {
  userId: string,
  userPincode: string,
  userCity: string,
  userState: string
}
interface userCurrentLocation {
  userPincode: string,
  userCity: string,
  userState: string
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _shoppingCart$ = new BehaviorSubject<any>({});
  userCart$ = this._shoppingCart$.asObservable();
  private token: string;
  private isAuthenticated = false;
  private tokenTimer: any;
  private loginUserInfo: any
  private genericUserInfo: any
  private authStatusListener = new Subject<boolean>(); //use this subject to push auth information into components that are interested and listening
  expirationDate: Date;
  private cartOfLoginUser: any;
  userCart: UsersCartItem;
  Checkthis = [];
  private domain: string | undefined
  extendedCart: { _id: string; active: boolean; currentUser: string; itemListings: any[]; };
  currentLocationAvailable: userCurrentLocation;
  constructor(private http: HttpClient, private router: Router,
    private _snackBar: MatSnackBar,
    private settingsService: SettingsService
  ) {
    const rawUserCart = localStorage.getItem("cachedCart")
    const userCart = JSON.parse(rawUserCart)
    this._shoppingCart$.next(userCart)
    this.domain = settingsService.cherryURL
  }

  createUser(
    accounttype: string,
    accountSubtypeList: string[],
    fullName: string,
    email: string,
    phone: string,
    password: string,
    // referral: string,
    // isRegistered: boolean,
    //COMPANY DETAILS
    companyName: string,
    pinCode: number,
    state: string,
    city: string,
    // addressLocality: string,
    // addressBuildingNameAndFloor: string,
    // landmark: string,

    //account ID details
    //license or MCARegistered 
    // incorporationType: string;
    // licenseId: string;
    isGSTAvailable: boolean,
    isGSTVerified: boolean,
    // isPANAvailable: boolean,
    // isPANVerified: boolean,
    GST_Id: string,
    // PAN_Id: string,
    // isAccountPremium: boolean,
    // accountRating: number,
    // Aadhaar_Id: string,
    // isAadhaarAvailable: boolean,
  ) {
    const authregisterData: UserAuthModel =
    {
      _id: null,
      accounttype: accounttype,
      accountSubtype: accountSubtypeList,
      firstname: fullName,
      lastname: null,
      email: email,
      phone: phone,
      password: password,
      profileAppCode: null,
      isRegistered: true,
      companyName: companyName,
      pinCode: pinCode,
      state: state,
      city: city,
      addressLocality: null,
      addressBuildingNameAndFloor: null,
      landmark: null,
      isGSTAvailable: isGSTAvailable,
      isGSTVerified: isGSTVerified,
      GST_Id: GST_Id,
      isAccountPremium: false,
      accountRating: 2,
      logoImage: null,
      shopImage: null,
      // Aadhaar_Id: Aadhaar_Id,
      // isAadhaarAvailable: isAadhaarAvailable,
    }
    const userAuthFormData = new FormData()
    userAuthFormData.append('_id', authregisterData._id)
    userAuthFormData.append('accounttype', authregisterData.accounttype)
    userAuthFormData.append('accountSubtype', JSON.stringify(authregisterData.accountSubtype))
    userAuthFormData.append('firstname', authregisterData.firstname)
    // userAuthFormData.append('lastname', authregisterData.lastname)
    userAuthFormData.append('email', authregisterData.email)
    userAuthFormData.append('phone', authregisterData.phone)
    userAuthFormData.append('password', authregisterData.password)
    // userAuthFormData.append('profileAppCode', authregisterData.profileAppCode)
    // userAuthFormData.append('isRegistered', JSON.stringify(authregisterData.isRegistered))
    userAuthFormData.append('companyName', authregisterData.companyName)
    userAuthFormData.append('pinCode', JSON.stringify(authregisterData.pinCode))
    userAuthFormData.append('state', authregisterData.state)
    userAuthFormData.append('city', authregisterData.city)
    // userAuthFormData.append('addressLocality', authregisterData.addressLocality)
    // userAuthFormData.append('addressBuildingNameAndFloor', authregisterData.addressBuildingNameAndFloor)
    // userAuthFormData.append('landmark', authregisterData.landmark)
    userAuthFormData.append('isGSTAvailable', JSON.stringify(authregisterData.isGSTAvailable))
    userAuthFormData.append('isGSTVerified', JSON.stringify(authregisterData.isGSTVerified))
    // userAuthFormData.append('isPANAvailable', JSON.stringify(authregisterData.isPANAvailable))
    // userAuthFormData.append('isPANVerified', JSON.stringify(authregisterData.isPANVerified))
    userAuthFormData.append('GST_Id', authregisterData.GST_Id)
    // userAuthFormData.append('PAN_Id', authregisterData.PAN_Id)
    // userAuthFormData.append('isAccountPremium', JSON.stringify(authregisterData.isAccountPremium))
    // userAuthFormData.append('accountRating', JSON.stringify(authregisterData.accountRating))
    // userAuthFormData.append('logoImage', authregisterData.logoImage)
    // userAuthFormData.append('shopImage', authregisterData.shopImage)

    return this.http.post<{ message: string }>(`${this.domain}api/user/signup`, userAuthFormData)
  }
  async checkVerifiedIDUsingPhone(phone: string): Promise<{ status: number; activatedID: string }> {
    const queryParams = `?phone=${phone}`
    const findIfVerified = this.http.get<{ status: number; activatedID: string }>(`${this.domain}api/user/findIfNumberVerf` + queryParams).toPromise()
    return findIfVerified
  }
  loginUser(phone: string, email: string, password: string, verifyOTP: string) {
    const authloginData: any = {
      phone: phone, email: email, password: password, verifyOTP: verifyOTP
    }
    //post<{token:string}> makes the api aware that a token is available in response (we configured the api so we know)
    this.http.post<{
      token: string, expiresIn: number, userId: string
      , firstname: string, lastname: string, email: string, phone: string, accounttype: string,
      accountSubtype: string, isRegistered: boolean, companyName: string, pinCode: string, state: string,
      city: string, addressLocality: string, addressBuildingNameAndFloor: string, landmark: string,
      isGSTAvailable: boolean,
      GST_Id: string, isGSTVerified: boolean, firstTime: Date, inTime: Date, outTime: Date, sessionNumber: number,
      isAccountPremium: boolean,
      accountRating: number
      logoImage: string,
      shopImage: string,
      profileAppCode: string
    }>(`${this.domain}api/user/login`,
      authloginData).subscribe(response => {
        const token = response.token
        this.token = token
        if (token) {
          const now = new Date();
          const expiresInDuration = response.expiresIn
          this.setAuthTimer(expiresInDuration);
          this.isAuthenticated = true
          //after successful login userinfo is extracted
          this.loginUserInfo = {
            profileAppCode: response.profileAppCode,
            userId: response.userId,
            firstname: response.firstname,
            lastname: response.lastname,
            // phone: response.phone,
            email: response.email,
            phone: response.phone,
            accounttype: response.accounttype,
            accountSubtype: response.accountSubtype,
            isRegistered: response.isRegistered,
            companyName: response.companyName,
            pinCode: response.pinCode,
            state: response.state,
            city: response.city,
            addressLocality: response.addressLocality,
            addressBuildingNameAndFloor: response.addressBuildingNameAndFloor,
            landmark: response.landmark,
            GST_Id: response.GST_Id,
            isGSTVerified: response.isGSTVerified,
            isGSTAvailable: response.isGSTAvailable,
            expiration: response.expiresIn,
            logoImage: response.logoImage,
            shopImage: response.shopImage,
            firstTime: response.firstTime,
            inTime: response.inTime,
            outTime: null,
            sessionNumber: response.sessionNumber,
            isAccountPremium: response.isAccountPremium,
            accountRating: response.accountRating,
          }
          this.authStatusListener.next(true);
          const expirationDateStruc = new Date(now.getTime() + expiresInDuration * 1000);
          const currentUserId = response.userId
          this.getUserCart(currentUserId).then(
            async res => {
              this.userCart = await res.cart
              localStorage.setItem('userCart', JSON.stringify(this.userCart));
              if (this.userCart && this.userCart.itemListings && this.userCart.itemListings.length !== 0) {
                this.createCacheCart(this.userCart)
              }
            }
          )
          // this._shoppingCart$.next(this.userCart)
          this.saveAuthData(token, expirationDateStruc, this.loginUserInfo)
          localStorage.removeItem('genericUserInfo');
          this.router.navigate(['/']);
        }
      }, error => {
        this.loginError(error.error.message, "Retry")
        this.authStatusListener.next(false);
      })
  }


  //every time a product is added in cart - api gets updated but so does cache-cart 
  createCacheCart(userCart: any) {
    //create a cache cart with item as embedded object in localstorage along with newly added listing attributes
    const allListingIds = [...new Set(userCart.itemListings.map((items: ItemDetails) => items.listingId))]
    if (allListingIds.length > 0) {
      this.getCartListings(allListingIds).subscribe(
        res => {
          this.Checkthis = []
          const currentItemDate = new Date()
          res.cart.forEach((item: any) => {
            for (let k = 0; k < userCart.itemListings.length; k++) {
              if (item._id === userCart.itemListings[k].listingId) {
                // group items in cart //groupcartitems by sellerId - sellerName etc //
                const creditTerms = {
                  'creditTermDays': userCart.itemListings[k].creditTermDays,
                  'creditTermPercentage': userCart.itemListings[k].creditTermPercentage,
                  'creditTermStatement': userCart.itemListings[k].creditTermStatement,
                }
                const packagingType = {
                  'packagingType': userCart.itemListings[k].packagingTypeUse,
                  'packagingTypeValue': userCart.itemListings[k].packagingTypeValue,
                  'packagingImg': userCart.itemListings[k].packagingImg,
                }
                const newItem: ItemListingsInCart = {
                  '_id': userCart.itemListings[k]._id,
                  'type': userCart.itemListings[k].type,
                  'sellerId': item.listingCreatorID,
                  'sellerName': item.listingCreator,
                  'sellerCompanyName': item.companyName,
                  'listingId': item._id,
                  'currentListing': item,
                  'creditTerms': creditTerms,
                  'packagingType': packagingType,
                  'quantityAdded': userCart.itemListings[k].quantityAdded,
                  'totalAmount': userCart.itemListings[k].quantityAdded * item.bulkPrice,
                  'addedDate': currentItemDate
                }
                this.Checkthis.push(newItem)
              }
            }
          })
          // add newItems created to this extendercart- this is the cart sent to DB that remembers users added items
          this.extendedCart = {
            '_id': userCart._id,
            'active': userCart.active,
            'currentUser': userCart.currentUser,
            'itemListings': this.Checkthis
          }
          localStorage.setItem('cachedCart', JSON.stringify(this.extendedCart))
          this._shoppingCart$.next(this.extendedCart)
          return this.Checkthis
        }
      )
    }
    else {
      this.Checkthis = []
      this.extendedCart = {
        '_id': userCart._id,
        'active': userCart.active,
        'currentUser': userCart.currentUser,
        'itemListings': this.Checkthis
      }
      localStorage.setItem('cachedCart', JSON.stringify(this.extendedCart))
      this._shoppingCart$.next(this.extendedCart)
    }

  }

  //update user details api
  async updateUserDetails(
    userId: string,
    firstname: string,
    phone: string,
    companyName: string,
    pinCode: number,
    state: string,
    city: string,
    addressLocality: string,
    addressBuildingNameAndFloor: string,
    landmark: string,
    isGSTAvailable: boolean,
    GST_Id: string,
    isGSTVerified: boolean,
    logoImage: string,
    shopImage: string
  ): Promise<{ message: string; status: number }> {
    const updateUserData = new FormData();
    updateUserData.append("_id", userId);
    updateUserData.append("firstname", firstname);
    updateUserData.append("phone", phone);
    updateUserData.append("companyName", companyName);
    updateUserData.append("pinCode", pinCode.toString());
    updateUserData.append("state", state);
    updateUserData.append("city", city);
    updateUserData.append("addressLocality", addressLocality);
    updateUserData.append("addressBuildingNameAndFloor", addressBuildingNameAndFloor);
    updateUserData.append("landmark", landmark);
    updateUserData.append("isGSTAvailable", isGSTAvailable.toString());
    updateUserData.append("GST_Id", GST_Id);
    updateUserData.append("isGSTVerified", isGSTVerified.toString());
    updateUserData.append("logoImage", logoImage);
    updateUserData.append("shopImage", shopImage);
    const updatedProfile = await this.http.put<{ message: string, status: number }>(`${this.domain}api/user/updateInformation/` + userId, updateUserData).toPromise()
    return updatedProfile
  }



  //get items of carts
  getCartListings(listingIds): Observable<any> {
    const itemData = new FormData();
    if (listingIds && listingIds.length >= 1) {
      listingIds.forEach(listingId => {
        itemData.append("listingIds[]", listingId)
      })
    }
    else {
      itemData.append("listingIds", listingIds)
    }
    return this.http.post<any>(`${this.domain}api/cartitems/itemsForCart/`, itemData)
  }

  //get user specific cart
  async getUserCart(userId: string): Promise<any> {
    const cartLoad = await this.http.get<{
      message: string,
      cart: UsersCartItem
    }>(`${this.domain}api/cartitems/getUserCartItems/` + userId).toPromise();
    return cartLoad
  }


  // login error notific
  loginError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }

  // Clear items on logout
  async logoutUser() {
    //remove the token
    const logoutUser = await this.http.get<{ status: number }>(`${this.domain}api/user/logoutUpdateSession/`).toPromise()
    if (logoutUser) {
      this.token = null;
      //change auth status
      this.isAuthenticated = false;
      //authstatus ready to be passed into components
      this.authStatusListener.next(false);
      //clear timer
      clearTimeout(this.tokenTimer);
      //set loginuserinfo to null when logout is successful
      this.loginUserInfo = null;
      // this.loginUserInfo = null;
      //clear auth data from local storage
      this.clearAuthData();
      //logout to a route
      this.router.navigate(['/'])

    }

  }
  async getAccountDetails(userId: string): Promise<any> {
    const accountInfo = await this.http.get<{
      status: number; message: string; result: {
        userId: string,
        firstname: string,
        lastname: string,
        email: string,
        phone: string,
        accounttype: string,
        accountSubtype: string,
        isRegistered: boolean,
        companyName: string,
        pinCode: string,
        state: string,
        city: string,
        addressLocality: string,
        addressBuildingNameAndFloor: string,
        landmark: string,
        isGSTAvailable: boolean,
        GST_Id: string,
        isGSTVerified: boolean,
        isAccountPremium: boolean,
        accountRating: number,
        shopImage: string,
        logoImage: string,
      }
    }>(`${this.domain}api/user/getUserInfo/` + userId).toPromise()
    return accountInfo
  }
  getUserLoginInfo() {
    //used when login()
    return this.loginUserInfo
  }
  getGenericUserInfo() {
    this.genericUserInfo = JSON.parse(localStorage.getItem('genericUserInfo'))
    return this.genericUserInfo
  }
  getIsAuth() {
    return this.isAuthenticated;
  }
  getToken() {
    //since token is private - we need to call this to access this token variable
    return this.token
  }
  getAuthStatusListener() {
    //need not extract token again and again, once extracted the boolean variable of subject will determine either the user is logged in or not
    return this.authStatusListener.asObservable(); //need not emit values from other components, just required to emit form this service so used asObservable
  }
  autoAuthUser() {
    const authInformationofLocalStorage = this.getAuthData();
    // if no local storage data :
    if (!authInformationofLocalStorage) {
      return;
    }
    const now = new Date();
    const authSessionDifference = authInformationofLocalStorage.expirationDate.getTime() - now.getTime();
    //We cant tell while getting the auth data from local storage if it is a valid token or not, 
    //since that can only be done from server when it regenerates a new token after session end, 
    //but we can obviously tell if token has expired for a future date or not
    //Check current date with stored expiration date and get the difference to check how long the user can still auto login until the date or time of expiry
    //authSessionDifference>0 means date is in future, and authsessiondifference also gives us the remaining time difference
    if (authInformationofLocalStorage.token && authSessionDifference > 0) {
      //means user is still authenticated , now we also need to set timer
      this.token = authInformationofLocalStorage.token;
      this.isAuthenticated = true;
      //if token is valid, initialize LoginUserObject with authinformationlocalstorage LogingUserObject
      //this part is dicey - should be usong this.loginUserInfo instead of this.storedLoginInfo
      this.loginUserInfo = authInformationofLocalStorage.loginUserInfo

      this.cartOfLoginUser = authInformationofLocalStorage.userCart
      //since authtimer works with Seconds and authsessionDifference is coming in Miliseconds, we divide by 1000
      this.setAuthTimer(authSessionDifference / 1000)
      this.authStatusListener.next(true);
    }
    if (!authInformationofLocalStorage.token) {
      this.isAuthenticated = false;
      this.loginUserInfo = authInformationofLocalStorage.genericUserInfo
      //since authtimer works with Seconds and authsessionDifference is coming in Miliseconds, we divide by 1000
      this.setAuthTimer(authSessionDifference / 1000)
      this.authStatusListener.next(true);
    }
  }
  private setAuthTimer(duration: number) {
    //setTimeout gives nodejs timer but it gives in ms so we multiple it by 1000 to get seconds : 3600 * 1000 is 1 hour
    this.tokenTimer = setTimeout(() => {
      this.logoutUser();
    }, duration * 1000)
  }
  //save token and expiration date in local storage
  private saveAuthData(token: string, expirationDate: Date, loginUserInfo: LoginResponse) {
    localStorage.setItem('token', token);
    localStorage.setItem('expiration', expirationDate.toISOString());
    //since we are saving login token and date in local storage for autoauth- we also must save Userinfo for autoauth
    localStorage.setItem('loginUserInfo', JSON.stringify(loginUserInfo));
  }
  //clear token and expiration date and loginuserinfo from local storage after TTL session expires (time to live)
  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('loginUserInfo');
    localStorage.removeItem('userCart')
    localStorage.removeItem('cachedCart')
  }

  private getAuthData() {
    try {
      //instead of token or expirationDate use the name what is being saved in localstorage while login using method LocalStorage.setitem('name',value)
      const token = localStorage.getItem("token");
      const expirationDate = localStorage.getItem("expiration")
      //authenticated user - after signup and login info
      const rawLoginInfo = localStorage.getItem("loginUserInfo")
      const loginUserInfo = JSON.parse(rawLoginInfo)

      //generic user - no signup and no login info
      const rawgenericInfo = localStorage.getItem("genericUserInfo")
      const genericUserInfo = JSON.parse(rawgenericInfo)

      //sessionDetailsForUse
      //cart items for the user 
      // Initialize shopping cart with default value
      const rawUserCart = localStorage.getItem("userCart")
      const userCart = JSON.parse(rawUserCart)
      if (!token || !expirationDate) {
        // set expiration date for generic session
        const daystoExpiry = 1;
        const now = new Date();
        this.expirationDate = new Date(now.setDate(now.getDate() + daystoExpiry))
        localStorage.setItem('expiration', this.expirationDate.toString());
        if (genericUserInfo) {
          this.createGenericSession(genericUserInfo)
          return {
            genericUserInfo: genericUserInfo,
            expirationDate: this.expirationDate,
          }
        }
        //generate random user Id
        const dateStr = Date.now().toString(36)
        // start at index 2 to skip decimal point
        const randomStr = Math.random().toString(36).substring(2, 8)
        const genericuserId = `generic-${dateStr}-${randomStr}`;
        const genericUserSession: GenericUserInterface = {
          userId: genericuserId,
          userPincode: null,
          userCity: null,
          userState: null
          // sessionExpiration: expirationDateStruc
        }

        localStorage.setItem('genericUserInfo', JSON.stringify(genericUserSession));
        this.createGenericSession(genericUserSession)

        // this.router.navigate(['/']);
        return {
          genericUserInfo: genericUserSession,
          expirationDate: new Date(expirationDate),
        }
      }
      // going into else here - login info exists
      localStorage.removeItem('genericUserInfo');
      localStorage.removeItem('sessionUserInfo');

      return {
        token: token,
        //take date from local storage and get serialized date using new Date constructor to display
        expirationDate: new Date(expirationDate),
        //get loginuserinfo for autoauthuser
        loginUserInfo: loginUserInfo,
        userCart: userCart
      }
    }
    catch (e) { }

  }

  isCurrentLocationAvailable() {
    try {
      const currentLocation = this.getCurrentLocation()
      if (!currentLocation || currentLocation === undefined) {
        return false
      }
      return true
    }

    catch (e) {
      throw e
    }
  }

  setCurrentLocation(pinc: string, district: string, state: string) {
    const currentUserLocation: userCurrentLocation = {
      'userPincode': pinc,
      'userCity': district,
      'userState': state,
    }
    localStorage.setItem('currentUserLocation', JSON.stringify(currentUserLocation))
    const rawgenericInfo = localStorage.getItem("genericUserInfo")
    let genericUserInfo = JSON.parse(rawgenericInfo)
    genericUserInfo.userPincode = currentUserLocation.userPincode
    genericUserInfo.userCity = currentUserLocation.userCity
    genericUserInfo.userState = currentUserLocation.userState
    localStorage.setItem('genericUserInfo', JSON.stringify(genericUserInfo));
    this.createGenericSession(genericUserInfo)
  }

  getCurrentLocation() {
    const currentLocation = localStorage.getItem("currentUserLocation");
    const userCurrentLocation = JSON.parse(currentLocation)
    this.currentLocationAvailable = userCurrentLocation
    return this.currentLocationAvailable
  }

  async createGenericSession(genericUserSession: GenericUserInterface): Promise<{ status: number }> {
    const genericUserForm = new FormData()
    genericUserForm.append('userID', genericUserSession.userId)
    genericUserForm.append('userPincode', genericUserSession.userPincode,)
    genericUserForm.append('userCity', genericUserSession.userCity)
    genericUserForm.append('userState', genericUserSession.userState)
    const genericUserSes = await this.http.post<{ status: number }>(`${this.domain}api/user/recordGenericSession/`, genericUserForm).toPromise()
    return genericUserSes
  }


  forgotPassword(email: string, phone: number): Observable<{ status: number; userId: string }> {
    const userEmailCheck: any = {
      email: email,
      phone: phone
    }
    //post<{token:string}> makes the api aware that a token is available in response (we configured the api so we know)
    return this.http.post<{ status: number, userId: string }>(`${this.domain}api/user/forgotPassword`,
      userEmailCheck)
  }


  resetPasswordwithOTP(userId: string, resetpasswordOTP: number, password: string): Promise<any> {
    const resetPassword: any = {
      password: password
    }

    return this.http.put<any>(`${this.domain}api/user/resetPassword/${userId}/${resetpasswordOTP}`, resetPassword).toPromise()
  }
  addLogoImageforUserProfile(ImagePicked: File): Observable<any> {
    const imageData = new FormData()
    imageData.append('Photos', ImagePicked)
    return this.http.post<any>(`${this.domain}api/posts/uploadReviewImage/`, imageData)
  }

}
