import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class VaayuputraService {
  private domain: string | undefined

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }

  async deliveryInvo(vaayuName: string, phone: number, otpVaayu: number, invoId: string): Promise<{ message: string; status: number }> {
    const vaayuForm = new FormData()
    vaayuForm.append('vaayuName', vaayuName)
    vaayuForm.append('phone', JSON.stringify(phone))
    vaayuForm.append('otpVaayu', JSON.stringify(otpVaayu))
    vaayuForm.append('invoId', invoId)
    const deliveredInvo = this.http.put<{ message: string, status: number }>(`${this.domain}api/invoiceOrderRequest/deliverVaayuInvo/`, vaayuForm).toPromise()
    return deliveredInvo
  }

  async deleteUserRequest(requestDeleteForm: any): Promise<{ message: string; status: number }> {
    const deleteUserForm = new FormData()
    deleteUserForm.append('accountType', requestDeleteForm.accountType)
    deleteUserForm.append('fullName', requestDeleteForm.fullName)
    deleteUserForm.append('companyName', requestDeleteForm.companyName)
    deleteUserForm.append('phone', requestDeleteForm.phone)
    deleteUserForm.append('reasonWhyDelete', requestDeleteForm.reasonWhyDelete)
    const requestReceived = this.http.post<{ message: string, status: number }>(`${this.domain}api/user/deleteServices`, deleteUserForm).toPromise()
    return requestReceived
  }
}
