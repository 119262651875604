import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductCategoryModel } from 'src/app/datamodels/productcategory';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { StateModel } from 'src/app/datamodels/Statemodel';
import { AllstatesService } from 'src/app/services/allstates.service';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {

  //matchip
  value: string;
  SearchName: any;
  categoryName: any;
  listofStatesAndCategory: Array<{ CategoryID: any[], StateID: any[] }> = [];
  @ViewChild('stateInput') stateInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  filercategories: ProductCategoryModel[]
  StateList: StateModel[] = []
  CategoryList: ProductCategoryModel[] = []
  selectedCategoriesCheck: string[]; //hold check box values for category
  selectedStatesCheck: string[]; //hold check box values for State
  SplitCategoryAndStatearray = []
  SearchCatArray: any[] = []
  SearchStatArray: any[] = []
  allCompleteCategories: boolean = false;
  allCompleteStates: boolean = false;
  filterstates: StateModel[];
  FilterPacket: any[] = []
  constructor(@Inject(MAT_DIALOG_DATA) public editCatData: any,
    public allStatesService: AllstatesService,
    public dialogRef: MatDialogRef<FilterComponent>) {
    this.selectedCategoriesCheck = new Array<string>();
    this.selectedStatesCheck = new Array<string>();
  }
  ngOnInit(): void {
    this.FilterPacket = this.editCatData.data
    this.CategoryList = this.FilterPacket[0]
    this.StateList = this.FilterPacket[1]
    // this.LoadProductCategories();
    // this.LoadAllStates();
  }
  myvalue() {
    this.SearchName = ""
    this.ngOnInit();
  }
  SearchTerm() {
    if (this.SearchName == "") {
      this.ngOnInit();
    }
    else {
      {
        this.SearchCatArray = this.SearchCatArray.filter(rescatSearch => {
          return rescatSearch.name.toLocaleLowerCase().match(this.SearchName.toLocaleLowerCase())
        })
      }
      {
        this.SearchStatArray = this.SearchStatArray.filter(resstatSearch => {
          return resstatSearch.name.toLocaleLowerCase().match(this.SearchName.toLocaleLowerCase())
        })
      }
    }
  }
  onReset() {
    this.setAllCategories(false);
    this.StateList.forEach(h => h.selected = false);
  }
  updateAllCompleteCategories() {
    this.allCompleteCategories = this.CategoryList != null && this.CategoryList.every(t => t.selected);
  }
  updateAllCompleteStates() {
    this.allCompleteStates = this.StateList != null && this.StateList.every(h => h.selected);
  }
  someCompleteCategories(): boolean {
    if (this.CategoryList == null) {
      return false;
    }
    return this.CategoryList.filter(t => t.selected).length > 0 && !this.allCompleteCategories;
  }
  someCompleteStates(): boolean {
    if (this.StateList == null) {
      return false;
    }
    return this.StateList.filter(h => h.selected).length > 0 && !this.allCompleteStates;
  }
  setAllCategories(selected: boolean) {
    this.allCompleteCategories = selected;
    if (this.CategoryList == null) {
      return;
    }
    this.CategoryList.forEach(t => t.selected = selected);
  }
  setAllStates(selected: boolean) {
    this.StateList.forEach(h => h.selected = !h.selected);
  }
  onApplyFilter() {
    this.listofStatesAndCategory.push({ StateID: this.StateList, CategoryID: this.CategoryList })
    this.dialogRef.close(this.listofStatesAndCategory)
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}


