<mat-spinner *ngIf="isLoading"></mat-spinner>
<div class="content" *ngIf="!isLoading">
    <div class="bannerData">
        <div class="categoriesOverlay" *ngIf="this.productcategories">
            <div class="categories">
                <div class="product-card" *ngFor="let category of this.productcategories | slice:0:6; let i=index">
                    <div class="singleCards" [routerLink]="['/viewItems',category.categoryName]">
                        <!-- <img mat-card-image src="https://ik.imagekit.io/ikmedia/backlit.jpg"> -->
                        <div class="image_wrapper">
                            <img [src]="category.categoryImages[0]" alt="Bulk wheat rice daal order" />
                            <div class="overlay overlay_1">
                                <div class="categoryName">
                                    <p>
                                        {{category.categoryName
                                        }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="viewCategorybutton">
                <button class="viewall" mat-raised-button routerLink="/viewItems/All">View All Categories</button>
            </div> -->
        </div>

        <div class="image">
            <!-- <img [src]=" this.currentListingwithId.imageUrls[0]" alt=""> -->
            <div class="img-slider">
                <div class="slide">
                    <mat-carousel class="mat-carousel" timings="300ms ease-in" [autoplay]="true" interval="4000"
                        color="accent" [loop]="true" maxWidth="auto" [hideIndicators]="true" [hideArrows]="true"
                        [useKeyboard]="true" [maintainAspectRatio]="false" orientation="ltr">
                        <mat-carousel-slide *ngFor="let slide of this.appBannersallproducthomegrid; let i = index"
                            [image]="slide" overlayColor="rgba(0, 0, 0, 0.1)" [hideOverlay]="true"></mat-carousel-slide>
                    </mat-carousel>
                </div>
            </div>


        </div>
    </div>
</div>