import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { MypseoService } from './services/mypseo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'myapaar-global';
  currentRoute: string;
  hideBackButton: boolean = false
  constructor(private router: Router, private authservice: AuthService,
    private mypSEO: MypseoService) {
  }

  ngOnInit() {
    this.mypSEO.getFAQsSetMeta()
    // this.mypSEO.getAllPostedFAQs()
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        if (event.url === '/') {
          this.hideBackButton = true
        }
      }
    });
    this.authservice.autoAuthUser();
  }
 

}
