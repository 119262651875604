import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-statefilter-tab',
  templateUrl: './statefilter-tab.component.html',
  styleUrls: ['./statefilter-tab.component.css']
})
export class StatefilterTabComponent implements OnInit {
  index: number;
  constructor() { }

  ngOnInit(): void {
        
  }
}