import { Component, OnInit, Input } from '@angular/core';
import { ProductCategoryModel } from '../../datamodels/productcategory';
// import { PRODUCTCATEGORIES } from '../../datamodels/productcategories';
import { ProductcategoryService } from '../../services/productcategory.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-categoriesproduct',
  templateUrl: './categoriesproduct.component.html',
  styleUrls: ['./categoriesproduct.component.css']
})
export class CategoriesproductComponent implements OnInit {
  @Input() appBannersallproducthomegrid: string[];

  isLoading = false; //spinner initialized
  productcategories: ProductCategoryModel[] = [];

  Holdcategory: ProductCategoryModel[] = [];
  constructor(public productcategoryService: ProductcategoryService,
    private router: Router) { }

  ngOnInit(): void {
    this.loadCategories();
  }
  loadCategories() {
    this.isLoading = true; //spinner starts
    this.productcategoryService.getCategories().subscribe((res) => {
      this.isLoading = false; //spinner stops

      this.productcategories = res
      //removeBelow and replace with above liner
    })
  }
}
